import css from 'styled-jsx/css';
import { colors, icons } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

export const InternationalShippingStyle = css`
  .international_icon_wrapper {
    display: flex;
    width: 100%;
    margin-bottom: 16px;
    margin-top: 6px;
  }
  .aeroplane-icon {
    background-image: url('${svgToDataUri(icons.aeroplaneRebranded)}');
    background-repeat: no-repeat;
    height: 32px;
    width: 32px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }
  .international-text {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    .more-info {
      border-bottom: 1px solid ${colors.gray.shade15};
      margin-left: 5px;
      cursor: pointer;
      font-family: inherit;
      color: ${colors.gray.shade15};
    }
    .csicon-right {
      color: ${colors.gray.shade15};
      width: 25px;
      height: 25px;
      font-size: 10px;
      margin-left: 6px;
    }
  }
`;
