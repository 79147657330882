import PropTypes from 'prop-types';
import React from 'react';
import _ from '../../utils/LodashImports';
import SpecificationTable from '../SpecificationTable/SpecificationTable';
import { ComboSpecificationsStyles } from './ComboSpecifications.style';

const ComboSpecifications = ({ comboItems, isDrawer }) => {
  return (
    <div className={`combo-specifications-wrapper ${isDrawer ? 'is-drawer' : ''}`}>
      {comboItems.map((item) => {
        const specifications = _.get(item, 'product.attributes.specifications', null);
        const displayName = _.get(item, 'product.displayName', null);
        return (
          specifications?.length > 0 && (
            <div className="combo-item-specifications" key={displayName} id={displayName}>
              <p className="item-name">{displayName}</p>
              <SpecificationTable specifications={specifications} />
            </div>
          )
        );
      })}
      <style jsx>{ComboSpecificationsStyles}</style>
    </div>
  );
};

ComboSpecifications.defaultProps = {
  isDrawer: false,
};

ComboSpecifications.propTypes = {
  comboItems: PropTypes.array.isRequired,
  isDrawer: PropTypes.bool,
};

export default ComboSpecifications;
