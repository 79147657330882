import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import { withProduct } from '../../../utils/ProductContext';
import Loader from '../../Loader/Loader';
import Accordion from '../../ui/Accordion/Accordion';
import { Modal } from '../../ui/Modal/Modal';
import { useInitialCMRData, useInstallments } from '../hooks';
import Styles from './CMRMobile.style';

const CMRPopup = dynamic({
  loader: () => import('../CMRPopup'),
  loading: () => <Loader />,
});

const AccordianContent = () => null;

const CMRCalculatorMobile = ({ errorMessage, product, labels, appCtx }) => {
  const [toggle, setToggle] = useState(false);
  const { regionCode } = appCtx;
  const initialCMRData = useInitialCMRData({
    regionCode,
  });

  const { deviceType } = appCtx;

  const {
    state: installmentsState,
    cache,
    defaults,
    installmentDispatch,
    fetchCMRInstallmentsHandler,
  } = useInstallments({
    error: errorMessage,
    initialData: {},
    quantity: 1,
    variantId: product.currentVariant,
    regionCode,
    labels,
  });

  const { installmentStatement, data: installmentData } = installmentsState;
  const currentInstallmentData = installmentData[cache] || installmentData[defaults];

  const toggleState = {
    visible: toggle,
    setVisible: (bool) => setToggle(bool),
  };

  const openModal = () => setToggle(true);
  const closeModal = () => setToggle(false);

  return (
    <>
      <Accordion
        items={[
          {
            title: _.get(currentInstallmentData, 'textDictionary.financeCalculatorLabelText'),
            logo: 'csicon-calculator',
            subTitle: installmentStatement,
            content: AccordianContent,
            iconOptions: {
              up: 'csicon-arrow_up_small',
              down: 'csicon-arrow_down_small',
              styles: {
                fontSize: '1rem',
                paddingRight: '9px',
              },
            },
            state: false,
            border: true,
            withOutExpansion: true,
            clickEventHandler: openModal,
            withPadding: false,
          },
        ]}
        hasCustomVisualsForItem={false}
      />
      <div className="cmr-modal">
        <Modal
          options={{
            disableOutsideClick: false,
            padding: '0px',
            overflow: 'hidden',
          }}
          withPadding={false}
          fullScreen
          toggleState={toggleState}
          content={(actions) => (
            <CMRPopup
              actions={actions}
              regionCode={regionCode}
              currentVariant={product.currentVariant}
              errorMessage={errorMessage}
              closeModal={closeModal}
              installmentsState={installmentsState}
              installmentDispatch={installmentDispatch}
              cache={cache}
              installmentData={currentInstallmentData}
              fetchCMRInstallmentsHandler={fetchCMRInstallmentsHandler}
              {...initialCMRData}
            />
          )}
          deviceType={deviceType}
        />
      </div>
      <style jsx>{Styles}</style>
    </>
  );
};

CMRCalculatorMobile.defaultProps = {
  errorMessage: '',
};

CMRCalculatorMobile.propTypes = {
  errorMessage: PropTypes.string,
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
};

export default withProduct(withApplicationContext(withLabels(CMRCalculatorMobile)));
