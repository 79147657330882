import PropTypes from 'prop-types';
import React from 'react';

import noop from '../../../utils/noop';
import { SizeButtonStyles } from './SizeButtons.style';

function SizeChartButton({
  sizeChartName,
  isSizeChartHidden,
  activateDrawer,
  displaySizeChartBtnRef,
  guideSymbol,
  labels,
  isEnhancedSizeSelectorActive,
}) {
  return (
    <>
      {sizeChartName && !isSizeChartHidden && (
        <span
          id="testId-sizeChart-table-btn"
          className="sizechart-link fa--size-chart-link"
          onKeyDown={noop}
          role="button"
          tabIndex={0}
          onClick={() => {
            activateDrawer(true);
          }}
          ref={displaySizeChartBtnRef}
        >
          {isEnhancedSizeSelectorActive ? (
            <i className="ruler-icon" />
          ) : (
            <i className={`${guideSymbol ? 'talla-pie' : 'csicon-scale'}`} />
          )}
          <span>{labels.SIZE_CHART}</span>
          {!isEnhancedSizeSelectorActive && <i className="csicon-arrow_right" />}
        </span>
      )}
      <style jsx>{SizeButtonStyles}</style>
    </>
  );
}

SizeChartButton.propTypes = {
  sizeChartName: PropTypes.string.isRequired,
  isSizeChartHidden: PropTypes.bool.isRequired,
  activateDrawer: PropTypes.func.isRequired,
  displaySizeChartBtnRef: PropTypes.object.isRequired,
  guideSymbol: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  isEnhancedSizeSelectorActive: PropTypes.bool.isRequired,
};

export default SizeChartButton;
