import { isSodimacStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React from 'react';
import Badges from '../../../../../components/Badges/Badges';
import { BrandName, ProductName, ProductShareIcon, VariantId } from '../../../../../components/PDP';
import { RatingAndReviews } from '../../../../../external_dependencies/ratingAndReviews/RatingAndReviews';
import { withApplicationContext } from '../../../../../utils/ApplicationContext';
import _ from '../../../../../utils/LodashImports';
import { withRatings } from '../../../../../utils/RatingsContext';
import { getVariant } from '../../../../../utils/variant';
import { SKUIdSeller } from '../../../../common/PDPBasicInfoShared';
import ProductSpecificationStyle from './PDPBasicInfoDesktop.style';

const PDPBasicInfoDesktop = ({ product, ratings, labels, appCtx }) => {
  const { badges = [], meatStickers = [], offerings = [] } = getVariant(product.variants, product.currentVariant);
  const reviewsClass = badges.length || ratings ? '' : 'hidden'; // Don't change this logic, in order to update reviews we need the section to render so data loads
  const rcClass = ratings ? '' : 'hidden';
  const { regionCode, store } = appCtx;
  const isSoCom = isSodimacStandalone(store);
  const isMeatStickerApplicableInPDP = _.get(appCtx, 'siteConfig.toggles.isMeatStickerApplicableInPDP', false);

  const hasStickers = isMeatStickerApplicableInPDP && meatStickers.length > 0;
  const { isOutOfStock } = product;
  const eventShippingMeatStickers = (meatStickers || []).filter((sticker) => ['event'].includes(sticker.type));

  if (isOutOfStock) {
    return (
      <div className="basic-details-Desktop rebranded out-of-stock">
        <div className="corner-details">
          <BrandName brandName={product.brandName} />
        </div>
        <div className="corner-details">
          <ProductName productName={product.name} />
        </div>
        <div className={`reviews ${reviewsClass}`}>
          <div className={`reviews-column ${rcClass}`}>
            {/* Space for Review need to present, to prevent bouncing */}
            <RatingAndReviews />
          </div>
        </div>

        <style jsx>{ProductSpecificationStyle}</style>
      </div>
    );
  }

  return (
    <div className="basic-details-Desktop rebranded">
      <div className="corner-details">
        <BrandName brandName={product.brandName} />
        <div className="variant-seller-ids">
          <VariantId variantId={product.currentVariant} labels={labels} isSoCom={isSoCom} />
          {SKUIdSeller({
            appCtx,
            offerings,
            labels,
            regionCode,
            product,
            customStyle: ProductSpecificationStyle,
          })}
        </div>
      </div>
      <div className="corner-details">
        <ProductName productName={product.name} />
        <ProductShareIcon />
      </div>
      <div className={`reviews ${reviewsClass}`}>
        <div className={`reviews-column ${rcClass}`}>
          {/* Space for Review need to present, to prevent bouncing */}
          <RatingAndReviews />
        </div>
        {meatStickers?.length > 0 && hasStickers && (
          <div className="event-sticker-wrapper">
            <Badges badgeItems={eventShippingMeatStickers} layout="PDP" isSticker={hasStickers} store={appCtx.store} />
          </div>
        )}
      </div>

      <style jsx>{ProductSpecificationStyle}</style>
    </div>
  );
};

PDPBasicInfoDesktop.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
};

export default withRatings(withApplicationContext(PDPBasicInfoDesktop));
