import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const ProductContainerStyles = css`
  .pdp-body {
    @mixin desktop {
      padding-top: 8px;
    }

    background-color: ${colors.gray.shade6};
    padding-bottom: 20px;
    .container-my-lists-alert {
      background: #fff;
    }
  }

  .productContainer {
    background-color: ${colors.white.shade1};
    @mixin desktop {
      border-radius: 12px;
    }
  }

  .additionInformation-container {
    margin-top: 30px;
    background-color: transparent;
    @mixin desktop {
      border-radius: 12px;
    }
  }

  .typeA-variants {
    margin-top: 20px;
    background-color: ${colors.white.shade1};
    padding: 0 23px 0 22px;

    @mixin desktop {
      padding: 0 40px 0 60px;
    }

    &-item {
      padding: 30px 0;
      border-bottom: 1px solid ${colors.gray.shade3};
    }
  }

  .promotionalProduct-container {
    display: flex;
    justify-content: center;
  }
  .surveybar-wrapper {
    flex-direction: row;
  }
  .fa--bazaarvoice-questions {
    background-color: ${colors.white.shade1};
  }
  .banner-styles {
    display: flex;
    justify-content: center;
  }
`;

export { ProductContainerStyles };
