import PropTypes from 'prop-types';
import React from 'react';
import noop from '../../../utils/noop';
import { arrowStyles } from './SingleChipletSelect.style';

export const ExpansionIcon = ({ setcollapseState, collapseState, collapseClass, collapseIds }) => {
  return (
    <div
      className="toggle-icon mkp"
      onClick={() => setcollapseState(!collapseState)}
      role="button"
      tabIndex={0}
      onKeyDown={noop}
    >
      <i
        className={collapseState ? collapseClass.down : collapseClass.up}
        id={collapseState ? collapseIds.open : collapseIds.close}
      />

      <style jsx>{arrowStyles}</style>
    </div>
  );
};

ExpansionIcon.propTypes = {
  setcollapseState: PropTypes.func.isRequired,
  collapseState: PropTypes.bool.isRequired,
  collapseClass: PropTypes.object.isRequired,
  collapseIds: PropTypes.object.isRequired,
};
