/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { svgToDataUri } from '../../../utils/styles/svgMixins';
import { icons, colors, zIndex, typography } from '../../../config/styles';

export const ModalHeaderStyle = css`
  .container {
    padding: 0 40px;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    h2 {
      line-height: 1;
    }
    .logo {
      box-sizing: border-box;
      margin-right: 18px;
      width: 36px;
      height: 30px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      &.deliveryLogo {
        background-image: url('${svgToDataUri(icons.deliveryIcon)}');
      }
      &.siteToStoreLogo {
        background-image: url('${svgToDataUri(icons.buyInStoreIcon)}');
      }
      &.pickupInStoreLogo {
        background-image: url('${svgToDataUri(icons.storeWithdrawalIcon)}');
      }
      i {
        line-height: 1;
      }
    }
  }
`;

export const ModalContentCss = css`
  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 458px;
    cursor: initial;
    &.hasBottomOption {
      height: calc(100vh - 75px);
      overflow-y: auto;
      @mixin tabletToDesktop {
        height: auto;
      }
    }

    .heading {
      display: flex;
      color: ${colors.black.shade7};
      font-weight: bold;
      font-size: ${typography.base};
      line-height: 1.1em;
      margin-top: 25px;
      margin-bottom: 10px;
    }

    .formContainer {
      padding: 0 35px;
      width: 100%;
      display: flex;
      flex-direction: column;
      min-height: 210px;
      @mixin tabletToDesktop {
        flex-direction: row;
        justify-content: space-between;
        min-height: 0;
      }

      &-item {
        display: flex;
        width: 100%;
        align-items: flex-start;
        flex-direction: column;
        box-sizing: border-box;
        flex: 1 1 auto;
        margin-right: 2%;

        &:last-child {
          margin-right: 0;
        }

        .dropdown {
          position: relative;
          width: 100%;
          select {
            font-family: inherit;
            color: ${colors.black.shade6};
            font-size: ${typography.base};
            line-height: 17px;
            appearance: none;
            border-radius: 3px;
            background-color: ${colors.white.shade1};
            border: solid ${colors.gray.shade5};
            border-width: 0 0 1px 0;
            max-width: 100%;
            min-width: 100%;
            outline: none;
            padding: 0 40px 10px 0;
            width: 100%;
            cursor: pointer;
            &::-ms-expand {
              display: none;
            }
          }
          i {
            pointer-events: none;
            position: absolute;
            color: ${colors.black.shade1};
            top: 25%;
            right: 0;
            line-height: 10px;

            &::before {
              color: ${colors.black.shade1};
              font-size: ${typography.xxs1};
              font-weight: 700;
            }
          }
        }
      }
    }

    .resultContainer {
      margin: 10px 0;
      width: 100%;
      .resultActive {
        margin-top: 12px;
        padding: 0 35px;
        &.hasBottomOption {
          margin-bottom: 100px;
          @mixin tabletToDesktop {
            margin-bottom: 70px;
          }
        }

        height: auto;
        @mixin tabletToDesktop {
          height: 260px;
        }

        overflow-y: auto;
        .heading {
          color: ${colors.gray.shade19};
          margin-top: 10px;
          margin-bottom: 27px;
          font-weight: normal;
        }
        &.rebranded {
          .bottomContainer-item.left {
            .icon {
              color: ${colors.gray.shade45};
            }
            .quinary {
              line-height: 1.2;
            }
          }
          .bottomContainer-item.right {
            .button.delete {
              font-size: ${typography.base};
              border-bottom: 0.5px solid ${colors.black.shade10};
              color: ${colors.black.shade10};
              line-height: 1.2;
              margin-right: 24px;
              @mixin mobileToTablet {
                margin-right: 32px;
              }
            }
            .button.delivery {
              font-size: ${typography.xla};
              font-weight: 400;
              letter-spacing: 1px;
              background-color: ${colors.gray.shade45};
              border-radius: 30px;
              text-transform: none;
              padding: 9px 23px;
              line-height: 1.2;
              &.disabled {
                background-color: ${colors.gray.shade46};
                color: ${colors.gray.shade47};
              }
            }
          }
        }
      }

      .resultInActive {
        display: flex;
        flex-flow: column;
        height: 330px;

        @mixin smallMobileOnly {
          height: calc(100vh - 400px);
        }
        @mixin phabletOnly {
          height: calc(100vh - 400px);
        }

        color: ${colors.gray.shade2};
        justify-content: center;
        text-align: center;
        align-items: center;
        div {
          flex: 0 1 auto;
          &.logo {
            height: 52px;
            width: 83px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            &.deliveryLogo {
              background-image: url('${svgToDataUri(icons.newDeliveryNotAvailableIcon)}');
            }
            &.siteToStoreLogo {
              background-image: url('${svgToDataUri(icons.buyInStoreDisabledIcon)}');
            }
            &.pickupInStoreLogo {
              background-image: url('${svgToDataUri(icons.newPickupInStoreNotAvailableIcon)}');
            }
          }
        }
        i,
        i::before,
        h2 {
          line-height: 1.2;
          padding: 10px;
          text-align: center;
          width: 285px;
          font-size: ${typography.xl3};
          color: ${colors.gray.shade2};
        }
        i,
        i::before {
          font-size: ${typography.xl11};
        }
      }
      &.rebranded {
        .bottomContainer-item.left {
          .icon {
            color: ${colors.gray.shade45};
          }
          .quinary {
            line-height: 1.2;
          }
        }
        .bottomContainer-item.right {
          .button.delete {
            font-size: ${typography.base};
            border-bottom: 0.5px solid ${colors.black.shade10};
            color: ${colors.black.shade10};
            line-height: 1.2;
            margin-right: 24px;
            @mixin mobileToTablet {
              margin-right: 32px;
            }
          }
          .button.delivery {
            font-size: ${typography.xla};
            font-weight: 400;
            letter-spacing: 1px;
            background-color: ${colors.gray.shade45};
            border-radius: 30px;
            text-transform: none;
            padding: 9px 23px;
            line-height: 1.2;
            &.disabled {
              background-color: ${colors.gray.shade46};
              color: ${colors.gray.shade47};
            }
          }
        }
      }
    }

    .bottomContainerLayout {
      width: 100%;
    }

    .right {
      float: right;
    }

    .quinary {
      font-size: 1.1rem;
      color: ${colors.black.shade7};
      font-weight: 400;
    }

    .bottomContainer {
      height: auto;
      width: 100%;
      padding: 14px 0;
      position: fixed;

      @mixin tabletToDesktop {
        position: absolute;
        padding: 20px 0;
      }

      bottom: 0;
      left: 0;
      border-radius: 3px;
      z-index: ${zIndex.availability.radioInputOverlay};
      display: flex;
      background-color: ${colors.white.shade1};
      box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
      flex-direction: column-reverse;

      @mixin tabletToDesktop {
        flex-direction: row;
      }

      &-item {
        width: 100%;
        justify-content: center;
        align-self: center;
        flex-direction: column;
        @mixin tabletToDesktop {
          flex-direction: row;
          width: 50%;
        }
        &.left {
          display: flex;
          align-items: center;
          flex-direction: row;
          font-size: ${typography.sm1};
          color: ${colors.black.shade7};
          margin: 0 20px;
          @mixin desktop {
            display: flex;
            justify-content: flex-start;
            align-items: center;
          }

          .icon {
            display: inline-flex;
            margin: 5px 10px 5px 5px;
            color: ${colors.green.shade1};
            height: 15px;
            width: 15px;
            font-size: ${typography.lg};
            line-height: 1;
          }
        }
        &.right {
          display: flex;
          text-align: center;
          flex-direction: row;
          justify-content: center;
          padding-bottom: 7px;
          @mixin tabletToDesktop {
            padding-bottom: 0;
            padding-right: 38px;
            justify-content: flex-end;
          }
          .button {
            padding: 12px 2.5em;
            border-radius: 5px;
            font-size: ${typography.sm};
            line-height: 1.4em;
            font-weight: 700;
            font-family: inherit;
            text-align: center;
            width: auto;
            cursor: pointer;
            text-transform: uppercase;
            letter-spacing: 0.075em;
            &.delete {
              font-weight: 400;
              align-self: center;
              margin-right: 38px;
              text-transform: capitalize;
              background-color: transparent;
              color: ${colors.black.shade9};
              border-bottom: 0.5px solid ${colors.black.shade9};
              border-radius: 0;
              padding: 0;
              line-height: 1.9em;
              letter-spacing: 0.19px;
              font-size: ${typography.sm1};
            }
            &.delivery {
              color: ${colors.white.shade1};
              background-color: ${colors.green.shade1};
              &.disabled {
                background-color: ${colors.gray.shade5};
              }
            }
          }
        }
      }
    }
  }

  .optionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 100%;
    flex-wrap: wrap;
    @mixin tabletToDesktop {
      flex-direction: row;
    }
  }

  .modal-content-wrapper {
    flex-direction: column;
  }
`;

export const PickupInStoreAvailabilityOptionCss = css`
  .optionContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 10px;
    width: 100%;
    flex-wrap: wrap;
    @mixin tabletToDesktop {
      flex-direction: row;
    }

    &-item {
      outline: none;
      border-radius: 4px;
      padding: 18px 10px;
      display: flex;
      border: 1px solid ${colors.gray.shade33};
      width: 100%;
      margin: 5px 0;
      align-items: center;
      &.active {
        background-color: rgba(170, 213, 0, 0.05);
        border: 1px solid ${colors.green.shade2};
      }
      @mixin tabletToDesktop {
        margin: 5px 0 10px 0;
        width: 47%;
        min-height: 165px;
      }
      .optionInput {
        position: relative;
        height: 26px;
        input[type='radio'],
        input[type='radio']:checked,
        input[type='radio']:not(:checked) {
          position: absolute;
          top: 30%;
          left: 7px;
          opacity: 0;
          font-size: ${typography.xl3};
          z-index: ${zIndex.availability.radioInput};
          cursor: pointer;
        }

        input[type='radio']:checked + label,
        input[type='radio']:not(:checked) + label {
          padding-left: 35px;
          padding-top: 3px;
          position: relative;
        }

        input[type='radio']:checked + label::before,
        input[type='radio']:not(:checked) + label::before {
          background: ${colors.white.shade1};
          border: 1px solid ${colors.gray.shade2};
          border-radius: 30px;
          content: '';
          height: 16px;
          left: 3px;
          padding: 10px;
          position: absolute;
          top: 5px;
          width: 16px;
        }

        input[type='radio']:checked + label::after {
          background: ${colors.green.shade1};
          border-radius: 30px;
          content: '';
          font-size: ${typography.xl5a};
          height: 12px;
          left: 8px;
          line-height: 0.8;
          position: absolute;
          top: 10px;
          width: 12px;
          opacity: 1;
          transform: scaleX(1);
        }
        input[type='radio']:checked + label::after,
        input[type='radio']:not(:checked) + label::after {
          transition: all 0.2s linear;
        }

        .input [type='radio']:not(:checked) + label::after {
          opacity: 0;
          transform: scale3d(0, 0, 0);
        }
      }
      .logo {
        width: 80px;
        min-width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          max-width: 100%;
          max-height: 100%;
        }
        @mixin tabletToDesktop {
          img {
            width: 100%;
            display: block;
            height: auto;
            max-width: 100%;
          }
        }
      }
      .descContainer {
        padding-left: 18px;
        display: block;
        line-height: 13px;
        font-size: ${typography.xs2};
        .optionName {
          font-size: ${typography.sm};
          color: ${colors.black.shade1};
          font-weight: bold;
        }
        .openingTimes {
          h4 {
            line-height: 15px;
            color: ${colors.black.shade7};
          }
        }
        .optionData {
          font-size: ${typography.xs2};
          color: ${colors.black.shade1};
          font-weight: 400;
        }
      }
      &.rebranded {
        &.active {
          background-color: ${colors.white.shade1};
          border: 2px solid ${colors.secondary_color};
        }
        .optionInput {
          input[type='radio']:checked + label::before {
            border: 1px solid ${colors.secondary_color};
          }
          input[type='radio']:checked + label::after {
            background: ${colors.secondary_color};
          }
        }
      }
    }
  }
`;
