import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import PropTypes from 'prop-types';
import React from 'react';
import { SizeButtonStyles } from './SizeButton.style';

const SizeButton = ({ value, available, index, selectedSize, onSizeChange, theme, enhancedSizeSelectorClassName }) => {
  const onClickHandler = () => {
    onSizeChange(value, index);
  };

  const isSelectedSize = (val) => selectedSize && val.toUpperCase() === selectedSize.toUpperCase();

  const themedStyles = SizeButtonStyles(theme);

  return (
    <button
      id={`testId-sizeButton-${value}`}
      type="button"
      key={value}
      className={`size-button rebranded ${enhancedSizeSelectorClassName} ${
        isSelectedSize(value) ? 'size-button-selected' : ''
      } ${!available ? 'size-button-unavailable' : ''} `}
      onClick={onClickHandler}
      disabled={!available}
    >
      {value}
      <style jsx>{themedStyles}</style>
    </button>
  );
};

SizeButton.defaultProps = {
  theme: null,
  enhancedSizeSelectorClassName: '',
};

SizeButton.propTypes = {
  value: PropTypes.string.isRequired,
  available: PropTypes.bool.isRequired,
  index: PropTypes.string.isRequired,
  selectedSize: PropTypes.string.isRequired,
  onSizeChange: PropTypes.func.isRequired,
  theme: PropTypes.object,
  enhancedSizeSelectorClassName: PropTypes.string,
};

export default withTheme(SizeButton);
