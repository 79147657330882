import PropTypes from 'prop-types';
import React from 'react';
import decodeHtml from '../../../utils/decodeHtml';
import { ProductInfoStyle } from './ProductInfo.style';

const resolveScopedStyles = (scope) => {
  return {
    className: scope.props.className,
    styles: () => scope.props.children,
    wrapClassNames: (...classNames) => [scope.props.className, ...classNames].filter(Boolean).join(' '),
  };
};

const ProductInfo = ({ description }) => {
  if (!description || typeof description !== 'string') {
    return null;
  }
  const style = resolveScopedStyles(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <style>{`
        ${ProductInfoStyle}
      `}</style>
    </>
  );

  return (
    <>
      {/* eslint-disable react/no-danger */}
      <div className={style.wrapClassNames('fb-product-information__product-information-tab')}>
        <div
          className={style.wrapClassNames('fb-product-information-tab__copy')}
          dangerouslySetInnerHTML={{
            __html: decodeHtml(decodeHtml(description)),
          }}
        />
      </div>
      <style.styles />
    </>
  );
};

ProductInfo.propTypes = {
  description: PropTypes.string.isRequired,
};

export default ProductInfo;
