const capitalizeText = (text) => {
  const words = text.split(' ');
  if (words.length === 1) {
    return `${words[0].charAt(0).toUpperCase()}${words[0].slice(1).toLowerCase()}`;
  }
  let capSentence = '';
  words.forEach((word) => {
    capSentence += `${word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()} `;
  });
  return capSentence;
};

export { capitalizeText };
