import { isSodimacStandalone } from '@digital-retail/store-manager';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import AdditionalPDPLabel from '../../../../components/AdditionalPDPLabel/AdditionalPDPLabel';
import AddToCartButton from '../../../../components/AddToCartButton/Desktop/AddToCartButtonDesktop';
import AddToCartButtonMobile from '../../../../components/AddToCartButton/Mobile/AddToCartButtonMobile';
import Badges from '../../../../components/Badges/Badges';
import FloorCalculator from '../../../../components/FloorCalculator/FloorCalculator';
import InternationalShipping from '../../../../components/InternationalShipping/InternationalShipping';
import MultipurposeBadge from '../../../../components/MultipurposeBadge/MultipurposeBadge';
import Operator from '../../../../components/Operator/Operator';
import { VariantId } from '../../../../components/PDP';
import ProductColorSwatches from '../../../../components/ProductColorSwatches/ProductColorSwatches';
import ProductPrices from '../../../../components/ProductPrices/ProductPrices';
import ProductTopSpecifications from '../../../../components/ProductTopSpecifications/ProductTopSpecifications';
import { ReaconditionedContainer } from '../../../../components/ReaconditionedContainer/ReaconditionedContainer';
import SellerInfo from '../../../../components/SellerInfo/SellerInfo';
import Size from '../../../../components/Size/Size';
import StockQuantity from '../../../../components/StockQuantity/StockQuantity';
import StockTable from '../../../../components/ui/StockTable/StockTable';
import constants from '../../../../config/constants';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import { withLabels } from '../../../../utils/LabelsContext';
import _ from '../../../../utils/LodashImports';
import noop from '../../../../utils/noop';
import * as productUtils from '../../../../utils/product/productUtils';
import { withProduct } from '../../../../utils/ProductContext';
import { getVariant } from '../../../../utils/variant';
import { SKUIdSeller } from '../../../common/PDPBasicInfoShared';
import GenericCalculatorContainer from '../../../GenericCalculatorContainer/GenericCalculatorContainer';
import { GetSharedDetails } from '../../shared';
import useInput from '../../useInput';
import { ProductSpecificationsMobileStyle } from './ProductSpecificationsMobile.style';

const ProductSpecificationsMobile = ({
  product,
  labels,
  appCtx,
  isPumAvailable,
  pumQuantity,
  primeShipping,
  colorVariants,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  sizes,
  selectedSize,
  sizeChangeHandler,
  handleAddToCart,
  isAddToCartInProgress,
  addToCartButtonVisible,
  handleRemoveFromCart,
  isRemoveFromCartInProgress,
  showCollectionButtonVisible,
  operators,
  operatorChangeHandler,
  setWarrantyOption,
  setServiceOption,
  isVendorLogin,
  storeSkuData,
  currentTab,
  selectedOperatorId,
  errorAddingToCart,
  requestProductFromStore,
  handleM2QuantityChange,
  m2Quantity,
  m2ResultValue,
  handleM2ResultChange,
  m2Value,
  handleM2ValueChange,
  isExtraM2Checked,
  handleExtraM2Change,
  m2AttributeValue,
  setShowLoginForm,
  showLoginForm,
  setErrorAddingToCart,
  sizeChartData,
  fomo,
}) => {
  const isInternationalShipping = _.get(product, 'internationalShipping.applicable', false);
  const { regionCode, deviceType, store } = appCtx;
  const {
    id: productId,
    currentVariant,
    variants,
    additionalPDPLabels,
    isOutOfStock,
    colorMetrics,
    darSectionActive = false,
  } = product;
  const variant = getVariant(variants, currentVariant);
  const maxCartQuantity = _.get(variant, 'maxOnCartAmount', constants.MAX_CART_PRODUCT);
  const comboData = _.get(variant, 'comboData', null);
  const { showQuantityError, inputVal, handleInputChange, setQuantityError, setInputVal } = useInput(maxCartQuantity);
  const { viewTemplate, pumUnits } = product.attributes;

  const sellerName = _.get(variants, '[0].offerings[0].sellerName', '');
  const is1pSeller = constants.FIRST_PARTY_SELLER_REGEX.test(sellerName.toLowerCase());
  const multipurposeBadges = _.get(variant, 'multipurposeBadges', []);
  const { prices, cmrPoints, discountBadge } = variant;
  const type =
    product.productType === constants.PRODUCT_CONSTANTS.CONNECT
      ? constants.PRODUCT_CONSTANTS.CONNECT
      : constants.PRODUCT_CONSTANTS.HARDLINE;
  const isConnectProduct = productUtils.isConnectProduct(product);
  const isMobile = deviceType === constants.DEVICE_MOBILE;

  const shouldRenderSize = viewTemplate !== constants.VIEW_TEMPLATE.TYPE_A && sizes.length > 0;

  const isStoreDataVaild = storeSkuData && Object.keys(storeSkuData).length > 0;
  const addToCartLabel = _.get(additionalPDPLabels, 'addToCart.enabled', false)
    ? _.get(additionalPDPLabels, 'addToCart', {})
    : null;

  const isMultipurposeBadgeApplicable = _.get(appCtx, 'siteConfig.toggles.isMultipurposeBadgeApplicable', false);

  const showStoreAvailability = _.get(appCtx, 'siteConfig.toggles.isStoreAvailabilityVisibleInPDP', false);

  const additionalWarrantyLabel = _.get(additionalPDPLabels, 'warranty.enabled', false)
    ? _.get(additionalPDPLabels, 'warranty', {})
    : null;
  const renderColorSwatch = () => {
    return (
      <>
        <span>{`${labels.COLOR}: `}</span>
        {colorVariants[selectedColorSwatchIndex] && colorVariants[selectedColorSwatchIndex].label}
        <ProductColorSwatches
          colorVariants={colorVariants}
          productId={product.id}
          selectedColorSwatchIndex={selectedColorSwatchIndex}
          colorSwatchHandler={colorSwatchHandler}
          type={type}
          size="large"
          appCtx={appCtx}
          colorMetrics={colorMetrics}
        />
      </>
    );
  };

  const showCMRCalculator = _.get(appCtx, 'siteConfig.toggles.isCMRCalculatorEnabledPDP', false);

  const isWarrantyTooltipEnabled = _.get(appCtx, 'siteConfig.toggles.isWarrantyTooltipEnabled', false);

  const isInternationalBuyVisiblePDP = _.get(appCtx, 'siteConfig.toggles.isInternationalBuyVisiblePDP', false);

  const isFloorCalculatorEnabled = _.get(appCtx, 'siteConfig.toggles.isFloorcalculatorEnabled', true);
  const isSoCom = isSodimacStandalone(store);
  const isReaconditionedProd = productUtils.isReaconditionedProduct(product);
  const { badges = [], meatStickers = [], promotions } = getVariant(product.variants, product.currentVariant);
  const allowed3PCampaignNamesToRender = _.get(
    appCtx,
    'siteConfig.textDictionary.ALLOWED_3P_CAMPAIGN_NAMES_TO_RENDER',
    'ENVIO GRATIS 3P, ENVIO_GRATIS_3P'
  );
  const freeShippingPromotionFor3p = promotions?.find((promotion) =>
    allowed3PCampaignNamesToRender
      .split(',')
      .map((name) => name.trim())
      .includes(promotion.campaignName)
  );
  const isMeatStickerApplicableInPDP = _.get(appCtx, 'siteConfig.toggles.isMeatStickerApplicableInPDP', false);
  const hasStickers = (isMeatStickerApplicableInPDP && meatStickers.length > 0) || freeShippingPromotionFor3p;
  const nonEventMeatStickers = (meatStickers || []).filter((sticker) => !['event'].includes(sticker.type));
  if (isOutOfStock) {
    return (
      <div
        className="product-specifications"
        data-backend-category={product.merchantCategoryId}
        data-availability={product.attributes && product.attributes.isPrimeAvailable}
        data-published={product.isPublished}
        data-variations={variants.length}
      >
        <div className="product-specifications-column">
          {isConnectProduct && renderColorSwatch()}
          <div className={clsx({ flex: !isMobile })}>
            <div>
              {prices && prices.length > 0 && (
                <div className="prices">
                  <ProductPrices
                    isVendorLogin={isVendorLogin}
                    currentTab={currentTab}
                    storeSkuData={storeSkuData}
                    prices={productUtils.getPriceForOutOfStock(prices)}
                    productId={productId}
                    regionCode={regionCode}
                    cmrPoints={cmrPoints}
                    isPumAvailable={isPumAvailable}
                    pumQuantity={pumQuantity}
                    pumUnit={pumUnits}
                    primeShipping={primeShipping}
                    isMobile={isMobile}
                    operators={operators}
                    selectedOperatorId={selectedOperatorId}
                    isConnectProduct={isConnectProduct}
                  />
                </div>
              )}
              <SellerInfo product={product} />
              {isReaconditionedProd && <ReaconditionedContainer appCtx={appCtx} productAttributes={product} />}
            </div>
          </div>
        </div>
        <style jsx>{ProductSpecificationsMobileStyle}</style>
      </div>
    );
  }
  return (
    <div
      className="product-specifications"
      data-backend-category={product.merchantCategoryId}
      data-availability={product.attributes && product.attributes.isPrimeAvailable}
      data-published={product.isPublished}
      data-variations={variants.length}
    >
      <div className="product-specifications-column">
        {isConnectProduct && renderColorSwatch()}
        <div className={clsx({ flex: !isMobile })}>
          <div>
            {prices && prices.length > 0 && (
              <div className="prices">
                <ProductPrices
                  isVendorLogin={isVendorLogin}
                  currentTab={currentTab}
                  storeSkuData={storeSkuData}
                  prices={prices}
                  productId={productId}
                  regionCode={regionCode}
                  cmrPoints={cmrPoints}
                  isPumAvailable={isPumAvailable}
                  pumQuantity={pumQuantity}
                  pumUnit={pumUnits}
                  primeShipping={primeShipping}
                  isMobile={isMobile}
                  operators={operators}
                  selectedOperatorId={selectedOperatorId}
                  isConnectProduct={isConnectProduct}
                  discountBadge={discountBadge}
                  darSectionActive={darSectionActive}
                />
              </div>
            )}
            {(badges?.length || meatStickers?.length || freeShippingPromotionFor3p?.badge) && (
              <div className="badges">
                <Badges
                  deviceType="mobile"
                  badgeItems={isMeatStickerApplicableInPDP ? nonEventMeatStickers : badges}
                  layout="PDP"
                  isSticker={hasStickers}
                  promotionBadge={freeShippingPromotionFor3p?.badge}
                  store={appCtx.store}
                />
              </div>
            )}
            {isMultipurposeBadgeApplicable && multipurposeBadges.length > 0 && (
              <div className="multipurpose-badge">
                <MultipurposeBadge badgeItems={multipurposeBadges} deviceType="mobile" inPdp store={appCtx.store} />
              </div>
            )}
            <StockQuantity />
            {m2AttributeValue > 0 && isFloorCalculatorEnabled && (
              <div className="floorCalc-pdp-container">
                <FloorCalculator
                  appCtx={appCtx}
                  m2AttributeValue={m2AttributeValue}
                  handleM2QuantityChange={handleM2QuantityChange}
                  handleM2ResultChange={handleM2ResultChange}
                  m2Value={m2Value}
                  m2ResultValue={m2ResultValue}
                  handleM2ValueChange={handleM2ValueChange}
                  isExtraM2Checked={isExtraM2Checked}
                  handleExtraM2Change={handleExtraM2Change}
                />
              </div>
            )}
            <SellerInfo product={product} />
            {isInternationalBuyVisiblePDP && isInternationalShipping && <InternationalShipping />}
            {isReaconditionedProd && <ReaconditionedContainer appCtx={appCtx} productAttributes={product} />}
            {addToCartLabel && <AdditionalPDPLabel label={addToCartLabel} type="addToCart" productType="hardline" />}
            <GenericCalculatorContainer handleInputChange={handleInputChange} />
            {colorVariants[selectedColorSwatchIndex] && (
              <div className="color-swatch-container fa--color-swatches__mobile">
                {!isConnectProduct && renderColorSwatch()}
              </div>
            )}
            {isConnectProduct && (
              <Operator
                operators={operators}
                operatorChangeHandler={operatorChangeHandler}
                selectedOperatorId={selectedOperatorId}
                isConnectProduct={isConnectProduct}
              />
            )}

            {shouldRenderSize && (
              <Size
                title={labels.SELECTION_SIZE}
                sizes={sizes}
                onSizeChange={sizeChangeHandler}
                selectedSize={selectedSize}
                sizeChartData={sizeChartData}
                fomo={fomo}
              />
            )}
          </div>
          {isVendorLogin && isStoreDataVaild && (
            <div className="stock-table">
              <StockTable
                stockData={storeSkuData}
                colorVariants={colorVariants}
                selectedColorSwatchIndex={selectedColorSwatchIndex}
                sizes={sizes}
                isMobile={isMobile}
                requestProductFromStore={requestProductFromStore}
                regionCode={regionCode}
              />
            </div>
          )}
        </div>
        {!isConnectProduct && <ProductTopSpecifications scollToElementId="productInfoContainer" />}
        <div className="variant-seller-ids">
          <VariantId variantId={product.currentVariant} labels={labels} isSoCom={isSoCom} />
          {SKUIdSeller({
            appCtx,
            offerings: variant.offerings,
            labels,
            regionCode,
            product,
          })}
        </div>
        {addToCartButtonVisible && isVendorLogin && (
          <AddToCartButton
            mobileView
            addToCartButtonVisible={addToCartButtonVisible}
            isAddToCartInProgress={isAddToCartInProgress}
            labels={labels}
            handleAddToCart={handleAddToCart}
            isVendorLogin={isVendorLogin}
            sizes={sizes}
            selectedSize={selectedSize}
            setShowLoginForm={setShowLoginForm}
            showLoginForm={showLoginForm}
            setErrorAddingToCart={setErrorAddingToCart}
            showQuantityError={showQuantityError}
            setQuantityError={setQuantityError}
            inputVal={inputVal}
            setInputVal={setInputVal}
            handleInputChange={handleInputChange}
          />
        )}
        <GetSharedDetails
          sizes={sizes}
          colorVariants={colorVariants}
          regionCode={regionCode}
          store={store}
          is1pSeller={is1pSeller}
          showStoreAvailability={showStoreAvailability}
          showCollectionButtonVisible={showCollectionButtonVisible}
          handleAddToCart={handleAddToCart}
          comboData={comboData}
          product={product}
          labels={labels}
          showCMRCalculator={showCMRCalculator}
          setServiceOption={setServiceOption}
          setWarrantyOption={setWarrantyOption}
          isWarrantyTooltipEnabled={isWarrantyTooltipEnabled}
          additionalWarrantyLabel={additionalWarrantyLabel}
          layout="hardline"
          isSoCom={isSoCom}
          appCtx={appCtx}
        />

        {addToCartButtonVisible && !isVendorLogin && (
          <AddToCartButtonMobile
            maxCartQuantity={maxCartQuantity}
            labels={labels}
            m2Object={{
              m2AttributeValue,
              m2ResultValue,
              m2Quantity,
              isExtraM2Checked,
              handleM2QuantityChange,
              m2Value,
            }}
            errorAddingToCart={errorAddingToCart}
            isAddToCartInProgress={isAddToCartInProgress}
            handleRemoveFromCart={handleRemoveFromCart}
            isRemoveFromCartInProgress={isRemoveFromCartInProgress}
            appCtx={appCtx}
            handleAddToCart={handleAddToCart}
            showQuantityError={showQuantityError}
            setQuantityError={setQuantityError}
            inputVal={inputVal}
            setInputVal={setInputVal}
            handleInputChange={handleInputChange}
            sizes={sizes}
            selectedSize={selectedSize}
          />
        )}
      </div>
      <style jsx>{ProductSpecificationsMobileStyle}</style>
    </div>
  );
};

ProductSpecificationsMobile.defaultProps = {
  isPumAvailable: false,
  pumQuantity: 0,
  primeShipping: false,
  colorVariants: [],
  selectedColorSwatchIndex: 0,
  colorSwatchHandler: noop,
  sizes: [],
  selectedSize: '',
  sizeChangeHandler: noop,
  handleAddToCart: noop,
  isAddToCartInProgress: false,
  handleRemoveFromCart: noop,
  isRemoveFromCartInProgress: false,
  addToCartButtonVisible: true,
  showCollectionButtonVisible: false,
  operators: [],
  operatorChangeHandler: noop,
  setWarrantyOption: noop,
  setServiceOption: noop,
  requestProductFromStore: noop,
  storeSkuData: {},
  currentTab: '',
  selectedOperatorId: null,
  errorAddingToCart: false,
  handleM2QuantityChange: noop,
  m2Quantity: 1,
  m2ResultValue: 0,
  handleM2ResultChange: noop,
  m2Value: '',
  handleM2ValueChange: noop,
  isExtraM2Checked: true,
  handleExtraM2Change: noop,
  m2AttributeValue: -1,
  showLoginForm: false,
  sizeChartData: {},
  fomo: {},
};

ProductSpecificationsMobile.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  isPumAvailable: PropTypes.bool,
  pumQuantity: PropTypes.number,
  primeShipping: PropTypes.bool,
  colorVariants: PropTypes.array,
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  sizes: PropTypes.array,
  selectedSize: PropTypes.string,
  sizeChangeHandler: PropTypes.func,
  handleAddToCart: PropTypes.func,
  isAddToCartInProgress: PropTypes.bool,
  handleRemoveFromCart: PropTypes.func,
  isRemoveFromCartInProgress: PropTypes.bool,
  addToCartButtonVisible: PropTypes.bool,
  showCollectionButtonVisible: PropTypes.bool,
  operators: PropTypes.array,
  operatorChangeHandler: PropTypes.func,
  setWarrantyOption: PropTypes.func,
  setServiceOption: PropTypes.func,
  requestProductFromStore: PropTypes.func,
  isVendorLogin: PropTypes.bool.isRequired,
  storeSkuData: PropTypes.object,
  currentTab: PropTypes.string,
  selectedOperatorId: PropTypes.string,
  errorAddingToCart: PropTypes.bool,
  handleM2QuantityChange: PropTypes.func,
  m2Quantity: PropTypes.number,
  m2ResultValue: PropTypes.number,
  handleM2ResultChange: PropTypes.func,
  m2Value: PropTypes.string,
  handleM2ValueChange: PropTypes.func,
  isExtraM2Checked: PropTypes.bool,
  handleExtraM2Change: PropTypes.func,
  m2AttributeValue: PropTypes.number,
  setShowLoginForm: PropTypes.func.isRequired,
  setErrorAddingToCart: PropTypes.func.isRequired,
  showLoginForm: PropTypes.bool,
  sizeChartData: PropTypes.object,
  fomo: PropTypes.object,
};

export default withProduct(withLabels(withApplicationContext(ProductSpecificationsMobile)));
