import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../utils/LabelsContext';
import noop from '../../../utils/noop';
import Button from '../../ui/Button/Button';
import Stepper from '../../ui/Stepper/Stepper';
import VariantAvailabilityOptions from '../../VariantAvailabilityOptions/VariantAvailabilityOptions';
import VariantHeader from '../../VariantHeader/VariantHeader';
import Warning from '../../Warning/Warning';
import { TypeAVariantDesktopStyles } from './TypeAVariantDesktop.style';

const TypeAVariantDesktop = ({
  brandName,
  variantName,
  prices,
  addToCart,
  variantId,
  labels,
  count,
  incrementCount,
  decrementCount,
  maxForStepper,
  showAlert,
  isHomeDeliveryAvailable,
  isPickFromStoreAvailable,
}) => {
  const buttonType = 'mkp-secondary';
  return (
    <div className="typeAVariant mkp">
      <VariantHeader brandName={brandName} variantName={variantName} prices={prices} variantId={variantId} />
      <div className="typeAVariant-count">
        <Stepper
          count={count}
          onIncrement={incrementCount}
          onDecrement={decrementCount}
          minimum={0}
          maximum={maxForStepper}
        />
      </div>
      <div className="addToCart-button-wrapper">
        {!showAlert ? (
          <>
            <Button size="medium-extend" type={buttonType} onClick={addToCart}>
              <span className="addToCart-button-text mkp">{labels.ADD_TO_BAG}</span>
            </Button>
            <VariantAvailabilityOptions
              deviceType="desktop"
              isHomeDeliveryAvailable={isHomeDeliveryAvailable}
              isPickFromStoreAvailable={isPickFromStoreAvailable}
            />
          </>
        ) : (
          <Warning label={labels.MAXIMUM_QUANTITY_LIMIT_LABEL} iconSize="copy2" labelSize="copy1" />
        )}
      </div>
      <style jsx>{TypeAVariantDesktopStyles}</style>
    </div>
  );
};

TypeAVariantDesktop.defaultProps = {
  brandName: '',
  prices: [],
  addToCart: noop,
  isHomeDeliveryAvailable: false,
  isPickFromStoreAvailable: false,
  showAlert: false,
};

TypeAVariantDesktop.propTypes = {
  brandName: PropTypes.string,
  variantName: PropTypes.string.isRequired,
  variantId: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  incrementCount: PropTypes.func.isRequired,
  decrementCount: PropTypes.func.isRequired,
  prices: PropTypes.arrayOf(PropTypes.object),
  addToCart: PropTypes.func,
  maxForStepper: PropTypes.number.isRequired,
  showAlert: PropTypes.bool,
  isHomeDeliveryAvailable: PropTypes.bool,
  isPickFromStoreAvailable: PropTypes.bool,
};

export default withLabels(TypeAVariantDesktop);
