import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import PropTypes from 'prop-types';
import React from 'react';
import Image from '../Image/Image';
import { ThumbnailImageStyles } from './ThumbnailImage.style';

const ThumbnailImage = ({ options, index, selectedImageIndex, handleColorSwatch, theme, colorMetrics }) => {
  const themedStyles = ThumbnailImageStyles(theme);
  const { extraInfo, mediaId, value, url, selected, label, mediaUrls, imageUrl } = options;

  const handleThumbnailClick = () => {
    colorMetrics.setColorAtIndex(index, label);
    handleColorSwatch(
      {
        extraInfo,
        mediaId,
        colorName: value,
        url,
        selected,
        label,
        mediaUrls: mediaUrls || [],
      },
      index
    );
  };

  return (
    <li>
      <button
        type="button"
        className={`${index === selectedImageIndex ? 'thumbnail-border-selected' : 'thumbnail-border'}`}
        id={`testId-pod-colorSwatch-${extraInfo}`}
        onClick={() => handleThumbnailClick()}
      >
        <div className="thumbnail-image">
          <Image
            id={extraInfo}
            type="thumbnail"
            alt={`thumbail-${extraInfo}`}
            useSrcSet={false}
            lazyload
            inlineDimensions
            imageUrl={imageUrl}
            width={52}
            height={52}
          />
        </div>
      </button>

      <style jsx>{themedStyles}</style>
    </li>
  );
};
ThumbnailImage.defaultProps = {
  theme: {},
  colorMetrics: { setColorAtIndex: () => {} },
};

ThumbnailImage.propTypes = {
  options: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  selectedImageIndex: PropTypes.number.isRequired,
  handleColorSwatch: PropTypes.func.isRequired,
  theme: PropTypes.object,
  colorMetrics: PropTypes.object,
};

export { ThumbnailImage };
export default withTheme(ThumbnailImage);
