import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { getReaconditionedValues } from '../../utils/product/productUtils';
import { ReaconditionedContainerStyle } from './ReaconditionedContainer.styles';

export const ReaconditionedContainer = ({ appCtx, productAttributes }) => {
  const condition = getReaconditionedValues(productAttributes);
  const isReaconditionedLinkEnabled = _.get(appCtx, 'siteConfig.toggles.isReaconditionedLinkEnabledPDP', false);
  const reaconditionedLink =
    appCtx.store === constants.STORES.so_com
      ? _.get(appCtx, 'siteConfig.textDictionary.SO_COM_REACONDITIONED_LINK', '')
      : _.get(
          appCtx,
          'siteConfig.textDictionary.REACONDITIONED_LINK',
          'https://assets.contentstack.io/v3/assets/blt7c5c2f2f888a7cc3/blt6e6552e1707e37fd/productos-reacondicionados.pdf'
        );

  return (
    <>
      {condition && condition.name !== 'nuevo' && (
        <div className="reaconditioned-container">
          <div className="reacond-icon">
            <i className="icon-buysection-reacond">&nbsp;</i>
          </div>

          <div className="reacond-message">
            <div className="reacond-text-available">
              {`Este producto ${condition.name === 'detalle estético' ? 'tiene' : 'es'} `}
              <span>{condition.name}</span>.
            </div>
            {isReaconditionedLinkEnabled && reaconditionedLink && (
              <div className="reacond-link-container">
                <a target="_blank" href={reaconditionedLink} rel="noopener noreferrer" className="more-info">
                  Conocer más
                </a>
                <i className="csicon-right">&nbsp;</i>
              </div>
            )}
          </div>
        </div>
      )}
      <style jsx>{ReaconditionedContainerStyle}</style>
    </>
  );
};

ReaconditionedContainer.propTypes = {
  appCtx: PropTypes.object.isRequired,
  productAttributes: PropTypes.object.isRequired,
};
