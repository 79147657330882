import css from 'styled-jsx/css';
import { colors } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';
import icons from '../../config/styles/icons';

const ComboItemsDesktop = css`
  .combos-container {
    display: flex;
    padding: 16px 0;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-top: 1px solid ${colors.gray.shade36};
    background: ${colors.white.shade1};
    margin-top: 16px;
  }

  .heading {
    display: flex;
    align-items: center;
    gap: 10px;
    .heading-logo {
      height: 32px;
      width: 32px;
      background-image: url('${svgToDataUri(icons.comboItemsHeadingIcon)}');
    }
    .title {
      font-size: 14px;
      font-weight: 700;
      line-height: 16.8px;
      color: ${colors.black.shade10};
    }
  }

  .combo-items-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

  .combo-item {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
  }

  .item-name {
    display: flex;
    height: 20px;
    align-self: stretch;
    overflow: hidden;
    color: ${colors.gray.shade40};
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -0.3px;
    margin-right: 8px;
    border-bottom: solid 1px ${colors.gray.shade40};

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 500px;
    }
  }

  .seller {
    color: ${colors.gray.shade44};
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 1.167px;
    line-height: 14px;
    text-transform: uppercase;
  }

  .item-price {
    color: ${colors.gray.shade52};
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }

  .show-specs-wrapper {
    width: 24px;
    height: 24px;
    align-self: center;

    .zoom-in-icon {
      align-self: center;
      width: 24px;
      height: 24px;
      background-image: url('${svgToDataUri(icons.zoomIcon)}');
    }
  }

  .summary-wrapper {
    display: flex;
    align-items: center;
    gap: 32px;
    align-self: stretch;

    .photos-section {
      display: flex;
      flex-direction: row;
      gap: 12px;
      align-items: center;
      flex-wrap: wrap;

      .photo {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 80px;
        height: 80px;
        overflow: hidden;
        border-radius: 4px;
        border: 1px solid ${colors.gray.shade65};
        a {
          height: 100%;
          width: 100%;
        }
        .image-wrapper {
          width: 80px;
          height: 80px;
        }
      }

      .plus-sign {
        color: ${colors.gray.shade63};
        font-size: 24px;
        width: 14px;
        height: 29px;
        line-height: 24px;
        font-weight: 700;
      }
    }

    .combo-total-price {
      margin-left: 20px;

      .label {
        color: ${colors.black.shade10};
        font-size: 14px;
        font-weight: 400;
        line-height: 16.8px;
      }

      .price-value {
        color: ${colors.gray.shade52};
        font-size: 16px;
        font-weight: 700;
        line-height: 19.2px;
      }
    }
  }
`;

export default ComboItemsDesktop;
