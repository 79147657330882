import { isSodimacStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import MediaComponent from '../../components/Media/MediaComponent';
import Accordion from '../../components/ui/Accordion/Accordion';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { changeTextCase } from '../../utils/jsUtils';
import { getUniqueOperators, getVariant } from '../../utils/variant';
import { helpLineStyle } from './HelpLine.style';

const Helpline = ({ product, labels, appCtx }) => {
  const { helplineNumbers = [], currentVariant, variants } = product;

  const isHelplineEnabled = _.get(appCtx, 'siteConfig.toggles.isHelplineEnabled', false);

  const { deviceType, store } = appCtx;

  const [showChatWithExperts, setshowChatWithExperts] = useState(false);
  const chatWithOurExpertsText = _.get(labels, 'CHAT_WITH_OUR_EXPERTS', 'Chat con especialistas');
  const enableChatWithExperts = () => {
    setshowChatWithExperts(true);
  };
  useEffect(() => {
    window.addEventListener('AOPDPSHOW', enableChatWithExperts);
    return () => {
      window.removeEventListener('AOPDPSHOW', enableChatWithExperts);
    };
  }, []);

  const formatTelephoneNumberForLink = (telephoneNumber) => {
    const teleNumber = telephoneNumber.replace(/ /g, '');
    return `tel:${teleNumber}`;
  };

  const helplineNumber = deviceType === 'mobile' ? helplineNumbers[1] : helplineNumbers[0];
  let needHelpCallUsAtText = '';

  if (helplineNumber && isHelplineEnabled) {
    let hasCategoryPlan = false;
    needHelpCallUsAtText = isSodimacStandalone(store)
      ? _.get(labels, 'SO_COM_NEED_HELP_CALL_US_AT', '¿Te ayudamos? Contáctanos al')
      : labels.NEED_HELP_CALL_US_AT;

    if (variants) {
      const variant = getVariant(variants, currentVariant);
      const { deviceCost = [] } = variant;
      const minProductPrice = Array.isArray(deviceCost) && deviceCost.length ? [deviceCost[0]] : [];
      const productColorCode = _.get(variant, 'attributes.colorName', '');
      const operators = getUniqueOperators(productColorCode, variants);

      hasCategoryPlan =
        operators.length > 0 && (minProductPrice.length > 0 || helplineNumber === constants.PLAN_HELP_NUMBER);
    }

    if (hasCategoryPlan) {
      needHelpCallUsAtText = labels.NEED_HELP_CALL_US_AT_PLAN;
    }
  }

  const subTitleElement = () => <div className="subtitle-text">{labels.CONTACT_OUR_SPECIALISTS}</div>;

  const showHelpline = helplineNumber && isHelplineEnabled;
  const showOnlyChatWihExperts = !showHelpline && showChatWithExperts;
  const needHelpText = isSodimacStandalone(appCtx.store)
    ? _.get(labels, 'SO_COM_NEED_HELP', '¿Te ayudamos?')
    : _.get(labels, 'NEED_HELP', '¿Necesitas ayuda?');

  return showHelpline || showChatWithExperts ? (
    <div className="helpLine-container rebranded">
      <div className={`${!showOnlyChatWihExperts && 'helpLine-section'}`}>
        <MediaComponent from="mobile" to="phablet">
          <Accordion
            items={[
              {
                title: needHelpText,
                subTitleElement,
                logo: 'icon-helpline-rebranded',
                content: () => (
                  <div className="helpline-options-container">
                    {showHelpline && (
                      <a className="telephone-link-button" href={formatTelephoneNumberForLink(helplineNumber)}>
                        <span className="telephone-link-text">{changeTextCase(labels.EXPERT_ADVICE)}</span>
                      </a>
                    )}
                    {showChatWithExperts && (
                      <button type="button" id="chat-con-especialistas " className="telephone-link-button">
                        <span className="telephone-link-text">{chatWithOurExpertsText}</span>
                      </button>
                    )}
                  </div>
                ),
                iconOptions: {
                  up: 'csicon-arrow_up_small',
                  down: 'csicon-arrow_down_small',
                  styles: {
                    fontSize: '1rem',
                    paddingRight: '9px',
                  },
                },
                state: false,
                border: true,
                withPadding: false,
              },
            ]}
            state
            hasCustomVisualsForItem={false}
          />
        </MediaComponent>
        {showHelpline && (
          <MediaComponent from="tablet" to="desktop">
            <div className="telephone-section-desktop">
              <span className="csicon-helpline rebranded" />
              <span className="telephone-text">{needHelpCallUsAtText}</span>
              <span className="telephone-link-number">{helplineNumber}</span>
            </div>
          </MediaComponent>
        )}
      </div>
      <style jsx>{helpLineStyle}</style>
    </div>
  ) : null;
};

Helpline.propTypes = {
  product: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
};

export { Helpline };

export default withApplicationContext(withLabels(Helpline));
