import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import constant from '../../../config/constants';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import { withProduct } from '../../../utils/ProductContext';
import { AvailabilityDesktopStyles } from './AvailabilityDesktop.style';
import AvailabilityDesktopDownloadable from './AvailabilityDesktopDownloadable';
import AvailabilityDesktopItem from './AvailabilityDesktopItem';

const AvailabilityDesktop = ({ availabilityList, labels, product, sizes, colorVariants }) => {
  const isSoftProduct = product.productType === constant.PRODUCT_TYPE.SOFT_GOOD;
  const isGiftCard = product.productType === constant.PRODUCT_TYPE.GIFTCARD;
  const hasTopSpecifications = _.get(product, 'attributes.topSpecifications', []);
  return (
    <div className="availability fa--availability-info__desktop">
      <div className={`availability-container ${!hasTopSpecifications.length ? 'new-hd-options' : ''} `}>
        {isSoftProduct || isGiftCard ? (
          <AvailabilityDesktopDownloadable />
        ) : (
          availabilityList.map((item, index) => {
            return (
              <Fragment key={item.shippingOptionType}>
                <AvailabilityDesktopItem
                  key={item.shippingOptionType}
                  labels={labels}
                  availability={item}
                  product={product}
                  isLast={false}
                  hasModal={index !== 2}
                  sizes={sizes}
                  colorVariants={colorVariants}
                />
                {index === 1 && availabilityList.length > 2 && (
                  <div className="availability-item title lastItem">
                    <span>{labels.AVAILABILITY_PDP}</span>
                  </div>
                )}
              </Fragment>
            );
          })
        )}
      </div>
      <style jsx>{AvailabilityDesktopStyles}</style>
    </div>
  );
};

AvailabilityDesktop.propTypes = {
  availabilityList: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.object.isRequired,
  product: PropTypes.object,
  sizes: PropTypes.array,
  colorVariants: PropTypes.array,
};

AvailabilityDesktop.defaultProps = {
  product: {},
  sizes: [],
  colorVariants: [],
};

export default withLabels(withProduct(AvailabilityDesktop));
