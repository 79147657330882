import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from '../../utils/LodashImports';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import Checkbox from '../ui/Checkbox/Checkbox';
import FloorCalculatorStyles from './FloorCalculator.style';
import { getResult, formatM2Value } from './FloorCalculatorUtils';

const FloorCalculator = ({
  appCtx,
  m2AttributeValue,
  handleM2QuantityChange,
  handleM2ResultChange,
  m2Value,
  m2ResultValue,
  handleM2ValueChange,
  isExtraM2Checked,
  handleExtraM2Change,
}) => {
  useEffect(() => {
    const resultVal = getResult(m2Value, m2AttributeValue, isExtraM2Checked);
    handleM2ResultChange(resultVal);
    handleM2QuantityChange(resultVal);
  }, [m2Value, isExtraM2Checked]);

  const handleWastageChange = ({ isSelected }) => {
    handleExtraM2Change(isSelected);
  };

  const floorCalcExtraLabel = _.get(
    appCtx,
    'siteConfig.textDictionary.FLOOR_CALC_EXTRA_LABEL',
    'Incluir 10% adicional para cubrir posibles daños y faltantes.'
  );

  const floorCalcTitle = _.get(
    appCtx,
    'siteConfig.textDictionary.FLOOR_CALC_TITLE',
    'Ingresa los m2 del proyecto y te diremos cuántas cajas necesitas'
  );

  const isFloorCalGuideActive = _.get(appCtx, 'siteConfig.toggles.isFloorCalculatorGuideActive', true);

  const floorCalcGuideRedirectUrl = _.get(
    appCtx,
    'siteConfig.endpoints.FLOOR_CALCULATOR_GUIDE_REDIRECT_URL',
    'https://assets.contentstack.io/v3/assets/blt7c5c2f2f888a7cc3/bltfe7ac313a4d3308e/Guia_como_calcular_m2.pdf'
  );

  return (
    <div className="floor-calc-container">
      <div className="calculator">
        <div className="calculator-icon-container">
          <i className="calculator-icon" />
        </div>
        <div className="calculator-info">
          <BodyCopy size="title22" isHighlighted>
            {floorCalcTitle}
          </BodyCopy>
        </div>
        <div className="calculator-input">
          <div className="calculator-input-wrapper">
            <input
              className="input-box"
              type="tel"
              value={m2Value}
              id="testId-input-calculator-input"
              min="0"
              maxLength="6"
              onChange={(evt) => handleM2ValueChange(evt)}
            />
          </div>
          <span className="line" />
          <span className="unit">m²</span>
        </div>
      </div>
      <div className="formulae">
        <span className="m2-formula">Largo x ancho = m²</span>
        {isFloorCalGuideActive && (
          <>
            |
            <span className="m2-calculations-guide">
              <a
                target="_blank"
                href={floorCalcGuideRedirectUrl}
                id="testId-Link-mt2CalcuationsGuide"
                rel="noopener noreferrer"
                className="m2-link"
              >
                Guía para calcular m²
              </a>
            </span>
          </>
        )}
      </div>
      {m2Value.length > 0 && m2ResultValue > 0 && (
        <div className="calculationResult">
          <p>
            Necesitas <span>{`${m2ResultValue} cajas`}</span> para cubrir <span>{`${formatM2Value(m2Value)}m²`}</span>
          </p>
          <div className="wastage-container">
            <Checkbox
              variant="secondary"
              isChecked={isExtraM2Checked}
              id="test-wastage-checkbox"
              type="mkp-succes"
              selectedBorderClass=" mkp-border"
              normalBorderClass=" mkp-normal-border"
              onChange={handleWastageChange}
              inFloorCalculator
            >
              <p className="wastage-label">{floorCalcExtraLabel}</p>
            </Checkbox>
          </div>
        </div>
      )}
      <style jsx>{FloorCalculatorStyles}</style>
    </div>
  );
};

FloorCalculator.propTypes = {
  appCtx: PropTypes.object.isRequired,
  m2AttributeValue: PropTypes.number.isRequired,
  handleM2QuantityChange: PropTypes.func.isRequired,
  handleM2ResultChange: PropTypes.func.isRequired,
  m2Value: PropTypes.string.isRequired,
  m2ResultValue: PropTypes.number.isRequired,
  handleM2ValueChange: PropTypes.func.isRequired,
  isExtraM2Checked: PropTypes.bool.isRequired,
  handleExtraM2Change: PropTypes.func.isRequired,
};

export default FloorCalculator;
