/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { typography, colors, icons } from '../../../config/styles';
import { svgUrl } from '../../../utils/staticUrls';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

export const SellerInfoDesktopStyle = css`
  .sellerText {
    display: flex;
    align-items: baseline;
    flex-direction: column;
    margin-bottom: 25px;

    .sellerInfoContainer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: ${colors.gray.shade15};
      letter-spacing: 0.14px;
      font-size: ${typography.sm1};
      line-height: ${typography.xla};
      padding-bottom: 12px;
      padding-top: 6px;
      border-bottom: 1px solid ${colors.gray.shade3};
      width: 100%;

      .link {
        text-decoration: none;
        background-color: transparent;
        color: inherit;
        .underline {
          border-bottom: 0.5px solid ${colors.gray.shade15};
          text-transform: capitalize;
        }
      }

      .highlight {
        text-transform: capitalize;
        font-weight: bold;
        color: ${colors.black.shade1};
      }
    }
    .flex {
      display: flex;
    }
    .capitalize {
      text-transform: capitalize;
    }
    .market-place-tag {
      height: 19px;
      width: 79px;
      background-color: #969696;
      display: flex;
      &-text {
        height: 14px;
        width: 67px;
        color: ${colors.white.shade1};
        font-size: 12px;
        letter-spacing: 0.1px;
        line-height: 14px;
        margin: auto;
        text-transform: capitalize;
        text-align: center;
      }
    }

    .falabellaIcon {
      background-image: url(${svgUrl('falabella.svg')});
      background-repeat: no-repeat;
      height: 18px;
      width: 12px;
      margin-left: 2px;
      margin-right: 6px;
      display: inline-block;
      vertical-align: middle;
    }
    .sodimacIcon,
    .homecenterIcon {
      background-image: url(${svgUrl('sodimac.svg')});
      background-repeat: no-repeat;
      height: 18px;
      width: 18px;
      margin-left: 2px;
      margin-right: 6px;
      align-self: flex-end;
      display: inline-block;
      vertical-align: middle;
    }
    .tottusIcon {
      background-image: url(${svgUrl('tottus.svg')});
      background-repeat: no-repeat;
      height: 18px;
      width: 18px;
      margin-left: 2px;
      margin-right: 6px;
      display: inline-block;
      vertical-align: middle;
    }
    .linioIcon {
      background-image: url(${svgUrl('linio.svg')});
      background-repeat: no-repeat;
      height: 18px;
      width: 18px;
      margin-left: 2px;
      margin-right: 6px;
      display: inline-block;
      vertical-align: middle;
    }

    #seller-tooltip-text {
      color: #767676;
    }
    .empty_international_icon {
      border-bottom: 1px solid #f8f8f8;
      margin-bottom: 12px;
    }
  }

  .alertIcon {
    cursor: pointer;
    display: inline-block;
    transform: rotate(180deg);
    margin-left: 5px;
    color: ${colors.gray.shade19};
    &:hover {
      color: ${colors.green.shade1};
    }

    font-size: ${typography.sm};
  }
  .rebranded {
    margin-bottom: 16px;
    .alertIcon {
      color: ${colors.gray.shade45};
      &:hover {
        color: ${colors.gray.shade45};
      }
    }
    .aeroplane-icon {
      background-image: url('${svgToDataUri(icons.aeroplaneRebranded)}');
    }
  }
  .sellerText.out-of-stock {
    .sellerInfoContainer {
      width: 300px;
      padding: 16px 0;
      border-top: 1px solid ${colors.gray.shade3};
      border-bottom: 1px solid ${colors.gray.shade3};
    }
  }
`;
