import css from 'styled-jsx/css';
import { colors, icons, typography } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const OptionsStyles = css`
  .options {
    background-color: #fff;
    margin-top: 12px;
    padding-bottom: 1px;

    &.options-services {
      display: grid;
      grid-template-rows: repeat(2, auto);
      grid-template-columns: repeat(2, 274px);
      column-gap: 8px;
    }
  }
  .options-mobile {
    background-color: ${colors.gray.shade32};
    padding: 11px 15px 1px 15px;
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .more-information {
    font-weight: 400;
    text-decoration: underline;
    color: ${colors.gray.shade40};
    font-size: ${typography.sm};
    line-height: 20px;
    letter-spacing: 0.2px;
  }
  .csicon-right {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    color: ${colors.gray.shade15};
    margin-top: 2px;
    &::before {
      font-size: ${typography.xxs};
    }
  }
  .link-wrapper {
    display: flex;
    align-items: center;
    padding-top: 2px;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
    @mixin mobileToDesktop {
      margin-top: 16px;
    }
    &.cart {
      padding-top: 0;
      @mixin mobileToDesktop {
        margin-top: 4px;
      }
    }
  }
`;

const SingleChipletSelectionStyles = css`
  .withBackground {
    background-color: ${colors.gray.shade41};
  }

  .withoutBackground {
    background-color: ${colors.white.shade1};
  }
  .arrow-font-size {
    font-size: 12px;
  }
  .chiplet-wrapper {
    width: 100%;
    padding: 11px 0;

    @mixin tabletToDesktop {
      padding: 16px 0 11px 12px;
    }
    @mixin phabletToTablet {
      padding: 15px 0 15px 15px;
    }

    .heading {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .primary-icon {
        font-size: ${typography.xl3};
        margin-right: 7px;
        color: ${colors.green.shade15};
      }

      :global(.primary-label) {
        line-height: 15px;
      }

      :global(.secondary-label) {
        height: 14px;
        letter-spacing: 0.1px;
        line-height: 12px;
        margin-right: 3px;
        border-bottom: 0.5px solid ${colors.gray.shade9};
      }

      .secondary-label-not-link {
        font-size: 1.3rem;
        color: ${colors.gray.shade40};
        @mixin desktop {
          font-size: 1rem;
        }
      }
    }

    &.mkp {
      .heading {
        .primary-icon {
          font-size: ${typography.xl3};
          margin-right: 7px;
          color: ${colors.gray.shade40};
        }
      }
    }

    .options {
      margin-top: 10px;
    }
  }

  .chiplet-wrapper.medium {
    .heading {
      .primary-label {
        font-size: ${typography.sm1};
      }
    }
  }
  .secondary-icon {
    color: ${colors.gray.shade5};
    transform: rotate(180deg);
    font-size: ${typography.lg2};
  }
  .service-icon {
    color: ${colors.gray.shade5};
    font-size: ${typography.lg2};
    transform: rotate(180deg);
    margin-left: 9px;
  }
  .chiplet-wrapper.mobile {
    padding: 13px 17px;
  }
  .secondary-icon:hover {
    color: ${colors.green.shade1};
  }
  .icons-label {
    display: flex;
    margin-left: 6px;
    align-items: flex-end;
  }
  .tooltip-text {
    line-height: 22px;
    font-size: 14px;
    .bold-text {
      font-size: 14px;
    }
    .tooltip-list {
      text-decoration: disc;
      li {
        word-wrap: break-word;
      }
    }
    .tooltip-link-wrapper {
      margin-top: 20px;
      display: flex;
      p {
        text-decoration: underline;
      }
      span {
        font-size: 9px;
        margin: 2px 0 0 7px;
      }
    }
    .arrow {
      transform: rotate(-90deg);
      margin-top: 20px;
    }
  }
  .pdp-mobile {
    margin: 20px 4% 20px 5%;
  }
  .tooltip-wrapper {
    margin: 0 8.5px;
  }
  .tooltip-wrapper:hover {
    color: ${colors.green.shade1};
  }
  .mkp {
    .secondary-icon:hover {
      color: ${colors.secondary_color};
    }
  }
  .chiplet-wrapper.rebranded {
    .heading {
      .primary-icon {
        color: ${colors.gray.shade45};
      }
      .csicon-preferences {
        width: 32px;
        height: 32px;
        background-image: url('${svgToDataUri(icons.servicesIconRebranded)}');
        background-size: 100%;
        &::before {
          content: '';
        }
      }
      .csicon-shield {
        min-width: 32px;
        height: 32px;
        background-image: url('${svgToDataUri(icons.extendedWarrantyRebranded)}');
        background-size: 100%;
        &::before {
          content: '';
        }
      }
      .secondary-icon,
      .secondary-icon:hover {
        color: ${colors.gray.shade45};
      }
    }
  }
`;

const arrowStyles = css`
  .csicon-arrow_down,
  .csicon-arrow_up {
    font-size: 8px;
  }
  .toggle-icon {
    outline: 0;
    margin-left: 20px;
    margin-bottom: 5px;
    &.mkp {
      margin-left: 16px;
    }
  }
`;

export { SingleChipletSelectionStyles, arrowStyles, OptionsStyles };
