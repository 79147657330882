const { default: constants } = require('../constants');

module.exports = {
  HOST: {
    CL: 'https://www.falabella.com',
    PE: 'https://www.falabella.com.pe',
    CO: 'https://www.falabella.com.co',
    AR: 'https://www.falabella.com.ar',
  },
  ENV_HOST: {
    CL: 'https://www.falabella.com',
    PE: 'https://www.falabella.com.pe',
    CO: 'https://www.falabella.com.co',
    AR: 'https://www.falabella.com.ar',
  },
  FULL_HOST: {
    CL: 'https://www.falabella.com/falabella-cl',
    PE: 'https://www.falabella.com.pe/falabella-pe',
    CO: 'https://www.falabella.com.co/falabella-co',
    AR: 'https://www.falabella.com.ar/falabella-ar',
    CL_SODIMAC: 'https://sodimac.falabella.com/sodimac-cl',
    CL_SO_COM: 'https://www.sodimac.cl/sodimac-cl',
    PE_SO_COM: 'https://www.sodimac.com.pe/sodimac-pe',
    CL_TO_COM: 'https://www.tottus.cl/tottus-cl',
    PE_TO_COM: 'https://www.tottus.com.pe/tottus-pe',
    CL_LINIO: 'https://linio.falabella.com/linio-cl',
    CL_TOTTUS: 'https://tottus.falabella.com/tottus-cl',
    CL_TIENDA: 'https://tienda.falabella.com/falabella-cl',
    PE_LINIO: 'https://linio.falabella.com.pe/linio-pe',
    PE_TOTTUS: 'https://tottus.falabella.com.pe/tottus-pe',
    PE_SODIMAC: 'https://sodimac.falabella.com.pe/sodimac-pe',
    PE_TIENDA: 'https://tienda.falabella.com.pe/falabella-pe',
    CO_LINIO: 'https://linio.falabella.com.co/linio-co',
    CO_TOTTUS: 'https://tottus.falabella.com.co/tottus-co',
    CO_SODIMAC: 'https://sodimac.falabella.com.co/sodimac-co',
    CO_TIENDA: 'https://tienda.falabella.com.co/falabella-co',
    CO_HOMECENTER: 'https://homecenter.falabella.com.co/homecenter-co',
  },
  TAXONOMY: '{host}/rest/model/falabella/rest/browse/BrowseActor/get-header-main-menu',
  TYPEAHEAD: 'https://www.falabella.com/s/typeahead/v3/{value}?source=web&country={region}',
  SEARCH: 'https://dev.falabella.com/fa/search/v1/{region}?{query}',
  HEADER: '{host}/rest/model/falabella/rest/browse/BrowseActor/fetch-header',
  FOOTER: '{host}/rest/model/falabella/rest/browse/BrowseActor/fetch-footer',
  EMAIL_SUBSCRIPTION: '{host}/rest/model/falabella/rest/common/CommonActor/subscribe-to-newsletter',
  LOCATIONS: 'http://35.230.186.49/fa/geo/zones/v1/falabella-{region}',
  IMAGES: {
    CL: 'https://falabella.scene7.com/is/image/Falabella/',
    CO: 'https://falabella.scene7.com/is/image/FalabellaCO/',
    PE: 'https://falabella.scene7.com/is/image/FalabellaPE/',
    AR: 'https://falabella.scene7.com/is/image/FalabellaAR/',
  },
  BANNER_MC: {
    CL: 'https://www.falabella.com/static/RDF/site/noResults/banner/bannerMC.html',
    CO: 'https://www.falabella.com/static/RDF/site/noResults/banner/bannerMC.html',
    AR: 'https://www.falabella.com/static/RDF/site/noResults/banner/bannerMC.html',
    PE: 'https://www.falabella.com/static/RDF/site/noResults/banner/bannerMC.html',
  },
  CATEGORY_BANNER: {
    CL: '{host}/static/RDF/site/noResults/banner/CategoryBanner.html',
    CO: '{host}/static/RDF/site/noResults/banner/bannerMC.html',
    AR: '{host}/static/RDF/site/noResults/banner/bannerMC.html',
    PE: '{host}static/RDF/site/noResults/banner/bannerMC.html',
  },
  SESSION_INFO: 'https://www.falabella.com/rest/model/atg/userprofiling/ProfileActor/fetchSessionInfo',
  TAG_MANAGER: {
    CL: '//assets.adobedtm.com/launch-EN3841a4834dd64c6bb9a812af8974e1cf.min.js',
    CO: '//assets.adobedtm.com/launch-ENefc886d6d08e45f1bcd6648ffbf43a9e.min.js',
    PE: '//assets.adobedtm.com/launch-EN12f948db3c6f482ca78e0ec9ef1c7fe0.min.js',
    AR: '//assets.adobedtm.com/launch-EN2c8098df45a14710b127b493f68a3987.min.js',
  },
  ANALYTICS_INFO: '{host}/rest/model/falabella/rest/common/CommonActor/analytics-info',
  EAN_TO_SKU:
    '{host}/rest/model/falabella/rest/browse/BrowseActor/scan-sku-details?{"productCode":"{code}","flagScanner":true,"codeType":"EAN"}',
  ITEMS_DETAILS:
    '{host}/rest/model/falabella/rest/browse/BrowseActor/fetch-item-details?{"products":[{"skuId":{skuId},"mediaType":"basic"}]}',
  PRODUCT_VARIATIONS: '{host}/rest/model/falabella/rest/browse/BrowseActor/storeItemInfo?{"skuId":"{skuId}"}',
  VENDOR_LOGOUT: 'https://facl-master-27d9c429-71320.test.falabella.com/falabella-{region}/includes/vendorLogout.jsp',
  VENDOR_NEW_CUSTOMER:
    'https://facl-master-27d9c429-71320.test.falabella.com/agentLocal/search.jsp?CJSESSIONID=PXwIJGIAHZS1ce5VkR08ULaW.node1&CURRENCYCODE=CLP&fromPage=&DPSLogout=true',
  VENDOR_HOME: '{host}/falabella-{region}/page/Guided-Selling?staticPageId=26000001',
  USER_SESSION_INFO: '{host}{url}',
  USER_LOGOUT: '{host}{url}',
  USER_LOGOUT_FOR_VENDOR:
    'https://facl-master-27d9c429-71320.test.falabella.com/falabella-{region}/includes/customerLogout.jsp',
  404: '{host}/falabella-{region}/error/404.html',
  500: '{host}/falabella-{region}/error/500.jspx',
  409: '{host}/falabella-{region}/error/409.jsp',
  DY_DYNAMIC_API: '//cdn.dynamicyield.com/api/{DY_ID}/api_dynamic.js',
  DY_STATIC_API: '//cdn.dynamicyield.com/api/{DY_ID}/api_static.js',
  CATEGORY_PAGE: '{full_host}/category/{categoryId}/{categoryName}',
  SEARCH_PAGE: '{full_host}/search?Ntt={searchTerm}',
  SA_SEARCH_PAGE: '{full_host}/buscar?Ntt={searchTerm}',
  COLLECTION_PAGE: '{full_host}/collection/{collectionId}',
  SELLER_PAGE: '{full_host}/seller/{sellerName}',
  BRAND_PAGE: '{full_host}/brand/{brandName}',
  FAV_32_ICON: '/a/fa/product/static/images/fav32.png',
  FAV_16_ICON: '/a/fa/product/static/images/fav16.png',
  APPLE_TOUCH_ICON: '/a/fa/product/static/images/apple-touch-icon.png',
  ANDROID_CHROME192_ICON: '/a/fa/product/static/images/android_chrome192.png',
  ANDROID_CHROME256_ICON: '/a/fa/product/static/images/android_chrome256.png',
  FAV_16_ICON_MKP_PNG:
    'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt463bbd2b9f3029c7/610b072b0d73bb3ec5daaa0d/favicon-16x16@2x.png',
  FAV_32_ICON_MKP_PNG:
    'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt89b89044dbb6274e/610b072bbe3cec3a86c11f87/favicon-32x32@2x.png',
  APPLE_TOUCH_ICON_MKP_PNG:
    'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt421937a7a3d0e580/610b072bf0c97e39497dab87/apple-touch-icon@2x.png',
  ANDROID_CHROME192_ICON_MKP_PNG:
    'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt2b52e6d0b3ba6b75/610b071593c9a33d7352f39d/android-chrome-192x192@2x.png',
  ANDROID_CHROME256_ICON_MKP_PNG:
    'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt5787e34d77f3c86f/610b07153ea66f3ecbdbdb89/android-chrome-256x256@2x.png',
  FAV_16_ICON_MKP_SVG:
    'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt648f725ba0d13ed8/favicon-16x16.svg',
  FAV_32_ICON_MKP_SVG:
    'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt28d77fde07e570f2/favicon-32x32.svg',
  APPLE_TOUCH_ICON_MKP_SVG:
    'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt6ae36b953175bc72/apple-touch-icon.svg',
  ANDROID_CHROME192_ICON_MKP_SVG:
    'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt9e2c51a35d5bde66/android-chrome-192x192.svg',
  ANDROID_CHROME256_ICON_MKP_SVG:
    'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/blt4175b80a0d58a4b3/android-chrome-256x256.svg',
  SAFARI_PINNED_ICON_MKP_SVG:
    'https://images.falabella.com/v3/assets/blt7c5c2f2f888a7cc3/bltdfdc9be33c7cb3c7/Final_safari-pinned-tab.svg',
  BAZAARVOICE_SCRIPT_URL: {
    CL: 'https://apps.bazaarvoice.com/deployments/Falabella/main_site/production/es_CL/bv.js',
    CO: 'https://apps.bazaarvoice.com/deployments/Falabella-CO/main_site/production/es_CO/bv.js',
    PE: 'https://apps.bazaarvoice.com/deployments/Falabella-PE/main_site/production/es_PE/bv.js',
    AR: 'https://apps.bazaarvoice.com/deployments/Falabella-AR/main_site/production/es_AR/bv.js',
  },
  PRODUCT_PAGE: '{full_host}/product/{productId}/{slug}/{variantId}',
  PLAN_URL: '/falabella-cl/plans/{productId}/{productName}/{variantId}',
  APERTURA_URL_VALID_CMR:
    'https://www.falabella.com/falabella-cl/page/CMR-enlinea?staticPageId=28700002&from=bShR3VyENqOK/iZsx/hUW4owR5kjQSQtt/XRCU1Pf3rhnbHrJ75QVFx4rZoioZA7Druyh/M/DtI1I/gPmmejNaiDTql/XJr0Nrso6iy+PPtSAco+SCZAjoRj9QapowiHgPJruBhfVvV0Jdebsu4pvl8k5AR1a6Uf4OACMk5vpUTP/PbJtfiNL84QpaAdHM9kMdlDR4m/CUNHMSWXv8dgA1fqCuR1U4tNoyEocdM3NqzhscbJT1l+tQVaqJ6M8jJ1IUa2dl1wJQOeE1tSzTHfjy4jdLHemq4nxQiVxzWxe5gTfft90rmWuwFgt7Yu/yzvuo61qnL0Db8d8uI2RJTKFQ=&detalleClic=pdpconOU',
  APERTURA_URL_INVALID_CMR:
    'https://www.falabella.com/falabella-cl/page/CMR-enlinea?staticPageId=28700002&from=bShR3VyENqOK/iZsx/hUW4owR5kjQSQtt/XRCU1Pf3rhnbHrJ75QVFx4rZoioZA7Druyh/M/DtI1I/gPmmejNaiDTql/XJr0Nrso6iy+PPtSAco+SCZAjoRj9QapowiHgPJruBhfVvV0Jdebsu4pvl8k5AR1a6Uf4OACMk5vpUTP/PbJtfiNL84QpaAdHM9kMdlDR4m/CUNHMSWXv8dgA1fqCuR1U4tNoyEocdM3NqzhscbJT1l+tQVaqJ6M8jJ1IUa2dl1wJQOeE1tSzTHfjy4jdLHemq4nxQiVxzWxe5gTfft90rmWuwFgt7Yu/yzvuo61qnL0Db8d8uI2RJTKFQ=&detalleClic=pdpsinOU',
  ATG_LOGIN_URL: '{full_host}/myaccount/includes/loginForm.jsp',
  CONFIGS_PER_STORE: {
    marketplace: {
      redirectsToFallback: false,
      validProductSites: [constants.sites.marketplace],
      sellerRedirections: [
        {
          regex: constants.SODIMAC_REGEX,
          forceSubdomain: constants.subdomains.sodimac,
        },
        {
          regex: constants.TOTUS_REGEX,
          forceSubdomain: constants.subdomains.tottus,
        },
        {
          regex: constants.HOMECENTER_REGEX,
          forceSubdomain: constants.subdomains.homecenter,
        },
      ],
    },
    sodimac: {
      redirectsToFallback: true,
      validProductSites: [constants.sites.sodimac],
    },
    linio: {
      redirectsToFallback: true,
      validProductSites: [constants.sites.linio],
    },
    tottus: {
      redirectsToFallback: true,
      validProductSites: [constants.sites.tottus],
    },
    homecenter: {
      redirectsToFallback: true,
      validProductSites: [constants.sites.homecenter],
    },
    so_com: {
      redirectsToFallback: false,
      validProductSites: [constants.sites.sodimac, constants.sites.so_com],
    },
    to_com: {
      redirectsToFallback: false,
      validProductSites: [constants.sites.to_com],
    },
    fa_america: {
      redirectsToFallback: false,
      validProductSites: [constants.sites.fa_america],
    },
    fa_carters: {
      redirectsToFallback: false,
      validProductSites: [constants.sites.fa_carters],
    },
    fa_aldo: {
      redirectsToFallback: false,
      validProductSites: [constants.sites.fa_aldo],
    },
    fa_crabarr: {
      redirectsToFallback: false,
      validProductSites: [constants.sites.fa_crabarr],
    },
  },
  SELLER_REDIRECTION_MAP: {
    local: {
      cl: {
        falabella: `https://tienda.falabella.com/falabella-cl`,
        sodimac: `https://sodimac.falabella.com/sodimac-cl`,
        tottus: `https://tottus.falabella.com/tottus-cl`,
        linio: `https://linio.falabella.com/linio-cl`,
        homecenter: `https://homecenter.falabella.com/homecenter-cl`,
      },
      co: {
        falabella: `https://tienda.falabella.com.co/falabella-co`,
        sodimac: `https://sodimac.falabella.com/sodimac-co`,
        tottus: `https://tottus.falabella.com.co/tottus-co`,
        linio: `https://linio.falabella.com.co/linio-co`,
        homecenter: `https://homecenter.falabella.com.co/homecenter-co`,
      },
      pe: {
        falabella: `https://tienda.falabella.com.pe/falabella-pe`,
        sodimac: `https://sodimac.falabella.com.pe/sodimac-pe`,
        tottus: `https://tottus.falabella.com.pe/tottus-pe`,
        linio: `https://linio.falabella.com.pe/linio-pe`,
        homecenter: `https://homecenter.falabella.com.pe/homecenter-pe`,
      },
    },
    production: {
      cl: {
        falabella: `https://tienda.falabella.com/falabella-cl`,
        sodimac: `https://sodimac.falabella.com/sodimac-cl`,
        tottus: `https://tottus.falabella.com/tottus-cl`,
        linio: `https://linio.falabella.com/linio-cl`,
        homecenter: `https://homecenter.falabella.com/homecenter-cl`,
      },
      co: {
        falabella: `https://tienda.falabella.com.co/falabella-co`,
        sodimac: `https://sodimac.falabella.com/sodimac-co`,
        tottus: `https://tottus.falabella.com.co/tottus-co`,
        linio: `https://linio.falabella.com.co/linio-co`,
        homecenter: `https://homecenter.falabella.com.co/homecenter-co`,
      },
      pe: {
        falabella: `https://tienda.falabella.com.pe/falabella-pe`,
        sodimac: `https://sodimac.falabella.com.pe/sodimac-pe`,
        tottus: `https://tottus.falabella.com.pe/tottus-pe`,
        linio: `https://linio.falabella.com.pe/linio-pe`,
        homecenter: `https://homecenter.falabella.com.pe/homecenter-pe`,
      },
    },
    uat: {
      cl: {
        falabella: `https://beta-tienda.falabella.com/falabella-cl`,
        sodimac: `https://beta-sodimac.falabella.com/sodimac-cl`,
        tottus: `https://beta-tottus.falabella.com/tottus-cl`,
        linio: `https://beta-linio.falabella.com/linio-cl`,
        homecenter: `https://beta-homecenter.falabella.com/homecenter-cl`,
      },
      co: {
        falabella: `https://beta-tienda.falabella.com.co/falabella-co`,
        sodimac: `https://beta-sodimac.falabella.com/sodimac-co`,
        tottus: `https://beta-tottus.falabella.com.co/tottus-co`,
        linio: `https://beta-linio.falabella.com.co/linio-co`,
        homecenter: `https://beta-homecenter.falabella.com.co/homecenter-co`,
      },
      pe: {
        falabella: `https://beta-tienda.falabella.com.pe/falabella-pe`,
        sodimac: `https://beta-sodimac.falabella.com.pe/sodimac-pe`,
        tottus: `https://beta-tottus.falabella.com.pe/tottus-pe`,
        linio: `https://beta-linio.falabella.com.pe/linio-pe`,
        homecenter: `https://beta-homecenter.falabella.com.pe/homecenter-pe`,
      },
    },
    staging: {
      cl: {
        falabella: `https://staging-tienda.falabella.com/falabella-cl`,
        sodimac: `https://staging-sodimac.falabella.com/sodimac-cl`,
        tottus: `https://staging-tottus.falabella.com.co/tottus-cl`,
        linio: `https://staging-linio.falabella.com/linio-cl`,
        homecenter: `https://staging-homecenter.falabella.com/homecenter-cl`,
      },
      co: {
        falabella: `https://staging-tienda.falabella.com.co/falabella-co`,
        sodimac: `https://staging-sodimac.falabella.com/sodimac-co`,
        tottus: `https://staging-tottus.falabella.com.co/tottus-co`,
        linio: `https://staging-linio.falabella.com.co/linio-co`,
        homecenter: `https://staging-homecenter.falabella.com.co/homecenter-co`,
      },
      pe: {
        falabella: `https://tienda.falabella.com.pe/falabella-pe`,
        sodimac: `https://sodimac.falabella.com.pe/sodimac-pe`,
        tottus: `https://tottus.falabella.com.pe/tottus-pe`,
        linio: `https://linio.falabella.com.pe/linio-pe`,
        homecenter: `https://homecenter.falabella.com.pe/homecenter-pe`,
      },
    },
  },
};
