import PropTypes from 'prop-types';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import noop from '../../../utils/noop';

const MatchMedia = ({ content, appCtx }) => {
  if (content[appCtx.deviceType]) {
    return content[appCtx.deviceType]();
  }
  return null;
  // return <>{content[appCtx.deviceType] && content[appCtx.deviceType]()}</>;
};

MatchMedia.defaultProps = {
  content: noop,
};

MatchMedia.propTypes = {
  appCtx: PropTypes.object.isRequired,
  content: PropTypes.object,
};

export default withApplicationContext(MatchMedia);
export { MatchMedia };
