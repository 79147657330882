import css from 'styled-jsx/css';
import { colors, icons } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';
import _ from '../../utils/LodashImports';

const A2CPersistContainerStyles = (theme) => {
  const primaryColor = _.get(theme, 'color', null) || colors.primary;
  return css`
    .persist-container {
      display: flex;
      align-items: center;
      gap: 4px;
      flex-direction: column;

      .cart-persist {
        display: flex;
        align-items: center;
        gap: 4px;
        .remove-from-cart-button {
          width: 40px;
          height: 40px;
          display: flex;
          background-color: ${primaryColor};
          border-radius: 80px;
          align-items: center;
          justify-content: center;

          .remove-from-cart-icon {
            width: 20px;
            height: 20px;
            background-image: url('${svgToDataUri(icons.removeFromCartTrash)}');
            background-size: contain;
            background-repeat: no-repeat;

            &.minus {
              background-image: url('${svgToDataUri(icons.removeFromCartMinus)}');
            }
          }
        }
        .count-from-cart {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 100px;
          border-radius: 4px;
          border: 1px solid ${primaryColor};
        }

        .add-to-cart-button {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${primaryColor};
          border-radius: 80px;
          .add-to-cart-icon {
            width: 20px;
            height: 20px;
            background-image: url('${svgToDataUri(icons.addToCartPlus)}');
            background-size: contain;
            background-repeat: no-repeat;
          }
          &.disabled {
            background-color: ${colors.gray.shade46};
            cursor: auto;
          }
        }
        &.disabled {
          .remove-from-cart-button,
          .add-to-cart-button {
            background-color: ${colors.gray.shade46};
            cursor: auto;
          }
          .count-from-cart {
            border: 1px solid ${colors.gray.shade46};
          }
        }
      }
      .max-units-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 14.4px;
        color: ${colors.gray.shade19};
      }
    }
  `;
};

export default A2CPersistContainerStyles;
