import { getUrlForProduct, isStandalone } from '@digital-retail/store-manager';
import constants from '../config/constants';
import getImages from './GetImages';
import _ from './LodashImports';
import { getBreadcrumbData } from './SEO/PDPSeo';

const getRegion = (regionCode) => {
  if (regionCode === 'cl' || !regionCode) {
    return '';
  }
  return `.${regionCode}`;
};

const getCanonicalUrl = (product, region = 'cl', store = null, config = {}) => {
  const { ENVIRONMENT = 'production' } = config;
  const { id, name, slug, currentVariant } = product;
  return getUrlForProduct({
    productId: id,
    skuId: currentVariant,
    name,
    productSlug: slug,
    regionCode: region,
    environment: ENVIRONMENT,
    store,
  }).toString();
};

const showStoreSeoTags = (store, page) => {
  return !!store && Object.keys(constants.STORES).includes(store) && page === 'PDP';
};

const getStoreKey = (store) => {
  const storeMap = {
    tottus: 'TOTTUS',
    sodimac: 'SODIMAC',
    linio: 'LINIO',
    tienda: 'TIENDA',
    homecenter: 'HOMECENTER',
    so_com: 'SO_COM',
    to_com: 'TO_COM',
  };
  return storeMap[store];
};

const getOpenGraphData = (product, appCtx, store = null, page = 'PDP', config = {}) => {
  const { regionCode, store: storeName } = appCtx;
  const { currentVariant: currentVariantId, primaryVariantId, variants, breadCrumb } = product;
  const breadcrumbData = getBreadcrumbData(regionCode, store, breadCrumb);
  const lastBreadCrumb = breadcrumbData.reverse().slice(0, 2)[1];
  const category = lastBreadCrumb?.name || '';
  const primaryVariant = variants.find(({ id }) => {
    return id === primaryVariantId;
  });
  const currentVariant = variants.find(({ id }) => {
    return id === currentVariantId;
  });
  const selectedVariant = primaryVariant || currentVariant;
  const mediaUrl = _.get(selectedVariant, 'medias[0].url');
  const sellerName = _.get(product, 'variants[0].offerings[0].sellerName', '').toLowerCase();
  const isCFMediasEnabled = _.get(appCtx, 'siteConfig.toggles.isCFMediasEnabled', false);
  const mediaDomains = _.get(appCtx, 'siteConfig.configurations.mediaDomains', []);
  const fallBackImagesDomain = _.get(appCtx, 'siteConfig.textDictionary.FALLBACK_IMAGES_DOMAIN', '');
  const imageUrl = getImages({
    variantId: `${currentVariantId || primaryVariantId}_1`,
    isCFMediasEnabled,
    sellerName,
    regionCode,
    url: mediaUrl,
    mediaDomains,
    fallBackImagesDomain,
  });
  let title = `${product.brandName} ${product.name}`;
  if (regionCode === 'pe' && page === 'PDP') {
    title = `${product.name} ${product.brandName}`;
  }
  const countryName = constants.COUNTRY[regionCode];
  const siteTitle = constants.META.OG[regionCode].TITLE;
  const isStoreMetaTags = showStoreSeoTags(store, page);
  const storeKey = getStoreKey(store);
  const descriptionExpression = isStoreMetaTags
    ? constants.META_SITE.DESCRIPTION[storeKey][regionCode][page]
    : constants.META.DESCRIPTION[regionCode][page];

  const url = getCanonicalUrl(product, regionCode, storeName, config);

  const result = {
    description: descriptionExpression
      .replace(/\$product_name\$/g, product.name)
      .replace(/\$brand_name\$/g, product.brandName)
      .replace(/\$country\$/g, countryName)
      .replace(/\$product_id\$/g, product.id)
      .replace(/\$category\$/g, category)
      .replace('Falabella Chile', 'Falabella.com'),
    canonicalUrl: url,
    url,
    site_name: isStandalone({ store: storeName })
      ? constants.META.OG[regionCode][storeName].SITENAME
      : constants.META.OG[regionCode].SITENAME,
    title: isStoreMetaTags
      ? constants.META_SITE.TITLE[storeKey][regionCode][page]
          .replace(/\$product_name\$/g, product.name)
          .replace(/\$country\$/g, countryName)
          .replace(/\$brand_name\$/g, product.brandName)
      : `${title} | ${siteTitle}`,
    images: [
      {
        url: imageUrl,
        alt: product.name,
      },
    ],
  };
  return result;
};

export { getCanonicalUrl, getOpenGraphData, getRegion };
