import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const WarrantyModalStyles = css`
  .drawer-sub-footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .sub-footer {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
    align-items: center;
    width: fit-content;
    justify-self: flex-end;
    padding: 12px 0;
    & :global(button) {
      align-self: center;
      width: auto;
    }
    & :global(button:last-child) {
      align-self: center;
      width: auto;
      padding: 0 20px;
    }
  }
  .cancel {
    display: flex;
    height: 40px;
    padding: 0 32px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
  }

  .warranty-pop-up {
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    cursor: initial;
    height: 100%;
    width: 100%;
    z-index: 200;
    background-color: ${colors.black.shade13};
    justify-content: center;
    align-items: center;
  }
  .modal-content {
    position: relative;
    width: 968px;
    padding: 24px;
    height: fit-content;
    border-radius: 12px;
    overflow: auto;
    background-color: ${colors.white.shade1};
  }
  .drawer-content {
    padding: 8px 20px 20px 20px;
  }
`;

export default WarrantyModalStyles;
