import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import apiConfig from '../../../../config/api/apiConfig';
import constants from '../../../../config/constants';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import CookieStorage from '../../../../utils/CookieStorage';
import { errorMessageHandler } from '../../../../utils/errorHandler';
import httpService from '../../../../utils/httpService';
import { changeTextCase } from '../../../../utils/jsUtils';
import { withLabels } from '../../../../utils/LabelsContext';
import _ from '../../../../utils/LodashImports';
import noop from '../../../../utils/noop';
import { getCommerceHeaders } from '../../../../utils/tenantHelper';
import WebStorage from '../../../../utils/WebStorage';
import { ModalContentCss } from '../../ModalContent/ModalContent.style';
import PickupInStoreAvailabilityOptionItem from './PickupInStoreItem';

const PickupInStoreModal = ({ labels, onClose, selection, setLoading, setError, appCtx, offeringId }) => {
  const { state, municipal, lat, long } = selection;
  const [data, setData] = useState([]);
  const [activeId, setActiveId] = useState(null);

  const { siteConfig, store: storeExp, regionCode } = appCtx;
  const commerceHeaders = getCommerceHeaders(regionCode, storeExp);
  useEffect(() => {
    setError('');
    setLoading(true);
    httpService()
      .get(apiConfig.getPickupFromStoreShippingUrl(regionCode, state, municipal, lat, long, offeringId), {
        headers: commerceHeaders,
      })
      .then((res) => {
        setLoading(false);
        const stores = _.get(res, 'data.data.storePickUp.listOfStores', []);
        setData(stores);
        if (res.statusCode !== 200 && res.error) {
          const resp = JSON.parse(res.data);
          const error = errorMessageHandler(resp.errors);
          setError(error ? error[0].message : '');
        } else if (!stores || stores.length === 0) {
          setError(_.get(siteConfig, `configurations.commerceApiAlerts[CHECKOUT_SLOT_NOT_AVAILABLE]`, ''));
        }
      });
  }, [selection]);

  useEffect(() => {
    try {
      const perviousState = JSON.parse(
        WebStorage.getItem({
          name: constants.LS_STORE_PICKUP_KEY,
          fromPersistence: true,
        })
      );

      setActiveId(perviousState.storeId);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);

  const handleSelection = (id) => {
    setActiveId(id);
  };

  const handleOnClick = () => {
    const store = data.find(({ storeId }) => activeId === storeId);
    // Set Availability_CC cookie
    CookieStorage.setCookie({
      name: constants.COOKIE_STORE_PICKUP_KEY,
      value: {
        ...selection,
        displayName: `En ${store.storeName}`,
        storeId: activeId,
      },
      regionCode,
      store: storeExp,
    });
    // Save CC information to local storage
    WebStorage.setItem({
      name: constants.LS_STORE_PICKUP_KEY,
      value: JSON.stringify({
        ...selection,
        displayName: `En ${store.storeName}`,
        storeId: activeId,
      }),
      shouldPersist: true,
    });

    onClose();
  };

  if (data.length === 0) {
    return null;
  }

  return (
    <div className="resultActive hasBottomOption rebranded">
      <div className="heading">
        <span>
          {data.length} {labels.PICKUP_INSTORE_STORES_AVAILABLE}
        </span>
      </div>

      <div className="optionContainer">
        {data.map((store) => (
          <PickupInStoreAvailabilityOptionItem
            key={store.storeId}
            store={store}
            onChange={handleSelection}
            selected={activeId === store.storeId}
          />
        ))}
      </div>

      <div className="bottomContainer">
        <div className="bottomContainer-item left">
          <div className="icon">
            <i className="csicon-alert" />
          </div>
          <span className="quinary">{labels.PICKUP_INSTORE_INFO_CAN_VARY}</span>
        </div>
        <div className="bottomContainer-item right">
          <button
            id="testId-PickupInStoreModalContent-clearselection"
            type="button"
            onClick={onClose}
            className="button delete"
          >
            {labels.CANCEL}
          </button>
          <button
            id="testId-PickupInStoreModalContent-saveselection"
            type="button"
            onClick={handleOnClick}
            className={`button delivery ${!activeId && 'disabled'}`}
          >
            {changeTextCase(labels.PICKUP_INSTORE_SAVESTORE_BUTTON)}
          </button>
        </div>
      </div>

      <style jsx>{ModalContentCss}</style>
    </div>
  );
};

PickupInStoreModal.propTypes = {
  labels: PropTypes.object,
  onClose: PropTypes.func,
  selection: PropTypes.shape({
    state: PropTypes.string,
    municipal: PropTypes.string,
    lat: PropTypes.string,
    long: PropTypes.string,
  }),
  setLoading: PropTypes.func,
  setError: PropTypes.func,
  appCtx: PropTypes.object,
  offeringId: PropTypes.string,
};

PickupInStoreModal.defaultProps = {
  labels: {},
  onClose: noop,
  selection: { state: '', municipal: '', lat: '', long: '' },
  setLoading: noop,
  setError: noop,
  appCtx: {},
  offeringId: '',
};

export { PickupInStoreModal };
export default withApplicationContext(withLabels(PickupInStoreModal));
