import css from 'styled-jsx/css';
import { typography, colors, icons } from '../../../config/styles';
import { svgUrl } from '../../../utils/staticUrls';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

export const SellerInfoMobileStyle = css`
  .borderContainer {
    padding: 15px 0 20px;
    position: relative;
    border-top: 1px solid ${colors.gray.shade3};
    @mixin mobileToTablet {
      padding-bottom: 0;
    }
    .sellerInfoContainer {
      display: flex;
      align-items: flex-end;

      @mixin desktop {
        border-bottom: 1px solid ${colors.gray.shade3};
      }

      flex-direction: column;
      flex-wrap: wrap;
      line-height: 2rem;
      flex-flow: wrap row;
      font-size: ${typography.sm1};
      color: ${colors.gray.shade15};
      padding-bottom: 16px;

      .link {
        text-decoration: none;
        background-color: transparent;
        color: inherit;
        margin-left: 5px;
        .underline {
          border-bottom: 0.5px solid ${colors.gray.shade15};
          text-transform: capitalize;
        }
      }
      div {
        display: inline-block;
      }
      .highlight {
        color: inherit;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
  .flex {
    display: flex;
    align-items: flex-end;
  }
  .capitalize {
    text-transform: capitalize;
  }
  .market-place-tag {
    height: 19px;
    width: 79px;
    background-color: #969696;
    display: flex;
    &-text {
      height: 14px;
      width: 67px;
      color: ${colors.white.shade1};
      font-size: 12px;
      letter-spacing: 0.1px;
      line-height: 14px;
      margin: auto;
      text-transform: capitalize;
      text-align: center;
    }
  }
  .alertIcon {
    cursor: pointer;
    display: inline-block;
    transform: rotate(180deg);
    margin-left: 6px;
    color: ${colors.gray.shade19};
    &:hover {
      color: ${colors.green.shade1};
    }

    font-size: ${typography.lg1};
  }
  .falabellaIcon {
    background-image: url(${svgUrl('falabella.svg')});
    background-repeat: no-repeat;
    height: 18px;
    width: 12px;
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
  }
  .sodimacIcon,
  .homecenterIcon {
    background-image: url(${svgUrl('sodimac.svg')});
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
  }
  .tottusIcon {
    background-image: url(${svgUrl('tottus.svg')});
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    margin-left: 5px;
    display: inline-block;
    vertical-align: middle;
  }
  .sellerTooltipText {
    color: #767676;
  }
  .international_icon_wrapper {
    padding-top: 14px;
    display: flex;
    width: 100%;
    padding-bottom: 20px;
  }
  .aeroplane-icon {
    background-image: url(${svgUrl('aeroplane.svg')});
    background-repeat: no-repeat;
    height: 23px;
    width: 33px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    background-size: 33px;
  }
  .international-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.156px;
    color: ${colors.gray.shade15};
    .more-info {
      border-bottom: 1px solid ${colors.gray.shade15};
      margin-left: 5px;
      cursor: pointer;
    }
    .csicon-right {
      color: ${colors.gray.shade15};
      width: 25px;
      height: 25px;
      font-size: 10px;
      margin-left: 6px;
    }
  }
  .rebranded {
    .alertIcon {
      color: ${colors.gray.shade45};
      &:hover {
        color: ${colors.gray.shade45};
      }
    }
    .aeroplane-icon {
      width: 33px;
      height: 23px;
      background-image: url('${svgToDataUri(icons.aeroplaneRebranded)}');
    }
  }
  .borderContainer.out-of-stock {
    padding: 0;
    border: none;
    .sellerInfoContainer {
      border: none;
    }
  }
`;
