import constants from '../../config/constants';

export const checkSellerType = (seller) => {
  if (constants.FALABELLA_REGEX.test(seller)) {
    return 'falabella';
  }
  if (constants.SODIMAC_REGEX.test(seller)) {
    return 'sodimac';
  }
  if (constants.TOTUS_REGEX.test(seller)) {
    return 'tottus';
  }
  if (constants.LINIO_REGEX.test(seller)) {
    return 'linio';
  }
  return '';
};

export const getStoreFromName = (storeName) => {
  let name = storeName;
  name = (name && name.toLowerCase()) || '';
  // eslint-disable-next-line no-nested-ternary
  const store = name.includes('tottus') ? 'tottus' : name.includes('sodimac') ? 'sodimac' : 'falabella';
  return store;
};
