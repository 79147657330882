import PropTypes from 'prop-types';
import React, { useState } from 'react';
import MarketplaceSpinner from '../ui/Spinner/MarketplaceSpinner';
import { loaderStyle } from './Loader.style';

const Loader = ({ size }) => {
  return (
    <div className="loader">
      <MarketplaceSpinner size={size} />
      <style jsx>{loaderStyle}</style>
    </div>
  );
};

const withLoader = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithLoader = (props) => {
    const [busy, setBusy] = useState(false);

    return (
      <>
        {busy && <Loader />}
        <WrappedComponent {...props} setBusy={setBusy} />
      </>
    );
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithLoader.getInitialProps = getInitialProps;
  }
  return ComponentWithLoader;
};

Loader.defaultProps = {
  size: 'medium',
};

Loader.propTypes = {
  size: PropTypes.string,
};

export default Loader;

export { withLoader };
