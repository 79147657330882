import apiConfig from '../../../config/api/apiConfig';
import constants from '../../../config/constants';
import httpService from '../../httpService';
import { getCommerceHeaders } from '../../tenantHelper';

const getAllListsCommerce = async ({ appCtx, listTypes = ['MY_FAVORITES', 'WISH_LIST'] }) => {
  const { regionCode, politicalId, store } = appCtx;
  const listTypesString = listTypes.toString();
  const { ATC_SESSION_EXPIRED_CODE } = constants;

  const additionalHeaders = getCommerceHeaders(regionCode, store, true);
  if (!additionalHeaders.Authorization)
    return {
      ok: false,
      error_code: 'USER_NOT_LOGGED_IN',
    };
  const url = apiConfig.getAllListsCommerceUrl(listTypesString, regionCode, politicalId);

  const response = await httpService().get(url, { headers: additionalHeaders }, 'json');
  if (response.statusCode === 200) {
    return { data: response.data.data, ok: true };
  }
  if (response.statusCode === 401) {
    return {
      ok: false,
      error_code: ATC_SESSION_EXPIRED_CODE,
    };
  }
  return {
    ok: false,
    error_code: 'UNKNOWN',
  };
};

export default getAllListsCommerce;
