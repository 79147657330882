import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../utils/LabelsContext';
import { AvailabilityDesktopStyles } from './AvailabilityDesktop.style';

const AvailabilityDesktopDownloadable = ({ labels }) => {
  return (
    <>
      <div className="availability-item rebranded">
        <div className="icon downloadAvailable" />
        <div className="content">
          <div className="content-sub">
            <div id="testId-Availability-Desktop-ModalOpen-downloadAvailable">
              <span className="underline">{labels.AVAILABILITY_IMMEDIATE_DELIVERY} </span>
            </div>
            <span className="bold">{`(${labels.AVAILABILITY_DOWNLOADABLE})`}</span>
          </div>
        </div>
      </div>
      <style jsx>{AvailabilityDesktopStyles}</style>
    </>
  );
};

AvailabilityDesktopDownloadable.propTypes = {
  labels: PropTypes.object,
};

AvailabilityDesktopDownloadable.defaultProps = {
  labels: {},
};

export { AvailabilityDesktopDownloadable };
export default withLabels(AvailabilityDesktopDownloadable);
