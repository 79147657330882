import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import CMRLoaderStyle from './CMRLoader.style';

const CMRLoader = ({ layout }) => {
  return (
    <>
      <div className={`animatedTableBackground  placeholder-${layout} placeholder-small`} />
      <div className="placeholder-large" />
      <style jsx>{CMRLoaderStyle}</style>
    </>
  );
};

CMRLoader.defaultProps = {
  layout: 'hardline',
};

CMRLoader.propTypes = {
  layout: PropTypes.oneOf(['hardline', 'softline']),
};

export default CMRLoader;
