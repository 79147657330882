import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import noop from '../../utils/noop';
import Prices from '../Prices/Prices';
import ProductColorSwatches from '../ProductColorSwatches/ProductColorSwatches';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import Heading from '../ui/Heading/Heading';
import Image from '../ui/Image/Image';
import { VariantHeaderStyles } from './VariantHeader.style';

const VariantHeader = ({
  brandName,
  variantName,
  prices,
  variantId,
  colorVariants,
  variant,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  productId,
  layout,
  width,
  height,
  medias,
  variantUrl,
  appCtx,
}) => {
  const isNewPriceApplicable = _.get(appCtx, 'siteConfig.toggles.isNewPriceApplicable', false);
  const isForCart = variant === 'cart';
  const firstImage = _.get(medias, '[0].url', undefined);

  return (
    <div className={`variantHeader ${variant}`}>
      <div className="variantHeader-image">
        <Image
          className="variantHeader-image"
          id={variantId}
          alt=""
          lazyLoad={!isForCart}
          width={width}
          height={height}
          inlineDimensions={false}
          imageUrl={firstImage}
        />
      </div>
      <div className="variantHeader-text">
        <div className="brandName">
          {isForCart ? (
            <BodyCopy type="title17" size="primary">
              {brandName.toUpperCase()}
            </BodyCopy>
          ) : (
            <Heading level={2} variation="title9">
              {brandName.toUpperCase()}
            </Heading>
          )}
        </div>
        {isForCart ? (
          <BodyCopy type="title19" size="primary" ellipsis maxlines={2}>
            {variantName}
          </BodyCopy>
        ) : (
          <h3
            className={clsx('variantName', {
              'mb-12px': isNewPriceApplicable,
              [`variantName-${layout}`]: layout,
            })}
          >
            {variantName}
          </h3>
        )}

        {!isForCart && prices.length > 0 && (
          <Prices priceList={prices} deviceType="desktop" page="PDP" priceCount={prices.length} variant="TYPE_A" />
        )}

        {isForCart && colorVariants[selectedColorSwatchIndex] && (
          <div className="color-swatch-container">
            <ProductColorSwatches
              colorVariants={colorVariants}
              selectedColorSwatchIndex={selectedColorSwatchIndex}
              colorSwatchHandler={colorSwatchHandler}
              type={constants.VIEW_TEMPLATE.TYPE_A}
              size="small"
              productId={productId}
              appCtx={appCtx}
            />
          </div>
        )}
      </div>
      <style jsx>{VariantHeaderStyles}</style>
    </div>
  );
};

VariantHeader.defaultProps = {
  brandName: '',
  prices: [],
  layout: undefined,
  variant: '',
  colorVariants: [],
  selectedColorSwatchIndex: -1,
  colorSwatchHandler: noop,
  productId: '',
  width: 150,
  height: 150,
  medias: [],
  variantUrl: '',
};

VariantHeader.propTypes = {
  variantId: PropTypes.string.isRequired,
  variantName: PropTypes.string.isRequired,
  brandName: PropTypes.string,
  prices: PropTypes.arrayOf(PropTypes.object),
  layout: PropTypes.string,
  variant: PropTypes.string,
  colorVariants: PropTypes.arrayOf(PropTypes.object),
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  productId: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  medias: PropTypes.array,
  variantUrl: PropTypes.string,
  appCtx: PropTypes.object.isRequired,
};

export default withApplicationContext(withLabels(VariantHeader));
export { VariantHeader };
