import React, { useEffect } from 'react';
import { clsx } from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { DeliveryOptionsContentStyles } from './DeliveryOptionsContent.style';
import DeliveryOptionsList from './DeliveryOptionsList';
import { getSelectedData } from '../DeliveryOptions.Helper';
import noop from '../../../../utils/noop';

const DeliveryOptionsContent = ({
  title,
  type,
  data,
  setBusy,
  zoneConfig,
  onCambiar,
  setIsCambiar,
  setSelectedDeliveryOption,
  isFreeShippingApplicable,
  deviceType,
  selectedStoreName,
  theme,
}) => {
  const selectedComunaName = _.get(zoneConfig, 'name', '').toLowerCase();
  const [lineBreak, setLineBreak] = React.useState(false);
  const themedStoresListStyles = DeliveryOptionsContentStyles(theme);

  useEffect(() => {
    setIsCambiar(false);
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      const { selectedType, price, date, month, storeName, storeId, isValid, locationName, politicalId, isDateRange } =
        getSelectedData(data, type, zoneConfig, isFreeShippingApplicable);
      if (isValid) {
        setSelectedDeliveryOption({
          type: selectedType,
          price,
          date,
          month,
          locationName,
          storeName,
          storeId,
          politicalId,
          isError: false,
          isDateRange,
          isFreeShippingApplicable,
        });
      }
    }
    return () => {
      setLineBreak(false);
    };
  }, [data]);
  useEffect(() => {
    const locationNameElement = document.querySelector('.location-name');
    if (locationNameElement && locationNameElement.clientHeight > 20) {
      setLineBreak(true);
    }
  }, [data, selectedStoreName, zoneConfig]);
  return (
    <div className="container">
      <div className="header">
        <div className="icon-container">
          <i
            className={clsx('icon', {
              homeDelivery: type === 'HomeDelivery',
              pickupInStore: type === 'PickupInStore',
            })}
          />
        </div>
        <div className="heading">
          <p>{title}</p>
        </div>
      </div>
      <div className="body">
        <div className="location-selection">
          <i className={`location-icon ${lineBreak ? 'icon-lb' : ''}`} />
          <p
            className={`location-name-prefix ${type === 'HomeDelivery' && lineBreak ? 'line-break-hd' : ''} ${
              type === 'PickupInStore' && lineBreak ? 'line-break-cc' : ''
            }`}
          >
            {type === 'HomeDelivery' ? 'A' : 'En'}
          </p>
          <p
            className={`location-name ${type === 'HomeDelivery' && lineBreak ? 'line-break-hd' : ''} ${
              type === 'PickupInStore' && lineBreak ? 'line-break-cc' : ''
            }`}
          >
            {type === 'HomeDelivery' ? selectedComunaName : selectedStoreName}
          </p>
          <div className={`change-location-container ${lineBreak ? 'location-lb' : ''}`}>
            <i className="change-location-icon" />
            <button type="button" className="change-location-button" onClick={() => onCambiar()}>
              Cambiar
            </button>
          </div>
        </div>
        {data && data.length > 0 && (
          <DeliveryOptionsList
            data={data}
            setBusy={setBusy}
            isFreeShippingApplicable={isFreeShippingApplicable}
            type={type}
            deviceType={deviceType}
          />
        )}
      </div>
      <div className="footer">
        <div className="footer-body">
          <i className="alert-icon" />
          <p className="alert-message">La disponibilidad puede variar durante la compra.</p>
        </div>
      </div>
      <style jsx>{themedStoresListStyles}</style>
    </div>
  );
};

DeliveryOptionsContent.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.array,
  setBusy: PropTypes.func,
  zoneConfig: PropTypes.object,
  onCambiar: PropTypes.func,
  setIsCambiar: PropTypes.func,
  setSelectedDeliveryOption: PropTypes.func,
  isFreeShippingApplicable: PropTypes.bool,
  deviceType: PropTypes.string,
  selectedStoreName: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

DeliveryOptionsContent.defaultProps = {
  title: '',
  type: '',
  data: [],
  setBusy: noop,
  zoneConfig: {},
  onCambiar: noop,
  setIsCambiar: noop,
  setSelectedDeliveryOption: noop,
  isFreeShippingApplicable: false,
  deviceType: '',
  selectedStoreName: '',
};
export default withTheme(DeliveryOptionsContent);
