import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SingleChipletSelect from '../ui/SingleChipletSelect/SingleChipletSelect';
import _ from '../../utils/LodashImports';
import { isEmpty } from '../../utils/customMethods';
import { withDigitalData } from '../../utils/DigitalDataContext';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { WarrantyStyles } from './Warranty.style';
import { withCart } from '../../utils/CartContext';
import WarrantyModal from './WarrantyModal';
import { getWarrantyLabel } from '../ui/WarrantySelect/WarrantySelect.helpers';

const Warranty = ({
  warrantyOptions,
  setWarrantyOption,
  startsFromLabel,
  tooltipToggle,
  additionalWarrantyLabel,
  sellerName,
  digitalData,
  cart,
  appCtx,
}) => {
  const { warrantyOptionCurrent } = cart;
  const firstWarranty = warrantyOptions.fieldOptions[0];
  const [showModal, setShowModal] = useState(false);
  const [highlightedWarranty, setHighlightedWarranty] = useState(firstWarranty);
  const toggleModalVisibility = () => {
    setShowModal((prevState) => !prevState);
  };

  useEffect(() => {
    if (warrantyOptionCurrent) {
      setHighlightedWarranty(warrantyOptionCurrent);
    }
  }, [warrantyOptionCurrent]);

  const {
    EXTENDED_WARRANTY_LABEL = 'Extiende la garantía del producto',
    MORE_WARRANTY_OPTIONS = 'Conocer más opciones',
    WARRANTY_LABEL_TEMPLATE = '{warrantyName}',
  } = _.get(appCtx, 'siteConfig.textDictionary', {});

  const handleWarrantyChange = (option) => {
    setHighlightedWarranty(option);
    setWarrantyOption(option);
  };
  const tooltipContent = !isEmpty(warrantyOptions.warrantyTooltip) ? warrantyOptions.warrantyTooltip : undefined;
  if (warrantyOptions && warrantyOptions.fieldOptions && warrantyOptions.fieldOptions.length) {
    digitalData.pdp.setExtendedWarrantyOptsQty(warrantyOptions.fieldOptions.length);
    const isImprovedWarrantyUiEnabled = _.get(appCtx, 'siteConfig.toggles.isImprovedWarrantyUiEnabled', true);
    const selectHandler = (option) => {
      const updatedOption = { ...option, selected: !option.selected };
      if (updatedOption.selected) {
        setWarrantyOption(updatedOption);
        return;
      }
      setWarrantyOption();
      setHighlightedWarranty(updatedOption);
    };
    return isImprovedWarrantyUiEnabled ? (
      <>
        <div className="warranty-container">
          <div className="title">
            <span className="warranty-icon" />
            <p>{EXTENDED_WARRANTY_LABEL}</p>
          </div>
          <div className="highlighted-warranty">
            <div className="label">
              <button
                type="button"
                className={`checkbox ${highlightedWarranty.selected ? 'full' : 'empty'}`}
                onClick={() => selectHandler(highlightedWarranty)}
              />
              <div className="warranty-name">
                {highlightedWarranty.offeringId
                  ? getWarrantyLabel({
                      name: highlightedWarranty.name,
                      sellerName,
                      warrantyLabelTemplate: WARRANTY_LABEL_TEMPLATE,
                    })
                  : highlightedWarranty.name}
              </div>
            </div>
            <div className="warranty-price">{highlightedWarranty.textPrice}</div>
          </div>

          <button type="button" className="more-warranty-options" onClick={toggleModalVisibility}>
            {MORE_WARRANTY_OPTIONS}
          </button>
        </div>
        <WarrantyModal
          appCtx={appCtx}
          options={warrantyOptions.fieldOptions}
          showModal={showModal}
          setShowModal={setShowModal}
          handleWarrantyChange={handleWarrantyChange}
          warrantyOptionCurrent={warrantyOptionCurrent}
        />
        <style jsx>{WarrantyStyles}</style>
      </>
    ) : (
      <div className="fa--warranty">
        <SingleChipletSelect
          options={warrantyOptions.fieldOptions}
          cheapestOption={warrantyOptions.fieldOptions[0].price}
          tooltipContent={tooltipContent}
          tooltipToggle={tooltipToggle}
          startsFromLabel={startsFromLabel}
          label={EXTENDED_WARRANTY_LABEL}
          onOptionSelected={handleWarrantyChange}
          secondaryLabelIcon="csicon-alert"
          icon="csicon-shield"
          shouldHaveBackground={false}
          type="medium"
          withPadding={false}
          highLightHeading
          clickableChiplet
          additionalWarrantyLabel={additionalWarrantyLabel}
          serviceType="warranty-option"
          hideSubtitle
        />
      </div>
    );
  }
  return null;
};

Warranty.defaultProps = {
  setWarrantyOption: () => {},
  warrantyOptions: {
    label: '',
    fieldOptions: [],
  },
  startsFromLabel: 'A partir de: ',
  tooltipToggle: false,
  additionalWarrantyLabel: {},
  sellerName: '',
};

Warranty.propTypes = {
  warrantyOptions: PropTypes.object,
  setWarrantyOption: PropTypes.func,
  startsFromLabel: PropTypes.string,
  tooltipToggle: PropTypes.bool,
  additionalWarrantyLabel: PropTypes.object,
  digitalData: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  sellerName: PropTypes.string,
};

export default withCart(withApplicationContext(withDigitalData(Warranty)));
export { Warranty };
