import constants from '../config/constants';
import { storeMap } from '../config/storeMap';
import CookieStorage from './CookieStorage';

const getLocation = (uri) => {
  const regex = new RegExp(
    [
      '^(https?:)//', // protocol
      '(([^:/?#]*)(?::([0-9]+))?)', // host (hostname and port)
      '(/{0,1}[^?#]*)', // pathname
      '(\\?[^#]*|)', // search
      '(#.*|)$', // hash
    ].join('')
  );
  const match = uri.match(regex);
  return (
    match && {
      href: uri,
      protocol: match[1].replace(':', ''),
      host: match[2],
      hostname: match[3],
      port: match[4] || '80',
      pathname: match[5] || '/',
      search: match[6],
      hash: match[7],
    }
  );
};

const getPage = (uri) => {
  const errorPage = document.getElementsByClassName('error-page');
  if (errorPage.length > 0) {
    return 'error';
  }
  const path = getLocation(uri);
  const paths = path.pathname.split('/');
  return paths.length > 2 ? paths[2] : '';
};

const parseQueryString = (url) => {
  const params = {};
  if (url.indexOf('?') === -1) {
    return params;
  }
  url
    .substring(url.indexOf('?') + 1)
    .split('&')
    .filter((param) => param)
    .filter((p) => !!p)
    .forEach((param) => {
      const [name = '', value = ''] = param.split('=');
      if (name) {
        params[decodeURIComponent(name)] = decodeURIComponent(value.replace(/\+/g, '%20')) || '';
      }
    });

  return params;
};

const serializeQuery = (params = {}, ignoreQuery = []) => {
  return Object.keys(params)
    .filter((name) => ignoreQuery.indexOf(name) === -1)
    .map((name) => {
      if (params[name]) {
        const value = encodeURIComponent(params[name]);
        return `${encodeURIComponent(name)}=${value.replace(/(%20)+/g, '+')}`;
      }
      return encodeURIComponent(name);
    })
    .join('&');
};

const getStoreValue = (seller) => {
  return storeMap[seller] ? seller.toLowerCase() : 'falabella';
};

const isMarketPlace = () => {
  const experience = CookieStorage.getCookie('seller_experience');
  return experience === 'MARKETPLACE';
};

const getSidCookie = () => {
  return CookieStorage.getCookie('sid');
};

const getRequestUrl = (reqUrl, tenant) => {
  const { site = tenant, store, ...rest } = parseQueryString(reqUrl);
  const baseURL = `/${site}${reqUrl.split('?')[0]}`;
  const qString = serializeQuery(rest);
  return qString ? `${baseURL}?${qString}` : `${baseURL}`;
};

const getProductUrlParams = ({ params, appCtx, storeQuery }) => {
  return {
    ...params,
    pgid: appCtx.priceGroupId,
    pid: appCtx.politicalId,
    zones: appCtx.zones,
    channel: 'web',
    isUserSelectedZone: appCtx.isUserSelectedZone,
    latAndLong: encodeURIComponent(JSON.stringify(appCtx.latLong || {})) || '',
    ...(storeQuery && { exp: storeQuery }),
  };
};

const is3pSeller = (sellerId) => {
  return !constants.FIRST_PARTY_SELLER_REGEX.test(sellerId.toLowerCase());
};

const getPreviousUrl = () => {
  return window.previousUrl || document.referrer;
};

export {
  getPage,
  getPreviousUrl,
  getProductUrlParams,
  getRequestUrl,
  getSidCookie,
  getStoreValue,
  is3pSeller,
  isMarketPlace,
};
