import { useEffect, useState } from 'react';
import constants from '../../config/constants';
import { isWindow } from '../../utils/deviceChecker';

const useColorSwatchesMetrics = () => {
  const { PURCHASE_COLOR_SWATCH, NOT_PURCHASE_COLOR_SWATCH } = constants.COLOR_SWATCH_METRICS.METRICS_DATA;

  const [colorSwatch, setColorSwatch] = useState([]);

  const [productId, setProductId] = useState('');
  const [isColorSwatchA2C, setIsColorSwatchA2C] = useState(NOT_PURCHASE_COLOR_SWATCH);
  const [selectedColor, setSelectedColor] = useState(null);

  const handleColorSwatchMetrics = (pdpColor) => {
    if (isWindow()) {
      const eventToDigitalData = new CustomEvent('DigitalDataColorsMetrics', {
        bubbles: true,
        detail: {
          payload: {
            pdpColor,
          },
        },
      });
      window.dispatchEvent(eventToDigitalData);
      setProductId('');
      setColorSwatch([]);
      setIsColorSwatchA2C(NOT_PURCHASE_COLOR_SWATCH);
    }
  };

  const handleUnload = () => {
    const pdpColor = `${productId}|${colorSwatch.join('|')}|${isColorSwatchA2C}`;
    if (colorSwatch.length !== 0) {
      handleColorSwatchMetrics(pdpColor);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      handleUnload();
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [productId, colorSwatch, isColorSwatchA2C, selectedColor]);

  const setColorAtIndex = (index, newColor) => {
    setColorSwatch((prevSwatch) => {
      const newSwatch = [...prevSwatch];
      newSwatch[index] = `CLICK ${newColor}`;
      return newSwatch;
    });
    setSelectedColor(newColor);
  };

  const sendIsColorPurshased = (currentColorName) => {
    setColorSwatch((prevSwatch) => {
      const newSwatch = [...prevSwatch];
      return newSwatch;
    });
    setIsColorSwatchA2C(`${PURCHASE_COLOR_SWATCH} ${currentColorName}`);
  };

  return {
    productId,
    setProductId,
    setColorAtIndex,
    isColorSwatchA2C,
    setIsColorSwatchA2C,
    PURCHASE_COLOR_SWATCH,
    selectedColor,
    sendIsColorPurshased,
    colorSwatch,
    setColorSwatch,
  };
};

export default useColorSwatchesMetrics;
