/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { svgToDataUri } from '../../../../utils/styles/svgMixins';
import { colors, icons } from '../../../../config/styles';
import _ from '../../../../utils/LodashImports';

export const DeliveryOptionsContentStyles = (theme) => {
  const secondaryColor = _.get(theme, 'secondary_color', null);

  return css`
    .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .relative {
      position: relative;
    }

    .header {
      width: 100%;
      height: 71px;
      border-bottom: 1px solid #f0f0f0;
      display: flex;
      align-items: center;
      padding: 27px 0 19px 37px;

      @mixin mobileToTablet {
        height: 83px;
        padding: 36px 0 24px 36px;
      }
      .icon-container {
        .icon {
          width: 40px;
          height: 24px;
          background-image: url('${svgToDataUri(icons.homeDeliveryIcon)}');
          background-size: contain;
          background-repeat: no-repeat;
          display: block;

          &.homeDelivery {
            width: 40px;
            height: 24px;
            background-image: url('${svgToDataUri(icons.homeDeliveryIcon)}');
          }

          &.pickupInStore {
            width: 32px;
            height: 32px;
            background-image: url('${svgToDataUri(icons.pickupInStoreIcon)}');
            margin: 0 3px 5px 0;
          }
        }
      }

      .heading {
        font-family: Lato, sans-serif;
        margin-left: 18px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #333;

        @mixin mobileToTablet {
          font-size: 22px;
          line-height: 26px;
        }
      }
    }

    .body {
      padding: 0 32px;
      width: 100%;
      flex: 1;

      @mixin mobileToTablet {
        padding: 0 17px;
      }

      .location-selection {
        width: 100%;
        height: 51px;
        background: #f2f2f2;
        border-radius: 4.5px;
        margin-top: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        .location-icon {
          margin: 6px 0 5px 8px;
          width: 40px;
          height: 40px;
          background-image: url('${svgToDataUri(icons.locationIcon)}');
          background-size: contain;
          background-repeat: no-repeat;
          display: block;

          &.icon-lb {
            margin-top: -5px;
          }
        }

        .location-name-prefix {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #333;
          text-transform: capitalize;
          @mixin mobileToTablet {
            font-size: 14px;
            line-height: 17px;
          }
          &.line-break-hd {
            position: absolute;
            top: 8.5px;
            left: 48px;
          }
          &.line-break-cc {
            position: absolute;
            left: 49px;
            top: 8.5px;
          }
        }

        .location-name {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          margin-left: 4px;
          color: #333;
          flex: 1;
          text-transform: capitalize;

          @mixin mobileToTablet {
            font-size: 14px;
            line-height: 17px;
            margin-left: 5px;
          }
          &.line-break-hd {
            text-indent: 15px;
            margin: 0 10px 0 0;
          }
          &.line-break-cc {
            text-indent: 20.5px;
            margin: 0 10px 0 0;
          }
        }

        .change-location-container {
          display: flex;
          margin-right: 24px;

          &.location-lb {
            align-self: start;
            margin-top: 8px;
          }
          .change-location-icon {
            width: 12px;
            height: 13px;
            background-image: url('${svgToDataUri(icons.changeLocationIcon)}');
            background-size: contain;
            background-repeat: no-repeat;
            display: block;
            margin-right: 6px;
            margin-top: 3px;
          }

          .change-location-button {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: right;
            text-decoration-line: underline;
            color: #333;

            @mixin mobileToTablet {
              font-size: 14px;
              line-height: 17px;
            }
          }
        }
      }
    }

    .data-section {
      width: 100%;
      margin: 24px 0;

      @mixin mobileToTablet {
        margin-top: 32px;
      }

      h1 {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #333;
      }

      .data-body {
        width: 100%;
        display: flex;
        flex-direction: column;

        .data-list {
          display: flex;
          width: 100%;
          margin-top: 24px;
          flex-direction: column;

          @mixin mobileToTablet {
            margin-top: 32px;
          }

          .data-entity {
            width: 100%;
            display: flex;
            align-items: center;

            .check-icon {
              width: 10px;
              height: 9px;
              background-image: url('${svgToDataUri(icons.darkCheckIcon)}');
              background-size: contain;
              background-repeat: no-repeat;
              display: block;
            }
            .data-title {
              font-family: 'Lato', sans-serif;
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 20px;
              color: #333;
              margin-left: 12px;

              .data-span {
                font-size: 14px;
                &.green {
                  color: #30871f;
                }
                &.bold {
                  font-weight: 700;
                }
                @mixin mobileToTablet {
                  float: right;
                  line-height: 17px;
                }
              }
              @mixin mobileToTablet {
                width: 100%;
              }
            }
          }

          .data-subtitle {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.2px;
            margin-left: 22px;
            color: #4a4a4a;
            margin-top: 2px;
            span {
              color: #30871f;
              font-weight: 700;
            }
            @mixin mobileToTablet {
              margin-top: 8px;
            }
          }
        }
      }
    }
    .view-button {
      position: absolute;
      top: 92px;
      right: 12px;
      z-index: 1;
      display: flex;
      @mixin tabletToDesktop {
        display: none;
      }
    }
    .list-view-btn {
      background-image: url('${svgToDataUri(icons.listViewIcon)}');
      width: 36px;
      display: block;
      height: 32px;
      &.active {
        background-image: url('${svgToDataUri(icons.listViewIconActive)}');
      }
    }
    .map-view-btn {
      background-image: url('${svgToDataUri(icons.mapsViewIcon)}');
      width: 36px;
      display: block;
      height: 32px;
      margin-right: 12px;
      &.active {
        background-image: url('${svgToDataUri(icons.mapsViewIconActive)}');
      }
    }
    .zone-change {
      font-size: 14px;
      text-decoration: underline;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${colors.gray.shade12};
      position: absolute;
      top: 100px;
      left: 10px;
      z-index: 1;
      height: 32px;
      width: 220px;
      font-weight: bold;
      border-radius: 5px;
      opacity: 90%;
      @mixin mobileToTablet {
        top: 92px;
      }
      &.list-view {
        background: none;
      }
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 8px;
        &:first-child {
          width: 75%;
        }
      }
      .arrow_down {
        background-image: url('/a/fa/product/static/styles/svg/HeaderIcons/arrow.svg');
        width: 14px;
        height: 16px;
        display: block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 9px;
      }
    }
    .flex-center {
      height: 72px;
      display: flex;
      justify-content: center;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
      align-items: center;
      background: ${colors.white.shade1};
      .save-zone {
        background-color: ${secondaryColor};
        color: ${colors.white.shade1};
        width: 147px;
        height: 40px;
        border-radius: 4px;
      }
      .cancel {
        width: 147px;
        height: 40px;
        color: ${colors.gray.shade64};
        font-weight: 700;
      }
      @mixin mobileToTablet {
        display: none;
      }
    }
    .fetch-more-stores {
      border-radius: 12px;
      background: rgb(51, 51, 51);
      color: rgb(255, 255, 255);
      font-family: Lato, sans-serif;
      width: 130px;
      height: 21px;
      font-size: 12px;
      opacity: 90%;
      position: fixed;
      bottom: 350px;
      left: 46%;
      display: none;
    }
    .footer {
      padding: 0 32px;
      width: 100%;
      height: 52px;

      @mixin mobileToTablet {
        padding: 0 16px;
        height: 49px;
      }

      .footer-body {
        width: 100%;
        height: 100%;
        border-top: 1px solid #f0f0f0;
        display: flex;
        align-items: center;

        .alert-icon {
          width: 15px;
          height: 15px;
          background-image: url('${svgToDataUri(icons.alertIcon)}');
          background-size: contain;
          background-repeat: no-repeat;
          display: block;

          @media screen and (max-width: 360px) {
            align-self: self-start;
            margin: 10px 0;
          }
        }

        .alert-message {
          font-family: Lato, sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #4a4a4a;
          margin-left: 8px;

          @mixin mobileToTablet {
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
  `;
};
