import css from 'styled-jsx/css';
import { colors } from '../../config/styles';

const VariantHeaderStyles = css`
  .variantHeader {
    display: flex;
    flex-direction: row;

    @mixin desktop {
      width: 380px;
    }

    &-image {
      flex-shrink: 0;
      width: 100px;
      height: 100px;

      @mixin desktop {
        width: 150px;
        height: 150px;
      }
    }

    &-text {
      margin-left: 30px;
      width: 200px;

      @mixin phabletToDesktop {
        margin-left: 30px;
      }
      @mixin desktop {
        margin-top: 20px;
        width: auto;
      }
      &.mkp {
        @mixin smallerMobile {
          margin-left: 7px;
        }
      }

      .variantName {
        text-align: left;
        margin-top: 5px;
        line-height: 19px;
        color: ${colors.black.shade1};

        @mixin desktop {
          margin-bottom: 30px;
        }

        &.mb-12px {
          margin-bottom: 12px;
        }
        &.mb-2px {
          margin-bottom: 2px;
        }
      }

      a.variant-anchor {
        text-decoration: none;
        color: inherit;
      }
    }
  }

  .variantHeader.cart {
    width: 161px;
    min-height: 90px;

    @mixin desktop {
      width: 162px;
      margin-right: 18px;
    }

    .brandName {
      :global(span) {
        color: ${colors.black.shade7};
        padding-bottom: 3px;
        font-weight: bold;
      }
    }

    .variantHeader-image {
      flex-shrink: 0;
      width: 90px;
      height: 90px;
      border: 1px solid ${colors.gray.shade43};

      @mixin tabletToDesktop {
        width: 46px;
        height: 46px;
      }
    }
    .variantHeader-text {
      margin-left: 19px;
      margin-top: 0;

      .variantName {
        margin-top: 2px;
        line-height: 15px;
      }
    }
  }
`;

export { VariantHeaderStyles };
