import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal } from '../ui/Modal/Modal';
import ServicesModalStyles from './ServiceModal.style';
import ServicesSelectEnhanced from '../ui/ServicesSelect/ServicesSelectEnhanced';
import Button from '../ui/Button/Button';
import Drawer from '../ui/Drawer/Drawer';
import noop from '../../utils/noop';
import _ from '../../utils/LodashImports';

const ServicesModal = ({ handleServiceChange, options, appCtx, showModal, setShowModal }) => {
  const [serviceoptions, setServiceOptions] = useState(options);

  useEffect(() => {
    setServiceOptions(options);
  }, [showModal]);

  const { CANCEL = 'Cancelar', ACCEPT_MKP = 'Aceptar' } = _.get(appCtx, 'siteConfig.textDictionary', {});

  const handleServicesSelect = (selectedOptions) => {
    setServiceOptions(selectedOptions);
  };

  const onSubmit = () => {
    handleServiceChange([...serviceoptions]);
    setShowModal(false);
  };

  const getContent = () => {
    return (
      <>
        <ServicesSelectEnhanced onOptionSelected={handleServicesSelect} options={serviceoptions} appCtx={appCtx} />
        <div className="sub-footer">
          <button type="button" className="cancel" onClick={() => setShowModal(false)}>
            {CANCEL}
          </button>
          <div className="submit-warranties">
            <Button type="mkp-secondary" onClick={onSubmit}>
              {ACCEPT_MKP}
            </Button>
          </div>
        </div>
        <style jsx>{ServicesModalStyles}</style>
      </>
    );
  };

  return appCtx.deviceType === 'desktop' ? (
    <Modal
      content={getContent}
      options={{
        disableOutsideClick: true,
        padding: '24px',
        overflow: 'hidden',
        width: 968,
        height: 'fit-content',
        borderRadius: '12px',
      }}
      withPadding={false}
      toggleState={{
        visible: showModal,
        setVisible: setShowModal,
      }}
    />
  ) : (
    <Drawer toggleState={[showModal, setShowModal]} isLoading={false} type="secondary" icon="services-icon">
      <div className="drawer-content">
        <ServicesSelectEnhanced
          onOptionSelected={handleServicesSelect}
          options={serviceoptions}
          appCtx={appCtx}
          source="drawer"
        />
        <div className="drawer-sub-footer">
          <div className="submit">
            <Button type="mkp-secondary" onClick={onSubmit}>
              {ACCEPT_MKP}
            </Button>
          </div>
          <button
            type="button"
            className="cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            {CANCEL}
          </button>
        </div>
        <style jsx>{ServicesModalStyles}</style>
      </div>
    </Drawer>
  );
};

ServicesModal.defaultProps = {
  showModal: false,
  setShowModal: noop,
  handleServiceChange: noop,
  options: [],
  appCtx: {},
};

ServicesModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  handleServiceChange: PropTypes.func,
  options: PropTypes.array,
  appCtx: PropTypes.object,
};

export default ServicesModal;
