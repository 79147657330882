import { isSodimacStandalone } from '@digital-retail/store-manager';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import AddToCartButton from '../../../../components/AddToCartButton/Desktop/AddToCartButtonDesktop';
import AddToCartButtonMobile from '../../../../components/AddToCartButton/Mobile/AddToCartButtonMobile';
import AdditionalPDPLabel from '../../../../components/AdditionalPDPLabel/AdditionalPDPLabel';
import Badges from '../../../../components/Badges/Badges';
import InternationalShipping from '../../../../components/InternationalShipping/InternationalShipping';
import MultipurposeBadge from '../../../../components/MultipurposeBadge/MultipurposeBadge';
import { VariantId } from '../../../../components/PDP';
import ProductColorSwatches from '../../../../components/ProductColorSwatches/ProductColorSwatches';
import ProductPrices from '../../../../components/ProductPrices/ProductPrices';
import { ReaconditionedContainer } from '../../../../components/ReaconditionedContainer/ReaconditionedContainer';
import SellerInfo from '../../../../components/SellerInfo/SellerInfo';
import Size from '../../../../components/Size/Size';
import StockQuantity from '../../../../components/StockQuantity/StockQuantity';
import BodyCopy from '../../../../components/ui/BodyCopy/BodyCopy';
import ScrollTo from '../../../../components/ui/ScrollTo/ScrollTo';
import StockTable from '../../../../components/ui/StockTable/StockTable';
import constants from '../../../../config/constants';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import { withDigitalData } from '../../../../utils/DigitalDataContext';
import { withLabels } from '../../../../utils/LabelsContext';
import _ from '../../../../utils/LodashImports';
import { withProduct } from '../../../../utils/ProductContext';
import noop from '../../../../utils/noop';
import { getPriceForOutOfStock, isReaconditionedProduct } from '../../../../utils/product/productUtils';
import { getVariant } from '../../../../utils/variant';
import { SKUIdSeller } from '../../../common/PDPBasicInfoShared';
import { GetSharedDetails } from '../../shared';
import useInput from '../../useInput';
import { ProductSpecificationsMobileStyle } from './ProductSpecificationMobile.style';

const ProductSpecificationsMobile = ({
  product,
  labels,
  appCtx,
  isPumAvailable,
  pumQuantity,
  primeShipping,
  colorVariants,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  sizes,
  selectedSize,
  sizeChangeHandler,
  handleAddToCart,
  isAddToCartInProgress,
  handleRemoveFromCart,
  isRemoveFromCartInProgress,
  addToCartButtonVisible,
  showCollectionButtonVisible,
  isVendorLogin,
  storeSkuData,
  currentTab,
  errorAddingToCart,
  requestProductFromStore,
  digitalData,
  setShowLoginForm,
  showLoginForm,
  setErrorAddingToCart,
  sizeChartData,
  fomo,
}) => {
  const isInternationalShipping = _.get(product, 'internationalShipping.applicable', false);
  const { regionCode, deviceType, store } = appCtx;
  const isSoCom = isSodimacStandalone(store);
  const {
    id: productId,
    currentVariant,
    variants,
    additionalPDPLabels,
    isOutOfStock,
    colorMetrics,
    darSectionActive = false,
  } = product;
  const variant = getVariant(variants, currentVariant);
  const maxCartQuantity = _.get(variant, 'maxOnCartAmount', constants.MAX_CART_PRODUCT);
  const comboData = _.get(variant, 'comboData', null);
  const { showQuantityError, inputVal, handleInputChange, setQuantityError, setInputVal } = useInput(maxCartQuantity);
  const { pumUnits } = product.attributes;
  const sellerName = _.get(variants, '[0].offerings[0].sellerName', '');
  const is1pSeller = constants.FIRST_PARTY_SELLER_REGEX.test(sellerName.toLowerCase());
  const multipurposeBadges = _.get(variant, 'multipurposeBadges', []);
  const { prices, cmrPoints, discountBadge } = variant;
  const isMobile = deviceType === constants.DEVICE_MOBILE;

  const getModelsBodyCharacteristics = () => {
    const specs = _.get(product, 'attributes.specifications', []);
    const modelo = specs.find((spec) => constants.MODEL_CHARACTERISTICS_SPECIFICATION_ID.includes(spec.name));
    return modelo && `${labels.MODEL_CHARACTERISTICS_PREFIX} ${modelo.value}`;
  };

  const modelCharacteristics = getModelsBodyCharacteristics();
  digitalData.pdp.setProductFeatures(modelCharacteristics ? '1' : '');
  const isStoreDataVaild = storeSkuData && Object.keys(storeSkuData).length > 0;

  const isReaconditionedProd = isReaconditionedProduct(product);
  const { badges = [], meatStickers = [], promotions } = getVariant(product.variants, product.currentVariant);
  const nonEventMeatStickers = (meatStickers || []).filter((sticker) => !['event'].includes(sticker.type));
  const allowed3PCampaignNamesToRender = _.get(
    appCtx,
    'siteConfig.textDictionary.ALLOWED_3P_CAMPAIGN_NAMES_TO_RENDER',
    'ENVIO GRATIS 3P, ENVIO_GRATIS_3P'
  );
  const freeShippingPromotionFor3p = promotions?.find((promotion) =>
    allowed3PCampaignNamesToRender
      .split(',')
      .map((name) => name.trim())
      .includes(promotion.campaignName)
  );
  const isMeatStickerApplicableInPDP = _.get(appCtx, 'siteConfig.toggles.isMeatStickerApplicableInPDP', false);
  const hasStickers = (isMeatStickerApplicableInPDP && meatStickers.length > 0) || freeShippingPromotionFor3p;

  if (isOutOfStock) {
    return (
      <div
        className="product-specifications"
        data-backend-category={product.merchantCategoryId}
        data-availability={product.attributes && product.attributes.isPrimeAvailable}
        data-published={product.isPublished}
        data-variations={variants.length}
      >
        <div className="product-specifications-column">
          <div className={clsx({ flex: !isMobile })}>
            <div>
              {prices && prices.length > 0 && (
                <div className="prices">
                  <ProductPrices
                    isVendorLogin={isVendorLogin}
                    currentTab={currentTab}
                    storeSkuData={storeSkuData}
                    prices={getPriceForOutOfStock(prices)}
                    productId={productId}
                    regionCode={regionCode}
                    cmrPoints={cmrPoints}
                    isPumAvailable={isPumAvailable}
                    pumQuantity={pumQuantity}
                    pumUnit={pumUnits}
                    primeShipping={primeShipping}
                    deviceType={constants.DEVICE_MOBILE}
                    isMobile={isMobile}
                  />
                </div>
              )}
              <SellerInfo product={product} />
              {isReaconditionedProd && <ReaconditionedContainer appCtx={appCtx} productAttributes={product} />}
            </div>
          </div>
        </div>
        <style jsx>{ProductSpecificationsMobileStyle}</style>
      </div>
    );
  }

  const showStoreAvailability = _.get(appCtx, 'siteConfig.toggles.isStoreAvailabilityVisibleInPDP', false);

  const isInternationalBuyVisiblePDP = _.get(appCtx, 'siteConfig.toggles.isInternationalBuyVisiblePDP', false);

  const isMultipurposeBadgeApplicable = _.get(appCtx, 'siteConfig.toggles.isMultipurposeBadgeApplicable', false);

  const showCMRCalculator = _.get(appCtx, 'siteConfig.toggles.isCMRCalculatorEnabledPDP', false);
  const addToCartLabel = _.get(additionalPDPLabels, 'addToCart.enabled', false)
    ? _.get(additionalPDPLabels, 'addToCart', {})
    : null;

  return (
    <div
      className="product-specifications"
      data-backend-category={product.merchantCategoryId}
      data-availability={product.attributes && product.attributes.isPrimeAvailable}
      data-published={product.isPublished}
      data-variations={variants.length}
    >
      <div className="product-specifications-column">
        <div className={clsx({ flex: !isMobile })}>
          <div>
            {prices && prices.length > 0 && (
              <div
                className={`prices
              ${isMultipurposeBadgeApplicable && multipurposeBadges.length > 0 ? 'withMultipurposeBadge' : ''}
              `}
              >
                <ProductPrices
                  isVendorLogin={isVendorLogin}
                  currentTab={currentTab}
                  storeSkuData={storeSkuData}
                  prices={prices}
                  productId={productId}
                  regionCode={regionCode}
                  cmrPoints={cmrPoints}
                  isPumAvailable={isPumAvailable}
                  pumQuantity={pumQuantity}
                  pumUnit={pumUnits}
                  primeShipping={primeShipping}
                  deviceType={constants.DEVICE_MOBILE}
                  isMobile={isMobile}
                  discountBadge={discountBadge}
                  darSectionActive={darSectionActive}
                />
              </div>
            )}
            {(badges?.length || meatStickers?.length || freeShippingPromotionFor3p?.badge) && (
              <div className="badges">
                <Badges
                  deviceType="mobile"
                  badgeItems={isMeatStickerApplicableInPDP ? nonEventMeatStickers : badges}
                  layout="PDP"
                  isSticker={hasStickers}
                  promotionBadge={freeShippingPromotionFor3p?.badge}
                  store={appCtx.store}
                />
              </div>
            )}
            {isMultipurposeBadgeApplicable && multipurposeBadges.length > 0 && (
              <div className="multipurpose-badge">
                <MultipurposeBadge badgeItems={multipurposeBadges} deviceType="mobile" inPdp store={appCtx.store} />
              </div>
            )}
            <StockQuantity />
            <SellerInfo product={product} />

            {isInternationalBuyVisiblePDP && isInternationalShipping && <InternationalShipping />}
            {isReaconditionedProd && <ReaconditionedContainer appCtx={appCtx} productAttributes={product} />}
            {addToCartLabel && <AdditionalPDPLabel label={addToCartLabel} type="addToCart" productType="softline" />}
            {colorVariants[selectedColorSwatchIndex] && (
              <div className="color-swatch-container fa--color-swatches__mobile">
                <span>{`${labels.COLOR}: `}</span>
                {colorVariants[selectedColorSwatchIndex].label}
                <ProductColorSwatches
                  colorVariants={colorVariants}
                  productId={product.id}
                  selectedColorSwatchIndex={selectedColorSwatchIndex}
                  colorSwatchHandler={colorSwatchHandler}
                  type={constants.PRODUCT_CONSTANTS.SOFTLINE}
                  size="large"
                  appCtx={appCtx}
                  colorMetrics={colorMetrics}
                />
              </div>
            )}
            {sizes.length ? (
              <Size
                title={labels.SELECTION_SIZE}
                sizes={sizes}
                onSizeChange={sizeChangeHandler}
                selectedSize={selectedSize}
                sizeChartData={sizeChartData}
                fomo={fomo}
              />
            ) : null}
          </div>
          <div className="variant-seller-ids">
            <VariantId variantId={product.currentVariant} labels={labels} isSoCom={isSoCom} />
            {SKUIdSeller({
              appCtx,
              offerings: variant.offerings,
              labels,
              regionCode,
              product,
            })}
          </div>
          {isVendorLogin && isStoreDataVaild && (
            <div className="stock-table">
              <StockTable
                stockData={storeSkuData}
                colorVariants={colorVariants}
                selectedColorSwatchIndex={selectedColorSwatchIndex}
                sizes={sizes}
                isMobile={isMobile}
                requestProductFromStore={requestProductFromStore}
                regionCode={regionCode}
              />
            </div>
          )}
        </div>
        {addToCartButtonVisible && isVendorLogin && (
          <AddToCartButton
            mobileView
            addToCartButtonVisible={addToCartButtonVisible}
            isAddToCartInProgress={isAddToCartInProgress}
            labels={labels}
            handleAddToCart={handleAddToCart}
            isVendorLogin={isVendorLogin}
            sizes={sizes}
            selectedSize={selectedSize}
            setShowLoginForm={setShowLoginForm}
            showLoginForm={showLoginForm}
            setErrorAddingToCart={setErrorAddingToCart}
            showQuantityError={showQuantityError}
            setQuantityError={setQuantityError}
            inputVal={inputVal}
            setInputVal={setInputVal}
            handleInputChange={handleInputChange}
          />
        )}
        {modelCharacteristics && (
          <div className="additional-info">
            <BodyCopy isHighlighted size="copy14">
              {labels.MODEL_CHARACTERISTICS_PREFIX_MOBILE}
            </BodyCopy>
            <BodyCopy size="copy14">
              <p className="info-label">{modelCharacteristics}</p>
            </BodyCopy>
            <ScrollTo scrollToElementId="productInfoContainer" type="softline" isSoCom={isSoCom} />
          </div>
        )}

        <GetSharedDetails
          sizes={sizes}
          colorVariants={colorVariants}
          regionCode={regionCode}
          store={store}
          is1pSeller={is1pSeller}
          showStoreAvailability={showStoreAvailability}
          showCollectionButtonVisible={showCollectionButtonVisible}
          handleAddToCart={handleAddToCart}
          comboData={comboData}
          product={product}
          labels={labels}
          showCMRCalculator={showCMRCalculator}
          layout="softline"
          isSoCom={isSoCom}
          appCtx={appCtx}
        />

        {addToCartButtonVisible && !isVendorLogin && (
          <AddToCartButtonMobile
            maxCartQuantity={maxCartQuantity}
            labels={labels}
            errorAddingToCart={errorAddingToCart}
            isAddToCartInProgress={isAddToCartInProgress}
            appCtx={appCtx}
            handleAddToCart={handleAddToCart}
            handleRemoveFromCart={handleRemoveFromCart}
            isRemoveFromCartInProgress={isRemoveFromCartInProgress}
            showQuantityError={showQuantityError}
            setQuantityError={setQuantityError}
            inputVal={inputVal}
            setInputVal={setInputVal}
            handleInputChange={handleInputChange}
            sizes={sizes}
            selectedSize={selectedSize}
          />
        )}
      </div>
      <style jsx>{ProductSpecificationsMobileStyle}</style>
    </div>
  );
};

ProductSpecificationsMobile.defaultProps = {
  isPumAvailable: false,
  pumQuantity: 0,
  primeShipping: false,
  colorVariants: [],
  selectedColorSwatchIndex: 0,
  colorSwatchHandler: noop,
  sizes: [],
  selectedSize: '',
  sizeChangeHandler: noop,
  handleAddToCart: noop,
  requestProductFromStore: noop,
  handleRemoveFromCart: noop,
  isRemoveFromCartInProgress: false,
  isAddToCartInProgress: false,
  addToCartButtonVisible: true,
  showCollectionButtonVisible: false,
  storeSkuData: {},
  currentTab: '',
  errorAddingToCart: false,
  showLoginForm: false,
  sizeChartData: {},
  fomo: {},
};

ProductSpecificationsMobile.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  isPumAvailable: PropTypes.bool,
  pumQuantity: PropTypes.number,
  primeShipping: PropTypes.bool,
  colorVariants: PropTypes.array,
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  sizes: PropTypes.arrayOf(PropTypes.object),
  selectedSize: PropTypes.string,
  sizeChangeHandler: PropTypes.func,
  requestProductFromStore: PropTypes.func,
  handleAddToCart: PropTypes.func,
  isAddToCartInProgress: PropTypes.bool,
  handleRemoveFromCart: PropTypes.func,
  isRemoveFromCartInProgress: PropTypes.bool,
  addToCartButtonVisible: PropTypes.bool,
  showCollectionButtonVisible: PropTypes.bool,
  isVendorLogin: PropTypes.bool.isRequired,
  storeSkuData: PropTypes.object,
  currentTab: PropTypes.string,
  errorAddingToCart: PropTypes.bool,
  digitalData: PropTypes.object.isRequired,
  setShowLoginForm: PropTypes.func.isRequired,
  setErrorAddingToCart: PropTypes.func.isRequired,
  showLoginForm: PropTypes.bool,
  sizeChartData: PropTypes.object,
  fomo: PropTypes.object,
};

export default withProduct(withLabels(withApplicationContext(withDigitalData(ProductSpecificationsMobile))));
