import apiConfig from '../../config/api/apiConfig';
import httpService from '../../utils/httpService';
// import {
//   sizeData,
//   colorData,
//   sizeAndColorData,
//   connectProductData
// } from '../../devUtils/data/storeSkuData';
const headers = {
  Authorization: 'Basic YWRvbXBhbmdAYWF4aXNjaGluYS5jb206MTExMTEx',
  'ACCESS-KEY': 'API Key',
  'ACCESS-TIMESTAMP': 'request timestamp',
  'ACCESS-SIGN': 'sign',
  'Content-Type': 'application/json',
};
export const requestSkuStocksFromStore = async (currentVariant, vendorData, regionCode) => {
  const url = apiConfig.getProductAvailabilityInStore(currentVariant.id, vendorData, regionCode);
  const response = await httpService().get(url, { headers });
  const { success } = response.data;
  if (success) return response.data;
  return null;
};

export const requestProductFromStore = async (currentVariant, vendorData, regionCode) => {
  const url = apiConfig.requestSkuStocksFromStore(currentVariant, vendorData, regionCode);
  const response = await httpService().get(url, { headers });
  const { success } = response.data;
  if (success) return response.data;
  return null;
};
