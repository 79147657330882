import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../../utils/LabelsContext';
import BodyCopy from '../../../ui/BodyCopy/BodyCopy';
import { deliveryCalendarStyle } from './DeliveryCalendar.style';

const SinglePriceCalendar = ({ labels, slot }) => {
  return (
    <div className="deliverySlot-container container bottom-pad">
      <div className="calendar-single">
        <BodyCopy isHighlighted size="copy1">
          {labels.HOME_DELI_DATE_RANGE}
        </BodyCopy>
        <div className="delivery-data">
          <BodyCopy size="copy3">{slot.description}</BodyCopy>

          <BodyCopy size="copy3" isHighlighted>{`${slot.cost}`}</BodyCopy>
        </div>
      </div>
      <style jsx>{deliveryCalendarStyle}</style>
    </div>
  );
};

SinglePriceCalendar.propTypes = {
  labels: PropTypes.object,
  slot: PropTypes.object,
};

SinglePriceCalendar.defaultProps = {
  labels: {},
  slot: {},
};

export default withLabels(SinglePriceCalendar);

export { SinglePriceCalendar };
