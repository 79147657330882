import {
  FETCH_INSTALLMENT,
  FETCH_INSTALLMENT_SUCCESS,
  FETCH_INSTALLMENT_ERROR,
  INSTALLMENT_NUM_CHANGE,
  SAVE_CARD_INFO_PROFILE,
  SAVE_CARD_INFO_PROFILE_SUCCESS,
  SAVE_CARD_INFO_PROFILE_ERROR,
  SAVE_PROMOTION_INFO,
  SAVE_PROMOTION_INFO_SUCCESS,
  SAVE_PROMOTION_INFO_ERROR,
  UPDATE_BANK_OR_CARD_NAME,
  UPDATE_INSTALLMENT_DETAILS,
  SAVE_INSTALLMENT_INFO,
  SAVE_INSTALLMENT_INFO_SUCCESS,
  SAVE_INSTALLMENT_INFO_ERROR,
} from '../actions';
import Logger from '../../../utils/Logger';

export const installmentReducer = (state, { type, payload }) => {
  switch (type) {
    case FETCH_INSTALLMENT:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case UPDATE_INSTALLMENT_DETAILS: {
      const { cache } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          [cache]: {
            ...state.data[cache],
            state: {
              ...state.data[cache].state,
            },
          },
        },
      };
    }
    case FETCH_INSTALLMENT_SUCCESS: {
      const { cache, ...rest } = payload;
      return {
        ...state,
        data: {
          ...state.data,
          [cache]: {
            ...rest,
          },
        },
        isLoading: false,
      };
    }
    case FETCH_INSTALLMENT_ERROR:
    case INSTALLMENT_NUM_CHANGE:
      return {
        ...state,
        ...payload,
      };
    default:
      Logger.error('installmentReducer: Undefined Action type');
      return state;
  }
};

export const bankCardsReducers = (state, { type, payload }) => {
  switch (type) {
    case SAVE_PROMOTION_INFO:
    case SAVE_CARD_INFO_PROFILE:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SAVE_PROMOTION_INFO_SUCCESS:
    case SAVE_PROMOTION_INFO_ERROR:
    case SAVE_CARD_INFO_PROFILE_SUCCESS:
    case SAVE_CARD_INFO_PROFILE_ERROR:
    case UPDATE_BANK_OR_CARD_NAME:
      return {
        ...state,
        ...payload,
      };
    default:
      Logger.error('bankCardsReducers: Undefined Action type');
      return state;
  }
};

export const installmentInfoReducer = (state, { type, payload }) => {
  switch (type) {
    case SAVE_INSTALLMENT_INFO:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case SAVE_INSTALLMENT_INFO_SUCCESS:
    case SAVE_INSTALLMENT_INFO_ERROR:
      return {
        ...state,
        ...payload,
      };
    default:
      Logger.error('installmentInfoReducer: Undefined Action type');
      return state;
  }
};
