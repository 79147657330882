/* eslint-disable no-console */
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { withProduct } from '../../utils/ProductContext';
import WithOutsideClick from '../../utils/WithOutsideClick';
import WithTogggle from '../../utils/WithToggle';
import { ShareButtonStyle, SocialIconStyle } from './ShareButton.style';

const SocialIcon = ({ name, icon, mobileOnly, shareUrl, appStoreURL }) => {
  const iconOnClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      window.location.href = appStoreURL;
    }, 500);
    window.location.href = shareUrl;
  };

  const anchorProps = {
    className: 'link',
    target: '_blank',
    rel: 'noopener noreferrer',
    href: shareUrl,
  };

  const actualProps = !mobileOnly ? anchorProps : { ...anchorProps, onClick: iconOnClick };

  return (
    <div className={`socialIcon ${mobileOnly && 'mobileOnly '}`} id={`testId-social-${name}`}>
      <a {...actualProps}>
        <i className={`csicon-${icon} icon`}>
          <span className="sr-only">{name}</span>
        </i>
      </a>
      <style jsx>{SocialIconStyle}</style>
    </div>
  );
};

SocialIcon.defaultProps = {
  mobileOnly: false,
};

SocialIcon.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  mobileOnly: PropTypes.bool,
  regionCode: PropTypes.string.isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  shareUrl: PropTypes.string.isRequired,
  appStoreURL: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

const ShareButton = ({ children, toggleState, product, appCtx: { regionCode, deviceType, platformType } }) => {
  const { visible, setVisible } = toggleState;
  const isDesktop = deviceType === constants.DEVICE_DESKTOP;
  const shareIcons = _.get(product, 'shareIcons', []);

  const getAppStoreUrlKey = () => {
    if (platformType === constants.PLATFORM_TYPE.ANDROID) {
      return 'playStore';
    }
    if (platformType === constants.PLATFORM_TYPE.IOS) {
      return 'appStore';
    }
    return undefined;
  };

  const appStoreBase = getAppStoreUrlKey();

  const nativeShareAPI = () => {
    if (navigator.share) {
      navigator
        .share({
          title: document.title,
          text: 'falabella.com | Todo lo que necesitas en un solo lugar',
          url: document.location.href,
        })
        .catch((error) => console.log(`Error: ${error.message}`));
    } else {
      // loading fallback ui for unsupported browsers
      console.log(
        "Loading fallback UI as Your browser doesn't support the Web Share API. Please use a different browser."
      );
      setVisible(!visible);
    }
  };

  return (
    <WithOutsideClick
      onOutsideClick={() => {
        setVisible(false);
      }}
    >
      <div className="shareContainer fa--share-icon" id="testId-ShareButton">
        <button type="button" onClick={() => nativeShareAPI()}>
          {children}
        </button>
        {visible && (
          <div className={`socialContainer ${isDesktop ? 'bottom' : 'left'}`}>
            <div className={`caret ${isDesktop ? 'bottom' : 'left'}`} />
            {shareIcons.map(
              (socialIcon) =>
                socialIcon.name !== 'Default' && (
                  <SocialIcon
                    key={socialIcon.name}
                    name={socialIcon.name}
                    icon={socialIcon.icon}
                    regionCode={regionCode}
                    mobileOnly={socialIcon.mobileOnly}
                    shareUrl={socialIcon.shareUrl}
                    helperText={socialIcon.shareUrl}
                    appStoreURL={socialIcon.appStoreURL && socialIcon.appStoreURL[appStoreBase]}
                  />
                )
            )}
          </div>
        )}
      </div>
      <style jsx>{ShareButtonStyle}</style>
    </WithOutsideClick>
  );
};

ShareButton.defaultProps = {
  children: null,
};
ShareButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  toggleState: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
};

export default WithTogggle(withLabels(withProduct(withApplicationContext(ShareButton))));
