import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { withProduct } from '../../utils/ProductContext';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import { StockQuantityStyles } from './StockQuantity.styles';

const StockQuantity = ({ product, appCtx, labels }) => {
  const getStockUnit = () => {
    const qty = product.stockUnits[product.currentVariant];
    return qty > 10 ? '10+' : qty;
  };

  const visible = _.get(appCtx, 'siteConfig.toggles.isStockQuantityVisibleInPDP');

  if (!visible) {
    return null;
  }

  return (
    <BodyCopy size={appCtx.deviceType === 'desktop' ? 'copy5' : 'copy3'}>
      {(cls) => (
        <p className={`${cls} stock-quantity`}>
          {labels.UNITS_AVAILABLE_LABEL}: <span>{getStockUnit()}</span>
          <style jsx>{StockQuantityStyles}</style>
        </p>
      )}
    </BodyCopy>
  );
};

StockQuantity.propTypes = {
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

export default withLabels(withApplicationContext(withProduct(StockQuantity)));
export { StockQuantity };
