import constants from '../config/constants';

const pattern = {
  mobile:
    /(?:phone|windowss+phone|ipod|blackberry|(?:android|bbd+|meego|silk|googlebot) .+?mobile|palm|windowss+ce|opera mini|avantgo|docomo)/i,
  tablet: /(?:ipad|playbook|(?:android|bbd+|meego|silk)(?! .+? mobile))/i,
  ios: /iPad|iPhone|iPod/,
  android: /android/i,
  ie: /MSIE|Trident.*rv/i,
};

const isWindow = () => process.browser;

const compareViewPort = (size) => {
  return isWindow() && window.matchMedia && window.matchMedia(`(max-width: ${size}px)`).matches;
};

const isMobile = () => compareViewPort('767');
const isIpadPotrait = () => compareViewPort('1023');
const isIpadLandscape = () => compareViewPort('1024');

const getDeviceType = (header) => {
  if (header) {
    if (header['CF-Device-Type']) {
      return header['CF-Device-Type'];
    }
    if (header['x-cf-device-type']) {
      return header['x-cf-device-type'];
    }
    let deviceType;
    const useAgent = header['user-agent'];
    if (pattern.mobile.test(useAgent)) {
      deviceType = 'mobile';
    } else if (pattern.tablet.test(useAgent)) {
      deviceType = 'tablet';
    } else {
      deviceType = 'desktop';
    }
    return deviceType;
  }
  if (isMobile()) {
    return 'mobile';
  }

  if (isIpadLandscape()) {
    return 'tablet';
  }

  return 'desktop';
};

const isInternetExplorer = (userAgent) => {
  return pattern.ie.test(userAgent);
};

function isSearchBot(userAgent) {
  /*
		DO NOT use this for serving different content for search crawlers and
		users which may amount to cloaking (https://support.google.com/webmasters/answer/66355?hl=en).
		Only used to toggle between SSR (server side rendering) and CSR (client side rendering)
		(https://developers.google.com/search/docs/guides/dynamic-rendering)
	*/
  const userAgentStr = userAgent.toLowerCase();
  const botUserAgents = [
    'googlebot',
    'google-structured-data-testing-tool',
    'bingbot',
    'linkedinbot',
    'mediapartners-google',
  ];

  return botUserAgents.some((botToken) => userAgentStr.indexOf(botToken) >= 0);
}

const getPlatformType = (header = {}) => {
  const userAgent = header['user-agent'];
  if (pattern.ios.test(userAgent)) {
    return constants.PLATFORM_TYPE.IOS;
  }
  if (pattern.android.test(userAgent)) {
    return constants.PLATFORM_TYPE.ANDROID;
  }
  return 'unknown';
};

export {
  getDeviceType,
  getPlatformType,
  isInternetExplorer,
  isIpadLandscape,
  isIpadPotrait,
  isMobile,
  isSearchBot,
  isWindow,
};
