import PropTypes from 'prop-types';
import React from 'react';
import { CustomModalStyles } from './CustomModal.style';

const ModalHeader = ({ icon, title }) => {
  return (
    <div className="modal-header">
      <i className={icon}>
        <span className="sr-only">{`${icon}-icon`}</span>
      </i>
      <p className="modal-heading">{title}</p>
      <style jsx>{CustomModalStyles}</style>
    </div>
  );
};

ModalHeader.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

const ModalContent = ({ children, withPadding = true }) => {
  return (
    <div className={`modal-content ${!withPadding && 'no-padding'}`}>
      {children}
      <style jsx>{CustomModalStyles}</style>
    </div>
  );
};
ModalContent.propTypes = {
  children: PropTypes.node.isRequired,
  withPadding: PropTypes.bool,
};
ModalContent.defaultProps = {
  withPadding: true,
};

const ModalFooter = ({ children }) => {
  return (
    <div className="modal-footer">
      {children}
      <style jsx>{CustomModalStyles}</style>
    </div>
  );
};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ModalContent, ModalFooter, ModalHeader };
