import PropTypes from 'prop-types';
import React from 'react';
import { AddtionInformationCardCss } from './AdditionalInformationCard.styles';

const AddtionInformationCard = ({ headerText, children, headerType }) => {
  return (
    <section className="container">
      <div className={headerType}>
        <span>{headerText}</span>
      </div>
      <div className="bodyContainer">{children}</div>
      <style jsx>{AddtionInformationCardCss}</style>
    </section>
  );
};

AddtionInformationCard.propTypes = {
  headerText: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  headerType: PropTypes.oneOf(['headerContainer', 'mkp-headerContainer', '']),
};

AddtionInformationCard.defaultProps = {
  headerType: 'headerContainer',
};

export default AddtionInformationCard;
