/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { svgToDataUri } from '../../utils/styles/svgMixins';
import { icons, colors, typography } from '../../config/styles';

export const NearbyStoresStyles = css`
  .red {
    color: ${colors.red.shade4};
  }
  .no-click {
    cursor: not-allowed;
  }
  div.availability-item {
    color: ${colors.black.shade1};
    @mixin tabletToDesktop {
      display: flex;
      flex-direction: column;
      gap: 4px;
      align-items: center;
    }
    &.title {
      span.softline-title {
        padding-top: 5px;
      }
      span {
        width: 100%;
        text-align: left;
        font-weight: bold;
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
    &.flex-product-standard {
      flex-basis: calc(33.33% - 6px);
    }
    &.flex-product-large {
      flex-basis: 66.6%;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: auto;
    box-sizing: border-box;
    .icon {
      min-width: 32px;
      height: 36px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      @mixin mobileToTablet {
        min-width: 32px;
      }
      &.see-stores {
        height: 48px;
        width: 48px;
        background-image: url('${svgToDataUri(icons.stockInStoreMint)}');
      }
      &.so-com-see-store {
        height: 48px;
        width: 48px;
        background-image: url('${svgToDataUri(icons.so_com_stockInStoreMint)}');
      }
      &.see-stores-disabled {
        margin-top: 3px;
        height: 30px;
        background-size: 34px;
        background-image: url('${svgToDataUri(icons.stockEnTiendaDisabled)}');
      }
    }
    .content {
      height: auto;
      width: 100%;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
      line-height: 18px;
      color: inherit;
      text-align: center;
      &.outofstock {
        justify-content: center;
        .underline {
          color: ${colors.gray.shade40};
          border-bottom: 1px solid ${colors.gray.shade40};
          width: fit-content;
          font-size: 1.2rem;
          line-height: 18px;
          @mixin mobileToTablet {
            line-height: 16.8px;
          }
        }
      }

      &-main {
        display: flex;
        flex-direction: column;
        .bold {
          font-weight: bold;
          line-height: 16.8px;
          &.no-modal {
            color: ${colors.gray.shade44};
          }
          @mixin tabletToDesktop {
            letter-spacing: 0.2px;
          }
          @mixin mobileToTablet {
            line-height: 19.2px;
            font-size: ${typography.lg1};
          }
        }
        &.outofstock {
          color: ${colors.gray.shade2};
        }
      }
      &-sub {
        width: 100%;
        font-size: 13px;
        cursor: pointer;
        color: ${colors.secondary_color};
        line-height: 18px;
        font-weight: 400;
        text-align: center;
        div {
          outline: none;
          display: inline;
          span {
            line-height: 18px;
            @mixin tabletToDesktop {
              font-size: 1.3rem;
            }
            @mixin mobileToTablet {
              line-height: 16.8px;
              letter-spacing: 0.2px;
            }
            &.caret > i {
              padding-left: 6px;
              display: inline-block;
              font-size: ${typography.xxs};
              @mixin tabletToDesktop {
                width: 4.04px;
                height: 7.14px;
                margin-left: 6px;
              }
            }
            &.underline {
              color: ${colors.secondary_color};
              text-decoration: underline;
              text-underline-offset: 2px;
              text-decoration-color: ${colors.secondary_color};
              font-size: ${typography.sm};
              font-weight: 400;
              line-height: 18px;
              text-align: center;
            }
          }
        }
      }
      .placeholder-text {
        color: ${colors.gray.shade19};
        font-size: 13px;
        letter-spacing: 0;
        line-height: 21px;
        span {
          font-weight: bold;
        }
      }
    }
  }
  .csicon-arrow_down_small {
    min-width: 20px;
    width: 20px;
    height: 20px;
    background-image: url('${svgToDataUri(icons.chevronRightRebranded)}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &::before {
      content: '';
    }
  }
  .out-of-stock.availability-item {
    display: inline-block;
    border: none;
    padding-top: 0;
    &.softline-product {
      border: none;
    }
  }
`;
