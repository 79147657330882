/* eslint-disable no-param-reassign */
import React, { Fragment } from 'react';
import MatchMedia from '../../components/ui/MatchMedia/MatchMedia';
import HardLineDesktop from './Desktop/HardLineDesktop';
import HardLineMobile from './Mobile/HardLineMobile';
import { ProductLandingContainerStyles } from './HardLineProductContainer.style';

const HardLineProductContainer = () => {
  return (
    <>
      <MatchMedia
        content={{
          mobile: () => <HardLineMobile />,
          tablet: () => <HardLineMobile />,
          desktop: () => <HardLineDesktop />,
        }}
      />
      <style jsx>{ProductLandingContainerStyles}</style>
    </>
  );
};

export default HardLineProductContainer;
