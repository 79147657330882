import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import apiConfig from '../../../config/api/apiConfig';
import httpService from '../../../utils/httpService';
import fetchSessionConfirmationNumber from '../../../utils/FetchSessionConfirmationNumber';
import cmrFormStyles from './CmrForm.style';
// import noop from '../../../utils/noop';

const CmrForm = ({ appCtx, actions, setAperturaStatus, redirectToLoginForm }) => {
  const [iframeUrl, setIframeUrl] = useState('');
  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const handleCmrIframe = (cmrResponseData) => {
    actions.setCustomHeader(
      <div className="cmrLogoContainer">
        <i className="csicon-cmr-logo logo-lg" />
        <style jsx>{cmrFormStyles}</style>
      </div>
    );
    setIframeUrl(cmrResponseData.iframeUrl);
  };

  const handleRedirectLogin = (cmrResponseData) => {
    setRedirectToLogin(cmrResponseData.redirectToLogin);
    // setWidth(380);
  };

  const handleIframePostCall = {
    '00': () => setAperturaStatus('2'),
    '01': () => setAperturaStatus('3'),
    '02': () => setAperturaStatus('4'),
  };

  const retrieveCMRToken = async ({ retailId, sessionConfirmationNumber, code }) => {
    const retrieveCMRTokenUrl = apiConfig.retrieveCMRToken(appCtx.regionCode);
    const reqBody = {
      sessionConfirmationNumber,
      retailCustomerId: retailId,
      code,
    };
    const { data: retrieveCMRTokenData } = await httpService().post(retrieveCMRTokenUrl, reqBody, {
      includeTracing: false,
    });

    if (retrieveCMRTokenData.messageCode) {
      handleIframePostCall[retrieveCMRTokenData.messageCode]();
    }
  };

  const cmrResponse = {
    retailCustomerId: '',
    sessionConfirmationNumber: '',
  };

  const messageEventHandler = (e) => {
    retrieveCMRToken({
      retailId: cmrResponse.retailCustomerId,
      sessionConfirmationNumber: cmrResponse.sessionConfirmationNumber,
      code: e.data.code,
    });
  };

  const handleCmrResponse = (reponse) => {
    if (reponse.redirectToLogin) {
      handleRedirectLogin(reponse);
    } else if (reponse.redirectToLogin === false) {
      window.addEventListener('message', messageEventHandler, { once: true });
      handleCmrIframe(reponse);
    }
  };

  const initCmrOnline = async (sessionConfirmationNumber) => {
    const applyCmrOnlineUrl = apiConfig.applyCmrOnline(sessionConfirmationNumber, appCtx.regionCode);
    const { data: cmrResponseData } = await httpService().get(applyCmrOnlineUrl, {
      includeTracing: false,
    });
    actions.setBusy(false);
    cmrResponse.retailCustomerId = cmrResponseData.retailCustomerId;
    cmrResponse.sessionConfirmationNumber = sessionConfirmationNumber;
    handleCmrResponse(cmrResponseData);
  };

  useEffect(() => {
    actions.setBusy(true);
    fetchSessionConfirmationNumber(appCtx)
      .then((sessionConfirmationNumber) => {
        initCmrOnline(sessionConfirmationNumber);
      })
      .catch(() => {
        actions.setBusy(false);
        actions.close();
      });

    return () => {
      window.removeEventListener('message', messageEventHandler);
    };
  }, []);

  useEffect(() => {
    if (redirectToLogin && !iframeUrl) {
      redirectToLoginForm(true);
      actions.close();
    }
  }, [redirectToLogin]);

  return (
    <div className="cmrForm">
      {!redirectToLogin && iframeUrl && (
        <iframe src={iframeUrl} frameBorder="0" title="Cmr iframe" height="550" width="100%" />
      )}
      <style jsx>{cmrFormStyles}</style>
    </div>
  );
};

// CmrForm.defaultProps = {
//   setWidth: noop
// };

CmrForm.propTypes = {
  appCtx: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  setAperturaStatus: PropTypes.func.isRequired,
  redirectToLoginForm: PropTypes.func.isRequired,
};

export default withApplicationContext(CmrForm);
