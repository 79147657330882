import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SingleChipletSelect from '../ui/SingleChipletSelect/SingleChipletSelect';
import _ from '../../utils/LodashImports';
import { withDigitalData } from '../../utils/DigitalDataContext';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { ProductServicesStyles } from './ProductServices.style';
import ServicesModal from './ServicesModal';

const ProductServices = ({ serviceOptions, setServiceOption, digitalData, appCtx }) => {
  const { services } = serviceOptions;
  const [options, setOptions] = useState(services);
  const [showModal, setShowModal] = useState(false);

  const {
    ADDITIONAL_SERVICES_LABEL = 'Conoce nuestros servicios adicionales',
    SERVICES_LEARN_MORE = 'Conocer más opciones',
  } = _.get(appCtx, 'siteConfig.textDictionary', {});
  const { isImprovedServicesUiEnabled = true } = _.get(appCtx, 'siteConfig.toggles', {});

  const toggleModalVisibility = () => {
    setShowModal((prevState) => !prevState);
  };

  const handleServiceChange = (selectedOptions) => {
    setOptions(selectedOptions);
    setServiceOption(selectedOptions);
  };

  const renderService = (service) => {
    const { name, textPrice, selected } = service;

    const serviceSelectHandler = () => {
      const updatedOptions = options.map((option) => {
        if (option.offeringId === service.offeringId) {
          return { ...option, selected: !option.selected };
        }
        return option;
      });
      handleServiceChange(updatedOptions);
    };

    return (
      <button className="service-sku" type="button" onClick={serviceSelectHandler}>
        <div className="label">
          <div className={`checkbox ${selected ? 'full' : 'empty'}`} />
          <div className="service-name">{name}</div>
        </div>
        <div className="service-price">{textPrice}</div>
        <style jsx>{ProductServicesStyles}</style>
      </button>
    );
  };

  if (services && services.length) {
    digitalData.pdp.setAdditionalServicesAvailableQty(services.length);

    return isImprovedServicesUiEnabled ? (
      <>
        <div className="services-wrapper">
          <div className="title">
            <span className="service-icon" />
            <p>{ADDITIONAL_SERVICES_LABEL}</p>
          </div>
          {options.map((service) => renderService(service))}
          <button type="button" className="more-service-options" onClick={toggleModalVisibility}>
            {SERVICES_LEARN_MORE}
          </button>
        </div>
        <ServicesModal
          handleServiceChange={handleServiceChange}
          appCtx={appCtx}
          options={options}
          showModal={showModal}
          setShowModal={setShowModal}
        />
        <style jsx>{ProductServicesStyles}</style>
      </>
    ) : (
      <div className="fa--additional-services">
        <SingleChipletSelect
          options={services}
          label={ADDITIONAL_SERVICES_LABEL}
          onOptionSelected={handleServiceChange}
          secondaryLabelIcon="csicon-alert"
          icon="csicon-preferences"
          shouldHaveBackground={false}
          type="medium"
          withPadding={false}
          forPDP
          highLightHeading
          clickableChiplet
          serviceType="additional-service"
          hideSubtitle
        />
      </div>
    );
  }
  return null;
};

ProductServices.defaultProps = {
  setServiceOption: () => {},
  serviceOptions: {
    services: [],
    header: '',
  },
  appCtx: {},
};

ProductServices.propTypes = {
  serviceOptions: PropTypes.object,
  setServiceOption: PropTypes.func,
  digitalData: PropTypes.object.isRequired,
  appCtx: PropTypes.object,
};

export default withApplicationContext(withDigitalData(ProductServices));
