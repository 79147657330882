import { debounce, deburr, find, get, isNumber, lowerCase, merge, set } from 'lodash';

const _ = {
  debounce,
  get,
  merge,
  find,
  deburr,
  lowerCase,
  isNumber,
  set,
};

export default _;
