import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import constant from '../../../config/constants';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import CookieStorage from '../../../utils/CookieStorage';
import { useRegionComuna } from '../../../utils/hooks';
import { useSelectedDeliveryOption } from '../../../utils/hooks/UseSelectedDeliveryOption';
import { capitalizeString } from '../../../utils/jsUtils';
import noop from '../../../utils/noop';
import { getVariant } from '../../../utils/variant';
import WebStorage from '../../../utils/WebStorage';
import { Modal } from '../../ui/Modal/Modal';
import AvailabilityModal from '../AvailabilityModal';
import { getMetaKey, getSelectionText } from '../common';
import DeliveryOptionsModal from '../DeliveryOptions/DeliveryOptionsModal';
import DeliveryOptionsSelectedText from '../DeliveryOptions/DeliveryOptionsSelectedText';
import { AvailabilityDesktopStyles } from './AvailabilityDesktop.style';

const AvailabilityDesktopItem = ({ appCtx, product, availability, labels, isLast, hasModal, sizes, colorVariants }) => {
  const { deviceType, store } = appCtx;
  const isSodimacStandAlone = store === constant.STORES.so_com;
  const { labelsKey, iconsClass } = getMetaKey(availability.shippingOptionType, isSodimacStandAlone);
  const { currentVariant, showShippingModal, shippingEstimatedPrice } = product;
  const variant = getVariant(product?.variants, currentVariant);
  const isNewDeliveryOptionsEnabled = _.get(appCtx, 'siteConfig.toggles.isNewDeliveryOptionsEnabled', false);
  const isOutOfStock = availability.stateOfStock === constant.AVAILABILITY_OUTOFSTOCK;

  const [selectedCommuna, setSelectedCommuna] = useState();
  const { selectedDeliveryOption, setSelectedDeliveryOption, zoneConfig, setZoneConfig } = useSelectedDeliveryOption(
    availability.shippingOptionType,
    appCtx,
    currentVariant
  );
  const comunaName = _.get(zoneConfig, 'name', '');
  const getSelectedCommuna = () => {
    const isHD = availability.shippingOptionType === 'HomeDelivery';
    // Get availabilityCookieValue Cookie
    const availabilityCookieValue = CookieStorage.getCookie(
      isHD ? constant.COOKIE_HOME_DELIVERY_KEY : constant.COOKIE_STORE_PICKUP_KEY
    );
    // Decode cookie value
    const availabilityCookieDecoded = CookieStorage.parseCookieValue(
      isHD ? constant.COOKIE_HOME_DELIVERY_KEY : constant.COOKIE_STORE_PICKUP_KEY,
      availabilityCookieValue
    );
    // Store comuna data for HD or CC in the LS
    if (availabilityCookieDecoded) {
      // Save HD or CC information to local storage
      WebStorage.setItem({
        name: isHD ? constant.LS_HOME_DELIVERY_KEY : constant.LS_STORE_PICKUP_KEY,
        value: JSON.stringify(availabilityCookieDecoded),
        shouldPersist: true,
      });
      return availabilityCookieDecoded.displayName;
    }
    // Get HD or CC from LS
    const selection = WebStorage.getItem({
      name: isHD ? constant.LS_HOME_DELIVERY_KEY : constant.LS_STORE_PICKUP_KEY,
      fromPersistence: true,
    });

    if (selection) {
      return JSON.parse(selection).displayName;
    }

    return '';
  };

  useEffect(() => {
    setSelectedCommuna(getSelectedCommuna());
  }, []);

  const [apiResult, setApiResult] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const { comuna, displayText } = useRegionComuna({
    modalVisible,
    lsComunaKey: labelsKey.localStorageComunaKey,
    lsRegionKey: labelsKey.localStorageRegionKey,
    lsCiudadKey: labelsKey.localStorageCiudadKey,
    displayKey: labelsKey.localAvailableTextDisplayKey,
  });

  const handleAvailabilityUpdate = () => {
    setSelectedCommuna(getSelectedCommuna());
  };

  const getContent = (actions) => (
    <AvailabilityModal
      actions={actions}
      type={availability.shippingOptionType}
      variant={currentVariant}
      onUpdate={handleAvailabilityUpdate}
    />
  );

  const renderOOSView = () => (
    <div className="content-main outofstock">
      {isNewDeliveryOptionsEnabled && (
        <div className="content-main">
          <span className="no-delivery underline">
            {labels[`${labelsKey.outOfStock}`]}
            {comunaName ? ` en ${capitalizeString(comunaName)}` : ''}
          </span>
        </div>
      )}
      {!isNewDeliveryOptionsEnabled && <span>{labels[`${labelsKey.outOfStock}`]}</span>}
      <style jsx>{AvailabilityDesktopStyles}</style>
    </div>
  );

  const getDeliveryModal = () => {
    return isNewDeliveryOptionsEnabled ? (
      <DeliveryOptionsModal
        type={availability.shippingOptionType}
        variant={currentVariant}
        onUpdate={handleAvailabilityUpdate}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        zoneConfig={zoneConfig}
        setZoneConfig={setZoneConfig}
        sizes={sizes}
        colorVariants={colorVariants}
        selectedDeliveryOption={selectedDeliveryOption}
        setSelectedDeliveryOption={setSelectedDeliveryOption}
      />
    ) : (
      <Modal
        content={getContent}
        options={{ width: 800 }}
        withPadding={false}
        toggleState={{
          visible: modalVisible,
          setVisible: setModalVisible,
        }}
        deviceType={deviceType}
      />
    );
  };

  return (
    <>
      <div>{hasModal && apiResult && modalVisible && getDeliveryModal()}</div>
      <div
        className={`availability-item ${isLast && 'lastItem'} rebranded`}
        onKeyDown={noop}
        role="button"
        tabIndex={0}
        onClick={() => {
          setModalVisible(true);
          setApiResult(true);
        }}
      >
        <div
          className={`icon ${
            isOutOfStock || selectedDeliveryOption.isError === true ? iconsClass.outOfStock : iconsClass.inStock
          }`}
        />
        <div className={`content ${isOutOfStock && 'outofstock'}`}>
          {!isOutOfStock
            ? labelsKey.pleaseSelect &&
              showShippingModal && (
                <div className="content-sub">
                  {comuna.name && <span>{labels[`${labelsKey.subPrefix}`]} </span>}
                  <div id={`testId-Availability-Desktop-ModalOpen-${availability.shippingOptionType}`}>
                    {isNewDeliveryOptionsEnabled && selectedDeliveryOption && !_.isEmpty(selectedDeliveryOption) && (
                      <DeliveryOptionsSelectedText
                        selectedDeliveryOption={selectedDeliveryOption}
                        availability={availability}
                      />
                    )}
                    <span className="underline">
                      <span>
                        {getSelectionText({
                          isNewDeliveryOptionsEnabled,
                          selectedDeliveryOption,
                          comunaName,
                          labels,
                          labelsKey,
                          selectedCommuna,
                          displayText,
                          shippingEstimatedPrice,
                          variant,
                          shippingOptionType: availability.shippingOptionType,
                        })}
                      </span>
                    </span>
                  </div>
                </div>
              )
            : renderOOSView()}
        </div>

        <style jsx>{AvailabilityDesktopStyles}</style>
      </div>
    </>
  );
};

AvailabilityDesktopItem.propTypes = {
  availability: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  isLast: PropTypes.bool,
  hasModal: PropTypes.bool,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  sizes: PropTypes.array,
  colorVariants: PropTypes.array,
};

AvailabilityDesktopItem.defaultProps = {
  isLast: false,
  hasModal: true,
  sizes: [],
  colorVariants: [],
};

export default withApplicationContext(AvailabilityDesktopItem);
