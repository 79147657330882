import React, { useContext } from 'react';
import createContainer from 'constate';
import { UserContext as ccUserContext } from '@digital-retail/falabella-ui-cross-components';

function useUserContext() {
  const userContext = useContext(ccUserContext.Context);
  const {
    firstName,
    setFirstName,
    isUserLoggedIn,
    setUserLoggedIn,
    cartCount,
    setCartCount,
    lastName,
    isVendorLogin,
    vendorData,
    refreshUserData,
  } = userContext;

  return {
    firstName,
    setFirstName,
    isUserLoggedIn,
    setUserLoggedIn,
    cartCount,
    setCartCount,
    lastName,
    isVendorLogin,
    vendorData,
    refreshUserData,
  };
}

const UserContext = createContainer(useUserContext);

const withUser = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;

  const ComponentWithUser = (props) => {
    const user = useContext(UserContext.Context);
    return <WrappedComponent {...props} {...user} />;
  };

  if (typeof getInitialProps === 'function') {
    ComponentWithUser.getInitialProps = getInitialProps;
  }

  return ComponentWithUser;
};

export default UserContext;
export { withUser, useUserContext };
