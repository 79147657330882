/* eslint-disable react/no-danger */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import constants from '../../../config/constants';
import defaultConfig from '../../../config/url/default.config';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withConfig } from '../../../utils/ConfigurationContext';
import { withDigitalData } from '../../../utils/DigitalDataContext';
import { withLabels } from '../../../utils/LabelsContext';
import Tooltip, { TooltipText } from '../../ui/Tooltip/Tooltip';
import { getSellerClass, getSellerLink, getTooltipText, validateSellerName } from '../helpers';
import { SellerInfoMobileStyle } from './SellerInfoMobile.style';

const SellerInfoMobile = ({ product, labels, appCtx, config, digitalData }) => {
  const { regionCode, store } = appCtx;
  const isSellerPopupEnabled = _.get(appCtx, 'siteConfig.toggles.isSellerPopupEnabled', false);
  const { ENVIRONMENT } = config;
  const { currentVariant, variants } = product;
  const variant = variants.find((item) => item.id === currentVariant);
  const sellerId = _.get(variant, 'offerings[0].sellerId', '');
  const sellerName = _.get(variant, 'offerings[0].sellerName', '');
  const sellerNameLower = sellerName && sellerName.toLowerCase();
  const tooltipRef = useRef();

  useEffect(() => {
    digitalData.pdp.setSoldBy(sellerNameLower);
  }, [sellerNameLower]);

  const shouldShowSellerInfo = _.get(appCtx, 'siteConfig.toggles.isSellerInfoVisibleInPDP', false);
  const isSISExperienceOn = _.get(appCtx, 'siteConfig.toggles.isSISExperienceOn', false);

  if (!sellerName || !sellerId || !shouldShowSellerInfo) {
    return null;
  }
  const sellerClass = getSellerClass({ sellerNameLower, constants });
  const sellerRedirectionMap = _.get(
    appCtx,
    'siteConfig.configurations.sellerRedirectionMap',
    defaultConfig.SELLER_REDIRECTION_MAP[ENVIRONMENT][regionCode]
  );
  const { isOutOfStock } = product;
  const validatedSellerName = validateSellerName(sellerNameLower, regionCode);

  return (
    <div className={`borderContainer rebranded ${isOutOfStock && 'out-of-stock'}`}>
      <div className="sellerInfoContainer fa--seller-info__mobile">
        <span>{`${labels.SELLER_SOLD_BY} `}</span>
        {sellerClass ? <span className={`${sellerClass}Icon`} /> : null}
        <a
          id="testId-SellerInfo-sellerName"
          href={getSellerLink({
            sellerClass,
            regionCode,
            sellerName: sellerClass === 'homecenter' ? validatedSellerName.toUpperCase() : sellerName,
            env: ENVIRONMENT,
            isSISExperienceOn,
            sellerRedirectionMap,
            store,
          })}
          className="link"
        >
          <span className="underline">{validatedSellerName}</span>
        </a>
        {isSellerPopupEnabled && (
          <Tooltip
            position="bottom-left-1"
            hasCaret
            tooltipContent={
              <TooltipText
                text={
                  <div
                    className="sellerTooltipText"
                    dangerouslySetInnerHTML={{
                      __html: getTooltipText({
                        sellerName: sellerClass || sellerNameLower,
                        labels,
                        storePlaceholderText: constants.STORE_PLACEHOLDER,
                        sellerData: product.sellerInfo,
                        isNational: !_.get(product, 'internationalShipping.applicable', false),
                      }),
                    }}
                  />
                }
                viewType="fullWidth"
              />
            }
            viewType="fullWidth"
            tooltipContentRef={tooltipRef}
          >
            <i ref={tooltipRef} id="seller-tooltip-icon" className="csicon-alert alertIcon">
              <span className="sr-only">Seller Info</span>
            </i>
          </Tooltip>
        )}
      </div>
      <style jsx>{SellerInfoMobileStyle}</style>
    </div>
  );
};

SellerInfoMobile.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  digitalData: PropTypes.object.isRequired,
};

export default withApplicationContext(withLabels(withConfig(withDigitalData(SellerInfoMobile))));
