import PropTypes from 'prop-types';
import React, { useState } from 'react';
import WithTogggle from '../../../../utils/WithToggle';
import { AlertWithTimeout } from '../../../ui/Alert/Alert';

const DesktopAlert = ({ content, toggleState, type }) => {
  const { visible, setVisible } = toggleState;
  useState(() => {
    setVisible(true);
  }, []);

  if (!visible) {
    return null;
  }

  return <AlertWithTimeout content={content} toggleState={toggleState} type={type} />;
};

DesktopAlert.propTypes = {
  toggleState: PropTypes.object,
  content: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['success', 'warning']),
};
DesktopAlert.defaultProps = {
  toggleState: null,
  type: 'success',
};

export default WithTogggle(DesktopAlert);
