import { isSodimacStandalone, isTottusStandalone } from '@digital-retail/store-manager';
import apiConfig from '../../config/api/apiConfig';
import { getLatAndLong, getQuantityUnitForPayload } from '../CartHelpers';
import CookieStorage from '../CookieStorage';
import httpService from '../httpService';
import _ from '../LodashImports';
import Logger from '../Logger';
import { getCommerceHeaders } from '../tenantHelper';
import { cartAlertsAndErrorSeggregator, mapProduct, segregateProduct } from './helpers';
import { queryMaker } from './helpers/segregator';

const addProduct =
  ({ cart, setCart, setShowCart, setVariantSelectionVisible, setQuantityWhileVariantSelectionVisible }) =>
  async ({
    appCtx,
    products = [],
    bundledProducts = [],
    bundledProductOrigin,
    showLightBox = true,
    replaceCartItems = false,
    isBuyNowApplicable = false,
  }) => {
    const { regionCode, politicalId, priceGroupId, store } = appCtx;
    const isSoCom = isSodimacStandalone(store);
    const isToCom = isTottusStandalone(store);

    const combinedProducts = [...products, ...bundledProducts];

    const {
      OFFER_PRICE_LABEL,
      NORMAL_PRICE_LABEL,
      A2C_CONTINUE_SHOPPING = '',
      A2C_VIEW_BASKET = '',
      A2C_EXT_WARRANTY = '',
      CART_ASSEMBLY_OPTION_SEC_LABEL = '',
      DEFAULT_LATITUDE = '-33.731683',
      DEFAULT_LONGITUDE = '-70.735547',
    } = _.get(appCtx, 'siteConfig.textDictionary', {});

    const cartLines = [];

    combinedProducts.forEach(
      ({ skuId, quantity, warrantySkuId, offeringId, warrantyOfferingId, serviceOptions, isInternational }) => {
        const cartLineItem = {
          cartLineNumber: offeringId || skuId,
          item: {
            variantId: skuId,
            offeringId,
          },
          quantity: {
            quantityNumber: quantity,
            quantityUnit: getQuantityUnitForPayload({ store }),
          },
        };
        if (isInternational) {
          cartLineItem.customInfo = [
            {
              name: 'geography',
              values: ['international'],
            },
          ];
        }
        cartLines.push(cartLineItem);
        if (warrantySkuId) {
          cartLines.push({
            parentLineNumber: offeringId || skuId,
            cartLineNumber: warrantyOfferingId || warrantySkuId,
            item: {
              variantId: warrantySkuId,
              offeringId: warrantyOfferingId,
            },
            quantity: {
              quantityNumber: quantity,
              quantityUnit: getQuantityUnitForPayload({ store }),
            },
          });
        }
        if (serviceOptions && serviceOptions.length) {
          serviceOptions.forEach((s) => {
            if (s.selected) {
              cartLines.push({
                parentLineNumber: offeringId || skuId,
                cartLineNumber: s.offeringId || s.value,
                item: {
                  variantId: s.value,
                  offeringId: s.offeringId,
                },
                quantity: {
                  quantityNumber: quantity,
                  quantityUnit: getQuantityUnitForPayload({ store }),
                },
              });
            }
          });
        }
      }
    );

    const reqBody = {
      data: {
        cart: {
          cartLines,
        },
      },
      metadata: {
        priceGroup: 'default',
      },
    };

    reqBody.metadata.politicalAreaId = politicalId;
    reqBody.metadata.priceGroup = priceGroupId;

    if (isToCom) {
      const { latitude = DEFAULT_LATITUDE, longitude = DEFAULT_LONGITUDE } = getLatAndLong();
      reqBody.metadata.latAndLong = {
        lat: `${latitude}`,
        long: `${longitude}`,
      };
    }
    const additionalHeaders = getCommerceHeaders(regionCode, store, true);

    const cartId = CookieStorage.getCookie('cartId') || _.get(cart, 'cartId', '');
    const query = queryMaker(cartId, isBuyNowApplicable, isToCom);
    const url = apiConfig.addToCartCommerceUrl(query, regionCode);
    const response = await httpService().post(
      url,
      reqBody,
      {
        credentials: 'include',
      },
      additionalHeaders,
      'json'
    );

    const commerceCartId = _.get(response, 'data.data.cart.cartId', '');
    if (commerceCartId) {
      CookieStorage.setCookie({
        name: 'cartId',
        value: commerceCartId,
        regionCode,
        expiryDays: 356,
        store: appCtx.store,
      });
    }
    const items = _.get(response, 'data.data.cart.cartLines', []);
    const alerts = _.get(response, 'data.data.cart.cartAlerts', []);
    const totalProductsQuantityCount = _.get(response, 'data.data.cart.totalProductsQuantityCount', 0);
    const totalProductsCount = totalProductsQuantityCount;
    const { mappedErrors, limitAlerts } = cartAlertsAndErrorSeggregator(alerts, appCtx.siteConfig, response.statusCode);
    const textDictionary = {
      continueShoppingText: A2C_CONTINUE_SHOPPING,
      viewBasketText: A2C_VIEW_BASKET,
      extendedWarrantyLightBoxLinkText: A2C_EXT_WARRANTY,
      CART_ASSEMBLY_OPTION_SEC_LABEL,
    };

    const {
      BASKET_PAGE_URI = '',
      EXT_WARRANTY_LIGHTBOX_LINK = '',
      BASKET_PAGE_URI_SODIMAC = '',
    } = _.get(appCtx, 'siteConfig.endpoints', {});

    const toggles = _.get(appCtx, 'siteConfig.toggles', {});

    const endPoints = {
      baskePagetUri: {
        path: !isSoCom ? BASKET_PAGE_URI : BASKET_PAGE_URI_SODIMAC,
      },
      extendedWarrantyLightBoxLink: {
        path: EXT_WARRANTY_LIGHTBOX_LINK,
      },
    };

    if (response.statusCode === 200 && items && !mappedErrors) {
      if (isBuyNowApplicable) {
        window.location.assign(apiConfig.getCheckoutExpressUrl(regionCode));
        return null;
      }
      const mappedProducts = items.map((item) => {
        return mapProduct(item, regionCode, { OFFER_PRICE_LABEL, NORMAL_PRICE_LABEL }, toggles, isSoCom);
      });

      const mappedItem = segregateProduct({
        mappedProducts,
        products,
        action: 'ADD',
        bundledProducts,
        bundleItems: cart.bundleItems,
        cartItems: replaceCartItems ? [] : cart.cartItems,
      });

      setCart({
        ...cart,
        ...mappedItem,
        cartId: commerceCartId,
        textDictionary,
        totalProductsCount,
        endPoints,
        bundledProductOrigin: bundledProductOrigin || cart.bundledProductOrigin,
        isAtgPrice: false,
        limitAlerts,
      });
      setQuantityWhileVariantSelectionVisible(null);
      setVariantSelectionVisible(false);
      setShowCart(showLightBox);
    } else {
      if (mappedErrors && mappedErrors.length > 0) {
        return mappedErrors;
      }
      const { errors } = _.get(response, 'data', {});
      Logger.error(`Failed to Add Product ${JSON.stringify(products)}`);
      return errors || [];
    }
    return null;
  };

export default addProduct;
