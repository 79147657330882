import PropTypes from 'prop-types';
import React from 'react';
import { BrandName, ProductName, ProductShareIcon, SellerSkuId } from '../../components/PDP';
import constants from '../../config/constants';
import { RatingAndReviews } from '../../external_dependencies/ratingAndReviews/RatingAndReviews';
import _ from '../../utils/LodashImports';
import PDPBasicInfoSharedStyle from './PDPBasicInfoShared.style';

const SKUIdSeller = ({ appCtx, offerings, labels, regionCode, product }) => {
  const isColombiaRegion = regionCode === 'co';

  const isSellerSkuIdColombiaEnabled = _.get(appCtx, 'siteConfig.toggles.isSellerSkuIdColombiaEnabled', false);
  const sellerSkuId = (offerings.length && offerings[0].sellerSkuId) || null;

  const sellerId = _.get(product, 'variants[0].offerings[0].sellerId', '');
  const is3pSeller = !constants.FIRST_PARTY_SELLER_REGEX.test(sellerId.toLowerCase());

  const skuIdSellerHtml = () => {
    if (sellerSkuId !== null && Number(sellerSkuId) !== 0 && !is3pSeller) {
      return (
        <>
          <div className="vertical-separator" />
          <SellerSkuId sellerSkuId={sellerSkuId} labels={labels} />
          <style jsx>{PDPBasicInfoSharedStyle}</style>
        </>
      );
    }
    return null;
  };

  if (!isColombiaRegion) {
    return skuIdSellerHtml();
  }
  if (isColombiaRegion && isSellerSkuIdColombiaEnabled) {
    return skuIdSellerHtml();
  }
  return null;
};

SKUIdSeller.propTypes = {
  appCtx: PropTypes.object.isRequired,
  offerings: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  regionCode: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
};

const PDPBasicInfoShared = ({ appCtx, product, ratings, template }) => {
  const rcClass = `reviews-column${ratings ? '' : '-hidden'}`; // Don't change this logic, in order to update reviews we need the section to render so data loads
  const { isOutOfStock } = product;

  if (isOutOfStock) {
    return (
      <div className="basic-details-Mobile rebranded">
        <BrandName brandName={product.brandName} />
        <div className="corner-details">
          <ProductName productName={product.name} />
        </div>
        <style jsx>{PDPBasicInfoSharedStyle}</style>
      </div>
    );
  }

  return (
    <div className="basic-details-Mobile rebranded">
      <div className="brand-and-rating">
        <BrandName brandName={product.brandName} />
        <div className={`${rcClass} fa--reviews-${template}__mobile`}>
          <RatingAndReviews />
        </div>
      </div>
      <div className="corner-details">
        <ProductName productName={product.name} />
        <ProductShareIcon />
      </div>
      <style jsx>{PDPBasicInfoSharedStyle}</style>
    </div>
  );
};

PDPBasicInfoShared.defaultProps = {
  template: 'softline',
};

PDPBasicInfoShared.propTypes = {
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  template: PropTypes.string,
};

export { PDPBasicInfoShared, SKUIdSeller };
