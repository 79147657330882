import css from 'styled-jsx/css';
import { colors } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';
import icons from '../../config/styles/icons';

const ComboItemsMobile = css`
  .combos-container {
    display: flex;
    gap: 12px;
    padding: 1.2rem 0;
    flex-direction: column;
  }

  .combo-items-wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
  }

  .price-specs-wrapper {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;

    .zoom-in-icon {
      align-self: center;
      width: 24px;
      height: 24px;
      background-image: url('${svgToDataUri(icons.zoomIcon)}');
    }

    .item-price {
      color: ${colors.gray.shade52};
      font-size: 14px;
      font-weight: 400;
      align-self: center;
      min-width: 70px;
      line-height: 16.8px;
    }
  }
  .item-specifications {
    height: 24px;
    width: 24px;
  }

  .combo-item {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
  }

  .photo {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 60px;
    height: 60px;
    border-radius: 4px;
    .image-wrapper {
      max-width: 60px;
      max-height: 60px;
    }
  }

  .name-seller-wrapper {
    width: 45%;

    .seller {
      color: ${colors.gray.shade44};
      font-size: 11px;
      font-weight: 700;
      letter-spacing: 1.167px;
      line-height: 13.2px;
      text-transform: uppercase;
    }

    .item-name {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      color: ${colors.gray.shade40};
      font-size: 13px;
      line-height: 15.6px;
      font-weight: 700;
      letter-spacing: -0.3px;
    }
  }

  .combo-total-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: 1px solid ${colors.gray.shade36};
    padding-top: 16px;

    .label {
      color: ${colors.black.shade1};
      font-size: 14px;
      font-weight: 400;
      line-height: 16.8px;
    }

    .price-value-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 12px;

      .price {
        font-weight: 700;
        font-size: 14px;
        text-align: center;
        min-width: 70px;
      }

      .green-check {
        height: 24px;
        width: 24px;
        background-image: url('${svgToDataUri(icons.greenCheck)}');
      }
    }
  }
`;

export default ComboItemsMobile;
