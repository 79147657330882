import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../utils/LabelsContext';
import { AvailabilityMobileStyles } from './AvailabilityMobile.style';

const AvailabilityMobileItemDownloadable = ({ labels }) => {
  return (
    <>
      <div className="availability-item">
        <div className="icon downloadAvailable rebranded" />
        <div className="content-container">
          <div className="content-sub">
            <div id="testId-Availability-Desktop-ModalOpen-downloadAvailable">
              <span className="underline">{labels.AVAILABILITY_IMMEDIATE_DELIVERY} </span>
            </div>
            <span className="bold">{`(${labels.AVAILABILITY_DOWNLOADABLE})`}</span>
          </div>
          <div className="iconCaret" />
        </div>
      </div>
      <style jsx>{AvailabilityMobileStyles}</style>
    </>
  );
};

AvailabilityMobileItemDownloadable.propTypes = {
  labels: PropTypes.object,
};

AvailabilityMobileItemDownloadable.defaultProps = {
  labels: {},
};

export default withLabels(AvailabilityMobileItemDownloadable);
