/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { colors, icons, typography } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const ReturnPolicyStyles = css`
  .return-policy-custom-container {
    display: flex;
    padding: 20px 60px 10px 76px;

    @mixin tabletToDesktop {
      padding: 0;
      padding-top: 12px;
      border-top: 1px solid ${colors.gray.shade3};

      .returnIcon {
        display: inline-block;
        width: 32px;
        height: 32px;
        background-image: url('${svgToDataUri(icons.returnIcon)}');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        &::before {
          content: '';
        }
      }
    }

    .return-policy-text {
      margin-left: 12px;
      line-height: 20px;
      text-align: left;
      color: ${colors.gray.shade40};
      font-family: inherit;
      display: flex;
      flex-direction: column;
      letter-spacing: 0.14px;

      .open-modal-btn {
        color: ${colors.black.shade1};
        line-height: 20px;
        letter-spacing: 0.14px;
        font-size: 14px;
        text-align: left;
        i {
          margin-left: 10px;
        }
        @mixin mobileToTablet {
          letter-spacing: 0.2px;
          color: ${colors.gray.shade40};
          text-align: left;
        }
        .csicon-right {
          font-size: 7px;
          font-weight: bold;
        }
        span {
          text-decoration-line: underline;
          text-underline-position: under;

          @mixin smallMobileOnly {
            margin-left: 0;
          }
        }
      }
      i {
        color: ${colors.gray.shade40};
        font-size: ${typography.xxs2};
        margin-left: 4px;
      }
      .header {
        color: ${colors.black.shade1};
        font-size: 16px;
        font-weight: 700;

        &.dar-section {
          font-size: 19px;
        }
      }
      .header-mobile {
        color: ${colors.black.shade1};
        letter-spacing: 0;
        line-height: 22px;
        font-size: 16px;
        font-weight: 700;

        &.dar-section {
          font-size: 19px;
        }
      }
      .info {
        color: ${colors.black.shade1};
      }
      .link {
        border-bottom: 1px solid ${colors.gray.shade40};
      }

      @mixin mobileOnly {
        margin-left: 16px;
      }
      @mixin smallMobileOnly {
        margin-left: 16px;
      }
      @mixin mobileToTablet {
        margin-left: 16px;
      }
      &.rebranded {
        margin-left: 16px;
        .header-mobile {
          color: ${colors.gray.shade40};
          line-height: 22px;
        }
        .info-mobile {
          letter-spacing: 0.2px;
        }
      }
    }
  }

  .return-policy-container:not(:empty) + .return-policy-custom-container {
    display: none;
  }

  .returnIcon {
    &.rebranded {
      min-width: 32px;
      .return-icon-mkp {
        width: 32px;
        height: 32px;
        background-image: url('${svgToDataUri(icons.returnIcon)}');
        background-repeat: no-repeat;
        background-size: contain;
        display: block;
        background-position: center;
      }
      .csicon-return-icon {
        &::before {
          color: ${colors.gray.shade45};
        }
      }
    }
  }

  .return-policy-mobile-header {
    @mixin tabletToDesktop {
      padding: 0 15px;
    }

    .mobileHeader {
      display: flex;
      padding: 0 20px 0 33px;
      height: 50px;
      outline: none;
      border-bottom: 1px solid ${colors.gray.shade36};

      .returnIcon {
        width: 46px;
        i {
          color: ${colors.green.shade1};
          text-align: center;
          font-size: ${typography.xl2b};
        }
        i::before {
          color: ${colors.green.shade1};
        }
      }

      .header {
        font-size: ${typography.lg1};
        font-weight: 700;
        color: ${colors.black.shade1};
        width: 286px;
      }

      .expandIcon {
        i {
          font-size: ${typography.xs};
        }
      }
    }
  }
  .mobile-padding {
    padding: 12px 16px 12px 24px;
    border-top: 1px solid ${colors.gray.shade46};
    border-bottom: 1px solid ${colors.gray.shade46};
  }
  .mobile-size {
    font-size: 24px;
  }
`;

export default ReturnPolicyStyles;
