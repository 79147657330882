import PropTypes from 'prop-types';
import React from 'react';
import _ from '../../../utils/LodashImports';
import { FloorCalculatorWarningStyles } from './FloorCalculatorWarning.style';

const FloorCalculatorWarning = ({ appCtx, isExtraM2Checked, m2Value, m2ResultValue, m2Quantity }) => {
  const getRequiredM2 = () => {
    const validatedM2Value = Number(m2Value);
    if (!Number.isNaN(validatedM2Value)) {
      return Math.floor((parseInt(validatedM2Value, 10) / m2ResultValue) * m2Quantity);
    }
    return 0;
  };

  const EXTRA_M2_NOT_CHECKED_WARNING_LABEL = _.get(
    appCtx,
    'siteConfig.textDictionary.EXTRA_M2_CHECKED_WARNING_LABEL',
    'La cantidad seleccionada solo cubre {value}m².'
  );

  const EXTRA_M2_CHECKED_WARNING_TITLE = _.get(
    appCtx,
    'siteConfig.textDictionary.EXTRA_M2_CHECKED_WARNING_TITLE',
    'Considerando el 10% adicional para cubrir posibles daños y faltantes.'
  );

  const EXTRA_M2_CHECKED_WARNING_LABEL = _.get(
    appCtx,
    'siteConfig.textDictionary.EXTRA_M2_CHECKED_WARNING_LABEL',
    'La cantidad seleccionada solo cubre {value}m².'
  );

  const getFloorCalcWarningMessage = () => {
    return !isExtraM2Checked ? (
      <>
        <p>{EXTRA_M2_NOT_CHECKED_WARNING_LABEL.replace('{value}', getRequiredM2())}</p>
        <style jsx>{FloorCalculatorWarningStyles}</style>
      </>
    ) : (
      <>
        <p>
          {EXTRA_M2_CHECKED_WARNING_TITLE}
          <br />
          <br />
          <span className="bold-text">{EXTRA_M2_CHECKED_WARNING_LABEL.replace('{value}', getRequiredM2())}</span>
        </p>
        <style jsx>{FloorCalculatorWarningStyles}</style>
      </>
    );
  };

  return (
    <div className="quantity-warning">
      <div className="warning-icon-container">
        <i className="warning-icon" />
      </div>
      <div className="warning-body">{getFloorCalcWarningMessage()}</div>
      <style jsx>{FloorCalculatorWarningStyles}</style>
    </div>
  );
};

FloorCalculatorWarning.propTypes = {
  appCtx: PropTypes.object.isRequired,
  isExtraM2Checked: PropTypes.bool.isRequired,
  m2Value: PropTypes.string.isRequired,
  m2ResultValue: PropTypes.number.isRequired,
  m2Quantity: PropTypes.number.isRequired,
};

export default FloorCalculatorWarning;
