import _ from '../../utils/LodashImports';
import httpService from '../../utils/httpService';
import apiConfig from '../../config/api/apiConfig';
import constants from '../../config/constants';

const IMAGE_GALLERY = {
  DEFAULT_IMAGE: 'default',
};

const isVideo = (media) => {
  if (typeof media === 'string') {
    return media.indexOf('www.youtube.com') !== -1;
  }
  return media && media.mediaType === 'VIDEO';
};

const filterForSoftline = (medias = []) => {
  return medias.filter((v) => v.mediaType === 'image');
};

const guessFirstImage = (product, variantId, sameImage, imageGalleryType = 'hardline') => {
  const currentVariant = product.variants.find((v) => v.id === product.currentVariant);
  const firstImageFallback = `Falabella/${variantId}_1`;
  const secondImageFallback = `Falabella/${variantId}_2`;
  let mediaList;

  if (!sameImage && _.get(currentVariant, 'medias.length', 0) > 0) {
    mediaList = currentVariant.medias;
  }
  if (sameImage && _.get(product, 'medias.length', 0) > 0) {
    mediaList = product.medias;
  }

  if (imageGalleryType === 'softline') {
    mediaList = filterForSoftline(mediaList);
  }

  if (
    (imageGalleryType === 'hardline' && mediaList) ||
    (imageGalleryType === 'softline' && mediaList && mediaList.length > 0)
  ) {
    const firstImage = _.get(mediaList, '[0]', firstImageFallback);

    if (imageGalleryType === 'softline') {
      const secondImage = _.get(mediaList, '[1]', secondImageFallback);
      return [firstImage, secondImage];
    }
    return [firstImage];
  }
  return imageGalleryType === 'softline' ? [firstImageFallback, secondImageFallback] : [firstImageFallback];
};

const getMediaData = (media) => {
  let mediaId = '';
  let mediaUrl = '';
  if (!media) {
    mediaId = IMAGE_GALLERY.DEFAULT_IMAGE;
  } else {
    mediaId = media.id || media;
    mediaUrl = media.url || (isVideo(media) ? media : '');
  }
  return { mediaId, mediaUrl };
};

const getCFDefaultImage = ({ sellerName = '', regionCode = 'cl', fallBackImagesDomain }) => {
  const imageUrls = constants.IMAGE_URlS;
  if (!fallBackImagesDomain || fallBackImagesDomain === '') {
    /* eslint-disable-next-line no-param-reassign */
    fallBackImagesDomain = 'https://media.falabella.com';
  }
  if (sellerName.toLowerCase().includes('tottus')) {
    return imageUrls.tottus.replace('{imageDomain}', fallBackImagesDomain);
  }
  if (sellerName.toLowerCase().includes('sodimac')) {
    return imageUrls.sodimac.replace('{imageDomain}', fallBackImagesDomain);
  }
  return imageUrls[regionCode].replace('{imageDomain}', fallBackImagesDomain);
};

// * Used inside eval
// eslint-disable-next-line no-unused-vars
const s7jsonResponse = (response) => {
  let items;

  if (Array.isArray(response.set.item)) {
    items = response.set.item.map((item) => item.i.n);
  } else {
    items = [response.set.item.i.n];
  }

  return items;
  // return [...items, 'https://www.youtube.com/embed/Ejt6zQR7HHs'];
};

const fetchImages = async ({
  variantId,
  regionCode = 'cl',
  sellerName,
  isCFMediasEnabled = false,
  media,
  fallBackImagesDomain,
}) => {
  if (isCFMediasEnabled) {
    if (media) {
      return media;
    }
    return getCFDefaultImage({ regionCode, sellerName, fallBackImagesDomain });
  }
  // 'https://falabella.scene7.com/is/image/Falabella/6630336?req=set,json&id=PDP',

  const { data } = await httpService().get(`${apiConfig.getImagesURL(regionCode)}/${variantId}?req=set,json&id=PDP`, {
    asText: true,
  });
  // ! Need to think for alternative approach
  // eslint-disable-next-line no-eval
  return eval(data);
};

export { isVideo, guessFirstImage, filterForSoftline, IMAGE_GALLERY, getMediaData, getCFDefaultImage, fetchImages };
