import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../../utils/LabelsContext';
import noop from '../../../../utils/noop';
import { PickupInStoreAvailabilityOptionCss } from '../../ModalContent/ModalContent.style';

const PickupInStoreAvailabilityOptionItem = ({ store, selected, onChange }) => {
  const { storeName, storeId, iconUrl, address, deliverySlots, workingDays } = store;

  const handleSelection = () => {
    onChange(storeId);
  };

  return (
    <div
      role="button"
      onKeyDown={noop}
      tabIndex="-1"
      className={`optionContainer-item ${selected ? 'active' : ''} rebranded`}
      onClick={handleSelection}
    >
      <div className="optionInput">
        <input
          id={storeId}
          checked={selected}
          className="pickupRadio"
          name="pickupStoreOption"
          type="radio"
          onChange={handleSelection}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={storeId}> </label>
      </div>
      <div className="logo">
        <img alt="" src={iconUrl} />
      </div>
      <div className="descContainer">
        <h4 className="optionName">
          {storeName}, {address.cityName}
        </h4>
        <div className="openingTimes">
          <h4>{workingDays}</h4>
          <h4>{address.addressLine1}</h4>
        </div>
        <div className="optionData">
          {/* {labels.PICKUP_INSTORE_AVAILABLEFROM}{' '} */}
          <span>{deliverySlots[0].description.value}</span>
        </div>
      </div>
      <style jsx>{PickupInStoreAvailabilityOptionCss}</style>
    </div>
  );
};

PickupInStoreAvailabilityOptionItem.propTypes = {
  store: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
};

PickupInStoreAvailabilityOptionItem.defaultProps = {
  selected: false,
  onChange: noop,
};

export default withLabels(PickupInStoreAvailabilityOptionItem);
