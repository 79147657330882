import { isSodimacStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React from 'react';
import _ from '../../../../utils/LodashImports';
import BodyCopy from '../../../ui/BodyCopy/BodyCopy';
import Button from '../../../ui/Button/Button';
import Warning from '../../../Warning/Warning';
import { CartItemStyles } from '../CartItem.style';

const CartItemActionLabel = ({
  showButton,
  totalQuantity,
  handleAddItem,
  labels,
  showMaxQuantityAlert,
  maxOnCartAmount,
  errorAddingToCart,
  addedQuantity,
  appCtx,
}) => {
  if (showButton && totalQuantity < 0) {
    const addToCartText = isSodimacStandalone(appCtx.store)
      ? _.get(labels, 'SO_COM_ADD_TO_CART_TEXT', 'Agregar al carro')
      : labels.ADD_TO_CART_TEXT;
    return (
      <div className="addToCart-button">
        <Button size="medium" type="secondary" onClick={handleAddItem}>
          <span>{addToCartText}</span>
        </Button>
        <style jsx>{CartItemStyles}</style>
      </div>
    );
  }
  if (showMaxQuantityAlert) {
    const maxQuantityWarning = _.get(
      labels,
      'MAXIMUM_QUANTITY_LIMIT_LABEL',
      'Solo puedes llevar {maxCartQuantity} unidades'
    ).replace('{maxCartQuantity}', maxOnCartAmount);
    return (
      <div className="maxQuantity-alert">
        <Warning label={maxQuantityWarning} iconSize="copy2" labelSize="copy5" type="secondary" />
        <style jsx>{CartItemStyles}</style>
      </div>
    );
  }
  if (errorAddingToCart) {
    return (
      <div className="maxQuantity-alert">
        <Warning label={errorAddingToCart} iconSize="copy2" labelSize="copy5" />
        <style jsx>{CartItemStyles}</style>
      </div>
    );
  }
  if (totalQuantity !== 0 && addedQuantity > 0) {
    return (
      <div className="product-add-text">
        <i className="csicon-icon-success-circle mkp">
          <span className="sr-only">{labels.ITEM_ADDED_A11Y_LABEL}</span>
        </i>
        <BodyCopy size={appCtx.deviceType === 'desktop' ? 'copy3' : 'copy14'}>
          {_.get(labels, 'PRODUCT_ADDED_MKP', 'Producto agregado')}
        </BodyCopy>
        <style jsx>{CartItemStyles}</style>
      </div>
    );
  }
  const maxQuantityText = _.get(labels, 'MAX_QUANTITY_TEXT_PDP', 'Máximo {maxCartQuantity} unidades.').replace(
    '{maxCartQuantity}',
    maxOnCartAmount
  );
  return (
    <>
      <p className="max-units-text">{maxQuantityText}</p>
      <style jsx>{CartItemStyles}</style>
    </>
  );
};

CartItemActionLabel.defaultProps = {
  showButton: false,
  totalQuantity: 0,
  showMaxQuantityAlert: false,
  maxOnCartAmount: 0,
  errorAddingToCart: '',
  addedQuantity: 0,
};

CartItemActionLabel.propTypes = {
  showButton: PropTypes.bool,
  totalQuantity: PropTypes.number,
  handleAddItem: PropTypes.func.isRequired,
  labels: PropTypes.object.isRequired,
  showMaxQuantityAlert: PropTypes.bool,
  maxOnCartAmount: PropTypes.number,
  errorAddingToCart: PropTypes.string,
  addedQuantity: PropTypes.number,
  appCtx: PropTypes.object.isRequired,
};

export default CartItemActionLabel;
