import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withLabels } from '../../../utils/LabelsContext';
import noop from '../../../utils/noop';
import { withProduct } from '../../../utils/ProductContext';
import { getVariant } from '../../../utils/variant';
import BodyCopy from '../../ui/BodyCopy/BodyCopy';
import Heading from '../../ui/Heading/Heading';
import { ModalContentCss } from '../ModalContent/ModalContent.style';
import { ModalHeaderStyle } from './AvailabilityModal.style';
import HomeDeliveryModal from './HomeDeliveryModal';
import PickupInStoreModal from './PickupInStoreModal';
import ShippingLocationSelection from './ShippingLocationSelection';

const ModalHeader = ({ title, logo }) => {
  return (
    <div className="container rebranded">
      <div className={`logo ${logo}`} />
      <Heading type="secondary" variation="title10">
        {title}
      </Heading>
      <style jsx>{ModalHeaderStyle}</style>
    </div>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
};

const getConfig = (type, labels) => {
  if (type === 'HomeDelivery') {
    return {
      logo: 'deliveryLogo',
      title: labels.HOME_DELI_TITLE,
      startingText: labels.HOME_DELI_RESULT_WILL_COME_HERE,
    };
  }

  return {
    logo: 'pickupInStoreLogo',
    title: labels.PICKUP_INSTORE_TITLE,
    startingText: labels.PICKUP_INSTORE_RESULT_WILL_COME_HERE,
  };
};

const AvailabilityModal = ({ type, actions, labels, variant, onUpdate, product: { variants } }) => {
  const [error, setError] = useState('');
  const config = getConfig(type, labels);
  const [selection, setSelection] = useState();
  const { offerings = [] } = getVariant(variants || [], variant);
  const offeringId = offerings.length > 0 && offerings[0].offeringId ? offerings[0].offeringId : variant;

  useEffect(() => {
    actions.setCustomHeader(<ModalHeader logo={config.logo} title={config.title} />);
  }, []);

  const handleChange = (userSelection) => {
    setSelection(userSelection);
  };

  const handleOnClose = () => {
    actions.close();
    onUpdate();
  };

  const renderSelection = (_type) => {
    if (_type === 'HomeDelivery') {
      return (
        <HomeDeliveryModal
          onClose={handleOnClose}
          selection={selection}
          setLoading={actions.setBusy}
          setError={setError}
          error={error}
          offeringId={offeringId}
        />
      );
    }

    return (
      <PickupInStoreModal
        onClose={handleOnClose}
        selection={selection}
        setLoading={actions.setBusy}
        setError={setError}
        offeringId={offeringId}
      />
    );
  };

  return (
    <div className="container">
      <ShippingLocationSelection onChange={handleChange} setLoading={actions.setBusy} type={type} setError={setError} />

      <div className="resultContainer">
        {selection && renderSelection(type)}

        {error && (
          <div className="resultActive hasBottomOption">
            <div className="heading">
              <BodyCopy>{error}</BodyCopy>
            </div>
          </div>
        )}

        {!selection && (
          <div className="resultInActive">
            <div className={`logo ${config.logo}`} />
            <div>
              <h2>{config.startingText}</h2>
            </div>
          </div>
        )}
      </div>

      <style jsx>{ModalContentCss}</style>
    </div>
  );
};

AvailabilityModal.propTypes = {
  type: PropTypes.string.isRequired,
  actions: PropTypes.object,
  labels: PropTypes.object,
  variant: PropTypes.string,
  onUpdate: PropTypes.func,
  product: PropTypes.object,
};

AvailabilityModal.defaultProps = {
  actions: { setBusy: noop, setCustomHeader: noop, close: noop },
  labels: {},
  variant: '',
  onUpdate: noop,
  product: {},
};

export { AvailabilityModal, ModalHeader };
export default withProduct(withLabels(AvailabilityModal));
