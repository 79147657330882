import apiConfig from '../../../config/api/apiConfig';
import constants from '../../../config/constants';
import httpService from '../../httpService';
import { getCommerceHeaders } from '../../tenantHelper';

const deleteFromListCommerce = async ({ appCtx, listId, listLineItemId, tagName }) => {
  const { regionCode, store } = appCtx;
  const { ATC_SESSION_EXPIRED_CODE } = constants;

  const additionalHeaders = getCommerceHeaders(regionCode, store, true);

  const url = new URL(apiConfig.dleteFromListCommerceUrl(listId, listLineItemId, regionCode));

  const response = await httpService().delete(url, { headers: additionalHeaders }, 'json');

  const defaultResponse = {
    tagName,
  };
  if (response.statusCode === 401) {
    return {
      ...defaultResponse,
      ok: false,
      error_code: ATC_SESSION_EXPIRED_CODE,
    };
  }

  if (response.statusCode === 204) {
    return { ...defaultResponse, data: response, ok: true };
  }

  return {
    ...defaultResponse,
    ok: false,
    error_code: 'UNKOWN',
  };
};

export default deleteFromListCommerce;
