import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import getImages from '../../../../utils/GetImages';
import _ from '../../../../utils/LodashImports';
import noop from '../../../../utils/noop';
import { withProduct } from '../../../../utils/ProductContext';
import Image from '../../../ui/Image/Image';
import { IMAGE_GALLERY, getMediaData, isVideo } from '../../ImageGalleryHelper';
import ImageGalleryControlButtons from '../ImageGalleryControlButtons/ImageGalleryControlButtons';
import ImageGalleryMobileVideo from '../ImageGalleryVideo/ImageGalleryVideo';
import { ImageGalleryHeadlineMobileStyles } from './ImageGalleryHeadlineMobile.style';
import helpers from './ImageGalleryMobileHelper';

const ImageGalleryHeadlineMobile = ({
  zoomSize,
  activeImageIndex,
  images,
  setActiveImageIndex,
  imageQuality,
  headlineImageHeight,
  headlineImageWidth,
  zoomFactor,
  zoomResetLimit,
  showControlButtons,
  crop,
  productName,
  brandName,
  product,
  appCtx,
}) => {
  const zoomLevel = useRef();
  const carouselRef = useRef();
  const zoomImageRef = useRef();
  const containerRef = useRef();

  const setZoomLevel = (level) => {
    const { clientWidth } = carouselRef.current || {};
    zoomLevel.current = level === clientWidth ? undefined : level;
  };
  const isCFMediasEnabled = _.get(appCtx, 'siteConfig.toggles.isCFMediasEnabled', false);
  const mediaDomains = _.get(appCtx, 'siteConfig.configurations.mediaDomains', []);
  const fallBackImagesDomain = _.get(appCtx, 'siteConfig.textDictionary.FALLBACK_IMAGES_DOMAIN', '');
  const { regionCode } = appCtx;
  const sellerName = _.get(product, 'variants[0].offerings[0].sellerName', '').toLowerCase();

  const [isVideoPlaying, setVideoPlaying] = useState();
  const [xOffset, setXOffset] = useState(0);
  const [startX, setStartX] = useState();
  const [animate, setAnimate] = useState(false);
  const [isImageClicked, setIsImageClicked] = useState(false);
  const [zoomOffSet, setZoomOffSet] = useState({ x: 0, y: 0 });

  const [zoomStartOffset, setZoomStartOffset] = useState({ x: 0, y: 0 });

  const [pinchDistance, setPinchDistance] = useState(0);
  const [pinchZoomLevel, setPinchZoomLevel] = useState(0);
  const [pinchSize, setPinchSize] = useState({ x: 0, y: 0 });
  const [pinchZoomOffset, setPinchZoomOffset] = useState({ x: 0, y: 0 });

  const [previousActiveIndex, setPreviousActiveIndex] = useState();

  useEffect(() => {
    if (isVideo(images[activeImageIndex])) {
      setVideoPlaying(true);
    } else {
      setVideoPlaying(false);
    }

    setZoomLevel();

    if (previousActiveIndex !== images.length - 1 && previousActiveIndex !== 0) {
      setAnimate(true);
    }

    const { clientWidth } = carouselRef.current;
    setXOffset(-(activeImageIndex * clientWidth));
    setPreviousActiveIndex(activeImageIndex);
  }, [activeImageIndex]);

  useEffect(() => {
    const { clientWidth, clientHeight } = carouselRef.current;

    if (zoomLevel.current && zoomLevel.current < clientWidth + zoomResetLimit) {
      setZoomLevel(undefined);
    }

    const { width, height } = zoomImageRef.current.getBoundingClientRect();

    const [X, Y] = helpers.getValidZoomOffset({
      X: zoomOffSet.x,
      Y: zoomOffSet.y,
      imageWidth: width,
      imageHeight: height,
      clientWidth,
      clientHeight,
    });

    setZoomOffSet({
      x: X,
      y: Y,
    });
  }, [zoomLevel.current]);

  const onDoubleTap = (e) => {
    if (e && e.target && e.targetTouches && !zoomLevel.current) {
      const { pageY, pageX } = e.targetTouches[0];

      const { top } = e.target.getBoundingClientRect();
      const { clientHeight, clientWidth } = carouselRef.current;

      const [X, Y] = helpers.getZoomCenter({
        pageX,
        pageY,
        topOffset: top + window.scrollY,
        zoomSize: zoomSize.x,
        containerWidth: clientWidth,
        containerHeight: clientHeight,
      });

      setZoomOffSet({ x: X, y: Y });
      setZoomLevel(zoomSize.x);
    } else {
      setZoomLevel(undefined);
    }
  };

  const onImageClick = (e) => {
    if (isVideo(images[activeImageIndex])) {
      return;
    }

    if (isImageClicked) {
      setIsImageClicked(false);
      onDoubleTap(e);
    } else {
      setIsImageClicked(true);
      setTimeout(() => {
        setIsImageClicked(false);
      }, 500);
    }
  };

  const onNextHeadline = (e) => {
    e.stopPropagation();
    let newActiveImageIndex = activeImageIndex + 1;

    if (activeImageIndex + 1 === images.length) {
      newActiveImageIndex = 0;
    }

    setActiveImageIndex(newActiveImageIndex);
  };

  const onPreviousHeadLine = (e) => {
    e.stopPropagation();
    let newActiveImageIndex = activeImageIndex - 1;

    if (activeImageIndex === 0) {
      newActiveImageIndex = images.length - 1;
    }

    setActiveImageIndex(newActiveImageIndex);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { targetTouches } = e;

    if (targetTouches.length === 1) {
      onImageClick(e);
    }

    const { pageX: x1, pageY: y1 } = targetTouches[0];

    if (targetTouches.length === 1) {
      if (zoomLevel.current) {
        setZoomStartOffset({ x: x1 - zoomOffSet.x, y: y1 - zoomOffSet.y });
      } else {
        const { clientX } = e.targetTouches[0];

        setStartX(clientX - xOffset);
      }
    } else {
      const { clientWidth } = carouselRef.current;
      setPinchZoomLevel(zoomLevel.current || clientWidth);
      const { top } = carouselRef.current.getBoundingClientRect();
      const { width, height } = zoomImageRef.current.getBoundingClientRect();

      setPinchSize({ x: width, y: height });

      const { pageX: x2, pageY: y2 } = targetTouches[1];

      const X = (x1 + x2) / 2;
      const Y = (y1 + y2) / 2 - (top + window.scrollY);

      setPinchZoomOffset({
        x: zoomOffSet.x,
        y: zoomOffSet.y,
      });

      setZoomStartOffset({
        x: X,
        y: Y,
      });

      const d = helpers.getEuclideanDistance(x1, y1, x2, y2);

      setPinchDistance(d);
    }
  };

  const handleTouchEnd = () => {
    setStartX(xOffset);

    const { offsetLeft } = carouselRef.current.children[activeImageIndex];

    const delta = Math.abs(xOffset) - offsetLeft;

    if (Math.abs(delta) < 30) {
      setActiveImageIndex(activeImageIndex);
    } else if (Math.abs(xOffset) < offsetLeft) {
      setActiveImageIndex(activeImageIndex - 1);
    } else {
      setActiveImageIndex(activeImageIndex + 1);
    }
  };

  const handleTouchMove = (e) => {
    // Product OutOfStock zoomSize=0 don't handle touch events
    if (!zoomSize.x) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();

    setIsImageClicked(false);
    const { clientWidth, clientHeight, scrollWidth } = carouselRef.current;
    const { targetTouches } = e;

    const { clientX, pageX: x1, pageY: y1 } = targetTouches[0];
    const { width, height } = zoomImageRef.current.getBoundingClientRect();

    if (targetTouches.length === 1) {
      if (zoomLevel.current) {
        const [X, Y] = helpers.getValidZoomOffset({
          X: x1 - zoomStartOffset.x,
          Y: y1 - zoomStartOffset.y,
          imageWidth: width,
          imageHeight: height,
          clientWidth,
          clientHeight,
        });

        setZoomOffSet({
          x: X,
          y: Y,
        });
      } else {
        const offset = clientX - startX;
        if (offset <= 0 && offset >= clientWidth - scrollWidth) {
          setXOffset(offset);
        }
      }
    } else {
      if (isVideo(images[activeImageIndex])) {
        return;
      }
      const { pageX: x2, pageY: y2 } = targetTouches[1];
      const d = helpers.getEuclideanDistance(x1, y1, x2, y2);

      const updatedZoomLevel = helpers.getNewZoomLevel({
        currentZoom: pinchZoomLevel,
        currentDistance: pinchDistance,
        newDistance: d,
        scale: zoomFactor,
        minZoom: clientWidth,
        maxZoom: zoomSize.x,
      });

      setZoomLevel(updatedZoomLevel);

      const [X, Y] = helpers.getNewZoomOffset({
        zoom: updatedZoomLevel,
        currentX: zoomStartOffset.x,
        currentY: zoomStartOffset.y,
        pinchX: pinchSize.x,
        pinchY: pinchSize.y,
        offsetX: pinchZoomOffset.x,
        offsetY: pinchZoomOffset.y,
      });

      const [newX, newY] = helpers.getValidZoomOffset({
        X,
        Y,
        imageWidth: width,
        imageHeight: height,
        clientWidth,
        clientHeight,
      });

      setZoomOffSet({
        x: newX,
        y: newY,
      });
    }
  };

  const onGestureRef = useRef((e) => {
    const shouldNotActOnGesture = zoomLevel.current || (e.targetTouches && e.targetTouches.length === 2);
    if (shouldNotActOnGesture) {
      e.preventDefault();
    }
  });

  useEffect(() => {
    containerRef.current.removeEventListener('touchmove', onGestureRef.current);
    containerRef.current.addEventListener('touchmove', onGestureRef.current);
    // For Safari in iOS
    document.addEventListener('gesturestart', onGestureRef.current);
  }, []);

  const onTransitionEnd = () => {
    setAnimate(false);
    if (!showControlButtons) {
      return;
    }
    if (activeImageIndex === images.length - 1) {
      setActiveImageIndex(1);
    } else if (activeImageIndex === 0) {
      setActiveImageIndex(images.length - 2);
    }
  };

  const showImageGalleryControlButtons = showControlButtons && !zoomLevel.current;

  const getZoomLevel = () => {
    if (!zoomLevel.current) {
      return 1;
    }
    return zoomLevel.current / window.innerWidth;
  };

  const renderHeadline = (image, index, arr) => {
    const { mediaId, mediaUrl } = getMediaData(image);
    let key = mediaId;

    if (index === 0) {
      key = `${image}-hidden-start`;
    } else if (index === arr.length - 1) {
      key = `${image}-hidden-end`;
    }

    return isVideo(image) ? (
      <ImageGalleryMobileVideo key={key} url={image.url || image} setVideoPlaying={setVideoPlaying} />
    ) : (
      <div key={key} className="image-headline">
        <Image
          id={mediaId}
          imageUrl={mediaUrl}
          type="headline"
          width={headlineImageWidth}
          height={headlineImageHeight}
          alt={`${brandName} - ${productName}`}
          quality={imageQuality}
          useSrcSet={false}
          crop={crop}
        />
        <style jsx>{ImageGalleryHeadlineMobileStyles}</style>
      </div>
    );
  };

  const activeImage = images[activeImageIndex] || IMAGE_GALLERY.DEFAULT_IMAGE;
  const { mediaId: activeImageId, mediaUrl: activeImageUrl } = getMediaData(activeImage);

  const zoomImageUrl = getImages({
    variantId: activeImageId,
    type: 'headline',
    regionCode,
    attr: { width: zoomSize.x, height: zoomSize.y, quality: imageQuality },
    url: activeImageUrl,
    sellerName,
    isCFMediasEnabled,
    mediaDomains,
    fallBackImagesDomain,
  });
  return (
    <div
      className="headline-wrapper fa--image-gallery-item__mobile"
      onFocus={noop}
      ref={(r) => {
        containerRef.current = r;
      }}
      role="button"
      onKeyDown={noop}
      tabIndex="0"
    >
      {showImageGalleryControlButtons && (
        <ImageGalleryControlButtons
          onNextHeadline={onNextHeadline}
          onPreviousHeadLine={onPreviousHeadLine}
          variant={isVideoPlaying ? 'opaque' : 'transparent'}
        />
      )}
      <div
        className="image-slider"
        onTouchEnd={handleTouchEnd}
        onTransitionEnd={onTransitionEnd}
        onTouchMove={handleTouchMove}
        onTouchStart={handleTouchStart}
      >
        <div
          className={`image-wrapper ${!showControlButtons ? 'out-of-stock' : ''}`}
          ref={(r) => {
            carouselRef.current = r;
          }}
          style={{
            transform: `translateX(${xOffset}px)`,
            transition: animate ? `all 0.2s ease-in-out` : `none`,
          }}
          role="button"
          tabIndex="0"
          onKeyPress={noop}
          onClick={(e) => {
            e.stopPropagation();
            return true;
          }}
        >
          {images.map(renderHeadline)}
        </div>

        <div
          className={`image-zoom-container ${zoomLevel.current && !isVideo(activeImage) ? 'visible' : ''}`}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onTouchStart={handleTouchStart}
        >
          <img
            ref={(r) => {
              zoomImageRef.current = r;
            }}
            className="image-headline zoom"
            style={{
              transform: `matrix(${getZoomLevel()}, 0, 0, ${getZoomLevel()}, ${zoomOffSet.x}, ${zoomOffSet.y})`,
            }}
            src={zoomImageUrl}
            alt=""
          />
        </div>
      </div>

      <style jsx>{ImageGalleryHeadlineMobileStyles}</style>
    </div>
  );
};

ImageGalleryHeadlineMobile.defaultProps = {
  zoomFactor: 2.5,
  zoomResetLimit: 10,
  showControlButtons: true,
  images: [],
  crop: undefined,
};

ImageGalleryHeadlineMobile.propTypes = {
  zoomSize: PropTypes.object.isRequired,
  activeImageIndex: PropTypes.number.isRequired,
  images: PropTypes.array,
  setActiveImageIndex: PropTypes.func.isRequired,
  imageQuality: PropTypes.number.isRequired,
  headlineImageHeight: PropTypes.number.isRequired,
  headlineImageWidth: PropTypes.number.isRequired,
  zoomFactor: PropTypes.number,
  zoomResetLimit: PropTypes.number,
  showControlButtons: PropTypes.bool,
  crop: PropTypes.object,
  productName: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
};

export default withApplicationContext(withProduct(ImageGalleryHeadlineMobile));
