import { isSodimacStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import { withProduct } from '../../../utils/ProductContext';
import { getVariant } from '../../../utils/variant';
import AddtionalInformationCard from '../../AdditionalInformationCard/AdditionaInformationCard';
import ComboSpecifications from '../../ComboSection/ComboSpecifications';
import ExpandableSwatch from '../../ExpandableSwatch/ExpandableSwatch';
import SpecificationTable from '../../SpecificationTable/SpecificationTable';
import OkayToShopInfo from '../ProductInfo/OkToShopInfo';
import ProductInfo from '../ProductInfo/ProductInfo';
import { AdditionalInformationStyleCss } from './AdditionalInformationMobile.style';

const AdditionalInformationMobile = ({
  specifications,
  description,
  topSpecifications,
  labels,
  product,
  isMobile,
  appCtx,
  okayToShopBarcodes,
}) => {
  const headerType = 'mkp-headerContainer';
  const swatchType = 'mkp-swatchButton';
  const { currentVariant, variants } = product;
  const isSoCom = isSodimacStandalone(appCtx.store);
  const specificationsTitle = isSoCom
    ? _.get(labels, 'SO_COM_SPECIFICATIONS_LABEL', 'Ficha técnica')
    : labels.SPECIFICATIONS_LABEL;
  const productInformationLabel = isSoCom
    ? _.get(labels, 'SO_COM_PRODUCT_INFORMATION_LABEL', 'Descripción')
    : labels.PRODUCT_INFORMATION_LABEL;
  const comboData = _.get(getVariant(variants, currentVariant), 'comboData', null);
  const isOkToShopFeatureEnabled = _.get(appCtx, 'siteConfig.toggles.isOkToShopFeatureEnabled', false);
  const showSpecifications = comboData?.hasSpecifications || (specifications.length > 0 && !topSpecifications.length);

  return (
    <div className="productInfoContainer" id="productInfoContainer">
      {showSpecifications && (
        <div className="specifications-container">
          <ExpandableSwatch height={562} buttonType={swatchType}>
            <div className="specification">
              <AddtionalInformationCard
                headerText={specificationsTitle}
                headerClass="specification-header"
                headerType={headerType}
              >
                {comboData && comboData.hasSpecifications ? (
                  <ComboSpecifications comboItems={comboData.comboItems} />
                ) : (
                  <SpecificationTable specifications={specifications} />
                )}
              </AddtionalInformationCard>
            </div>
          </ExpandableSwatch>
        </div>
      )}
      {description && !isMobile && (
        <div className="productInfo-container">
          <ExpandableSwatch height={818} buttonType={swatchType}>
            <div className="productInfo">
              <AddtionalInformationCard headerText={productInformationLabel} headerType={headerType}>
                {/* eslint-disable react/no-danger */}
                <div className="htmlBox-container">
                  <ProductInfo description={description} />
                </div>
              </AddtionalInformationCard>
            </div>
          </ExpandableSwatch>
        </div>
      )}
      {(description && !isMobile) ||
        (okayToShopBarcodes?.length > 0 && (
          <div className="productInfo-container">
            <ExpandableSwatch height={818} buttonType={swatchType}>
              <div className="productInfo">
                <AddtionalInformationCard headerText={productInformationLabel} headerType={headerType}>
                  {/* eslint-disable react/no-danger */}
                  <div className="htmlBox-container">
                    {isOkToShopFeatureEnabled && okayToShopBarcodes?.length > 0 && (
                      <OkayToShopInfo okayToShopBarcodes={okayToShopBarcodes} />
                    )}
                    {description && <ProductInfo description={description} />}
                  </div>
                </AddtionalInformationCard>
              </div>
            </ExpandableSwatch>
          </div>
        ))}

      <style jsx>{AdditionalInformationStyleCss}</style>
    </div>
  );
};

AdditionalInformationMobile.defaultProps = {
  description: undefined,
  topSpecifications: [],
  isMobile: false,
  okayToShopBarcodes: [],
};

AdditionalInformationMobile.propTypes = {
  specifications: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
  topSpecifications: PropTypes.array,
  description: PropTypes.string,
  isMobile: PropTypes.bool,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  okayToShopBarcodes: PropTypes.array,
};
export { AdditionalInformationMobile };
export default withProduct(withLabels(AdditionalInformationMobile));
