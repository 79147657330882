import React, { Fragment, Children, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ModalStyles, ModalStylesGlobal, clickElementStyle } from './Modal.style';
import BackgroundOverlay from '../BackgroundOverlay/BackgroundOverlay';
import WithToggle from '../../../utils/WithToggle';
import WithOutsideClick from '../../../utils/WithOutsideClick';
import { toggleBodyClass } from '../../../utils/jsUtils';
import noop from '../../../utils/noop';
import { disableScroll, enableScroll } from '../../../utils/manageScroll';
import MarketplaceSpinner from '../Spinner/MarketplaceSpinner';
import _ from '../../../utils/LodashImports';

const Modal = ({
  children,
  toggleState,
  content,
  options,
  header,
  visible,
  withPadding,
  showCloseButton,
  fullScreen,
  appendClickElement,
  showSpinner,
  className,
  deviceType,
  stopScroll,
  closeBtnStyles,
  customClass,
  fullWidth,
  customClassBody,
  callbackForCloseBtn,
}) => {
  if (visible) {
    toggleState.setVisible(true);
  }
  const [customHeader, setCustomHeader] = useState(null);
  const config = {
    width: undefined,
    height: undefined,
    disableOutsideClick: false,
    ...options,
  };
  const [modalBusy, setModalBusy] = useState(showSpinner);
  const [isModalVisible, setModalVisible] = useState(false);

  const removeClasses = () => {
    toggleBodyClass(false, 'no-scroll');
    if (deviceType !== 'desktop' && !stopScroll) {
      enableScroll();
    }
    setModalVisible(false);
  };

  useEffect(() => {
    if (toggleState.visible) {
      setModalVisible(true);
      toggleBodyClass(true, 'no-scroll');
      if (deviceType !== 'desktop' && !stopScroll) {
        disableScroll();
      }
    } else if (isModalVisible) {
      removeClasses();
    }
    return removeClasses; // This line executes when component will unmount
  }, [toggleState.visible]);

  const actions = {
    close: (e) => {
      toggleState.setVisible(false);
      callbackForCloseBtn();
      if (e && e.stopPropagation) {
        e.stopPropagation();
      }
    },
    setCustomHeader,
    setBusy: (toggle) => {
      setModalBusy(toggle);
    },
  };

  const newChildren = Children.map(children, (element) => {
    return appendClickElement ? (
      <div
        onClick={() => {
          toggleState.setVisible(true);
        }}
        onKeyDown={noop}
        role="button"
        tabIndex={0}
        className="click-element"
      >
        {children}
        <style jsx>{clickElementStyle}</style>
      </div>
    ) : (
      <element.type
        onClick={() => {
          toggleState.setVisible(true);
        }}
        {...element.props}
      />
    );
  });

  const { height, width, overflow = 'auto', padding, minHeight, maxHeight, borderRadius } = options;
  let heightStyle = height;

  if (_.isNumber(heightStyle)) {
    heightStyle = `${heightStyle}px`;
  }
  const desktopModalClass = deviceType === 'desktop' ? 'modal-container-desktop' : '';

  return (
    <>
      {newChildren}
      {toggleState.visible && (
        <div className={`modal-container ${fullScreen ? 'full-screen' : ''} ${className} ${desktopModalClass}`}>
          <WithOutsideClick
            onOutsideClick={() => {
              if (!config.disableOutsideClick) {
                actions.close();
              }
            }}
          >
            <div
              style={{
                width: fullScreen || fullWidth ? '100vw' : `${width}px`,
                height: fullScreen ? '100vh' : `${heightStyle}`,
                overflow,
                padding,
                minHeight,
                maxHeight,
                borderRadius,
              }}
              className={`modal ${withPadding && 'withPadding'} ${customClass}`}
            >
              {modalBusy && (
                <div className="modal-busy-overlay">
                  <MarketplaceSpinner />
                </div>
              )}
              {showCloseButton && (
                <button
                  type="button"
                  style={closeBtnStyles}
                  className="modal-close"
                  id="testId-modal-close"
                  onClick={actions.close}
                >
                  <i className="csicon-danger" />
                </button>
              )}
              {header && (
                <div className={`modal-header ${withPadding && 'withPadding'}`}>
                  <i className={`csicon-${header.icon}`} /> {header.title}
                </div>
              )}
              {!header && customHeader && (
                <div className={`modal-header ${withPadding && 'withPadding'}`}>{customHeader}</div>
              )}
              <div
                className={`modal-body ${(header || customHeader) && 'with-header'} ${
                  fullScreen ? 'full-screen' : ''
                } ${withPadding && 'withPadding'} ${customClassBody}`}
              >
                {content(actions)}
              </div>
            </div>
          </WithOutsideClick>
          <style jsx>{ModalStyles}</style>
          <style jsx>{ModalStylesGlobal}</style>
          <BackgroundOverlay />
        </div>
      )}
    </>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  toggleState: PropTypes.object.isRequired,
  content: PropTypes.func.isRequired,
  options: PropTypes.object,
  header: PropTypes.objectOf({
    icon: PropTypes.string,
    title: PropTypes.string,
  }),
  visible: PropTypes.bool,
  withPadding: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  fullScreen: PropTypes.bool,
  appendClickElement: PropTypes.bool,
  showSpinner: PropTypes.bool,
  className: PropTypes.string,
  deviceType: PropTypes.string,
  stopScroll: PropTypes.bool,
  closeBtnStyles: PropTypes.object,
  customClass: PropTypes.string,
  fullWidth: PropTypes.bool,
  customClassBody: PropTypes.string,
  callbackForCloseBtn: PropTypes.func,
};
Modal.defaultProps = {
  children: [],
  options: {},
  header: null,
  visible: false,
  withPadding: true,
  showCloseButton: true,
  fullScreen: false,
  appendClickElement: false,
  showSpinner: false,
  className: '',
  deviceType: false,
  stopScroll: false,
  closeBtnStyles: {},
  fullWidth: false,
  customClass: '',
  customClassBody: '',
  callbackForCloseBtn: noop,
};

export { Modal };
export default WithToggle(Modal);
