import { getCommerceHeaders as storeCommerceHeaders } from '@digital-retail/store-manager';
import { getSidCookie, isMarketPlace } from './urlHelper';

const tenants = {
  cl: 'FACL',
  co: 'FACO',
  pe: 'FAPE',
  ar: 'FAAR',
};
const getTenant = (regionCode) => {
  return tenants[regionCode];
};

const getCommerceHeaders = (regionCode, store, includeAuth = false) => {
  const mkp = isMarketPlace();
  const h = storeCommerceHeaders({ store, regionCode, isMarketPlace: mkp });

  const sid = getSidCookie();
  const headers = {
    'x-channel-id': 'WEB',
    'x-commerce': 'FCM',
    'x-origin-name': 'PDP',
    'x-origin-view': 'Product',
    ...h,
  };
  if (sid && includeAuth) {
    headers.Authorization = sid;
  }
  return headers;
};

export { getCommerceHeaders, getTenant };
