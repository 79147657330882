import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';
import _ from '../../../utils/LodashImports';

const ThumbnailImageStyles = (theme) => {
  const secondaryColor = _.get(theme, 'secondary_color', null) || colors.secondary_color;
  return css`
    .thumbnail-color {
      border: 1px solid #888;
      border-radius: 4px;
      height: 52px;
      width: 52px;
    }

    .thumbnail-image,
    .thumbnail-image img {
      cursor: pointer;
      height: 52px;
      width: 52px;
      overflow: hidden;
    }

    .thumbnail-image img {
      border: 1px solid #eee;
      border-radius: 4px;
    }

    .thumbnail-border {
      border: 2px solid #fff;
      padding: 2px;
      border-radius: 4px;
    }

    .thumbnail-border-selected {
      border: 2px solid ${secondaryColor};
      border-radius: 4px;
      box-sizing: border-box;
      padding: 2px;
    }
    .thumbnail-border-selected .thumbnail-image img {
      border: none;
    }
  `;
};

export { ThumbnailImageStyles };
