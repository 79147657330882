import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withProduct } from '../../../utils/ProductContext';
import { ServicesSelectEnhancedStyles } from './SevicesSelect.style';
import { getServicesBenefits } from './ServicesSelect.helpers';
import constants from '../../../config/constants';
import { getAdditionalServicesUrl } from '../SingleChipletSelect/helpers';
import _ from '../../../utils/LodashImports';
import noop from '../../../utils/noop';

const ServicesSelectEnhanced = ({ options, onOptionSelected, appCtx, product, source = '' }) => {
  const [serviceOptions, setServiceOptions] = useState(options);
  const [benefitsVisibility, setBenefitsVisibility] = useState(false);

  useEffect(() => {
    setServiceOptions(options);
  }, [options]);

  const toggleBenefitsVisibility = () => {
    setBenefitsVisibility((prevState) => !prevState);
  };
  const handleOptionSelected = (selectedIndex) => {
    const updatedServiceOptions = serviceOptions.map((option, index) =>
      index === selectedIndex ? { ...option, selected: !option.selected } : option
    );
    setServiceOptions(updatedServiceOptions);
    onOptionSelected(updatedServiceOptions, selectedIndex);
  };

  const { store, deviceType, siteConfig, regionCode } = appCtx;
  const isMobile = deviceType === 'mobile';
  const {
    SERVICES_MODAL_LABEL = 'Conoce nuestros servicios adicionales',
    SERVICES_BENEFITS_LABEL = 'Servicios adicionales disponibles',
    MORE_INFORMATION = 'Más información',
  } = _.get(appCtx, 'siteConfig.textDictionary', {});

  const sellerName = _.get(product, 'variants[0].offerings[0].sellerName', '');
  const isSodimacStandAlone = store === constants.STORES.so_com;
  const moreInfoUrl = getAdditionalServicesUrl(
    sellerName.toLowerCase(),
    regionCode,
    _.get(appCtx, 'siteConfig.endpoints', {}),
    isSodimacStandAlone
  );

  const servicesBenefits = getServicesBenefits({ siteConfig, regionCode });

  return (
    <div>
      <div id="test-id-additional-service" className="service-options-wrapper">
        <div className="title">
          <div className="services-icon" />
          <p>{SERVICES_MODAL_LABEL}</p>
        </div>
        <div className="body">
          <div className="sku-list">
            {serviceOptions.map((service, index) => {
              const { name, textPrice, selected } = service;
              return (
                <button
                  type="submit"
                  id="service-sku"
                  className={`service-sku ${selected ? 'selected' : ''}`}
                  onClick={() => handleOptionSelected(index)}
                >
                  <div className="label-wrapper">
                    <span className="label">
                      <div className={`checkbox ${selected ? 'full' : 'empty'}`} />
                      <div>{name}</div>
                    </span>
                  </div>
                  <div className="price">{textPrice}</div>
                </button>
              );
            })}
          </div>
          {servicesBenefits.length > 0 && (
            <div className="benefits-list">
              <div className={`title ${source}`}>
                <p>{SERVICES_BENEFITS_LABEL}</p>
                {isMobile && source !== 'drawer' && (
                  <button type="submit" onClick={toggleBenefitsVisibility}>
                    <i className={`${benefitsVisibility ? 'collapse' : 'expand'}`} />
                  </button>
                )}
              </div>
              {(!isMobile || source === 'drawer' || benefitsVisibility) && (
                <div className="list">
                  {servicesBenefits.map((benefit) => (
                    <span className="benefit">
                      <i className="tick-icon" />
                      <div className="label">
                        <p className="title">{benefit.title}</p>
                        <p className="description">{benefit.description}</p>
                      </div>
                    </span>
                  ))}
                  {moreInfoUrl && (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={moreInfoUrl}
                      className="link-wrapper"
                      tabIndex={0}
                    >
                      <span className="more-information">{MORE_INFORMATION}</span>
                      <i className="csicon-right" />
                    </a>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <style jsx>{ServicesSelectEnhancedStyles}</style>
    </div>
  );
};

ServicesSelectEnhanced.defaultProps = {
  options: [],
  onOptionSelected: noop,
  appCtx: {},
  product: {},
  source: '',
};

ServicesSelectEnhanced.propTypes = {
  onOptionSelected: PropTypes.func,
  options: PropTypes.array,
  appCtx: PropTypes.object,
  product: PropTypes.object,
  source: PropTypes.string,
};

export default withProduct(ServicesSelectEnhanced);
