import _ from './LodashImports';
// import apiConfig from '../config/api/apiConfig';
import httpService from './httpService';

const fetchSessionConfirmationNumber = async (appCtx) => {
  let sessionConfirmation = '';
  // const sessionInfoPath = _.get(
  //   appCtx,
  //   'header.endPoints.fetchSessionInfo.path',
  //   ''
  // );
  // const regionCode = _.get(appCtx, 'regionCode', '');
  // const sessionInfoUrl = apiConfig.getUserSessionInfo(
  //   regionCode,
  //   sessionInfoPath
  // );
  const atgBaseUrl = _.get(appCtx, 'siteConfig.endpoints.ATG_AUTH_BASEURL', 'https://www.falabella.com');
  // const sessionInfoUrl = apiConfig.getFetchSessionInfoUrl(regionCode);
  const sessionInfoUrl = `${atgBaseUrl}/rest/model/atg/userprofiling/ProfileActor/fetchSessionInfo`;
  const { error: sessionInfoError, data: sessionData } = await httpService().get(sessionInfoUrl, {
    includeTracing: false,
  });

  if (sessionInfoError) {
    appCtx.setBusy(false);
    sessionConfirmation = '';
    throw new Error('Rrror getting session confirmation id');
  }

  if (sessionData && sessionData.state && sessionData.state.sessionConfirmationNumber) {
    const { sessionConfirmationNumber } = sessionData.state;
    sessionConfirmation = sessionConfirmationNumber;
  }
  return sessionConfirmation;
};

export default fetchSessionConfirmationNumber;
