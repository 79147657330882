import { createScriptTag, appendScriptsToHead } from '../../utils/DOMUtil';
import constants from '../../config/constants';

const loadGoogleMapScript = (googleMapApiKey, tenant) => {
  const dynamicScript = createScriptTag({
    src: `${constants.GOOGLE_MAP}key=${googleMapApiKey}&language=ES-419&region=${tenant.toUpperCase()}`,
    defer: true,
    async: true,
  });
  appendScriptsToHead(dynamicScript);
};

export default loadGoogleMapScript;
