import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import WithUrl from '../../utils/WithUrl';
import bvRebrandingStyles from './BVRebranding.styles';

const BazaarVoiceHead = ({ country }) => {
  return (
    <Head>
      <script type="text/javascript" async src={WithUrl('BAZAARVOICE_SCRIPT_URL', country)} />
      <style type="text/css">{bvRebrandingStyles}</style>
    </Head>
  );
};

BazaarVoiceHead.propTypes = {
  country: PropTypes.string.isRequired,
};

const BazaarVoiceComponent = ({ componentName, productId }) => {
  const [attributes, setAttributes] = useState({});
  useEffect(() => {
    setAttributes({
      'data-bv-show': componentName,
      'data-bv-product-id': productId,
    });
  }, []);
  return <div {...attributes} />;
};

BazaarVoiceComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  productId: PropTypes.string.isRequired,
};

export { BazaarVoiceComponent, BazaarVoiceHead };
