import { isSodimacStandalone } from '@digital-retail/store-manager';
import _ from 'lodash';
import apiConfig from '../../config/api/apiConfig';

export const getSellerLink = ({
  sellerClass,
  regionCode = 'cl',
  sellerName,
  env,
  isSISExperienceOn,
  sellerRedirectionMap,
  store = '',
}) => {
  if (isSISExperienceOn && ['falabella', 'sodimac', 'tottus', 'linio', 'homecenter'].includes(sellerClass)) {
    const isSoCom = isSodimacStandalone(store);
    if (isSoCom) {
      let environment = '';
      if (env === 'uat') {
        environment = 'beta.';
      } else if (env !== 'production') {
        environment = `${env}.`;
      }
      const sodimacTopLevelDomain = regionCode === 'cl' ? regionCode : `com.${regionCode}`;
      return `https://${environment}sodimac.${sodimacTopLevelDomain}/sodimac-${regionCode}`;
    }
    return sellerRedirectionMap[sellerClass];
  }
  return apiConfig.getSellerURL({
    regionCode,
    sellerName,
    env,
    store,
  });
};

export const getTooltipText = ({ sellerName, labels, storePlaceholderText, sellerData, isNational }) => {
  const { OWN_SELLER_INFO = '' } = labels;
  const { regNumber, businessName, regNumberType } = sellerData;
  const tooltipText = OWN_SELLER_INFO.replace(storePlaceholderText, sellerName);
  const InternationText = _.get(labels, 'INTERNATIONAL_TOOLTIP_TEXT', 'Venta Internacional');
  const companyNameInfo = isNational ? `</br></br>${businessName}` : `</br></br>${businessName} ${InternationText}`;
  const rutSellerInfo = ` ${businessName ? '</br>' : '</br></br>'}${regNumberType} ${regNumber}`;

  const additionalInfo = `${businessName ? companyNameInfo : ''}${regNumber ? rutSellerInfo : ''}`;
  return `${tooltipText}${additionalInfo}`;
};

export const validateSellerName = (name, country) => {
  if (country === 'co' && name === 'sodimac') {
    return 'Homecenter';
  }
  return name;
};

export const getSellerClass = ({ constants, sellerNameLower }) => {
  if (constants.FALABELLA_REGEX.test(sellerNameLower)) {
    return 'falabella';
  }
  if (constants.SODIMAC_REGEX.test(sellerNameLower)) {
    return 'sodimac';
  }
  if (constants.HOMECENTER_REGEX.test(sellerNameLower)) {
    return 'homecenter';
  }
  if (constants.TOTTUS_REGEX.test(sellerNameLower)) {
    return 'tottus';
  }
  if (constants.LINIO_REGEX.test(sellerNameLower)) {
    return 'linio';
  }
  return '';
};
