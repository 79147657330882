import { isWhitelabel } from '@digital-retail/store-manager';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import AdditionalPDPLabel from '../../../../components/AdditionalPDPLabel/AdditionalPDPLabel';
import Availability from '../../../../components/Availability/Availability';
import { AvailabilityLocation } from '../../../../components/Availability/AvailabilityLocation/AvailabilityLocation';
import Badges from '../../../../components/Badges/Badges';
import CMRApertura from '../../../../components/CMRApertura/CMRApertura';
import CMRCalculatorDesktop from '../../../../components/CMRCalculator/Desktop';
import FloorCalculator from '../../../../components/FloorCalculator/FloorCalculator';
import FloorCalculatorWarning from '../../../../components/FloorCalculator/FloorCalculatorWarning/FloorCalculatorWarning';
import InternationalShipping from '../../../../components/InternationalShipping/InternationalShipping';
import MultipurposeBadge from '../../../../components/MultipurposeBadge/MultipurposeBadge';
import NearbyStores from '../../../../components/NearbyStores/index';
import Operator from '../../../../components/Operator/Operator';

import { getDeliveryOptionBorderClassName } from '../../../../components/Availability/DeliveryOptions/utils';
import ProductColorSwatches from '../../../../components/ProductColorSwatches/ProductColorSwatches';
import ProductPrices from '../../../../components/ProductPrices/ProductPrices';
import ProductServices from '../../../../components/ProductServices/ProductServices';
import ProductTopSpecifications from '../../../../components/ProductTopSpecifications/ProductTopSpecifications';
import { ReaconditionedContainer } from '../../../../components/ReaconditionedContainer/ReaconditionedContainer';
import SellerInfo from '../../../../components/SellerInfo/SellerInfo';
import SellerRatings from '../../../../components/SellerRatings/SellerRatings';
import Size from '../../../../components/Size/Size';
import StockQuantity from '../../../../components/StockQuantity/StockQuantity';
import BodyCopy from '../../../../components/ui/BodyCopy/BodyCopy';
import Button from '../../../../components/ui/Button/Button';
import Stepper from '../../../../components/ui/Stepper/Stepper';
import StockTable from '../../../../components/ui/StockTable/StockTable';
import Warranty from '../../../../components/Warranty/Warranty';
import constants from '../../../../config/constants';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import { withCart } from '../../../../utils/CartContext';
import { useSelectedDeliveryOption } from '../../../../utils/hooks/UseSelectedDeliveryOption';
import { withLabels } from '../../../../utils/LabelsContext';
import _ from '../../../../utils/LodashImports';
import noop from '../../../../utils/noop';
import * as productUtils from '../../../../utils/product/productUtils';
import { withProduct } from '../../../../utils/ProductContext';
import { getVariant } from '../../../../utils/variant';
import ProductQuantitySelectionContainer from './ProductQuantitySelectionContainer';
import { ProductSpecificationsDesktopStyle } from './ProductSpecificationsDesktop.style';

const ProductSpecificationsDesktop = ({
  product,
  labels,
  appCtx,
  isPumAvailable,
  pumQuantity,
  primeShipping,
  colorVariants,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  sizes,
  selectedSize,
  sizeChangeHandler,
  handleAddToCart,
  handleRemoveFromCart,
  isRemoveFromCartInProgress,
  isAddToCartInProgress,
  operators,
  operatorChangeHandler,
  addToCartButtonVisible,
  showCollectionButtonVisible,
  setWarrantyOption,
  setServiceOption,
  isVendorLogin,
  storeSkuData,
  currentTab,
  selectedOperatorId,
  errorAddingToCart,
  requestProductFromStore,
  handleM2QuantityChange,
  m2Quantity,
  m2ResultValue,
  handleM2ResultChange,
  m2Value,
  handleM2ValueChange,
  isExtraM2Checked,
  handleExtraM2Change,
  m2AttributeValue,
  setShowLoginForm,
  showLoginForm,
  setErrorAddingToCart,
  sizeChartData,
  fomo,
}) => {
  const {
    variants,
    id: productId,
    currentVariant: currentVariantId,
    additionalPDPLabels,
    isOutOfStock,
    colorMetrics,
    darSectionActive = false,
    cmrBannerActive = true,
    serviceOptions,
    warrantyOptions,
  } = product;
  const isInternationalShipping = _.get(product, 'internationalShipping.applicable', false);

  const isConnectProduct = productUtils.isConnectProduct(product);
  const { regionCode, store } = appCtx;
  const { viewTemplate, pumUnits } = product.attributes;
  const [comunaName, setComunaName] = useState('');

  const isMeatStickerApplicableInPDP = _.get(appCtx, 'siteConfig.toggles.isMeatStickerApplicableInPDP', false);
  const { badges = [], meatStickers = [], promotions } = getVariant(product.variants, product.currentVariant);
  const allowed3PCampaignNamesToRender = _.get(
    appCtx,
    'siteConfig.textDictionary.ALLOWED_3P_CAMPAIGN_NAMES_TO_RENDER',
    'ENVIO GRATIS 3P, ENVIO_GRATIS_3P'
  );
  const freeShippingPromotionFor3p = promotions?.find((promotion) =>
    allowed3PCampaignNamesToRender
      .split(',')
      .map((name) => name.trim())
      .includes(promotion.campaignName)
  );
  const hasStickers = (isMeatStickerApplicableInPDP && meatStickers.length > 0) || freeShippingPromotionFor3p;
  const nonEventMeatStickers = (meatStickers || []).filter((sticker) => !['event'].includes(sticker.type));
  const variant = getVariant(variants, currentVariantId);
  const maxCartQuantity = _.get(variant, 'maxOnCartAmount', constants.MAX_CART_PRODUCT);
  const sellerName = _.get(variants, '[0].offerings[0].sellerName', '');
  const is1pSeller = constants.FIRST_PARTY_SELLER_REGEX.test(sellerName.toLowerCase());
  const multipurposeBadges = _.get(variant, 'multipurposeBadges', []);
  const { prices, cmrPoints, discountBadge, cmrSavings } = variant;
  const type =
    product.productType === constants.PRODUCT_CONSTANTS.CONNECT
      ? constants.PRODUCT_CONSTANTS.CONNECT
      : constants.PRODUCT_CONSTANTS.HARDLINE;

  const shouldRenderSize = viewTemplate !== constants.VIEW_TEMPLATE.TYPE_A && sizes.length > 0;

  const isStoreDataVaild = storeSkuData && Object.keys(storeSkuData).length > 0;

  const {
    isImprovedWarrantyUiEnabled = true,
    isImprovedServicesUiEnabled = true,
    isFloorcalculatorEnabled = true,
    isSellerRatingsEnabled = true,
    isInternationalBuyVisiblePDP = false,
    isStoreAvailabilityVisibleInPDP = false,
    isQuantityEditableEnabled = false,
    isWarrantyTooltipEnabled = false,
    isCMRCalculatorEnabledPDP = false,
    isMultipurposeBadgeApplicable = false,
    isCMRAperturaEnabledForSoCom = false,
    isCMRAperturaEnabled = false,
  } = _.get(appCtx, 'siteConfig.toggles', {});

  const isSoCom = appCtx.store === constants.STORES.so_com;
  const showCMRApertura = isSoCom ? isCMRAperturaEnabledForSoCom : isCMRAperturaEnabled;

  const addToCartLabel = _.get(additionalPDPLabels, 'addToCart.enabled', false)
    ? _.get(additionalPDPLabels, 'addToCart', {})
    : null;

  const additionalWarrantyLabel = _.get(additionalPDPLabels, 'warranty.enabled', false)
    ? _.get(additionalPDPLabels, 'warranty', {})
    : null;

  const isReaconditionedProduct = productUtils.isReaconditionedProduct(product);

  const isSodimacStandAlone = appCtx.store === constants.STORES.so_com;
  const isWhitelabelSite = isWhitelabel({ store: appCtx.store });

  const { availability } = variant;
  const { zoneConfig } = useSelectedDeliveryOption(availability?.shippingOptionType, appCtx, currentVariantId);
  const deliveryOptionsBorderClassName = getDeliveryOptionBorderClassName(isSodimacStandAlone, isWhitelabelSite);

  useEffect(() => {
    if (zoneConfig) {
      setComunaName(zoneConfig.name);
    }
  }, [zoneConfig]);

  if (isOutOfStock) {
    return (
      <div
        className="product-specifications-out-of-stock"
        data-backend-category={product.merchantCategoryId}
        data-availability={product.attributes && product.attributes.isPrimeAvailable}
        data-published={product.isPublished}
        data-variations={product.variants.length}
      >
        {prices && prices.length > 0 && (
          <div className="price">
            <ProductPrices
              isVendorLogin={isVendorLogin}
              currentTab={currentTab}
              storeSkuData={storeSkuData}
              prices={productUtils.getPriceForOutOfStock(prices)}
              productId={productId}
              regionCode={regionCode}
              cmrPoints={cmrPoints}
              pumUnit={pumUnits}
              isPumAvailable={isPumAvailable}
              pumQuantity={pumQuantity}
              primeShipping={primeShipping}
              deviceType={constants.DEVICE_DESKTOP}
              operators={operators}
              selectedOperatorId={selectedOperatorId}
              isConnectProduct={isConnectProduct}
            />
          </div>
        )}

        <SellerInfo product={product} />
        {isReaconditionedProduct && <ReaconditionedContainer appCtx={appCtx} productAttributes={product} />}
        <style jsx>{ProductSpecificationsDesktopStyle}</style>
      </div>
    );
  }

  return (
    <div
      className="product-specifications"
      data-backend-category={product.merchantCategoryId}
      data-availability={product.attributes && product.attributes.isPrimeAvailable}
      data-published={product.isPublished}
      data-variations={product.variants.length}
    >
      <div className="product-specifications-column fa--product-specifications-column__desktop">
        <SellerInfo product={product} />
        {colorVariants[selectedColorSwatchIndex] && (
          <div className="color-swatch-container fa--color-swatches__desktop">
            <BodyCopy size="copy3" isHighlighted>
              {`${labels.COLOR}: `}
            </BodyCopy>
            <BodyCopy size="copy3">{colorVariants[selectedColorSwatchIndex].label}</BodyCopy>

            <ProductColorSwatches
              colorVariants={colorVariants}
              productId={product.id}
              selectedColorSwatchIndex={selectedColorSwatchIndex}
              colorSwatchHandler={colorSwatchHandler}
              type={type}
              size="medium"
              appCtx={appCtx}
              colorMetrics={colorMetrics}
              sellerName={sellerName}
            />
          </div>
        )}
        {shouldRenderSize && (
          <Size
            title={labels.SELECTION_SIZE}
            sizes={sizes}
            selectedSize={selectedSize}
            onSizeChange={sizeChangeHandler}
            sizeChartData={sizeChartData}
            fomo={fomo}
          />
        )}
        {isVendorLogin && isStoreDataVaild && (
          <StockTable
            stockData={storeSkuData}
            colorVariants={colorVariants}
            selectedColorSwatchIndex={selectedColorSwatchIndex}
            sizes={sizes}
            requestProductFromStore={requestProductFromStore}
            regionCode={regionCode}
          />
        )}
        <ProductTopSpecifications scollToElementId="productInfoContainer" />
        {!!product.showShippingModal && (
          <div className={clsx('delivery-options-wrapper', `${deliveryOptionsBorderClassName}`)}>
            {comunaName && (
              <div className="avl-content">
                <AvailabilityLocation comunaName={comunaName} />
              </div>
            )}
            <div className="dlv-opt-wrapper">
              <Availability sizes={sizes} colorVariants={colorVariants} regionCode={regionCode} />
              {(!!store || is1pSeller) && isStoreAvailabilityVisibleInPDP && (
                <NearbyStores sizes={sizes} colorVariants={colorVariants} />
              )}
            </div>
          </div>
        )}
        {isReaconditionedProduct && <ReaconditionedContainer appCtx={appCtx} productAttributes={product} />}
      </div>

      <div className="product-specifications-column fa--product-specifications-column__desktop">
        <div
          className={`price
        ${isMultipurposeBadgeApplicable && multipurposeBadges.length > 0 ? 'withMultipurposeBadge' : ''}`}
        >
          <ProductPrices
            isVendorLogin={isVendorLogin}
            currentTab={currentTab}
            storeSkuData={storeSkuData}
            prices={prices}
            productId={productId}
            regionCode={regionCode}
            cmrPoints={cmrPoints}
            pumUnit={pumUnits}
            isPumAvailable={isPumAvailable}
            pumQuantity={pumQuantity}
            primeShipping={primeShipping}
            deviceType={constants.DEVICE_DESKTOP}
            operators={operators}
            selectedOperatorId={selectedOperatorId}
            isConnectProduct={isConnectProduct}
            discountBadge={discountBadge}
            darSectionActive={darSectionActive}
          />
        </div>
        {isInternationalBuyVisiblePDP && isInternationalShipping && <InternationalShipping />}
        {(badges?.length || meatStickers?.length || freeShippingPromotionFor3p?.badge) && (
          <div className="meatstickers-container">
            <Badges
              badgeItems={isMeatStickerApplicableInPDP ? nonEventMeatStickers : badges}
              layout="PDP"
              isSticker={hasStickers}
              promotionBadge={freeShippingPromotionFor3p?.badge}
              store={appCtx.store}
            />
          </div>
        )}
        {isMultipurposeBadgeApplicable && multipurposeBadges.length > 0 && (
          <div className="multipurpose-badge">
            <MultipurposeBadge badgeItems={multipurposeBadges} deviceType="desktop" inPdp store={appCtx.store} />
          </div>
        )}
        <StockQuantity />
        {isConnectProduct && (
          <Operator
            isDesktop
            isConnectProduct={isConnectProduct}
            operators={operators}
            operatorChangeHandler={operatorChangeHandler}
            selectedOperatorId={selectedOperatorId}
          />
        )}
        {addToCartLabel && (
          <AdditionalPDPLabel label={addToCartLabel} type="addToCart" productType="hardline" desktop />
        )}
        {m2AttributeValue > 0 && isFloorcalculatorEnabled && (
          <div className="floorCalc-pdp-container">
            <FloorCalculator
              appCtx={appCtx}
              m2AttributeValue={m2AttributeValue}
              handleM2QuantityChange={handleM2QuantityChange}
              handleM2ResultChange={handleM2ResultChange}
              m2Value={m2Value}
              m2ResultValue={m2ResultValue}
              handleM2ValueChange={handleM2ValueChange}
              isExtraM2Checked={isExtraM2Checked}
              handleExtraM2Change={handleExtraM2Change}
            />
            <div className="quantity-selector">
              <Stepper
                type="secondary"
                inputType="text"
                minimum={1}
                maximum={maxCartQuantity}
                count={m2Quantity}
                incrementButtonId="testId-floorCalc-stepper-increment-btn"
                decrementButtonId="testId-floorCalc-stepper-decrement-btn"
                editable={isQuantityEditableEnabled}
                onInputValueChange={handleM2QuantityChange}
                inputMaxLength={3}
              />
            </div>
            {m2ResultValue > 0 && m2Quantity < m2ResultValue && (
              <FloorCalculatorWarning
                appCtx={appCtx}
                isExtraM2Checked={isExtraM2Checked}
                m2Value={m2Value}
                m2ResultValue={m2ResultValue}
                m2Quantity={m2Quantity}
              />
            )}
          </div>
        )}
        <ProductQuantitySelectionContainer
          errorAddingToCart={errorAddingToCart}
          addToCartButtonVisible={addToCartButtonVisible}
          isAddToCartInProgress={isAddToCartInProgress}
          labels={labels}
          maxCartQuantity={maxCartQuantity}
          handleAddToCart={handleAddToCart}
          handleRemoveFromCart={handleRemoveFromCart}
          isRemoveFromCartInProgress={isRemoveFromCartInProgress}
          isVendorLogin={isVendorLogin}
          sizes={sizes}
          selectedSize={selectedSize}
          setShowLoginForm={setShowLoginForm}
          showLoginForm={showLoginForm}
          setErrorAddingToCart={setErrorAddingToCart}
          m2AttributeValue={m2AttributeValue}
          layout="hardline"
        />
        {showCollectionButtonVisible && (
          <div className="addToCart-container fa--add-to-cart__desktop">
            <Button type="outline" onClick={handleAddToCart} size="xtra-large">
              <span>
                {labels.SEE_COLLECTION_BUTTON_LABEL}
                <i className="csicon-arrow_down_small" />
              </span>
            </Button>
          </div>
        )}
        <Warranty
          warrantyOptions={warrantyOptions}
          setWarrantyOption={setWarrantyOption}
          startsFromLabel={labels.PDP_STARTS_FROM}
          tooltipToggle={isWarrantyTooltipEnabled}
          additionalWarrantyLabel={additionalWarrantyLabel}
          sellerName={sellerName}
        />
        {isImprovedWarrantyUiEnabled && isImprovedServicesUiEnabled && <div className="services-divider" />}
        <ProductServices serviceOptions={serviceOptions} setServiceOption={setServiceOption} />

        {showCMRApertura && cmrBannerActive && (
          <div className="cmrApertura-container">
            <CMRApertura prices={prices} cmrSavings={cmrSavings} />
          </div>
        )}
        {isCMRCalculatorEnabledPDP && (
          <CMRCalculatorDesktop errorMessage={labels.CMR_INSTALLMENT_ERROR_MESSAGE} layout="hardline" />
        )}
        {isSellerRatingsEnabled && product.sellerInfo?.rating && <SellerRatings sellerInfo={product.sellerInfo} />}
      </div>
      <style jsx>{ProductSpecificationsDesktopStyle}</style>
    </div>
  );
};

ProductSpecificationsDesktop.defaultProps = {
  isPumAvailable: false,
  pumQuantity: 0,
  primeShipping: false,
  colorVariants: [],
  selectedColorSwatchIndex: 0,
  colorSwatchHandler: noop,
  sizes: [],
  selectedSize: '',
  sizeChangeHandler: noop,
  handleAddToCart: noop,
  isAddToCartInProgress: false,
  handleRemoveFromCart: noop,
  isRemoveFromCartInProgress: false,
  operators: [],
  operatorChangeHandler: noop,
  addToCartButtonVisible: true,
  showCollectionButtonVisible: false,
  setWarrantyOption: noop,
  setServiceOption: noop,
  requestProductFromStore: noop,
  storeSkuData: {},
  currentTab: '',
  selectedOperatorId: null,
  errorAddingToCart: false,
  handleM2QuantityChange: noop,
  m2Quantity: 1,
  m2ResultValue: 0,
  handleM2ResultChange: noop,
  m2Value: '',
  handleM2ValueChange: noop,
  isExtraM2Checked: true,
  handleExtraM2Change: noop,
  m2AttributeValue: -1,
  showLoginForm: false,
  sizeChartData: {},
  fomo: {},
};

ProductSpecificationsDesktop.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  isPumAvailable: PropTypes.bool,
  pumQuantity: PropTypes.number,
  primeShipping: PropTypes.bool,
  colorVariants: PropTypes.array,
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  sizes: PropTypes.array,
  selectedSize: PropTypes.string,
  sizeChangeHandler: PropTypes.func,
  handleAddToCart: PropTypes.func,
  isAddToCartInProgress: PropTypes.bool,
  handleRemoveFromCart: PropTypes.func,
  isRemoveFromCartInProgress: PropTypes.bool,
  operators: PropTypes.array,
  operatorChangeHandler: PropTypes.func,
  requestProductFromStore: PropTypes.func,
  addToCartButtonVisible: PropTypes.bool,
  showCollectionButtonVisible: PropTypes.bool,
  setWarrantyOption: PropTypes.func,
  setServiceOption: PropTypes.func,
  isVendorLogin: PropTypes.bool.isRequired,
  storeSkuData: PropTypes.object,
  currentTab: PropTypes.string,
  selectedOperatorId: PropTypes.string,
  errorAddingToCart: PropTypes.bool,
  handleM2QuantityChange: PropTypes.func,
  m2Quantity: PropTypes.number,
  m2ResultValue: PropTypes.number,
  handleM2ResultChange: PropTypes.func,
  m2Value: PropTypes.string,
  handleM2ValueChange: PropTypes.func,
  isExtraM2Checked: PropTypes.bool,
  handleExtraM2Change: PropTypes.func,
  m2AttributeValue: PropTypes.number,
  setShowLoginForm: PropTypes.func.isRequired,
  setErrorAddingToCart: PropTypes.func.isRequired,
  showLoginForm: PropTypes.bool,
  sizeChartData: PropTypes.object,
  fomo: PropTypes.object,
};

export default withCart(withProduct(withLabels(withApplicationContext(ProductSpecificationsDesktop))));
