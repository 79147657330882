import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';

const ProductsCarouselPodStyles = css`
  .products-carousel-pod-wrapper {
    padding: 16px 0 16px 0;
    @mixin phabletToDesktop {
      min-width: 20%;
    }
    @mixin mobileToTablet {
      max-width: 77%;
      padding: 16px 0;
    }
    &.sponsored-products-carousel-pod-wrapper {
      @mixin mobileToTablet {
        padding: 12px 24px;
        max-width: none;
      }
    }
  }
  .products-carousel-pod {
    @mixin tabletToDesktop {
      margin: 0 36px;
    }
  }
  .pod-anchor {
    text-decoration: none;
    cursor: pointer;
  }
  .products-carousel-pod-layout {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 300px;
    min-width: 135px;
    position: relative;
  }
  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    &:hover {
      opacity: 0.5;
    }
  }
  .image-wrapper {
    width: 135.53px;
    height: 136px;
  }
  .pod-details {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    :global(> span) {
      margin-bottom: 3px;
      letter-spacing: 0.1px;
    }
    .pod-rating {
      margin-top: 4px;
      display: flex;
    }
    .no-rating {
      visibility: hidden;
    }
  }
  .pod-product-brand {
    text-transform: uppercase;
    line-height: 1.4rem;
    font-weight: 700;
    font-size: 12px;
    color: ${colors.gray.shade44};
    letter-spacing: 1px;
    margin-bottom: 0.4rem;
  }
  .pod-product-description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0.8rem;
    line-height: 1.2;
    color: ${colors.gray.shade40};
    height: 34px;
    overflow: hidden;
    @mixin mobileToTablet {
      &.pod-sponsored-product-description {
        margin-bottom: 0.2rem;
      }
    }
  }
  .pod-product-price {
    margin-top: 6.797px;
    @mixin mobileToTablet {
      &.pod-sponsored-product-price {
        margin-top: 0.2rem;
      }
    }
  }
  .pod-button {
    width: 164px;
    margin: 0 21px 0 22px;
    @mixin mobileToTablet {
      margin: 12px 0 0 0;
    }

    &.sponsored-pod-button {
      @mixin mobileToTablet {
        width: 100%;
      }
    }
  }
  .sponsored-product-text {
    font-size: 12px;
    color: #767676;
    display: flex;
    justify-content: end;
    padding-right: 12px;
  }
  .patrocinado-pod.hidden {
    margin-right: 10px;
    display: none;
  }
`;

export { ProductsCarouselPodStyles };
