const decodeHtml = (encodedString) => {
  const translate = {
    nbsp: ' ',
    amp: '&',
    quot: '"',
    lt: '<',
    gt: '>',
  };

  return encodedString
    .replace(/&(nbsp|amp|quot|lt|gt);/g, (match, entity) => {
      return translate[entity];
    })
    .replace(/&#(\d+);/gi, (match, numStr) => {
      const num = parseInt(numStr, 10);
      return String.fromCharCode(num);
    });
};

export default decodeHtml;
