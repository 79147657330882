import React from 'react';
import MatchMedia from '../../ui/MatchMedia/MatchMedia';
import ImageGalleryCarouselDesktop from './ImageGalleryCarouselDesktop/ImageGalleryCarouselDesktop';
import ImageGalleryCarouselMobile from './ImageGalleryCarouselMobile/ImageGalleryCarouselMobile';

const ImageGalleryCarousel = (props) => {
  return (
    <MatchMedia
      content={{
        mobile: () => <ImageGalleryCarouselMobile {...props} />,
        tablet: () => <ImageGalleryCarouselMobile {...props} />,
        desktop: () => <ImageGalleryCarouselDesktop {...props} />,
      }}
    />
  );
};

export default ImageGalleryCarousel;
