import _ from './LodashImports';
import constants from '../config/constants';

const cartAlertsAndErrorSeggregator = (alerts, siteConfig, statusCode = 200) => {
  const errors = [];
  const limitAlerts = {};

  const statusCodeAllowedForErrors = _.get(
    siteConfig,
    `textDictionary.STATUS_CODE_ALLOWED_FOR_ERRORS`,
    [400, 401, 403, 404, 500, 502, 503]
  );

  alerts.forEach((alert) => {
    const alertCode = alert.alertCode.toUpperCase() || '';
    const { cartLineId = 'basket' } = alert;
    let error = _.get(siteConfig, `configurations.commerceApiErrors[${alertCode}]`, '');

    const limitAlert = _.get(siteConfig, `configurations.commerceLimitApiAlerts[${alertCode}]`, '');

    if (alertCode === 'AL022' && !statusCodeAllowedForErrors.includes(statusCode)) {
      error = undefined;
    }

    if (error) {
      errors.push({
        message: _.get(siteConfig, alertCode === 'AL013' ? undefined : `textDictionary.A2C_ERROR_${alertCode}`, error),
        code: alertCode,
      });
    }
    if (limitAlert) {
      limitAlerts[cartLineId] = {
        message: limitAlert,
        code: alertCode,
      };
    }
  });
  return {
    mappedErrors: errors.length > 0 ? errors : undefined,
    limitAlerts: Object.keys(limitAlerts).length > 0 ? limitAlerts : undefined,
  };
};

const getErrorMessage = (errors, siteConfig) => {
  const errorResponse = errors[0];
  const alertCode = _.get(errorResponse, 'code', '');
  const defaultMessage = _.get(
    siteConfig,
    `configurations.commerceApiErrors.CART_DEFAULT_MESSAGE`,
    constants.CART_DEFAULT_MESSAGE
  );

  return _.get(siteConfig, `configurations.commerceApiErrors[${alertCode}]`, defaultMessage);
};

const errorMessageHandler = (responseError, siteConfig) => {
  const errors = [];
  responseError.forEach((err) => {
    const alertCode = err.code;
    const mappedError = _.get(siteConfig, `configurations.commerceApiAlerts[${alertCode}]`, '');
    const defaultMessage = mappedError ? mappedError.message : err.message;
    errors.push({
      message: _.get(siteConfig, `textDictionary.API_ERROR_${alertCode}`, defaultMessage),
    });
  });
  return errors.length > 0 ? errors : undefined;
};

export { cartAlertsAndErrorSeggregator, getErrorMessage, errorMessageHandler };
