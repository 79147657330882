import css from 'styled-jsx/css';
import { colors, icons } from '../../../config/styles';
import _ from '../../../utils/LodashImports';
import { svgReplaceColor, svgToDataUri } from '../../../utils/styles/svgMixins';

const ComparativeTablePodStyles = (theme) => {
  const secondaryColor = _.get(theme, 'secondary_color', null) || colors.secondary_color;
  return css`
    .comparative-table-pod-wrapper {
      display: flex;
      flex-direction: column;
      position: relative;
      @mixin phabletToDesktop {
        min-width: 20%;
        max-width: 195px;
      }
    }
    .comparative-table-pod {
      margin: 0 12px;
      @mixin tabletToDesktop {
        margin: 0 36px;
      }
    }
    .pod-anchor {
      text-decoration: none;
      cursor: pointer;
    }
    .comparative-table-pod-layout {
      display: flex;
      flex: 1;
      flex-direction: column;
      min-height: 300px;
      min-width: 120px;
      position: relative;
    }
    .image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 6px;
      &:hover {
        opacity: 0.5;
      }
    }
    .image-wrapper {
      width: 100%;
      height: 115px;
    }
    .pod-details {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      :global(> span) {
        margin-bottom: 3px;
        letter-spacing: 0.1px;
      }
      .pod-rating {
        display: flex;
        width: 95px;
        height: 16px;
        color: ${colors.black.shade3};
        margin-bottom: 6px;
      }
      .no-rating {
        visibility: hidden;
      }
    }
    .pod-product-brand {
      text-transform: lowercase;
      line-height: 12px;
      font-weight: 400;
      font-size: 12px;
      color: ${colors.gray.shade58};
      font-style: normal;
      margin-bottom: 6px;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    .pod-product-description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 6px;
      line-height: 16px;
      color: ${colors.black.shade10};
      height: 33px;
      overflow: hidden;
    }
    .pod-product-price {
      margin-bottom: 6px;
    }
    .pod-button {
      margin: 0 12px 20px 12px;
      @mixin tabletToDesktop {
        margin: 0 7px 20px;
      }
      .button {
        background-color: white;
        border: 1.5px solid ${secondaryColor};
        border-radius: 26px;
        padding: 10px 0;
        display: block;
        color: ${secondaryColor};
        @mixin tabletToDesktop {
          padding: 14px 0;
          cursor: pointer;
        }
        &.so_com {
          border-radius: 4px;
        }
      }
    }
    .add-to-cart-text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 13.9342px;
      .mobile-text {
        display: flex;
      }
      @mixin tabletToDesktop {
        font-weight: 700;
        font-size: 17px;
        line-height: 17px;
      }
      .cart-icon {
        margin-left: 4px;
        width: 13.39px;
        height: 12.39px;
        background-image: url('${svgToDataUri(svgReplaceColor(icons.cart, colors.secondary_color))}');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        align-self: center;
      }
    }
    .comparative-texts {
      margin: 6px 0;

      .head-section {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: ${colors.gray.shade56};
        min-height: 14px;
        position: relative;
        @mixin tabletToDesktop {
          color: ${colors.gray.shade58};
          font-weight: 700;
          font-size: 14px;
          line-height: 16px;
        }
      }
      .title {
        position: absolute;
        background: ${colors.white.shade1};
        top: -5px;
        left: 0;
        display: inline-block;
        padding-right: 9px;
        @mixin tabletToDesktop {
          top: 30px;
          left: -114px;
          width: 112px;
        }
      }

      .separator {
        width: 100%;
        height: 0;
        border-bottom: 1px solid ${colors.gray.shade57};
        font-size: 12px;
        line-height: 14px;
        &.first {
          @mixin tabletToDesktop {
            position: absolute;
            left: -132px;
            width: 800px;
          }
        }
      }
      .text {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: ${colors.gray.shade58};
        padding: 12px;
        @mixin tabletToDesktop {
          font-size: 17px;
          line-height: 20px;
        }
      }
    }
  `;
};

export { ComparativeTablePodStyles };
