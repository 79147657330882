import React, { Fragment } from 'react';
import MatchMedia from '../../ui/MatchMedia/MatchMedia';
import ImageGalleryHeadlineMobile from './ImageGalleryHeadlineMobile/ImageGalleryHeadlineMobile';
import ImageGalleryHeadlineDesktop from './ImageGalleryHeadlineDesktop/ImageGalleryHeadlineDesktop';

const ImageGalleryHeadLine = (props) => {
  return (
    <MatchMedia
      content={{
        mobile: () => <ImageGalleryHeadlineMobile {...props} />,
        tablet: () => <ImageGalleryHeadlineMobile {...props} />,
        desktop: () => <ImageGalleryHeadlineDesktop {...props} />,
      }}
    />
  );
};

export default ImageGalleryHeadLine;
