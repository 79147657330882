import PropTypes from 'prop-types';
import React from 'react';
import _ from '../../../utils/LodashImports';
import { withProduct } from '../../../utils/ProductContext';
import { getBackgroundCSS } from '../../../utils/variant/getBackgroundCSS';
import { ThumbnailColor } from '../Thumbnail/ThumbnailColor';
import ThumbnailImage from '../Thumbnail/ThumbnailImage';
import colorSwatchGlobalStyles from './ColorSwatch.style';

const ColorSwatch = (props) => {
  const {
    colorVariants = [],
    handleColorSwatch,
    selectedColorIndex,
    productDisplayLink,
    maximum,
    productId,
    size,
    page,
    layout,
    appCtx,
    colorMetrics,
    product,
  } = props;

  const colorSwatchMarkup = colorVariants.length > maximum ? colorVariants.slice(0, maximum) : colorVariants;

  if (!colorSwatchMarkup.length) {
    return null;
  }

  const isThumbnailImageEnable = _.get(appCtx, 'siteConfig.toggles.isThumbnailImageEnable', true);
  const isCFMediasEnabled = _.get(appCtx, 'siteConfig.toggles.isCFMediasEnabled', false);

  const sellerName = _.get(product, 'variants[0].offerings[0].sellerName', '');

  const regex = /(defaultImage|NoImage|espanol_default|portugues_default)/;

  return (
    <ul
      className={`swatch--container
    ${size === 'small' && page !== 'PDP' ? 'fixed-height' : ''}
    swatch--container-${page} swatch--container-${layout}
    fa--color-swatch`}
    >
      {colorSwatchMarkup.map((options, index) => {
        if (isThumbnailImageEnable) {
          return (
            <div key={`thumbnail-${options.extraInfo}`}>
              {options.imageUrl && !regex.test(options.imageUrl) ? (
                <ThumbnailImage
                  options={options}
                  index={index}
                  selectedImageIndex={selectedColorIndex}
                  handleColorSwatch={handleColorSwatch}
                  productId={productId}
                  colorMetrics={colorMetrics}
                />
              ) : (
                <ThumbnailColor
                  options={options}
                  index={index}
                  selectedImageIndex={selectedColorIndex}
                  handleColorSwatch={handleColorSwatch}
                  productId={productId}
                  getBackgroundCSS={getBackgroundCSS}
                  colorMetrics={colorMetrics}
                  isCFMediasEnabled={isCFMediasEnabled}
                  sellerName={sellerName}
                  regionCode={appCtx.regionCode}
                />
              )}
            </div>
          );
        }
        return (
          <li
            className={index === selectedColorIndex ? `colorSwatch-li-selected-${size}` : `colorSwatch-li-${size}`}
            key={`swatch-${options.extraInfo}`}
          >
            <button
              className={`colorSwatch-${size}`}
              type="button"
              id={`testId-pod-colorSwatch-${productId}`}
              style={getBackgroundCSS({
                value: options.value,
                sellerName,
                regionCode: appCtx.regionCode,
                isCfMediasEnabled: isCFMediasEnabled,
              })}
              onClick={() =>
                handleColorSwatch(
                  {
                    extraInfo: options.extraInfo,
                    mediaId: options.mediaId,
                    colorName: options.value,
                    url: options.url,
                    selected: options.selected,
                    label: options.label,
                    mediaUrls: options.mediaUrls || [],
                  },
                  index
                )
              }
            />
          </li>
        );
      })}
      {colorVariants.length > maximum && (
        <li>
          <a href={productDisplayLink} className="colorSwatch-link">
            <span className="csicon-add" />
          </a>
        </li>
      )}
      <style jsx>{colorSwatchGlobalStyles}</style>
    </ul>
  );
};

ColorSwatch.defaultProps = {
  maximum: Infinity,
  productDisplayLink: '',
  size: 'small',
  page: 'POD',
  layout: ' 4_GRID',
  product: {},
};

ColorSwatch.propTypes = {
  maximum: PropTypes.number,
  productDisplayLink: PropTypes.string,
  productId: PropTypes.string.isRequired,
  colorVariants: PropTypes.array.isRequired,
  handleColorSwatch: PropTypes.func.isRequired,
  selectedColorIndex: PropTypes.number.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  page: PropTypes.oneOf(['PDP', 'POD']),
  layout: PropTypes.string,
  appCtx: PropTypes.object.isRequired,
  colorMetrics: PropTypes.object.isRequired,
  product: PropTypes.object,
};

export default withProduct(ColorSwatch);
