import css from 'styled-jsx/css';
import colors from '../../../../config/styles/colors';
import { svgToDataUri } from '../../../../utils/styles/svgMixins';
import { videoIcon } from '../../ImageGallery.style';
import _ from '../../../../utils/LodashImports';

const ImageGalleryCarouselStyles = (theme) => {
  const themeColor = _.get(theme, 'color', null) || colors.secondary_color;

  return css`
    .image-carousel {
      flex: 1;
    }

    .carousel {
      display: flex;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      max-width: 485px;

      .carousel-wrapper {
        margin: 0 8px 0 8px;
        outline: none;
        height: 45px;
        width: 45px;
        position: relative;

        &.active::before {
          content: '';
          position: absolute;
          height: 3px;
          left: 0;
          right: 0;
          background-color: ${colors.green.shade11};
          bottom: 0;
        }

        &.video {
          background: url('${svgToDataUri(videoIcon)}');
          background-size: 38px 38px;
          background-clip: border-box;
          background-repeat: no-repeat;
          background-position: 50% 48%;
        }

        .image-carousel {
          cursor: pointer;
          height: 45px;
          width: 45px;
        }
      }

      .carousel-wrapper :global(img) {
        height: 100%;
        width: auto;
      }
    }

    .image-carousel.rebranded {
      .carousel {
        .carousel-wrapper.active::before {
          background-color: ${themeColor};
          z-index: 1;
        }
      }
    }

    .indicator {
      display: flex;
      justify-content: center;
      height: 8px;
      align-items: center;
      margin-top: 20px;

      .dot {
        height: 6px;
        width: 6px;
        outline: none;
        border-radius: 50%;
        margin: 0 7px;
        background-color: ${colors.gray.shade33};
        cursor: pointer;
        transition: all 0.2s;

        &-active {
          height: 6px;
          width: 6px;
          background-color: ${colors.green.shade11};
          &.rebranded {
            width: 8px;
            height: 8px;
            background-color: ${colors.gray.shade62};
          }
        }
      }
    }
  `;
};

export { ImageGalleryCarouselStyles };
