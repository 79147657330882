import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import getImages from '../../../../utils/GetImages';
import _ from '../../../../utils/LodashImports';
import noop from '../../../../utils/noop';
import { withProduct } from '../../../../utils/ProductContext';
import { imageUrl as imageURLMaker } from '../../../../utils/staticUrls';
import Image from '../../../ui/Image/Image';
import { IMAGE_GALLERY, getCFDefaultImage, getMediaData, isVideo } from '../../ImageGalleryHelper';
import ImageGalleryControlButtons from '../ImageGalleryControlButtons/ImageGalleryControlButtons';
import ImageGalleryVideo from '../ImageGalleryVideo/ImageGalleryVideo';
import { ImageGalleryHeadlineDesktopStyles, PreloadImageStyles } from './ImageGalleryHeadlineDesktop.style';

const ImageGalleryHeadlineDesktop = ({
  zoomSize,
  activeImageIndex,
  images,
  imagesPerSlide,
  activeIndicatorIndex,
  setActiveIndicatorIndex,
  setActiveImageIndex,
  imageQuality,
  headlineImageHeight,
  headlineImageWidth,
  showControlButtons,
  crop,
  setVideoPlayingForSoftline,
  isConnect,
  productName,
  brandName,
  product,
  appCtx,
}) => {
  const [zoomLevel, setZoomLevel] = useState();
  const [isVideoPlaying, setVideoPlaying] = useState();

  const headlineRef = useRef();

  const isCFMediasEnabled = _.get(appCtx, 'siteConfig.toggles.isCFMediasEnabled', false);
  const mediaDomains = _.get(appCtx, 'siteConfig.configurations.mediaDomains', []);
  const fallBackImagesDomain = _.get(appCtx, 'siteConfig.textDictionary.FALLBACK_IMAGES_DOMAIN', '');
  const regionCode = _.get(appCtx, 'regionCode');
  const sellerName = _.get(product, 'variants[0].offerings[0].sellerName', '').toLowerCase();
  const onErrorFallback = ({ currentTarget }) => {
    /* eslint-disable no-param-reassign */
    currentTarget.onerror = null;
    currentTarget.src = isCFMediasEnabled
      ? getCFDefaultImage({ sellerName, regionCode, fallBackImagesDomain })
      : imageURLMaker('defaultImage.jpg');
  };

  const handleMouseLeave = () => {
    setZoomLevel();
  };

  const onNextHeadline = () => {
    setVideoPlaying(false);
    setVideoPlayingForSoftline(false);
    let newActiveIndicatorIndex = activeIndicatorIndex;
    let newActiveImageIndex = activeImageIndex + 1;

    const isLastImage = activeImageIndex + 1 === images.length;
    const isLastImageOfSlide = (activeImageIndex + 1) % imagesPerSlide === 0;

    if (isLastImage) {
      newActiveIndicatorIndex = 0;
      newActiveImageIndex = 0;
    } else if (!isLastImage && isLastImageOfSlide) {
      newActiveIndicatorIndex = activeIndicatorIndex + 1;
    }

    setActiveIndicatorIndex(newActiveIndicatorIndex);
    setActiveImageIndex(newActiveImageIndex);
  };

  const onPreviousHeadLine = () => {
    setVideoPlaying(false);
    setVideoPlayingForSoftline(false);
    let newActiveIndicatorIndex = activeIndicatorIndex;
    let newActiveImageIndex = activeImageIndex - 1;

    const isFirstImage = activeImageIndex === 0;
    const isFirstImageOfSlide = activeImageIndex % imagesPerSlide === 0;

    if (isFirstImage) {
      newActiveImageIndex = images.length - 1;
      newActiveIndicatorIndex = Math.ceil(images.length / imagesPerSlide) - 1;
    } else if (!isFirstImage && isFirstImageOfSlide) {
      newActiveIndicatorIndex = activeIndicatorIndex - 1;
    }

    setActiveIndicatorIndex(newActiveIndicatorIndex);
    setActiveImageIndex(newActiveImageIndex);
  };

  const handleMouseOver = (e) => {
    const { pageX, pageY } = e;
    const {
      width: clientWidth,
      height: clientHeight,
      left: offsetLeft, // x is not supported in IE
      top: offsetTop, // y is not supported in IE
    } = headlineRef.current.getBoundingClientRect();
    const { scrollTop } = document.documentElement; // As window.screenY is not supported in IE
    if (!zoomSize.x) {
      return;
    }
    const zoomRatio = {
      x: (clientWidth - zoomSize.x) * ((pageX - offsetLeft) / clientWidth),
      y: (clientHeight - zoomSize.y) * ((pageY - scrollTop - offsetTop) / clientHeight),
    };

    setZoomLevel(zoomRatio);
  };

  const handleMouseOverButton = (e) => {
    e.stopPropagation();
    setZoomLevel();
  };

  const preloadImages = (imageCrop) => {
    return (
      <>
        {images.map((img, index) => {
          const { mediaId, mediaUrl } = getMediaData(img);
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className="hidden" key={`${mediaId}-desktop-${index}`}>
              <Image
                id={mediaId}
                imageUrl={mediaUrl}
                type="headline"
                width={headlineImageWidth}
                height={headlineImageHeight}
                alt={`${brandName} - ${productName}`}
                useSrcSet={false}
                crop={imageCrop}
                inlineDimensions={false}
              />
            </div>
          );
        })}
        <style jsx>{PreloadImageStyles}</style>
      </>
    );
  };

  const activeImage = images[activeImageIndex] || IMAGE_GALLERY.DEFAULT_IMAGE;
  const { mediaId: activeImageId, mediaUrl: activeImageUrl } = getMediaData(activeImage);
  const isScene7Default = activeImageId === IMAGE_GALLERY.DEFAULT_IMAGE;
  const variantId = activeImageId.includes('/') ? activeImageId.split('/')[1] : activeImageId;
  const activeImgZoomBaseUrl = getImages({
    variantId,
    type: 'headline',
    attr: {
      width: headlineImageWidth,
      height: headlineImageHeight,
      quality: imageQuality,
    },
    isCFMediasEnabled,
    sellerName,
    regionCode,
    url: activeImageUrl,
    mediaDomains,
    fallBackImagesDomain,
  });

  const imageCrop = isScene7Default ? {} : crop;
  const activeImgZoomBaseSrc = getImages({
    variantId,
    type: 'headline',
    attr: {
      width: zoomSize.x,
      height: zoomSize.y,
      quality: imageQuality,
      crop: imageCrop,
    },
    isCFMediasEnabled,
    sellerName,
    regionCode,
    url: activeImageUrl,
    mediaDomains,
    fallBackImagesDomain,
  });

  return (
    <div
      className="headline-wrapper fa--image-gallery-item__desktop"
      onMouseMove={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={noop}
      ref={(r) => {
        headlineRef.current = r;
      }}
    >
      {preloadImages(imageCrop)}
      {showControlButtons && (
        <ImageGalleryControlButtons
          variant={isVideoPlaying ? 'opaque' : 'transparent'}
          onNextHeadline={onNextHeadline}
          onPreviousHeadLine={onPreviousHeadLine}
          onMoveOver={handleMouseOverButton}
          isConnect={isConnect}
        />
      )}

      {isVideo(activeImage) ? (
        <ImageGalleryVideo
          url={activeImageUrl}
          setVideoPlaying={showControlButtons ? setVideoPlaying : setVideoPlayingForSoftline}
        />
      ) : (
        <Image
          id={activeImageId}
          imageUrl={activeImageUrl}
          type="headline"
          width={headlineImageWidth}
          height={headlineImageHeight}
          alt={`${brandName} - ${productName}`}
          useSrcSet={false}
          crop={imageCrop}
          inlineDimensions={false}
        />
      )}

      {zoomLevel && !isVideo(activeImage) && (
        <div className="image-headline-wrapper-zoom">
          <img
            style={{
              transform: `translate(${zoomLevel.x}px, ${zoomLevel.y}px)`,
              width: `${zoomSize.x}px`,
              height: `${zoomSize.y}px`,
              backgroundImage: `url(${activeImgZoomBaseUrl})`,
              backgroundSize: 'cover',
            }}
            className="image-headline zoom"
            src={activeImgZoomBaseSrc}
            onError={onErrorFallback}
            alt=""
          />
        </div>
      )}
      <style jsx>{ImageGalleryHeadlineDesktopStyles}</style>
    </div>
  );
};

ImageGalleryHeadlineDesktop.defaultProps = {
  showControlButtons: true,
  crop: undefined,
  setVideoPlayingForSoftline: noop,
  isConnect: false,
  images: [],
};

ImageGalleryHeadlineDesktop.propTypes = {
  zoomSize: PropTypes.object.isRequired,
  activeImageIndex: PropTypes.number.isRequired,
  images: PropTypes.array,
  imagesPerSlide: PropTypes.number.isRequired,
  activeIndicatorIndex: PropTypes.number.isRequired,
  setActiveIndicatorIndex: PropTypes.func.isRequired,
  setActiveImageIndex: PropTypes.func.isRequired,
  imageQuality: PropTypes.number.isRequired,
  headlineImageHeight: PropTypes.number.isRequired,
  headlineImageWidth: PropTypes.number.isRequired,
  showControlButtons: PropTypes.bool,
  crop: PropTypes.object,
  setVideoPlayingForSoftline: PropTypes.func,
  isConnect: PropTypes.bool,
  productName: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
};

export default withApplicationContext(withProduct(ImageGalleryHeadlineDesktop));
export { ImageGalleryHeadlineDesktop };
