import { isSodimacStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withDigitalData } from '../../utils/DigitalDataContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { withProduct } from '../../utils/ProductContext';
import { getVariant } from '../../utils/variant';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import ScrollTo from '../ui/ScrollTo/ScrollTo';
import { ImageGalleryGlobalStyles } from './ImageGallery.style';
import ImageGalleryCarousel from './ImageGalleryCarousel/ImageGalleryCarousel';
import ImageGalleryControlButtons from './ImageGalleryHeadLine/ImageGalleryControlButtons/ImageGalleryControlButtons';
import ImageGalleryHeadlineDesktop from './ImageGalleryHeadLine/ImageGalleryHeadlineDesktop/ImageGalleryHeadlineDesktop';
import { fetchImages, filterForSoftline, guessFirstImage } from './ImageGalleryHelper';
import { ImageGallerySoftlineStyles } from './ImageGallerySoftline.style';

const ImageGallerySoftline = ({
  product,
  carouselImageHeight,
  carouselImageWidth,
  desktopImagesPerSlide,
  zoomSize,
  imageQuality,
  headlineImageHeight,
  headlineImageWidth,
  imagesPerSlide,
  appCtx,
  labels,
  crop,
  digitalData,
}) => {
  const { isOutOfStock, variants, attributes, plusSizes = [], brandName, name } = product;
  const { sameImage } = attributes;

  const isDottedImageCarouselEnabled = _.get(appCtx, 'siteConfig.toggles.isDottedImageCarouselEnabled', false);

  const variantId = !sameImage && product.currentVariant ? product.currentVariant : product.id;

  const { regionCode, store } = appCtx;
  const isSoCom = isSodimacStandalone(store);
  const isCFMediasEnabled = _.get(appCtx, 'siteConfig.toggles.isCFMediasEnabled', false);
  const sellerName = _.get(product, 'variants[0].offerings[0].sellerName', '').toLowerCase();
  const fallBackImagesDomain = _.get(appCtx, 'siteConfig.textDictionary.FALLBACK_IMAGES_DOMAIN');
  const [selectedColor, setSelectedColor] = useState();
  const [selectedVariant, setSelectedVariant] = useState(variantId);

  const getVariantDetails = () => {
    const currentVariantData = getVariant(variants, variantId);
    const size = _.get(currentVariantData, 'attributes.size', '').toUpperCase();
    const color = _.get(currentVariantData, 'attributes.colorName');
    return { size, color, id: currentVariantData.id };
  };

  const [isPrevPlusSize, setIsPrevPlusSize] = useState(plusSizes.includes(getVariantDetails().size));

  const shouldChangeImage = (size) => {
    const isCurrentPlusSize = plusSizes.includes(size);
    return isPrevPlusSize !== isCurrentPlusSize;
  };

  useEffect(() => {
    if (!sameImage) {
      const { color, id, size } = getVariantDetails();
      if (selectedColor !== color || shouldChangeImage(size)) {
        setIsPrevPlusSize(plusSizes.includes(size));
        setSelectedColor(color);
        setSelectedVariant(id);
      }
    }
  }, [variantId]);
  const firstImagesGuess = guessFirstImage(product, variantId, sameImage, 'softline');
  const [images, setImages] = useState(firstImagesGuess);
  const [leftActiveImageIndex, setLeftActiveImageIndex] = useState(0);
  const [rightActiveImageIndex, setRightActiveImageIndex] = useState(1);

  const [activeIndicatorIndex, setActiveIndicatorIndex] = useState(0);

  const [carouselImages, setCarouselImages] = useState([]);
  const [isVideoPlaying, setVideoPlaying] = useState();

  useEffect(() => {
    const variantObj = product.variants.find((variant) => variant.id === product.currentVariant);

    if (!sameImage && variantObj && variantObj.medias && filterForSoftline(variantObj.medias).length > 0) {
      setImages(filterForSoftline(variantObj.medias));
      return;
    }
    if (sameImage && product.medias && filterForSoftline(product.medias).length > 0) {
      setImages(filterForSoftline(product.medias));
      return;
    }
    const media = _.get(variantObj, 'medias') || _.get(product, 'medias');
    fetchImages({
      variantId: selectedVariant,
      regionCode,
      sellerName,
      isCFMediasEnabled,
      media,
      fallBackImagesDomain,
    })
      .then((img) => {
        if (img) {
          setImages([...img]);
        } else {
          setImages(firstImagesGuess);
        }
      })
      .catch(() => {
        setImages(firstImagesGuess);
      });
  }, [selectedVariant]);

  useEffect(() => {
    setCarouselImages(
      images.slice(activeIndicatorIndex * desktopImagesPerSlide, (1 + activeIndicatorIndex) * desktopImagesPerSlide)
    );
  }, [activeIndicatorIndex, images]);

  useEffect(() => {
    if (appCtx.deviceType === 'desktop') {
      setLeftActiveImageIndex(0);
    } else {
      setLeftActiveImageIndex(isOutOfStock ? 0 : 1);
    }
  }, [images]);

  useEffect(() => {
    let newActiveRightImage = leftActiveImageIndex + 1;
    if (leftActiveImageIndex + 1 === images.length) {
      newActiveRightImage = 0;
    }
    setRightActiveImageIndex(newActiveRightImage);
  }, [leftActiveImageIndex]);

  const getImages = () => {
    if (isOutOfStock) {
      return appCtx.deviceType === 'desktop' ? [images[0], images[1]] : [images[0]];
    }
    return appCtx.deviceType === 'desktop' ? images : [images[images.length - 1], ...images, images[0]];
  };

  const onNextHeadline = () => {
    let newActiveIndicatorIndex = activeIndicatorIndex;
    let newActiveImageIndex = leftActiveImageIndex + 1;

    const isLastImage = leftActiveImageIndex + 1 === images.length;
    const isLastImageOfSlide = (leftActiveImageIndex + 1) % imagesPerSlide === 0;

    if (!isLastImage && isLastImageOfSlide) {
      newActiveIndicatorIndex = activeIndicatorIndex + 1;
    }

    if (isLastImage) {
      newActiveIndicatorIndex = 0;
      newActiveImageIndex = 0;
    }

    setActiveIndicatorIndex(newActiveIndicatorIndex);
    setLeftActiveImageIndex(newActiveImageIndex);
  };

  const onPreviousHeadLine = () => {
    let newActiveIndicatorIndex = activeIndicatorIndex;
    let newActiveImageIndex = leftActiveImageIndex - 1;

    const isFirstImage = leftActiveImageIndex === 0;
    const isFirstImageOfSlide = leftActiveImageIndex % imagesPerSlide === 0;

    if (isFirstImage) {
      newActiveImageIndex = images.length - 1;
      newActiveIndicatorIndex = Math.ceil(images.length / imagesPerSlide) - 1;
    } else if (!isFirstImage && isFirstImageOfSlide) {
      newActiveIndicatorIndex = activeIndicatorIndex - 1;
    }

    setActiveIndicatorIndex(newActiveIndicatorIndex);
    setLeftActiveImageIndex(newActiveImageIndex);
  };

  const handleMouseOverButton = (e) => {
    e.stopPropagation();
  };

  const getModelsBodyCharacteristics = () => {
    const specs = _.get(product, 'attributes.specifications', []);
    const modelo = specs.find((spec) => constants.MODEL_CHARACTERISTICS_SPECIFICATION_ID.includes(spec.name));
    return modelo && `${labels.MODEL_CHARACTERISTICS_PREFIX} ${modelo.value}`;
  };

  const modelCharacteristics = getModelsBodyCharacteristics();

  digitalData.pdp.setProductFeatures(modelCharacteristics ? '1' : '');
  digitalData.pdp.setProductBigImagesQty(
    // eslint-disable-next-line no-nested-ternary
    images.length > 1 ? 2 : images.length > 0 ? 1 : 0
  );
  digitalData.pdp.setProductSmallImagesQty(images.length);

  if (isOutOfStock) {
    return (
      <div className="imageGallery">
        <div className="imageHeadlineContainer out-of-stock">
          <div className="left-image">
            <ImageGalleryHeadlineDesktop
              imageQuality={imageQuality}
              zoomSize={!isOutOfStock ? zoomSize : { x: 0, y: 0 }}
              activeImageIndex={leftActiveImageIndex}
              images={getImages()}
              imagesPerSlide={desktopImagesPerSlide}
              activeIndicatorIndex={activeIndicatorIndex}
              setActiveIndicatorIndex={setActiveIndicatorIndex}
              setActiveImageIndex={setLeftActiveImageIndex}
              headlineImageHeight={headlineImageHeight}
              headlineImageWidth={headlineImageWidth}
              tenant={regionCode}
              showControlButtons={false}
              crop={crop}
              setVideoPlayingForSoftline={setVideoPlaying}
              productName={name}
              brandName={brandName}
            />
          </div>
          <div className="right-image">
            <ImageGalleryHeadlineDesktop
              imageQuality={imageQuality}
              zoomSize={!isOutOfStock ? zoomSize : { x: 0, y: 0 }}
              activeImageIndex={rightActiveImageIndex}
              images={getImages()}
              imagesPerSlide={desktopImagesPerSlide}
              activeIndicatorIndex={activeIndicatorIndex}
              setActiveIndicatorIndex={setActiveIndicatorIndex}
              setActiveImageIndex={setLeftActiveImageIndex}
              headlineImageHeight={headlineImageHeight}
              headlineImageWidth={headlineImageWidth}
              tenant={regionCode}
              showControlButtons={false}
              crop={crop}
              setVideoPlayingForSoftline={setVideoPlaying}
              productName={name}
              brandName={brandName}
            />
          </div>
        </div>
        <style jsx>{ImageGalleryGlobalStyles}</style>
        <style jsx>{ImageGallerySoftlineStyles}</style>
      </div>
    );
  }

  return (
    <div className="imageGallery">
      <div className="imageHeadlineContainer">
        {!isOutOfStock && (
          <ImageGalleryControlButtons
            onNextHeadline={onNextHeadline}
            onPreviousHeadLine={onPreviousHeadLine}
            onMoveOver={handleMouseOverButton}
            variant={isVideoPlaying ? 'opaque' : 'transparent'}
          />
        )}
        <div className="left-image">
          <ImageGalleryHeadlineDesktop
            imageQuality={imageQuality}
            zoomSize={!isOutOfStock ? zoomSize : { x: 0, y: 0 }}
            activeImageIndex={leftActiveImageIndex}
            images={getImages()}
            imagesPerSlide={desktopImagesPerSlide}
            activeIndicatorIndex={activeIndicatorIndex}
            setActiveIndicatorIndex={setActiveIndicatorIndex}
            setActiveImageIndex={setLeftActiveImageIndex}
            headlineImageHeight={headlineImageHeight}
            headlineImageWidth={headlineImageWidth}
            tenant={regionCode}
            showControlButtons={false}
            crop={crop}
            setVideoPlayingForSoftline={setVideoPlaying}
            productName={name}
            brandName={brandName}
          />
        </div>
        <div className="right-image">
          <ImageGalleryHeadlineDesktop
            imageQuality={imageQuality}
            zoomSize={!isOutOfStock ? zoomSize : { x: 0, y: 0 }}
            activeImageIndex={rightActiveImageIndex}
            images={getImages()}
            imagesPerSlide={desktopImagesPerSlide}
            activeIndicatorIndex={activeIndicatorIndex}
            setActiveIndicatorIndex={setActiveIndicatorIndex}
            setActiveImageIndex={setLeftActiveImageIndex}
            headlineImageHeight={headlineImageHeight}
            headlineImageWidth={headlineImageWidth}
            tenant={regionCode}
            showControlButtons={false}
            crop={crop}
            setVideoPlayingForSoftline={setVideoPlaying}
            productName={name}
            brandName={brandName}
          />
        </div>
      </div>
      <div className="control-section">
        {modelCharacteristics && (
          <div className="additional-info rebranded">
            <BodyCopy isHighlighted size="copy3">
              {labels.MODEL_CHARACTERISTICS_PREFIX_MOBILE}
            </BodyCopy>
            <p className="info-label">{modelCharacteristics}</p>
            <ScrollTo scrollToElementId="productInfoContainer" type="softline" isSoCom={isSoCom} />
          </div>
        )}
        {!isOutOfStock && (
          <ImageGalleryCarousel
            imageQuality={imageQuality}
            carouselImageHeight={carouselImageHeight}
            carouselImageWidth={carouselImageWidth}
            activeImageIndex={leftActiveImageIndex}
            activeIndicatorIndex={activeIndicatorIndex}
            setActiveImageIndex={setLeftActiveImageIndex}
            setActiveIndicatorIndex={setActiveIndicatorIndex}
            images={getImages()}
            imagesPerSlide={imagesPerSlide}
            carouselImages={carouselImages}
            headlineImageHeight={headlineImageHeight}
            headlineImageWidth={headlineImageWidth}
            tenant={regionCode}
            productName={name}
            brandName={brandName}
            isDottedImageCarouselEnabled={isDottedImageCarouselEnabled}
          />
        )}
      </div>
      <style jsx>{ImageGalleryGlobalStyles}</style>
      <style jsx>{ImageGallerySoftlineStyles}</style>
    </div>
  );
};

ImageGallerySoftline.defaultProps = {
  carouselImageHeight: 100,
  carouselImageWidth: 100,
  headlineImageHeight: 1500,
  headlineImageWidth: 1004,
  desktopImagesPerSlide: 5,
  zoomSize: { x: 1004, y: 1500 },
  imageQuality: 70,
  imagesPerSlide: 5,
  crop: { x: 248, y: 0 },
};

ImageGallerySoftline.propTypes = {
  product: PropTypes.object.isRequired,
  carouselImageHeight: PropTypes.number,
  carouselImageWidth: PropTypes.number,
  desktopImagesPerSlide: PropTypes.number,
  zoomSize: PropTypes.object,
  imageQuality: PropTypes.number,
  headlineImageWidth: PropTypes.number,
  headlineImageHeight: PropTypes.number,
  imagesPerSlide: PropTypes.number,
  appCtx: PropTypes.object.isRequired,
  crop: PropTypes.object,
  labels: PropTypes.object.isRequired,
  digitalData: PropTypes.object.isRequired,
};

export default withLabels(withProduct(withApplicationContext(withDigitalData(ImageGallerySoftline))));
export { ImageGallerySoftline };
