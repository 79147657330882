import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import css from 'styled-jsx/css';
import AperturaButton from './AperturaButton/AperturaButton';
import CardStatus from './CardStatus/CardStatus';
import { withLabels } from '../../utils/LabelsContext';
import CookieStorage from '../../utils/CookieStorage';

const CMRAperturaStyles = css`
  .softline-cmr-wrapper {
    margin-top: 13px;
  }
`;
const CMRApertura = ({ prices, labels, isSoftline, cmrSavings }) => {
  const [showApertura, setApertura] = useState(false);
  const [symbol, setSymbol] = useState('');
  const [aperturaStatus, setAperturaStatus] = useState('0');
  const { CMRONLINESUCCESS, CMRONLINESUCCESSNOTENROLLED, CMRONLINEDECLINED } = labels;

  useEffect(() => {
    if (prices.length > 0) {
      setSymbol(prices[0].symbol);
      setApertura(true);
      const [, status] = (CookieStorage.getCookie('CMR_APERTURA_COOKIE') || '1*1').split('*');
      setAperturaStatus(status);
    }
  }, []);
  const ApeturaStatus = {
    0: '',
    1: <AperturaButton savings={cmrSavings} symbol={symbol} setAperturaStatus={setAperturaStatus} />,
    2: <CardStatus status="success" message={CMRONLINESUCCESS} />,
    3: <CardStatus status="warning" message={CMRONLINESUCCESSNOTENROLLED} />,
    4: <CardStatus status="warning" message={CMRONLINEDECLINED} />,
  };

  return (
    <>
      <div className={isSoftline ? 'softline-cmr-wrapper' : ''}>{showApertura && ApeturaStatus[aperturaStatus]}</div>
      <style jsx>{CMRAperturaStyles}</style>
    </>
  );
};

CMRApertura.propTypes = {
  prices: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
  isSoftline: PropTypes.bool,
  cmrSavings: PropTypes.string,
};

CMRApertura.defaultProps = {
  isSoftline: false,
  cmrSavings: '0',
};
export default withLabels(CMRApertura);
