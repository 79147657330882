import css from 'styled-jsx/css';
import { colors, icons, typography } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const ProductPricesStyles = css`
  .cmr-points {
    font-size: ${typography.xs2};
    margin-top: 11px;
    color: ${colors.black.shade7};
  }
  .mt-4px {
    margin-top: 4px;
  }
  .mt-8px {
    margin-top: 8px;
  }
  .mt-9px {
    margin-top: 9px;
  }
  .mt-18px {
    margin-top: 18px;
  }

  .boomIcon {
    background-image: url('${svgToDataUri(icons.boomIcon)}');
    background-repeat: no-repeat;
    width: 60px;
    height: 13px;
    margin: 10px 0;
    display: block;
  }
  .phoneIcon {
    background-image: url('${svgToDataUri(icons.phoneIcon)}');
    height: 16px;
    width: 16px;
    margin-right: 4px;
  }
  .priceAppPromotion {
    display: flex;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .priceAppPromotionLabel {
    color: ${colors.gray.shade40};
    font-size: 12px;
    font-weight: 400;
    margin-top: 1px;
    line-height: 14px;
    text-decoration-line: underline;
  }
  .new-cmr-points {
    margin-top: 12px;
    height: 48px;
    background: #f9f9f9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .more-link-container {
      display: flex;
      justify-content: space-between;
      width: 84px;
      align-items: center;
      @mixin mobileToTablet {
        width: 94px;
      }
      .more-options {
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        text-align: right;
        text-decoration-line: underline;
        text-underline-offset: 2px;
        font-family: Lato, sans-serif;
        color: #333;
        min-width: 63px;
        max-height: 13px;
        @mixin mobileToTablet {
          min-width: 68px;
          max-height: 14px;
          font-size: 12px;
          line-height: 14.4px;
        }
      }
      .cmr-more-icon {
        width: 20px;
        height: 20px;
        align-items: center;
        @mixin mobileToTablet {
          width: 32.5px;
          height: 32.5px;
        }
      }
    }

    .cmr-icon {
      margin: 8px;
    }
    .cmr-text-icon {
      height: 11.46px;
      width: 24px;
    }
  }
`;

export { ProductPricesStyles };
