import getConfig from 'next/config';
import apiConfig from '../config/api/apiConfig';

import { formatSearchTerm, getStore, makeRequest } from '../utils/SponsoredHelper';
import WebStorage from '../utils/WebStorage';

const nextConfig = getConfig();
const publicRuntimeConfig = (nextConfig && nextConfig.publicRuntimeConfig) || {};

const sponsoredUrlBase = publicRuntimeConfig.SPONSORED_ENDPOINT;

const getMabayaSessionId = () => {
  const sessionId = WebStorage.getItem({
    name: 'mabayaSession',
    fromPersistence: true,
  });

  if (sessionId) {
    return sessionId;
  }

  const newValue = window.btoa(Date.now());
  WebStorage.setItem({
    name: 'mabayaSession',
    value: newValue,
    shouldPersist: true,
  });

  return newValue;
};

const sponsoredClickServiceByXLP = async ({ platform, regionCode, isXLP, ...sponsoredClickData }) => {
  const url = apiConfig.sponsoredClickUrl(sponsoredUrlBase);

  await makeRequest(url, sponsoredClickData, platform, regionCode);
};

const sponsoredClickService = async ({ adInfo, mPos, enabled, sku, platform, store, regionCode }) => {
  if (`${enabled}` === 'false') {
    return;
  }

  const cookie = getMabayaSessionId();

  const vasStore = getStore(store);
  const data = {
    pageType: 'product',
    adType: 'products',
    context: {
      userIdentifier: cookie,
      adIdentifier: adInfo,
      slot: mPos,
      signal: sku,
      ...(vasStore && { store: vasStore }),
    },
  };

  const url = apiConfig.sponsoredClickUrl(sponsoredUrlBase);
  await makeRequest(url, data, platform, regionCode);
};

const sponsoredImpressionService = async ({ platform, adInfo, mPos, enabled, regionCode }) => {
  if (`${enabled}` === 'false') {
    return;
  }

  const cookie = getMabayaSessionId();

  const data = {
    context: {
      ...(cookie && { userIdentifier: cookie }),
      adIdentifier: adInfo,
      slot: mPos,
    },
  };

  const url = apiConfig.sponsoredImpressionUrl(sponsoredUrlBase);
  await makeRequest(url, data, platform, regionCode);
};

const sponsoredVisitService = async ({
  referrerUrl = '',
  sku,
  enabled,
  isPDP = false,
  platform,
  categoryId,
  regionCode,
}) => {
  if (`${enabled}` === 'false') {
    return;
  }

  const isSLP = !!referrerUrl.split('Ntt=')[1];
  const referrerSignal = isSLP ? formatSearchTerm(referrerUrl) : categoryId;
  if (!isPDP && !referrerSignal) {
    return;
  }

  const cookie = (isPDP && getMabayaSessionId()) || '';

  const data = {
    context: {
      skuId: sku,
      searchTerm: referrerUrl,
      ...(cookie && { userIdentifier: cookie }),
    },
  };

  const url = apiConfig.sponsoredVisitUrl(sponsoredUrlBase);
  await makeRequest(url, data, platform, regionCode);
};

export {
  getMabayaSessionId,
  sponsoredClickService,
  sponsoredClickServiceByXLP,
  sponsoredImpressionService,
  sponsoredVisitService,
};
