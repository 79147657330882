import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import { withProduct } from '../../../utils/ProductContext';
import Loader from '../../Loader/Loader';
import { Modal } from '../../ui/Modal/Modal';
import CMRLoader from '../CMRLoader';
import { useInitialCMRData, useInstallments } from '../hooks';
import Styles from './CMRDesktop.style';

export const CMRPopup = dynamic({
  loader: () => import('../CMRPopup'),
  loading: () => <Loader />,
});

const CMRCalculatorDesktop = ({ errorMessage, layout, product, labels, appCtx }) => {
  const [toggle, setToggle] = useState(false);
  const { regionCode } = appCtx;
  const initialCMRData = useInitialCMRData({
    regionCode,
  });
  const { deviceType } = appCtx;

  const {
    state: installmentsState,
    cache,
    defaults,
    installmentDispatch,
    fetchCMRInstallmentsHandler,
  } = useInstallments({
    error: errorMessage,
    initialData: {},
    quantity: 1,
    variantId: product.currentVariant,
    regionCode,
    labels,
  });

  const {
    installmentStatement,
    data: installmentData,
    installmentInfoLoading,
    isLoading: installmentsLoading,
  } = installmentsState;
  const currentInstallmentData = installmentData[cache] || installmentData[defaults];

  const toggleState = {
    visible: toggle,
    setVisible: (bool) => setToggle(bool),
  };
  const isLoading = installmentsLoading || installmentInfoLoading;
  const openModal = () => setToggle(true);
  const closeModal = () => setToggle(false);
  const height = regionCode === 'ar' ? 580 : 436;
  const contentType = regionCode === 'ar' ? 'large' : 'medium';

  return (
    <>
      {isLoading && (
        <div
          className={`cmr-calculator-container cmr-calculator-${layout} fa--cmr-calculator-${layout}__desktop rebranded`}
        >
          <CMRLoader layout={layout} />
        </div>
      )}
      {installmentStatement && !isLoading && (
        <div
          className={`cmr-calculator-container cmr-calculator-${layout} fa--cmr-calculator-${layout}__desktop rebranded`}
        >
          <div className="cmr-calculator flex-top">
            <div>
              <i className="icon csicon-calculator" />
            </div>
            <div>
              <p className="title-text">{_.get(currentInstallmentData, 'textDictionary.financeCalculatorLabelText')}</p>
              <div className="flex-middle">
                <div
                  className="calculator-popup-button"
                  onClick={openModal}
                  onKeyPress={openModal}
                  role="button"
                  tabIndex={0}
                  id="testId-CMRCalculator-info"
                >
                  <span>{installmentStatement}</span>
                  <i className="csicon-arrow_right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {currentInstallmentData && currentInstallmentData.state && (
        <div className="cmr-modal">
          <Modal
            options={{
              disableOutsideClick: false,
              width: 380,
              height,
              padding: '0px',
              overflow: 'hidden',
            }}
            toggleState={toggleState}
            content={(actions) => (
              <CMRPopup
                actions={actions}
                regionCode={regionCode}
                currentVariant={product.currentVariant}
                errorMessage={errorMessage}
                closeModal={closeModal}
                installmentsState={installmentsState}
                installmentDispatch={installmentDispatch}
                cache={cache}
                installmentData={currentInstallmentData}
                fetchCMRInstallmentsHandler={fetchCMRInstallmentsHandler}
                {...initialCMRData}
                contentType={contentType}
              />
            )}
            deviceType={deviceType}
          />
        </div>
      )}
      <style jsx>{Styles}</style>
    </>
  );
};

CMRCalculatorDesktop.defaultProps = {
  errorMessage: '',
  layout: 'hardline',
};

CMRCalculatorDesktop.propTypes = {
  errorMessage: PropTypes.string,
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  layout: PropTypes.oneOf(['hardline', 'softline']),
};

export { CMRCalculatorDesktop };
export default withProduct(withApplicationContext(withLabels(CMRCalculatorDesktop)));
