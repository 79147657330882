import css from 'styled-jsx/css';
import colors from '../../../config/styles/colors';
import { svgToDataUri } from '../../../utils/styles/svgMixins';
import { icons, typography } from '../../../config/styles';

const AvailabilityDesktopStyles = css`
  .availability {
    flex-basis: 66.66%;
    &-container {
      display: flex;
      flex-direction: row;
      height: auto;
      width: 100%;
      font-family: Lato, serif;
      gap: 12px;
      .availability-item {
        color: ${colors.black.shade1};
        flex: 1;
        &.title {
          span.softline-title {
            padding-top: 5px;
          }
          span {
            width: 100%;
            text-align: left;
            font-weight: bold;
            font-size: 14px;
            padding-top: 10px;
            padding-bottom: 10px;
          }
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        height: auto;
        width: 100%;
        margin: 0;
        box-sizing: border-box;
        .icon {
          min-width: 32px;
          height: 36px;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          &.deliveryAvailable {
            width: 28px;
            height: 16px;
            background-image: url('${svgToDataUri(icons.deliveryIcon)}');
          }
          &.deliveryNotAvailable {
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            background-image: url('${svgToDataUri(icons.noHomeDelivery)}');
          }
          &.siteToStoreAvailable {
            margin-top: 3px;
            height: 21px;
            width: 24px;
            background-image: url('${svgToDataUri(icons.buyInStoreIcon)}');
          }
          &.siteToStoreNotAvailable {
            background-image: url('${svgToDataUri(icons.buyInStoreNotAvaliableIcon)}');
          }
          &.pickupInStoreAvailable {
            width: 32px;
            height: 32px;
            background-image: url('${svgToDataUri(icons.storeWithdrawalIcon)}');
          }
          &.pickupInStoreNotAvailable {
            width: 48px;
            height: 48px;
            flex-shrink: 0;
            background-image: url('${svgToDataUri(icons.noPickupInStore)}');
          }
          &.downloadAvailable {
            height: 20px;
            width: 27px;
            background-image: url('${svgToDataUri(icons.downloadIcon)}');
          }
          &.purchaseOnlineAvailable {
            height: 18px;
            width: 20px;
            background-image: url('${svgToDataUri(icons.purchaseOnlineAvailableIcon)}');
          }
          &.purchaseOnlineNotAvailable {
            height: 30px;
            background-image: url('${svgToDataUri(icons.purchaseOnlineNotAvailableIcon)}');
          }
        }
        &.rebranded {
          .deliveryAvailable {
            background-image: url('${svgToDataUri(icons.homeDeliveryMint)}');
            height: 48px;
            width: 48px;
            flex-shrink: 0;
          }
          .so-deliveryAvailable {
            background-image: url('${svgToDataUri(icons.so_com_homeDeliveryMint)}');
            height: 48px;
            width: 48px;
            flex-shrink: 0;
          }
          .siteToStoreAvailable {
            background-image: url('${svgToDataUri(icons.buyInStoreIconRebranded)}');
          }
          .pickupInStoreAvailable {
            background-image: url('${svgToDataUri(icons.pickUpInStoreMint)}');
            height: 48px;
            width: 48px;
            flex-shrink: 0;
          }
          .so-pickupInStoreAvailable {
            background-image: url('${svgToDataUri(icons.so_com_pickUpInStoreMint)}');
            height: 48px;
            width: 48px;
            flex-shrink: 0;
          }
          .downloadAvailable {
            background-image: url('${svgToDataUri(icons.downloadIconRebranded)}');
            height: 48px;
            width: 48px;
            flex-shrink: 0;
          }
          .purchaseOnlineAvailable {
            background-image: url('${svgToDataUri(icons.purchaseOnlineAvailableIconRebranded)}');
          }
        }
        &.lastItem {
          margin-top: 10px;
          border-top: 1px solid ${colors.gray.shade3};
        }
        .content {
          height: auto;
          width: 100%;
          text-align: center;
          justify-content: center;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 14px;
          line-height: 18px;
          color: inherit;
          letter-spacing: 0.14px;
          &.outofstock {
            display: block;
            .underline {
              text-decoration: underline;
              text-underline-offset: 2px;
              text-decoration-color: ${colors.gray.shade52};
            }
            .no-delivery {
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 18px;
              color: ${colors.gray.shade52};
            }
          }

          &-main {
            display: flex;
            flex-direction: column;
            .bold {
              font-weight: bold;
            }
            &.outofstock {
              color: ${colors.gray.shade44};
            }
          }
          &-sub {
            font-size: ${typography.sm};
            cursor: pointer;
            color: ${colors.gray.shade40};
            width: 100%;
            div {
              outline: none;
              display: inline;
              span {
                &.caret > i {
                  padding-left: 6px;
                  display: inline-block;
                  font-size: ${typography.xxs};
                }
                &.underline {
                  text-align: center;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 500;
                  line-height: 18px;
                  color: ${colors.secondary_color};
                  text-decoration: underline;
                  text-underline-offset: 2px;
                  text-decoration-color: ${colors.secondary_color};
                }
              }
            }
          }
          .placeholder-text {
            color: ${colors.gray.shade44};
            font-size: 12px;
            letter-spacing: 0;
            line-height: 21px;
            span {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
`;

export { AvailabilityDesktopStyles };
