import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import apiConfig from '../../../../config/api/apiConfig';
import constants from '../../../../config/constants';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import CookieStorage from '../../../../utils/CookieStorage';
import { errorMessageHandler } from '../../../../utils/errorHandler';
import httpService from '../../../../utils/httpService';
import { changeTextCase } from '../../../../utils/jsUtils';
import { withLabels } from '../../../../utils/LabelsContext';
import _ from '../../../../utils/LodashImports';
import noop from '../../../../utils/noop';
import { getCommerceHeaders } from '../../../../utils/tenantHelper';
import WebStorage from '../../../../utils/WebStorage';
import BodyCopy from '../../../ui/BodyCopy/BodyCopy';
import { ModalContentCss } from '../../ModalContent/ModalContent.style';
import DeliveryCalendar from '../DeliveryCalendar';
import SinglePriceCalendar from '../DeliveryCalendar/SinglePriceCalendar';

const HomeDeliveryModal = ({ labels, onClose, setLoading, selection, setError, error, appCtx, offeringId }) => {
  const { state, municipal } = selection;
  const [data, setData] = useState();
  const [dateRange, setDateRange] = useState();
  const { siteConfig, regionCode, store } = appCtx;
  const commerceHeaders = getCommerceHeaders(regionCode, store);

  useEffect(() => {
    setError('');
    setLoading(true);
    httpService()
      .get(
        apiConfig.getHomeDeliveryShippingUrl(regionCode, state, municipal, offeringId),
        {
          headers: commerceHeaders,
        },
        'json'
      )
      .then((res) => {
        setLoading(false);
        const homeDeliverySlots = _.get(res, 'data.data.homeDelivery.deliverySlots', []);
        const homeDeliveryDateRangeSlots = _.get(res, 'data.data.homeDeliveryDateRange.deliverySlots', []);
        setData(homeDeliverySlots);
        setDateRange(homeDeliveryDateRangeSlots);
        if (res.statusCode !== 200 && res.error) {
          const resp = res.data;
          const err = errorMessageHandler(resp.errors);
          setError(err ? err[0].message : '');
        } else if (
          (!homeDeliverySlots || homeDeliverySlots.length === 0) &&
          (!homeDeliveryDateRangeSlots || homeDeliveryDateRangeSlots.length === 0)
        ) {
          setError(_.get(siteConfig, `configurations.commerceApiAlerts[CHECKOUT_SLOT_NOT_AVAILABLE]`, ''));
        }
      });
  }, [selection]);

  const handleOnClick = () => {
    // Set Availability_HD cookie
    CookieStorage.setCookie({
      name: constants.COOKIE_HOME_DELIVERY_KEY,
      value: selection,
      regionCode,
      store,
    });
    // Save HD information to local storage
    WebStorage.setItem({
      name: constants.LS_HOME_DELIVERY_KEY,
      value: JSON.stringify(selection),
      shouldPersist: true,
    });

    onClose();
  };

  const deliveryCalenderAvailable = data && Array.isArray(data) && data.length > 0;
  const singlePriceCalendarAvailable = dateRange && Array.isArray(dateRange) && dateRange[0] && dateRange[0].cost;
  return (
    <div className="resultContainer rebranded">
      {(deliveryCalenderAvailable || singlePriceCalendarAvailable) && (
        <div className="resultActive">
          {!error && (
            <div className="heading">
              <BodyCopy>{labels.HOME_DELI_AVAILABLE_RESULT}</BodyCopy>
            </div>
          )}

          {singlePriceCalendarAvailable && <SinglePriceCalendar slot={dateRange[0]} />}

          {deliveryCalenderAvailable && <DeliveryCalendar setBusy={setLoading} slots={data} />}
        </div>
      )}
      <div className="bottomContainer">
        <div className="bottomContainer-item left">
          <div className="icon">
            <i className="csicon-alert" />
          </div>
          <span className="quinary">{labels.PICKUP_INSTORE_INFO_CAN_VARY}</span>
        </div>
        <div className="bottomContainer-item right">
          <button
            id="testId-HomeDeliveryModalContent-clearselection"
            type="button"
            onClick={onClose}
            className="button delete"
          >
            {labels.CANCEL}
          </button>
          <button
            id="testId-HomeDeliveryModalContent-saveselection"
            type="button"
            onClick={handleOnClick}
            disabled={error && error.length > 0}
            className={`button delivery ${error && 'disabled'}`}
          >
            {changeTextCase(labels.HOME_DELI_SAVE)}
          </button>
        </div>
      </div>
      <style jsx>{ModalContentCss}</style>
    </div>
  );
};

HomeDeliveryModal.propTypes = {
  labels: PropTypes.object,
  onClose: PropTypes.func,
  selection: PropTypes.shape({
    state: PropTypes.string,
    municipal: PropTypes.string,
  }),
  setLoading: PropTypes.func,
  setError: PropTypes.func,
  error: PropTypes.string,
  appCtx: PropTypes.object,
  offeringId: PropTypes.string,
};

HomeDeliveryModal.defaultProps = {
  labels: {},
  onClose: noop,
  setLoading: noop,
  selection: { state: '', municipal: '' },
  setError: noop,
  error: '',
  appCtx: {},
  offeringId: '',
};

export { HomeDeliveryModal };
export default withApplicationContext(withLabels(HomeDeliveryModal));
