import CookieStorage from './CookieStorage';
import _ from './LodashImports';

export function getItemFromLocalStorage(key) {
  if (typeof window === 'undefined') return '';
  const localStorageData = window.localStorage.getItem(key);
  if (localStorageData === 'undefined') {
    return null;
  }
  try {
    return JSON.parse(localStorageData);
  } catch (e) {
    return localStorageData;
  }
}

export const fetchToggleFromStorage = (key, commerceConfig = {}) => {
  const isDocumentDefined = typeof document !== 'undefined';
  const cookie = isDocumentDefined && CookieStorage.getCookie(key);
  return getItemFromLocalStorage(key) || cookie === 'true' || _.get(commerceConfig, `toggles.${key}`, false);
};
