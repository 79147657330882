import React, { useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import _ from '../../../utils/LodashImports';
import { StoresListStyles } from './StoresList.style';
import { ModalContent, ModalHeader, ModalFooter } from '../CustomModal/CustomModal';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withProduct } from '../../../utils/ProductContext';
import Spinner from '../../ui/Spinner/MarketplaceSpinner';
import StoreDetail from '../StoreDetail/StoreDetail';
import useGoogleMap, { getStoreIconPathFromStoreName } from '../../../utils/useGoogleMap';
import { svgToDataUri } from '../../../utils/styles/svgMixins';
import noop from '../../../utils/noop';
import { capitalizeText } from '../../Pod/podUtils';
import constants from '../../../config/constants';
import { icons } from '../../../config/styles';
import { getStoreFromName } from '../helper';

const StoresList = ({
  config,
  appCtx,
  loading,
  storesList,
  setScreen,
  selectedStore,
  setSelectedStore,
  zoneConfig,
  product,
  actions,
  theme,
}) => {
  const themedStoresListStyles = StoresListStyles(theme);
  if (loading) {
    return (
      <>
        <div className="spinner">
          <Spinner />
        </div>
        <style jsx>{themedStoresListStyles}</style>
      </>
    );
  }
  const { deviceType, store: storeCtx } = appCtx;
  const selectedComuna = _.get(zoneConfig, 'comuna.name', '');
  const HAVE_STOCK =
    storeCtx === constants.STORES.so_com
      ? _.get(appCtx, 'siteConfig.textDictionary.SO_COM_HAVE_STOCK', 'tenemos stock online en sodimac.com')
      : _.get(appCtx, 'siteConfig.textDictionary.HAVE_STOCK', 'tenemos stock online en falabella.com');
  const {
    IN = 'en',
    CANCEL,
    CHECK_STOCK_ONLINE = 'Revisar stock online',
    CHANGE_RAGION = 'Lo sentimos. Puedes cambiar la ubicación o aprovechar que aún',
    CHANGE = 'Cambiar',
    FEW_UNITS_LEFT = 'Quedan pocas unidades',
    EXACT_UNITS_LEFT = 'Quedan solo {units} unidades',
    UNITS_AVAILABLE = 'Unidades disponibles',
    EXACT_UNITS_AVAILABLE = '{units} unidades disponibles',
    NEAR = 'Cerca de',
    IN_STOCK_IN_STORE = 'tiendas con stock',
    OUT_OF_STOCK = 'Sin stock',
    STORES_WITH_STOCK_IN_OTHER_COMUNA = 'tiendas con stock en otras comunas',
  } = _.get(appCtx, 'siteConfig.textDictionary', {});
  useEffect(() => {
    setSelectedStore(!!storesList.length && storesList[0]);
  }, []);
  const mapRef = useRef();
  const { map, marker } = useGoogleMap(mapRef, selectedStore);

  const outOfStock = !storesList.some(
    ({ address }) => _.lowerCase(address.district) === _.lowerCase(_.get(zoneConfig, 'comuna.name', ''))
  );

  const geofinderText = {
    false: ['', `${NEAR} `, IN_STOCK_IN_STORE],
    true: [`${OUT_OF_STOCK} `, `${NEAR.toLowerCase()} `, STORES_WITH_STOCK_IN_OTHER_COMUNA],
  };
  const { stockCategoryConfig, stockCategoryLimit } = product;

  const storeSelectHandler = (store) => {
    setSelectedStore(store);
    if (deviceType !== 'desktop') {
      setScreen('screen4');
      return;
    }
    const latLng = {
      lat: store.address.latitude,
      lng: store.address.longitude,
    };
    const storePath = getStoreIconPathFromStoreName(_.get(store, 'storeName', ''));
    marker.setIcon(svgToDataUri(icons[`mapMarker${storePath}`]));
    marker.setPosition(latLng);
    map.setCenter(latLng);
  };

  const getUnits = (number) => {
    let textUnits =
      number <= stockCategoryLimit
        ? EXACT_UNITS_LEFT.replace('{units}', number)
        : EXACT_UNITS_AVAILABLE.replace('{units}', number);
    if (stockCategoryConfig === constants.STOCK_CATEGORY_CONFIG.RANGE) {
      textUnits = number <= stockCategoryLimit ? FEW_UNITS_LEFT : UNITS_AVAILABLE;
    }
    return textUnits;
  };

  const workingHours = {
    falabella: _.get(appCtx, 'siteConfig.endpoints.WORKING_HOURS_TIENDA', ''),
    sodimac: _.get(appCtx, 'siteConfig.endpoints.WORKING_HOURS_SODIMAC', ''),
    tottus: _.get(appCtx, 'siteConfig.endpoints.WORKING_HOURS_TOTTUS', ''),
  };

  const listContainerClass = !storesList.length ? 'max-width' : '';
  const titleComuna = capitalizeText(selectedComuna);
  const titleHeader = storesList.length ? `${storesList.length} ${geofinderText[outOfStock][2]}` : '';

  return (
    <>
      <ModalHeader title={config.title} icon={config.icon} />
      <ModalContent>
        <div className="container">
          <div className={`list-section ${listContainerClass}`}>
            <div className={`geofinder-container ${outOfStock && 'out-of-stock'}`}>
              <div className="selected-comuna flex">
                <i className="location-icon" />
                <p className="region-section ellipsis">
                  <span className="red bold">{geofinderText[outOfStock][0]}</span>
                  <span>{storesList.length ? geofinderText[outOfStock][1] : <span>{IN} </span>}</span>
                  <span className="region-name">{titleComuna}</span>
                </p>
              </div>
              <div
                id="testId-change-comuna"
                role="button"
                tabIndex={-1}
                onKeyPress={noop}
                className="flex change-location"
                onClick={() => setScreen('screen2')}
              >
                <i className="edit-icon" />
                <span className="change-region-button">{CHANGE}</span>
              </div>
            </div>
            <div className="list-header">
              <p>{titleHeader}</p>
            </div>
            <div className="list-container">
              {!storesList.length && (
                <div className="list-header">
                  <p className="out-of-stock">
                    {`${CHANGE_RAGION} `}
                    <span className="bold">{HAVE_STOCK}</span>
                  </p>
                </div>
              )}
              {storesList.map((store) => {
                const { number } = store.stockQuantity;
                const classUnits = number <= stockCategoryLimit ? 'not-available' : 'available';
                const textUnits = getUnits(number);
                const storeFromName = getStoreFromName(_.get(store, 'storeName', ''));
                return (
                  <div
                    key={store.id}
                    role="button"
                    tabIndex={-1}
                    id="testId-store-item"
                    onKeyPress={noop}
                    onClick={() => storeSelectHandler(store)}
                    className={`store-list-item-wrapper ${
                      selectedStore.id === store.id && deviceType === 'desktop' ? 'selected' : ''
                    }`}
                  >
                    <div className={`store-icon ${storeFromName}`} />
                    <div className="store-item-details">
                      <p className="store-name ellipsis capitalize">{capitalizeText(store.storeName)}</p>
                      <div className="store-location">
                        <i className="location-icon small" />
                        <p className="store-location-text ellipsis capitalize">
                          <span>{capitalizeText(`${store.address.district},${store.address.state}`)}</span>
                        </p>
                        {deviceType !== 'desktop' && <i className="csicon-right" />}
                      </div>
                      <p className={`store-availability ${classUnits}`}>{textUnits}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {appCtx.deviceType === 'desktop' && storesList.length ? (
            <div className="floating-details">
              <div className="map-section" ref={mapRef} />
              <StoreDetail
                deviceType={deviceType}
                selectedStore={selectedStore}
                type="secondary"
                labels={_.get(appCtx, 'siteConfig.textDictionary', {})}
                workingHours={workingHours}
              />
            </div>
          ) : null}
        </div>
      </ModalContent>
      <ModalFooter customClass="store-list-footer">
        {storesList.length ? (
          <div className="footer-with-stock">
            <i className="alert-icon" />
            <p className="footer-text">{config.footer}</p>
          </div>
        ) : (
          <div className="footer-out-of-stock">
            <button type="button" className="cancel" onClick={actions.close}>
              {CANCEL}
            </button>
            <button id="testId-select-stock" type="button" className="check" onClick={actions.close}>
              {CHECK_STOCK_ONLINE}
            </button>
          </div>
        )}
      </ModalFooter>
      <style jsx>{themedStoresListStyles}</style>
    </>
  );
};

StoresList.propTypes = {
  config: PropTypes.object.isRequired,
  selectedStore: PropTypes.object,
  appCtx: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  storesList: PropTypes.array,
  setScreen: PropTypes.func.isRequired,
  setSelectedStore: PropTypes.func.isRequired,
  zoneConfig: PropTypes.object,
  product: PropTypes.object,
  actions: PropTypes.object,
  theme: PropTypes.object,
};

StoresList.defaultProps = {
  selectedStore: {},
  storesList: [],
  zoneConfig: {
    region: {},
    comuna: {},
  },
  product: {},
  actions: {
    close: () => {},
  },
  theme: null,
};
export default withTheme(withProduct(withApplicationContext(StoresList)));
export { StoresList };
