import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { clsx } from 'clsx';
import { StockTableStyle, StockCellStyle } from './StockTable.style';
import Button from '../Button/Button';
import { withProduct } from '../../../utils/ProductContext';
import { convertSize } from '../../../utils/variant/getUniqueSizes';
import * as productUtils from '../../../utils/product/productUtils';
import { withUser } from '../../../utils/UserContext';
import { withLabels } from '../../../utils/LabelsContext';
import constants from '../../../config/constants';

const mapGSColors = (colorName = '') => constants.guidedSellingColors[colorName.toLowerCase()] || '000';

const StockTable = ({ stockData, product, isMobile, requestProductFromStore, vendorData, regionCode, labels }) => {
  const { variants, currentVariant } = product;
  const isConnectProduct = productUtils.isConnectProduct(product);

  const variations = stockData.variations || [];

  const [mapStockData, setMapStockData] = useState([]);
  const [tableType, setTableType] = useState('');
  const [selectedSku, setSelectedSku] = useState({});
  const [requestProduct, setRequestProduct] = useState(false);
  const checkTableType = (callback) => {
    // if (isConnectProduct) callback(variations, 'connect');
    // else
    callback(variations, 'default');
  };
  const onlyInternet = (
    <div className="icon-container">
      <span className="icon onlyInternet" />
      <style jsx>{StockCellStyle}</style>
    </div>
  );
  const returnMappedData = (item, flag) => {
    // switch (table) {
    //   case 'connect': {
    //     setTableType('connect');
    //     if (flag)
    //       return {
    //         colorName: item.color,
    //         onlyInternet: true,
    //         id: item.id,
    //         colorCode: item.colorCode,
    //         size: item.size.toUpperCase(),
    //         connectOperator: item.connectOperator
    //       };
    //     return {
    //       colorName: item.color,
    //       onlyInternet: false,
    //       id: item.id,
    //       colorCode: item.colorCode,
    //       size: item.size.toUpperCase(),
    //       connectOperator: item.connectOperator
    //     };
    //   }
    //   default: {
    setTableType('default');
    if (flag)
      return {
        size: item.size.toUpperCase(),
        stock: item.storeStock,
        id: item.id,
        colorName: item.color,
        colorCode: item.colorCode,
        onlyInternet: true,
      };
    return {
      size: item.size.toUpperCase(),
      stock: item.storeStock,
      id: item.id,
      colorName: item.color,
      colorCode: item.colorCode,
      onlyInternet: false,
    };
    // }
    // }
  };
  const mapAndCheckStock = (data, table) => {
    setMapStockData(
      data
        .map((s) => {
          const internetVariant = variants.find((p) => p.id === s.id);
          if (!internetVariant) return returnMappedData(s, false, table);

          const available = internetVariant.isPurchaseable;
          if (s.storeStock === 0) {
            if (available) return returnMappedData(s, true, table);
            return undefined;
          }
          return returnMappedData(s, false, table);
        })
        .filter(Boolean)
        .sort((a, b) => convertSize(a.size) - convertSize(b.size))
    );
  };

  const handleSkuSelect =
    ({ id, stock }) =>
    () => {
      setSelectedSku({ id, stock });
      setRequestProduct(false);
    };

  useEffect(() => {
    if (variations && variations.length > 0 && variants) {
      checkTableType(mapAndCheckStock);
    }
  }, [requestProduct]);

  if (!mapStockData.length || !variations || !variants.length || isConnectProduct) return null;

  const tbody = () =>
    mapStockData.map((s) => {
      // switch (tableType) {
      //   case 'connect':
      //     return (
      //       <tr>
      //         <td>
      //           <div className="flex">
      //             <div
      //               style={{
      //                 backgroundColor: `#${s[2]}`
      //               }}
      //               className="color"
      //             />
      //             <p className={`color-name color-name-${tableType}`}>{s[0]}</p>
      //           </div>
      //         </td>
      //         <td>{s[4]}</td>
      //         <td>{s[1]}</td>
      //         <style jsx>{StockCellStyle}</style>
      //       </tr>
      //     );
      //   default:
      return (
        <tr
          id="selectedStoreItemId"
          className={clsx({ selected: selectedSku.id === s.id })}
          onClick={handleSkuSelect(s)}
        >
          <td>
            <div className="flex">
              <div
                style={{
                  backgroundColor: mapGSColors(s.colorName),
                }}
                className="color"
              />
              <p className={`color-name color-name-${tableType}`}>{s.colorName}</p>
            </div>
          </td>
          <td>{s.size}</td>
          <td>{s.stock ? s.stock : onlyInternet}</td>
          <style jsx>{StockCellStyle}</style>
        </tr>
      );
      // }
    });

  const requestProductHandler = async () => {
    const result = await requestProductFromStore(currentVariant, vendorData, regionCode);
    if (result) setRequestProduct(true);
    else setRequestProduct(false);
  };
  return (
    <div
      className={clsx(`stock-container-${tableType}`, {
        'stock-container-mobile': isMobile,
      })}
    >
      <span className="stock-title">{labels.SELECTION_TIENDA}</span>
      <table
        className={clsx(`stock-table stock-table-${tableType}`, {
          'stock-table-mobile': isMobile,
        })}
      >
        <tr>
          <th>{labels.COLOR.toUpperCase()}</th>
          <th>{labels.SIZE}</th>
          {/* {isConnectProduct && <th>{labels.CHIP}</th>} */}
          <th>{labels.STOCK}</th>
        </tr>
        {tbody()}
      </table>
      {!requestProduct ? (
        <div
          className={clsx('request-product-wrapper', {
            'mobile-spacing': isMobile,
            'desktop-spacing': !isMobile,
          })}
        >
          <Button
            size="medium"
            id="requestProductFromStoreBtn"
            disabled={!selectedSku.stock}
            type="secondary"
            onClick={() => requestProductHandler(currentVariant, vendorData, regionCode)}
          >
            <span className="request-product-text">{labels.REQUEST_PRODUCT}</span>
          </Button>
        </div>
      ) : (
        <div className="request-success-wrapper">
          <p className="success-title">{labels.REQUEST_ACCEPTED}</p>
          <p>{labels.REQUEST_ACCEPTED_MSG_1}</p>
        </div>
      )}
      <style jsx>{StockTableStyle}</style>
    </div>
  );
};

StockTable.defaultProps = {
  isMobile: false,
};

StockTable.propTypes = {
  stockData: PropTypes.object.isRequired,
  vendorData: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  requestProductFromStore: PropTypes.func.isRequired,
  regionCode: PropTypes.string.isRequired,
};

export { StockTable };
export default withLabels(withUser(withProduct(StockTable)));
