import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BodyCopyClassName, BodyCopyStyles } from './BodyCopy.style';

const BodyCopy = ({
  children,
  size,
  type,
  importance,
  isHighlighted,
  id,
  ellipsis,
  maxlines,
  centered,
  crossed,
  width,
  lineHeight,
}) => {
  const tags = {
    title2: 'b',
  };

  const Tag = tags[size] || 'span';
  const highlightedClass = isHighlighted ? 'bold' : 'normal';
  const ellipsisClass = ellipsis ? 'ellipsis' : '';
  const lineClampClass = maxlines ? `line-clamp-${maxlines}` : '';
  const centeredClass = centered ? 'centered' : '';
  const crossedClass = crossed ? 'crossed' : '';

  const parentClassName = `${size} ${type} ${importance} ${BodyCopyClassName} ${highlightedClass} ${ellipsisClass} ${lineClampClass} ${centeredClass} ${crossedClass} ${width} ${lineHeight}`;

  const child =
    typeof children === 'function' ? (
      children(parentClassName)
    ) : (
      <Tag id={id} className={`${parentClassName}`}>
        {children}
      </Tag>
    );

  return (
    <>
      {child}
      {BodyCopyStyles}
    </>
  );
};

BodyCopy.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.node, PropTypes.bool]).isRequired,
  size: PropTypes.oneOf([
    'copy1',
    'copy2',
    'copy3',
    'copy4',
    'copy5',
    'copy6',
    'copy7',
    'copy8',
    'copy9',
    'copy10',
    'copy11',
    'copy12',
    'copy13',
    'copy14',
    'copy15',
    'copy16',
    'copy17',
    'title',
    'title1',
    'title2',
    'title3',
    'title4',
    'title5',
    'title6',
    'title7',
    'title8',
    'title9',
    'title10',
    'title11',
    'title12',
    'title13',
    'title14',
    'title15',
    'title16',
    'title17',
    'title18',
    'title19',
    'title20',
  ]),
  type: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'quaternary', 'quinary', 'senary', 'septenary']),
  importance: PropTypes.oneOf(['high', 'medium', 'low', 'septenary', 'senary', '']),
  isHighlighted: PropTypes.bool,
  id: PropTypes.string,
  ellipsis: PropTypes.bool,
  maxlines: PropTypes.number,
  centered: PropTypes.bool,
  crossed: PropTypes.bool,
  width: PropTypes.string,
  lineHeight: PropTypes.string,
};

BodyCopy.defaultProps = {
  size: 'copy1',
  type: 'primary',
  importance: '',
  isHighlighted: false,
  id: '',
  ellipsis: false,
  maxlines: undefined,
  centered: false,
  crossed: false,
  width: '',
  lineHeight: '',
};

export default BodyCopy;
