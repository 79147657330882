import { isSodimacStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import { withProduct } from '../../../utils/ProductContext';
import { getVariant } from '../../../utils/variant';
import AddtionalInformationCard from '../../AdditionalInformationCard/AdditionaInformationCard';
import ComboSpecifications from '../../ComboSection/ComboSpecifications';
import ExpandableSwatch from '../../ExpandableSwatch/ExpandableSwatch';
import SpecificationTable from '../../SpecificationTable/SpecificationTable';
import OkayToShopInfo from '../ProductInfo/OkToShopInfo';
import ProductInfo from '../ProductInfo/ProductInfo';
import { AdditionalInformationDesktopCss } from './AdditionalInformationDesktop.styles';

const AdditionalInformationDesktop = ({ specifications, description, labels, product, appCtx, okayToShopBarcodes }) => {
  const headerType = 'mkp-headerContainer';
  const swatchType = 'mkp-swatchButton';
  const { currentVariant, variants } = product;

  const comboData = _.get(getVariant(variants, currentVariant), 'comboData', null);
  const isOkToShopFeatureEnabled = _.get(appCtx, 'siteConfig.toggles.isOkToShopFeatureEnabled', false);

  const isSoCom = isSodimacStandalone(appCtx.store);
  const specificationsTitle = isSoCom
    ? _.get(labels, 'SO_COM_SPECIFICATIONS_LABEL', 'Ficha técnica')
    : labels.SPECIFICATIONS_LABEL;
  const productInformationLabel = isSoCom
    ? _.get(labels, 'SO_COM_PRODUCT_INFORMATION_LABEL', 'Descripción')
    : labels.PRODUCT_INFORMATION_LABEL;

  const showSpecifications = comboData?.hasSpecifications || specifications.length > 0;

  return (
    <ExpandableSwatch height={700} buttonType={swatchType}>
      <div className="productInfoContainer" id="productInfoContainer">
        {showSpecifications && (
          <div className={`specification ${description ? 'specification-2-column' : 'specification-1-column'}`}>
            <AddtionalInformationCard headerText={specificationsTitle} headerType={headerType}>
              {comboData?.hasSpecifications ? (
                <ComboSpecifications comboItems={comboData.comboItems} />
              ) : (
                <SpecificationTable specifications={specifications} />
              )}
            </AddtionalInformationCard>
          </div>
        )}
        {(description || okayToShopBarcodes?.length > 0) && (
          <div className={`description ${showSpecifications ? 'description-2-column' : 'description-1-column'}`}>
            <AddtionalInformationCard headerText={productInformationLabel} headerType={headerType}>
              {isOkToShopFeatureEnabled && okayToShopBarcodes?.length > 0 && (
                <OkayToShopInfo okayToShopBarcodes={okayToShopBarcodes} />
              )}
              <ProductInfo description={description} />
            </AddtionalInformationCard>
          </div>
        )}
        <style jsx>{AdditionalInformationDesktopCss}</style>
      </div>
    </ExpandableSwatch>
  );
};

AdditionalInformationDesktop.defaultProps = {
  description: undefined,
  okayToShopBarcodes: [],
};

AdditionalInformationDesktop.propTypes = {
  specifications: PropTypes.array.isRequired,
  description: PropTypes.string,
  labels: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  okayToShopBarcodes: PropTypes.array,
};

export { AdditionalInformationDesktop };

export default withProduct(withLabels(AdditionalInformationDesktop));
