import _ from '../../utils/LodashImports';
import { formatNumberToPrice, getPriceToNumber, mapATGPricesToCatalystPrices } from '../../utils/PriceHelpers';

const getComboItemPrice = ({ prices, regionCode, toggles }) => {
  if (!prices || !prices.length) {
    return null;
  }
  const itemPrices = mapATGPricesToCatalystPrices(prices, regionCode, toggles);
  let itemPrice = itemPrices.filter((p) => p.type === 'internetPrice');
  if (itemPrice.length === 0) {
    itemPrice = itemPrices.filter((p) => p.type === 'normalPrice');
  }

  return itemPrice.length ? itemPrice : null;
};

const dispatchDDComboEvent = () => {
  const comboEventToDigitalData = new CustomEvent('DDCOMBOSPAGE');
  setTimeout(() => {
    window.dispatchEvent(comboEventToDigitalData);
  }, 2000);
};
const hasAtleastOneSpecification = ({ comboItems }) => {
  let hasSpecifications = false;
  comboItems.forEach((item) => {
    const specifications = _.get(item, 'product.attributes.specifications', null);
    if (specifications && specifications.length > 0) {
      hasSpecifications = true;
    }
  });
  return hasSpecifications;
};

const calculateTotalPrice = ({ comboItems, regionCode, toggles }) => {
  let totalPrice = 0;
  let nullFound = false;
  if (!comboItems || comboItems.length === 0) {
    return null;
  }
  const symbol = _.get(comboItems[0], 'product.prices[0].symbol', null);
  if (!symbol) {
    return null;
  }

  comboItems.forEach((item) => {
    const prices = _.get(item, 'product.prices');
    const normalPrice = getComboItemPrice({ prices, regionCode, toggles });
    const itemPrice = _.get(normalPrice, '[0].price[0]');
    if (!itemPrice) {
      nullFound = true;
      return;
    }
    totalPrice += getPriceToNumber(itemPrice, regionCode);
  });

  if (nullFound) {
    return null;
  }

  return totalPrice;
};
const validateTotalPriceAmount = ({ totalPrice, variantData }) => {
  const internetPriceObj =
    variantData?.prices?.find((price) => price.type === 'eventPrice') ??
    variantData?.prices?.find((price) => price.type === 'internetPrice');
  const internetPrice = internetPriceObj?.price?.[0];
  return totalPrice >= internetPrice;
};
const constructComboData = ({ comboItems, bundleLinks, regionCode, toggles, variantData }) => {
  const reducedComboItems = comboItems.flatMap((item) => {
    const { id, brand, displayName, prices, url, attributes, mediaUrls } = item.product;
    const count = _.get(
      bundleLinks.find((link) => link.id === id),
      'quantity',
      null
    );
    if (count > 1) {
      return Array.from({ length: count }, () => ({
        product: { id, brand, displayName, prices, url, attributes, mediaUrls },
      }));
    }

    return {
      product: { id, brand, displayName, prices, url, attributes, mediaUrls },
    };
  });

  const totalPrice = calculateTotalPrice({
    comboItems: reducedComboItems,
    regionCode,
    toggles,
  });
  if (!totalPrice) {
    return null;
  }
  const symbol = _.get(comboItems[0], 'product.prices[0].symbol', null);
  if (!symbol) {
    return null;
  }
  const formattedComboTotalPrice = formatNumberToPrice(totalPrice, regionCode);
  const comboData = {
    comboItems: reducedComboItems,
    totalPrice: `${symbol}${formattedComboTotalPrice}`,
    showTotalPrice: validateTotalPriceAmount({
      totalPrice: formattedComboTotalPrice,
      variantData,
    }),
    hasSpecifications: hasAtleastOneSpecification({
      comboItems: reducedComboItems,
    }),
  };

  return comboData;
};

export {
  calculateTotalPrice,
  constructComboData,
  dispatchDDComboEvent,
  getComboItemPrice,
  hasAtleastOneSpecification,
  validateTotalPriceAmount,
};
