import { getUrlParts } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withConfig } from '../../../utils/ConfigurationContext';
import { withLabels } from '../../../utils/LabelsContext';
import { withMyLists } from '../../../utils/MyListsContext';
import DesktopAlert from './Desktop/DesktopAlert';
import MobileAlert from './Mobile/MobileAlert';
import style from './MyListAlert.style';

const MyListsAlert = ({ deviceType, myLists, labels, config, page, appCtx }) => {
  const { addedLists = [], undo, alertType } = myLists || {};
  const environment = config?.ENVIRONMENT || 'production';
  const { store, regionCode } = appCtx;
  const { href: baseUrl } = getUrlParts({
    store,
    environment,
    regionCode,
  });
  const urlMyLists = `${baseUrl}/myaccount/myLists`;
  const l = {
    savedChanges: labels.MY_LISTS_SAVED_CHANGES || 'Se guardaron los cambios.',
    undo: labels.MY_LISTS_UNDO || 'Deshacer',
    checkList: labels.MY_LISTS_CHECK_LISTS || 'Revisar listas',
    hasBeenAdded: labels.MY_LISTS_ADDED_LIST_MESSAGE || 'Se agregó a:',
  };
  const Content = () => {
    return (
      <div>
        {alertType === 'warning' ? (
          <div className="my-lists-alert warning">
            {l.savedChanges}{' '}
            {undo && (
              <button type="button" className="link" onClick={undo}>
                {l.undo}
              </button>
            )}
          </div>
        ) : (
          <div className="my-lists-alert">
            {l.hasBeenAdded}{' '}
            {addedLists.map((e, i, a) => (
              <b key={e}>
                {e}
                {i !== a.length - 1 && ', '}
              </b>
            ))}
            .{' '}
            <a className="link" href={urlMyLists}>
              {l.checkList}
            </a>
          </div>
        )}
        <style jsx>{style}</style>
      </div>
    );
  };
  return (
    addedLists.length > 0 && (
      <>
        {deviceType !== 'desktop' && <MobileAlert content={Content} type={alertType} page={page} />}
        {deviceType === 'desktop' && <DesktopAlert content={Content} type={alertType} />}
      </>
    )
  );
};

MyListsAlert.propTypes = {
  myLists: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  page: PropTypes.string,
  config: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};
MyListsAlert.defaultProps = {
  page: '',
};

export { MyListsAlert };
export default withLabels(withConfig(withMyLists(withApplicationContext(MyListsAlert))));
