import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../utils/LabelsContext';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import AdditionalInformationDesktop from './Desktop/AdditionalInformationDesktop';
import AdditionalInformationMobile from './Mobile/AdditionalInformationMobile';

const AdditionalInformation = ({ specifications, description, topSpecifications, appCtx, okayToShopBarcodes }) => {
  return (
    <MatchMedia
      content={{
        desktop: () => (
          <AdditionalInformationDesktop
            specifications={specifications}
            description={description}
            appCtx={appCtx}
            okayToShopBarcodes={okayToShopBarcodes}
          />
        ),
        mobile: () => (
          <AdditionalInformationMobile
            specifications={specifications}
            description={description}
            topSpecifications={topSpecifications}
            isMobile
            appCtx={appCtx}
            okayToShopBarcodes={okayToShopBarcodes}
          />
        ),
        tablet: () => (
          <AdditionalInformationMobile
            specifications={specifications}
            description={description}
            topSpecifications={topSpecifications}
            isMobile
            appCtx={appCtx}
          />
        ),
      }}
    />
  );
};

AdditionalInformation.defaultProps = {
  description: undefined,
  topSpecifications: [],
  okayToShopBarcodes: [],
};

AdditionalInformation.propTypes = {
  specifications: PropTypes.array.isRequired,
  topSpecifications: PropTypes.array,
  description: PropTypes.string,
  appCtx: PropTypes.object.isRequired,
  okayToShopBarcodes: PropTypes.array,
};

export { AdditionalInformation };

export default withLabels(AdditionalInformation);
