import getVariant from './getVariant';
import { getUniqueSizes, isSizesValid, getUniqueAvailableSizes } from './getUniqueSizes';
import { getUniqueOperators } from './getUniqueOperators';
import {
  getUniqueColorVariants,
  getSelectedColorSwatchIndex,
  getUniqueColorVariantsSLP,
} from './getUniqueColorVariants';
import sizeOrder from './constants';
import { getMatchingVariants, getFirstMatchingVariantId, getCheapestVariantId } from './chooseVariant';
import { isAvailable, isNetworkInStock } from './availability';

const getMeasurements = ({ product, productMeasurement }) => {
  if (productMeasurement) {
    const { format, unit } = productMeasurement;
    if (!format || !unit) {
      return null;
    }
    return {
      formato: format,
      'unidad-de-medida': unit,
      skuminsaleqty: productMeasurement?.skuminsaleqty,
    };
  }

  const { variants, currentVariant } = product;
  if (!variants?.length) {
    return null;
  }
  const variant = getVariant(variants, currentVariant);
  if (!variant?.attributes?.measurement) {
    return null;
  }
  const { measurement } = variant.attributes;
  return {
    formato: measurement?.formato,
    'unidad-de-medida': measurement?.['unidad-de-medida'],
    skuminsaleqty: measurement?.skuminsaleqty,
  };
};

export {
  getVariant,
  getUniqueSizes,
  isSizesValid,
  getUniqueAvailableSizes,
  getUniqueOperators,
  getUniqueColorVariants,
  getSelectedColorSwatchIndex,
  sizeOrder,
  getMatchingVariants,
  getFirstMatchingVariantId,
  isAvailable,
  isNetworkInStock,
  getCheapestVariantId,
  getUniqueColorVariantsSLP,
  getMeasurements,
};
