import PropTypes from 'prop-types';
import React, { useState } from 'react';
import constants from '../../config/constants';
import noop from '../../utils/noop';
import ColorSwatch from '../ui/ColorSwatch/ColorSwatch';
import { ProductColorSwatchesStyles } from './ProductColorSwatches.style';

const RegularColorSwatches = ({
  productId,
  colorVariants,
  type,
  handleColorSwatch,
  selectedColorIndex,
  size,
  appCtx,
  colorMetrics,
}) => {
  return (
    <div className={`color-swatch-container color-swatch-${type} mkp-color-swatch`}>
      <ColorSwatch
        colorVariants={colorVariants}
        handleColorSwatch={handleColorSwatch}
        selectedColorIndex={selectedColorIndex}
        productId={productId}
        key={productId}
        size={size}
        page={constants.PAGES.PDP}
        appCtx={appCtx}
        colorMetrics={colorMetrics}
      />
      <style jsx>{ProductColorSwatchesStyles}</style>
    </div>
  );
};

RegularColorSwatches.defaultProps = {
  handleColorSwatch: noop,
  selectedColorIndex: 0,
};

RegularColorSwatches.propTypes = {
  colorVariants: PropTypes.array.isRequired,
  productId: PropTypes.string.isRequired,
  handleColorSwatch: PropTypes.func,
  type: PropTypes.string.isRequired,
  selectedColorIndex: PropTypes.number,
  size: PropTypes.string.isRequired,
  appCtx: PropTypes.object.isRequired,
  colorMetrics: PropTypes.object.isRequired,
};

const ExpandableColorSwatches = ({
  productId,
  colorVariants,
  type,
  handleColorSwatch,
  selectedColorIndex,
  size,
  appCtx,
  colorMetrics,
}) => {
  const [expandState, changeExpand] = useState('collapse');
  const toggleExpand = () => {
    const updatedState = expandState === 'collapse' ? 'expand' : 'collapse';
    changeExpand(updatedState);
  };
  return (
    <div
      className={`color-swatch-container
          color-swatch-${type}
          ${expandState === 'collapse' ? `color-swatch-${type}-collapse` : ''}
        `}
    >
      <ColorSwatch
        colorVariants={colorVariants}
        handleColorSwatch={handleColorSwatch}
        selectedColorIndex={selectedColorIndex}
        productId={productId}
        key={productId}
        size={size}
        page={constants.PAGES.PDP}
        appCtx={appCtx}
        colorMetrics={colorMetrics}
      />
      <button type="button" id="testId-expand-toggle" className="expand-swatch" onClick={toggleExpand}>
        {expandState === 'collapse' ? (
          <i className="csicon-arrow_down_small" />
        ) : (
          <i className="csicon-arrow_up_small" />
        )}
      </button>
      <style jsx>{ProductColorSwatchesStyles}</style>
    </div>
  );
};

ExpandableColorSwatches.propTypes = {
  colorVariants: PropTypes.array.isRequired,
  productId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  handleColorSwatch: PropTypes.func.isRequired,
  selectedColorIndex: PropTypes.number.isRequired,
  size: PropTypes.string.isRequired,
  appCtx: PropTypes.object.isRequired,
  colorMetrics: PropTypes.object.isRequired,
};

const ProductColorSwatches = ({
  colorVariants,
  productId,
  maxColorSwatches,
  type,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  size,
  appCtx,
  colorMetrics,
}) => {
  const isRegularColorSwatch =
    type === constants.PRODUCT_CONSTANTS.CONNECT || maxColorSwatches[type] >= colorVariants.length;

  return isRegularColorSwatch ? (
    <RegularColorSwatches
      productId={productId}
      colorVariants={colorVariants}
      type={type}
      handleColorSwatch={colorSwatchHandler}
      selectedColorIndex={selectedColorSwatchIndex}
      size={size}
      appCtx={appCtx}
      colorMetrics={colorMetrics}
    />
  ) : (
    <ExpandableColorSwatches
      productId={productId}
      colorVariants={colorVariants}
      type={type}
      handleColorSwatch={colorSwatchHandler}
      selectedColorIndex={selectedColorSwatchIndex}
      size={size}
      appCtx={appCtx}
      colorMetrics={colorMetrics}
    />
  );
};

ProductColorSwatches.defaultProps = {
  type: constants.PRODUCT_CONSTANTS.HARDLINE,
  selectedColorSwatchIndex: 0,
  colorSwatchHandler: noop,
  maxColorSwatches: {
    HARDLINE: 24,
    SOFTLINE: 22,
  },
  size: 'medium',
};

ProductColorSwatches.propTypes = {
  colorVariants: PropTypes.array.isRequired,
  productId: PropTypes.string.isRequired,
  type: PropTypes.string,
  maxColorSwatches: PropTypes.object,
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  size: PropTypes.oneOf(['medium', 'large']),
  appCtx: PropTypes.object.isRequired,
  colorMetrics: PropTypes.object.isRequired,
};

export default ProductColorSwatches;
