import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { isWhitelabel } from '@digital-retail/store-manager';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import BodyCopy from '../ui/BodyCopy/BodyCopy';
import badgesStyles from './Badges.style';
import stickersStyles from './Stickers.style';

const Badges = ({ badgeItems, deviceType, layout, isSticker, promotionBadge, store, theme }) => {
  const getBadgeSize = () => {
    if (deviceType === 'mobile') {
      return layout.indexOf('PDP') !== -1 ? 'copy4' : 'copy7';
    }
    return 'copy8';
  };
  return (
    <div
      className={clsx('pod-badges', {
        'sticker-group': isSticker,
      })}
    >
      {badgeItems &&
        badgeItems.map((badge) => (
          <BodyCopy size={getBadgeSize()} key={badge.label} isHighlighted>
            {() => {
              const newBadgeLabel = badge?.label?.replace('<b>', '<b style="font-weight:700">');
              return (
                newBadgeLabel && (
                  <span
                    id={`testId-Pod-badges-${badge.label}`}
                    className={clsx({
                      [`pod-badges-item-${layout}`]: true,
                      'pod-badges-item': true,
                    })}
                    style={
                      isWhitelabel({ store })
                        ? { backgroundColor: theme?.secondary_color, color: '#FFFFFF' }
                        : {
                            backgroundColor: `${badge.styles.backgroundColor}`,
                            color: `${badge.styles.textColor}`,
                          }
                    }
                    dangerouslySetInnerHTML={{ __html: newBadgeLabel }}
                  />
                )
              );
            }}
          </BodyCopy>
        ))}

      {promotionBadge && (
        <BodyCopy size={getBadgeSize()} key={promotionBadge.displayName} isHighlighted>
          {(classes) => (
            <span
              className={`${classes} pod-badges-item-${layout} pod-badges-item`}
              style={{
                backgroundColor: `${promotionBadge.bgColor}`,
                color: `${promotionBadge.textColor}`,
              }}
            >
              {promotionBadge.displayName}
            </span>
          )}
        </BodyCopy>
      )}
      <style jsx>{badgesStyles}</style>
      <style jsx>{stickersStyles}</style>
    </div>
  );
};

Badges.propTypes = {
  badgeItems: PropTypes.array,
  deviceType: PropTypes.oneOf(['mobile', 'tablet', 'desktop']).isRequired,
  layout: PropTypes.string,
  isSticker: PropTypes.bool,
  promotionBadge: PropTypes.object,
  store: PropTypes.string,
  theme: PropTypes.object,
};

Badges.defaultProps = {
  badgeItems: [],
  layout: '',
  isSticker: false,
  promotionBadge: undefined,
  store: '',
  theme: null,
};

export default withTheme(Badges);
