import { getCFDefaultImage } from '../components/ImageGallery/ImageGalleryHelper';
import apiConfig from '../config/api/apiConfig';

const RACKCDN_SUFFIXES = {
  THUMB: '-thumb', // 50px width and height
  SMALL: '-small', // 150px width and height
  MEDIUM: '-medium', // 400px width and height
  LARGE: '-large', // 700px width and height
  ZOOM: '-zoom', // 1400px width and height
};

const getCfUrlForThumbnail = ({ url }) => {
  return url.replace(/\/(public|thumbnail|preview|carousel)\b/g, '/thumbnail');
};

const generateCfUrl = ({ url, width, height, crop }) => {
  const queries = [];
  let cfUrl = url;
  if (width && height) {
    queries.push(`w=${width}`, `h=${height}`);
    if (Object.prototype.hasOwnProperty.call(crop, 'x') && Object.prototype.hasOwnProperty.call(crop, 'y')) {
      queries.push(`fit=cover`);
    } else {
      queries.push(`fit=pad`);
    }
  }

  if (queries.length > 0) {
    cfUrl = cfUrl.replace(/\/(public|thumbnail|preview|carousel)\b/g, '/');
    cfUrl += queries.join(',');
  }
  return cfUrl;
};

const getImages = ({
  variantId,
  regionCode = 'cl',
  useBasePath = false,
  attr = {},
  type = '',
  sellerName = '',
  isCFMediasEnabled = false,
  url = '',
  mediaDomains,
  fallBackImagesDomain,
  // internetSpeed = 1
}) => {
  const { width, height, quality, crop = {} } = attr;
  if (!mediaDomains || mediaDomains.length === 0) {
    /* eslint-disable-next-line no-param-reassign */
    mediaDomains = ['https://media.', 'https://staging-media.', 'imagedelivery.net'];
  }
  if (isCFMediasEnabled && !url) {
    return getCFDefaultImage({ sellerName, regionCode, fallBackImagesDomain });
  }

  if (mediaDomains.some((domain) => url.includes(domain))) {
    return type === 'thumbnail' ? getCfUrlForThumbnail({ url }) : generateCfUrl({ width, height, url, crop });
  }

  const baseUrl = useBasePath ? apiConfig.imagesBaseURL : apiConfig.getImagesURL(regionCode);

  let imageUrl = url || `${baseUrl}/${variantId}`;

  if (!imageUrl.includes('scene7')) {
    // temporary fix to resize tottus images until they are migrated to scene7
    if (imageUrl.includes('rackcdn.com')) {
      const suffix = (width || 400) <= 150 ? RACKCDN_SUFFIXES.SMALL : RACKCDN_SUFFIXES.MEDIUM;
      const fileExtension = imageUrl.match(/.(jpg|jpeg|png|jfif)$/i);

      if (!fileExtension) {
        return imageUrl;
      }

      const regex = new RegExp(`${fileExtension[0]}$`, 'i');
      return imageUrl.replace(regex, `${suffix}${fileExtension[0]}`);
    }
    return imageUrl;
  }

  if (width && height) {
    imageUrl += `?wid=${width}&hei=${height}`;
  }

  if (
    Object.prototype.hasOwnProperty.call(crop, 'x') &&
    Object.prototype.hasOwnProperty.call(crop, 'y') &&
    width &&
    height
  ) {
    imageUrl += `&crop=${crop.x},${crop.y},${width},${height}`;
  }

  if (quality) {
    imageUrl += imageUrl.includes('?') ? `&qlt=${quality}` : `?qlt=${quality}`;
  }

  return imageUrl;
};

export default getImages;
