import css from 'styled-jsx/css';
import { colors, typography } from '../../../../config/styles';

const ProductSpecificationsDesktopStyle = css`
  .product-specifications {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    &-column {
      width: 50%;
      padding-left: 30px;
      &:first-child {
        padding-left: 0;
      }
      &:empty {
        width: auto;
        padding-left: 0;
        margin-left: -30px;
      }

      span {
        font-size: 14px;
        b {
          font-weight: bold;
          margin-left: 2px;
        }
      }
    }
    .fa--product-specifications-column__desktop {
      display: flex;
      flex-direction: column;
    }
  }

  .price {
    margin-bottom: 16px;

    &.withMultipurposeBadge {
      margin-bottom: 16px;
    }

    @mixin desktop {
      border-color: ${colors.gray.shade3};
    }
  }

  .addToCart-container {
    margin-bottom: 24px;
    :global(.csicon-arrow_down_small, .csicon-arrow_right) {
      margin-left: 9px;
      font-size: ${typography.xs2};
    }
  }

  .floorCalc-pdp-container {
    margin-bottom: 8px;
  }
  .quantity-selector {
    margin-top: 24px;
    margin-bottom: 8px;
  }
  .multipurpose-badge {
    width: 100%;
    margin-bottom: 24px;
  }
  .product-specifications-out-of-stock {
    .price {
      padding: 16px 0;
      margin: 0;
    }
  }
  .delivery-options-wrapper {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(29, 29, 29, 0.1);
  }

  .meatstickers-container {
    margin-bottom: 16px;
  }

  .dlv-opt-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
  }
  .so-border {
    border: 1px solid ${colors.white.shade4};
  }
  .mkp-border {
    border: 1px solid ${colors.green.shade24};
  }
  .services-divider {
    border-bottom: 1px solid ${colors.white.shade4};
  }
  .whitelabel {
    border: none;
    box-shadow: none;
  }
`;

export { ProductSpecificationsDesktopStyle };
