import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import httpService from '../../../../utils/httpService';
import apiConfig from '../../../../config/api/apiConfig';
import { withProduct } from '../../../../utils/ProductContext';
import _ from '../../../../utils/LodashImports';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import { withLabels } from '../../../../utils/LabelsContext';
import SelectInput from '../../../ui/SelectInput';
import { ShippingLocationSelectionStyle } from './ShippingLocationSelection.style';
import noop from '../../../../utils/noop';
import constants from '../../../../config/constants';
import WebStorage from '../../../../utils/WebStorage';

const getInitialSelection = (type) => {
  const key = type === 'HomeDelivery' ? constants.LS_HOME_DELIVERY_KEY : constants.LS_STORE_PICKUP_KEY;
  const selection = WebStorage.getItem({ name: key, fromPersistence: true });

  if (selection) {
    return JSON.parse(selection);
  }

  return false;
};

const ShippingLocationSelection = ({ appCtx, onChange, setLoading, type, setError, labels }) => {
  const [regionsList, setRegionsList] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [communas, setCommunas] = useState([]);
  const [selectedCommuna, setSelectedCommuna] = useState('');
  const [cuidads, setCuidads] = useState([]);
  const [selectedCuidad, setSelectedCuidad] = useState('');
  const [initialSelection, setInitialSelection] = useState(getInitialSelection(type));

  const { regionCode } = appCtx;

  const getRegions = async () => {
    const url = apiConfig.getRegionsFromGeoApi(regionCode);
    const response = await httpService().get(url, {
      headers: {},
      includeTracing: false,
    });
    setRegionsList(_.get(response, 'data.data', []));
    setSelectedRegion(_.get(initialSelection, 'state', ''));
  };

  const communaCuidadFetchHandler = async (query, setDataHandler, politicalId = '') => {
    const countyPoliticalId = regionCode !== 'cl' ? selectedCuidad : '';
    const url = apiConfig.getCommunasFromGeoApi(regionCode, politicalId, countyPoliticalId);
    const response = await httpService().get(url, {
      headers: {},
      includeTracing: false,
    });

    const data = _.get(response, 'data.data', []);
    setDataHandler(data);
    return data;
  };

  const andesCuidadFetchHandler = async (setDataHandler, politicalId = '') => {
    const url = apiConfig.getProvincesFromGeoApi(regionCode, politicalId);
    const response = await httpService().get(url, {
      headers: {},
      includeTracing: false,
    });

    const data = _.get(response, 'data.data', []);
    setDataHandler(data);
    return data;
  };

  const getCommunas = async () => {
    const query = regionCode === 'cl' ? `?stateCode=${selectedRegion}` : `?cityCode=${selectedCuidad}`;
    const selectedRegionObj = regionsList.find(({ politicalId }) => politicalId === selectedRegion);
    const politicalId = _.get(selectedRegionObj, 'politicalId', '');
    const communaList = await communaCuidadFetchHandler(query, setCommunas, politicalId);
    setSelectedCommuna(_.get(initialSelection, 'municipal', ''));

    if (initialSelection) {
      const municipalCode = _.get(initialSelection, 'municipal', '');
      const communa = communaList.find((obj) => obj.politicalId === municipalCode);
      onChange({
        state: selectedRegion,
        city: selectedCuidad,
        municipal: municipalCode,
        displayName: `A ${_.get(communa, 'name', '')}`,
        lat: _.get(communa, 'latitude', ''),
        long: _.get(communa, 'longitude', ''),
      });
    }
  };

  const getCuiDad = async () => {
    await andesCuidadFetchHandler(setCuidads, selectedRegion);
    setSelectedCuidad(_.get(initialSelection, 'city', ''));
  };

  const regionChangeHandler = (region) => {
    setInitialSelection(false);
    setSelectedRegion(region.politicalId);

    onChange('');
    setError('');
  };

  const communaChangeHandler = (communa) => {
    setInitialSelection(false);
    const municipal = communa.politicalId;
    setSelectedCommuna(municipal);

    onChange({
      state: selectedRegion,
      city: selectedCuidad,
      municipal,
      displayName: `A ${communa.name}`,
      lat: communa.latitude,
      long: communa.longitude,
    });
  };

  const cuidadChangeHandler = (region) => {
    setInitialSelection(false);
    setSelectedCuidad(region.politicalId);
  };

  const callWithLoader = async (fn) => {
    setLoading(true);
    await fn();
    setLoading(false);
  };

  useEffect(() => {
    if (selectedRegion) {
      if (regionCode === 'cl') {
        callWithLoader(getCommunas);
      } else {
        callWithLoader(getCuiDad);
      }
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedCuidad) {
      callWithLoader(getCommunas);
    }
  }, [selectedCuidad]);

  useEffect(() => {
    callWithLoader(getRegions);
  }, []);

  const regionText = labels.REGION;
  const ciudadText = labels.CIUDAD;
  const comunaText = labels.COMUNA;

  return (
    <div className="formContainer">
      <SelectInput
        key={regionText}
        onSelectChange={regionChangeHandler}
        fieldName={regionText}
        defaultValue={selectedRegion || 'default'}
        fieldOptions={regionsList}
        fieldKey="politicalId"
      />

      {!(regionCode === 'cl') && (
        <SelectInput
          key={ciudadText}
          onSelectChange={cuidadChangeHandler}
          fieldName={ciudadText}
          defaultValue={selectedCuidad || 'default'}
          fieldOptions={cuidads}
          disabled={cuidads.length <= 0}
          fieldKey="politicalId"
        />
      )}

      <SelectInput
        key={comunaText}
        onSelectChange={communaChangeHandler}
        fieldName={comunaText}
        defaultValue={selectedCommuna || 'default'}
        fieldOptions={communas}
        disabled={communas.length <= 0}
        fieldKey="politicalId"
      />

      <style jsx>{ShippingLocationSelectionStyle}</style>
    </div>
  );
};

ShippingLocationSelection.defaultProps = {
  onChange: noop,
  setLoading: noop,
  appCtx: {},
  type: '',
  setError: noop,
  labels: {
    REGION: 'Region',
    CIUDAD: 'Cuidad',
    COMUNA: 'Communa',
  },
};

ShippingLocationSelection.propTypes = {
  appCtx: PropTypes.object,
  onChange: PropTypes.func,
  setLoading: PropTypes.func,
  type: PropTypes.string,
  setError: PropTypes.func,
  labels: PropTypes.object,
};

export default withProduct(withLabels(withApplicationContext(ShippingLocationSelection)));

export { ShippingLocationSelection };
