import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../utils/LabelsContext';

import { withApplicationContext } from '../../utils/ApplicationContext';
import { withProduct } from '../../utils/ProductContext';

import { svgToDataUri } from '../../utils/styles/svgMixins';
import { CmrOptionLabelStyle } from './CmrOptionLabel.style';

const CmrOptionLabel = ({ labels, cmrPoints, cmrPuntosIcons, cmrPuntosTextIcon }) => {
  return (
    <div className="new-cmr-points-container">
      <img alt="cmricon" className="cmr-icon" src={svgToDataUri(cmrPuntosIcons)} />
      <div className="box-text">
        <span className="accum-text">{labels.ACCUMULATE}</span>
        <div className="box-points">
          <span className="cmr-points-text">{cmrPoints}</span>
          <img alt="cmr-text-icon" className="cmr-text-icon" src={svgToDataUri(cmrPuntosTextIcon)} />
        </div>
      </div>
      <style jsx>{CmrOptionLabelStyle}</style>
    </div>
  );
};

CmrOptionLabel.defaultProps = {
  labels: {},
  cmrPoints: '',
  cmrPuntosIcons: '',
  cmrPuntosTextIcon: '',
};

CmrOptionLabel.propTypes = {
  labels: PropTypes.object,
  cmrPoints: PropTypes.string,
  cmrPuntosIcons: PropTypes.string,
  cmrPuntosTextIcon: PropTypes.string,
};

export default withApplicationContext(withProduct(withLabels(CmrOptionLabel)));
