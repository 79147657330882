import _ from 'lodash';
import React from 'react';
import css from 'styled-jsx/css';
import constants from '../../config/constants';
import { colors } from '../../config/styles';
import { CURRENCY_MAPPER, formatters } from '../../utils/PriceHelpers';

const shippingEstimatedPriceStyles = css`
  .arrival-label {
    font-weight: 700;
    color: ${colors.green.shade25};
  }
`;

function hasValidShippingEstimatedPrice(shippingEstimatedPrice) {
  const deliveryCost = shippingEstimatedPrice?.deliveryCost;
  if (!deliveryCost) {
    return false;
  }

  const { centAmount, fraction, currency } = deliveryCost;
  return Boolean(centAmount && fraction && currency);
}

function getFormattedShippingEstimatedPrice(shippingEstimatedPrice, variant) {
  const { centAmount, fraction, currency } = shippingEstimatedPrice?.deliveryCost ?? {};
  const label = variant?.meatStickers[0]?.label || 'Llega mañana';
  const connector = 'por';
  const formattedPrice = formatters.DOT_SEPERATOR(centAmount / fraction);

  return (
    <>
      <span className="estimated-shipping-price">
        <span className="arrival-label">{label}</span> {connector} {CURRENCY_MAPPER[currency] || ''}
        {formattedPrice}
      </span>
      <style jsx>{shippingEstimatedPriceStyles}</style>
    </>
  );
}

const getMetaKey = (shippingOption, isSodimacStandAlone) => {
  switch (shippingOption) {
    case constants.AVAILABILITY_HOMEDELIVERY: {
      return {
        labelsKey: {
          title: 'HOME_DELI_TITLE',
          so_title: 'HOME_DELI_SO_TITLE',
          outOfStock: 'HOME_DELI_OUTOFSTOCK',
          subPrefix: 'HOME_DELI_SUB_PREFIX',
          pleaseSelect: 'HOME_DELI_STORE_PLEASE_SELECT',
          seeMoreOptions: 'HOME_DELI_SEE_MORE_OPTIONS',
          seeAvailability: 'HOME_DELI_SEE_AVAILABILITY',
          localStorageRegionKey: 'REGION_S',
          localStorageComunaKey: 'COMUNA_S',
          localStorageCiudadKey: 'CIUDAD_S',
          localAvailableTextDisplayKey: 'COMUNA_S',
        },
        iconsClass: {
          outOfStock: 'deliveryNotAvailable',
          inStock: isSodimacStandAlone ? 'so-deliveryAvailable' : 'deliveryAvailable',
        },
      };
    }
    case constants.AVAILABILITY_PICKUPINSTORE: {
      return {
        labelsKey: {
          title: 'PICKUP_INSTORE_TITLE',
          so_title: 'PICKUP_INSTORE_SO_TITLE',
          subPrefix: 'PICKUP_INSTORE_SUB_PREFIX',
          outOfStock: 'PICKUP_INSTORE_OUTOFSTOCK',
          pleaseSelect: 'PICKUP_INSTORE_PLEASE_SELECT',
          seeMoreOptions: 'PICKUP_INSTORE_SEE_MORE_OPTIONS',
          seeAvailability: 'PICKUP_INSTORE_SEE_AVAILABILITY',
          localStorageRegionKey: 'REGION_PU',
          localStorageComunaKey: 'COMUNA_PU',
          localAvailableTextDisplayKey: 'STORE_PU',
        },
        iconsClass: {
          outOfStock: 'pickupInStoreNotAvailable',
          inStock: isSodimacStandAlone ? 'so-pickupInStoreAvailable' : 'pickupInStoreAvailable',
        },
      };
    }
    case constants.AVAILABILITY_PURCHASEONLINE: {
      return {
        labelsKey: {
          title: 'PURCHASE_ONLINE_TITLE',
          outOfStock: 'PURCHASE_ONLINE_OUTOFSTOCK',
        },
        iconsClass: {
          inStock: isSodimacStandAlone ? 'so-purchaseOnlineAvailable' : 'purchaseOnlineAvailable',
          outOfStock: 'purchaseOnlineNotAvailable',
        },
      };
    }
    default: {
      return {};
    }
  }
};

const getSelectionText = ({
  isNewDeliveryOptionsEnabled,
  selectedDeliveryOption,
  comunaName,
  labels,
  labelsKey,
  selectedCommuna,
  displayText,
  isSodimacStandAlone,
  shippingEstimatedPrice = {},
  variant,
  shippingOptionType,
}) => {
  if (isNewDeliveryOptionsEnabled) {
    if (
      hasValidShippingEstimatedPrice(shippingEstimatedPrice) &&
      _.isEmpty(selectedDeliveryOption) &&
      shippingOptionType === 'HomeDelivery'
    ) {
      return getFormattedShippingEstimatedPrice(shippingEstimatedPrice, variant);
    }
    if (selectedDeliveryOption && !_.isEmpty(selectedDeliveryOption)) {
      return '';
    }
    if (comunaName) {
      return labels[`${labelsKey.seeAvailability}`];
    }
    return labels[`${isSodimacStandAlone ? labelsKey.so_title : labelsKey.title}`];
  }
  return selectedCommuna || displayText || labels[`${labelsKey.pleaseSelect}`];
};

export {
  getFormattedShippingEstimatedPrice,
  getMetaKey,
  getSelectionText,
  hasValidShippingEstimatedPrice,
  shippingEstimatedPriceStyles,
};
