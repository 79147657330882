import css from 'styled-jsx/css';
import { svgToDataUri } from '../../../../utils/styles/svgMixins';
import { playIcon } from '../../ImageGallery.style';

const ImageGalleryMobileVideoStyles = css`
  .video-headline {
    position: relative;
    flex-shrink: 0;
    width: 100vw;
    height: 100%;

    span.video-icon {
      position: absolute;
      width: 49px;
      height: 49px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url('${svgToDataUri(playIcon)}');

      @mixin desktop {
        width: 63px;
        height: 63px;
      }
    }

    .video-thumbnail {
      height: 100%;
      width: 100%;
    }
  }

  .video-frame {
    width: 100%;
    height: 100%;
  }

  .video-preview {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &.showVideo {
      display: none;
    }
  }
`;

export { ImageGalleryMobileVideoStyles };
