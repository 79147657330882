import _ from '../LodashImports';
import constants from '../../config/constants';

const isConnectProduct = (product) => {
  const viewTemplate = _.get(product, 'attributes.viewTemplate', 'REGULAR');
  return viewTemplate === 'CONNECT';
};

const isAnInternationalPhone = (product, labels) => {
  const productMerchantCategoryId = _.get(product, 'merchantCategoryId', '');
  const validCategoriesArr = _.get(labels, 'VALID_CATEGORIES_FOR_INTERNATIONAL_PHONES', 'J11050701').split(',');
  return validCategoriesArr.includes(productMerchantCategoryId);
};

const getM2ArrtibuteValue = (attributes) => {
  const m2Attribute = _.get(attributes, 'specifications', []).filter((val) => val.name === 'Rendimiento por caja');
  if (m2Attribute.length === 0) {
    return -1;
  }
  const m2ValueStrSplit = m2Attribute[0].value.split(' ');
  const m2Value = m2ValueStrSplit.length > 0 ? parseFloat(m2ValueStrSplit[0].replace(',', '.')) : -1;
  if (m2Value && !Number.isNaN(m2Value)) {
    return m2Value;
  }
  return -1;
};

const getProductCategory = (product, categoryInitialLetter = 'J') => {
  const attributes = _.get(product, 'attributes', {});
  const sortedCategoriesRelevance = ['subclase', 'clase', 'sublinea', 'linea'];
  if (categoryInitialLetter === 'G') {
    sortedCategoriesRelevance.push('GSCCategoryId');
  }
  const startWithRegex = new RegExp(`^${categoryInitialLetter}`);
  let result = '';
  sortedCategoriesRelevance.forEach((categoryName) => {
    const categoryValue = attributes[categoryName];
    if (!result && categoryValue && startWithRegex.test(categoryValue)) {
      result = categoryValue;
    }
  });
  return result;
};

const getPriceForOutOfStock = (priceList) => {
  return priceList
    .filter((elem, idx) => {
      return idx === priceList.length - 1;
    })
    .map((elem) => {
      return {
        ...elem,
        crossed: false,
      };
    });
};

const checkSpecification = ({ product, specId, specValue }) => {
  const colorSpec = _.get(product, 'attributes.specifications', []).find((spec) => spec.id === specId);
  if (!colorSpec) {
    return undefined;
  }
  return colorSpec.value === specValue;
};
const isReaconditionedProduct = (product) => {
  const isReaconditioned = product.attributes.specifications?.some((spec) => spec.id === '10_condicion_del_producto');
  return isReaconditioned;
};
const getReaconditionedValues = (product) => {
  const filterData = product.attributes.specifications.find((spec) => spec.id === '10_condicion_del_producto');
  if (!filterData) {
    return null;
  }

  const condition = {
    name: constants.REACONDITIONED[filterData.value]?.toLowerCase(),
  };
  return condition;
};

export {
  isConnectProduct,
  getM2ArrtibuteValue,
  getProductCategory,
  getPriceForOutOfStock,
  checkSpecification,
  isAnInternationalPhone,
  isReaconditionedProduct,
  getReaconditionedValues,
};
