import PropTypes from 'prop-types';
import React from 'react';
import MatchMedia from '../../../ui/MatchMedia/MatchMedia';
import SizeButton from '../SizeButton/SizeButton';
import EquilancyTable from './EquilancyTable/EquilancyTable';
import { cssTriangleStyles } from './SizeEquivalencies.style';

const SizeEquivalencies = ({ value, available, index, onSizeChange, selectedSize, sizeMap, regionCode }) => {
  const tenantSize = regionCode.toUpperCase();

  const EquilancyTableForMobile = () => (
    <>
      <div className="pointer rebranded" />
      <EquilancyTable isMobile sizeMap={sizeMap} tenantValue={value} tenantSize={tenantSize} />
      <style jsx>{cssTriangleStyles}</style>
    </>
  );

  const isSelectedSize = (val) => selectedSize && val.toUpperCase() === selectedSize.toUpperCase();

  return (
    <div className="button-wrapper">
      <SizeButton
        value={value}
        available={available}
        selectedSize={selectedSize}
        index={index}
        onSizeChange={onSizeChange}
      />
      {isSelectedSize(value) && sizeMap && (
        <MatchMedia
          content={{
            mobile: () => <EquilancyTableForMobile />,
            tablet: () => <EquilancyTableForMobile />,
            desktop: () => <EquilancyTable sizeMap={sizeMap} tenantValue={value} tenantSize={tenantSize} />,
          }}
        />
      )}
    </div>
  );
};

SizeEquivalencies.defaultProps = {
  sizeMap: undefined,
};

SizeEquivalencies.propTypes = {
  value: PropTypes.string.isRequired,
  available: PropTypes.bool.isRequired,
  selectedSize: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  onSizeChange: PropTypes.func.isRequired,
  sizeMap: PropTypes.object,
  regionCode: PropTypes.object.isRequired,
};

export default SizeEquivalencies;
