import css from 'styled-jsx/css';
import { colors, icons, typography } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const accordionStyle = css`
  .accordion {
    background-color: ${colors.white.shade1};
    max-width: 100vw;
  }
`;

const accordionItemStyle = css`
  li {
    padding: 9px 0;
    list-style-type: none;
    .csicon-shield {
      font-size: 24px;
    }
    &.withBorder {
      border-top: none;
      border-bottom: 1px solid ${colors.gray.shade46};
      border-radius: 3px;
      background-color: ${colors.white.shade1};
      padding: 12px 16px 12px 24px;
      @mixin tabletToDesktop {
        border-top: 1.1px solid ${colors.gray.shade36};
        border-bottom: 1.1px solid ${colors.gray.shade36};
      }
      &.isCart {
        border: none;
      }
    }
    &.withBorderBottom {
      position: relative;
      &::after {
        content: '';
        display: flex;
        position: absolute;
        bottom: 0;
        left: -14px;
        width: calc(100% + 14px);
        border-bottom: 1px solid ${colors.green.shade1};
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    &.expanded {
      background-color: ${colors.gray.shade32};
    }
    button {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .container {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .logo {
          color: ${colors.green.shade1};
          text-align: center;
          font-size: ${typography.xl2b};
          &.rebranded {
            color: ${colors.gray.shade45};
            .csicon-disponibilidad {
              @mixin mobileToTablet {
                font-size: 2.7rem;
              }
            }
            .icon-helpline-rebranded {
              display: inline-block;
              width: 32px;
              height: 32px;
              background-image: url('${svgToDataUri(icons.helplineRebranded)}');
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              &::before {
                content: '';
              }
            }
            .combos-heading-logo {
              display: inline-block;
              height: 32px;
              width: 32px;
              background-image: url('${svgToDataUri(icons.comboItemsHeadingIcon)}');
              background-size: contain;
              background-position: center;
              background-repeat: no-repeat;
              &::before {
                content: '';
              }
            }
            .csicon-preferences {
              @mixin mobileToTablet {
                background-image: url('${svgToDataUri(icons.servicesIconRebranded)}');
                width: 32px;
                height: 32px;
                display: inline-block;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                position: relative;
                top: 3px;
                left: 1px;
                &::before {
                  content: '';
                }
              }
            }
            .csicon-shield {
              @mixin mobileToTablet {
                display: block;
                margin: 0 auto;
                width: 32px;
                height: 32px;
                background-image: url('${svgToDataUri(icons.extendedWarrantyRebranded)}');
                background-size: 100%;
                background-position: center;
                &::before {
                  content: '';
                }
              }
            }

            .csicon-calculator {
              @mixin mobileToTablet {
                font-size: 2.5rem;
              }
            }
          }

          &.withPadding {
            padding-right: 14px;
            padding-left: 22px;
          }
        }
        .headingContainer {
          padding-left: 16px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          > * {
            text-align: left;
          }
          &.flexRow {
            flex-direction: row;
            align-items: center;
          }
        }
      }
    }
    .shipping {
      padding-bottom: 4px;
    }
    .icon {
      font-size: ${typography.xxs};
      display: flex;
    }
    div[hidden] {
      display: hidden;
    }
    .csicon-cmr-logo {
      display: block;
      width: 40px;
      height: 24px;
      background-image: url('${svgToDataUri(icons.cmrLogoRebranded)}');
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      &::before {
        content: '';
      }
    }
    .csicon-arrow_down_small {
      min-width: 20px;
      width: 20px;
      height: 20px;
      background-image: url('${svgToDataUri(icons.chevronDownRebranded)}');
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      &::before {
        content: '';
      }
    }
    .csicon-arrow_up_small {
      min-width: 20px;
      width: 20px;
      height: 20px;
      background-image: url('${svgToDataUri(icons.chevronDownRebranded)}');
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
      transform: rotate(180deg);
      &::before {
        content: '';
      }
    }
  }
`;

export { accordionStyle, accordionItemStyle };
