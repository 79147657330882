import PropTypes from 'prop-types';
import React from 'react';
import BodyCopy from '../../components/ui/BodyCopy/BodyCopy';
import MatchMedia from '../../components/ui/MatchMedia/MatchMedia';
import { Modal } from '../../components/ui/Modal/Modal';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withDigitalData } from '../../utils/DigitalDataContext';
import _ from '../../utils/LodashImports';
import ReturnPolicyStyles from './ReturnPolicy.style';
import ReturnPolicyModalContent from './ReturnPolicyModal/ReturnPolicyModal';

const ReturnPolicyDesktop = ({
  returnPolicy,
  forMobile,
  deviceType,
  textDictionary,
  toggleState,
  setToggleState,
  regionCode,
  darSectionActive,
}) => {
  return (
    <div>
      <div className="return-policy-container" />
      <div className="return-policy-custom-container">
        <div className="returnIcon rebranded" />
        <div className="return-policy-text">
          <BodyCopy size="copy3" type="senary">
            <span
              className={`header ${darSectionActive ? 'dar-section' : ''}`}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: returnPolicy.headerLabel }}
            />
          </BodyCopy>
          <BodyCopy size={`${forMobile ? 'copy5' : 'copy3'}`} type="senary">
            <button
              type="button"
              id="testId-more-information-btn"
              className="open-modal-btn"
              onClick={() => setToggleState(!toggleState)}
            >
              <span>{returnPolicy.linkLabel}</span>
              <i className="csicon-right" />
            </button>
          </BodyCopy>
        </div>
      </div>
      <Modal
        content={(actions) => (
          <ReturnPolicyModalContent
            actions={actions}
            textDictionary={textDictionary}
            url={returnPolicy.linkURL}
            deviceType={deviceType}
            tenant={regionCode}
          />
        )}
        options={{
          height: 530,
          width: 500,
          overflow: 'hidden',
        }}
        withPadding={false}
        closeBtnStyles={{ top: '23px', right: '25px' }}
        deviceType={deviceType}
        toggleState={{
          visible: toggleState,
          setVisible: (bool) => setToggleState(bool),
        }}
      />
      <style jsx>{ReturnPolicyStyles}</style>
    </div>
  );
};

ReturnPolicyDesktop.defaultProps = {
  forMobile: false,
  toggleState: false,
};

ReturnPolicyDesktop.propTypes = {
  returnPolicy: PropTypes.string.isRequired,
  forMobile: PropTypes.bool,
  textDictionary: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  toggleState: PropTypes.bool,
  setToggleState: PropTypes.func.isRequired,
  regionCode: PropTypes.string.isRequired,
  darSectionActive: PropTypes.bool.isRequired,
};

const ReturnPolicyMobile = ({
  returnPolicy,
  deviceType,
  textDictionary,
  toggleState,
  setToggleState,
  regionCode,
  darSectionActive,
}) => (
  <div>
    <div className="return-policy-container" />
    <div className="return-policy-custom-container mobile-padding">
      <div className="returnIcon rebranded">
        <i className="return-icon-mkp" />
      </div>
      <div className="return-policy-text rebranded">
        <BodyCopy size="copy3" type="senary">
          <span
            className={`header-mobile ${darSectionActive ? 'dar-section' : ''}`}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: returnPolicy.headerLabel }}
          />
        </BodyCopy>
        <BodyCopy size="copy3" type="senary">
          <button
            type="button"
            className="open-modal-btn"
            id="testId-more-information-btn"
            onClick={() => setToggleState(!toggleState)}
          >
            <span>{returnPolicy.linkLabel}</span>
            <i className="csicon-right" />
          </button>
        </BodyCopy>
      </div>
    </div>
    <Modal
      content={(actions) => (
        <ReturnPolicyModalContent
          actions={actions}
          textDictionary={textDictionary}
          url={returnPolicy.linkURL}
          deviceType={deviceType}
          tenant={regionCode}
        />
      )}
      withPadding={false}
      fullScreen
      stopScroll
      closeBtnStyles={{ top: '16px', right: '20px', fontSize: '16px' }}
      deviceType={deviceType}
      options={{ overflow: 'hidden', disableOutsideClick: true }}
      toggleState={{
        visible: toggleState,
        setVisible: (bool) => setToggleState(bool),
      }}
    />
    <style jsx>{ReturnPolicyStyles}</style>
  </div>
);

ReturnPolicyMobile.defaultProps = {
  toggleState: false,
};

ReturnPolicyMobile.propTypes = {
  returnPolicy: PropTypes.string.isRequired,
  textDictionary: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  toggleState: PropTypes.bool,
  setToggleState: PropTypes.func.isRequired,
  regionCode: PropTypes.string.isRequired,
  darSectionActive: PropTypes.bool.isRequired,
};

const ReturnPolicy = ({ returnPolicy, darSectionActive, appCtx, digitalData }) => {
  const { deviceType, regionCode } = appCtx;
  const textDictionary = _.get(appCtx, 'siteConfig.textDictionary', {});
  digitalData.pdp.setFreeReturns(!!returnPolicy);
  const [toggleState, setToggleState] = React.useState(false);

  if (!returnPolicy) {
    return null;
  }

  return (
    <MatchMedia
      content={{
        desktop: () => (
          <ReturnPolicyDesktop
            returnPolicy={returnPolicy}
            deviceType={deviceType}
            textDictionary={textDictionary}
            toggleState={toggleState}
            setToggleState={setToggleState}
            regionCode={regionCode}
            darSectionActive={darSectionActive}
          />
        ),
        mobile: () => (
          <ReturnPolicyMobile
            returnPolicy={returnPolicy}
            deviceType={deviceType}
            textDictionary={textDictionary}
            toggleState={toggleState}
            setToggleState={setToggleState}
            regionCode={regionCode}
            darSectionActive={darSectionActive}
          />
        ),
        tablet: () => (
          <ReturnPolicyMobile
            returnPolicy={returnPolicy}
            deviceType={deviceType}
            textDictionary={textDictionary}
            toggleState={toggleState}
            setToggleState={setToggleState}
            regionCode={regionCode}
            darSectionActive={darSectionActive}
          />
        ),
      }}
    />
  );
};

ReturnPolicy.defaultProps = {
  darSectionActive: false,
};

ReturnPolicy.propTypes = {
  returnPolicy: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  digitalData: PropTypes.object.isRequired,
  darSectionActive: PropTypes.bool,
};

export default withApplicationContext(withDigitalData(ReturnPolicy));
