import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import Button from '../ui/Button/Button';
import Drawer from '../ui/Drawer/Drawer';
import { Modal } from '../ui/Modal/Modal';
import _ from '../../utils/LodashImports';
import WarrantySelectEnhanced from '../ui/WarrantySelect/WarrantySelectEnhanced';
import WarrantyModalStyles from './WarrantyModal.style';
import noop from '../../utils/noop';

const WarrantyModal = ({ appCtx, options, showModal, setShowModal, warrantyOptionCurrent, handleWarrantyChange }) => {
  const { CANCEL = 'Cancelar', ACCEPT_MKP = 'Aceptar' } = _.get(appCtx, 'siteConfig.textDictionary', {});
  const [warrantyOption, setWarrantyOption] = useState(warrantyOptionCurrent);

  useEffect(() => {
    setWarrantyOption(warrantyOptionCurrent);
  }, [showModal]);

  const handleWarrantySelect = (selectedOption) => {
    setWarrantyOption(selectedOption);
  };
  const onSubmit = () => {
    if (warrantyOption) {
      handleWarrantyChange({ ...warrantyOption, selected: true });
    }
    setShowModal(false);
  };

  const getContent = () => {
    return (
      <>
        <WarrantySelectEnhanced
          appCtx={appCtx}
          options={options}
          warrantyOptionCurrent={warrantyOption}
          handleWarrantyOptionsSelected={handleWarrantySelect}
        />
        <div className="sub-footer">
          <button type="button" className="cancel" onClick={() => setShowModal(false)}>
            {CANCEL}
          </button>
          <div className="submit">
            <Button type="mkp-secondary" onClick={onSubmit}>
              {ACCEPT_MKP}
            </Button>
          </div>
        </div>
        <style jsx>{WarrantyModalStyles}</style>
      </>
    );
  };
  return appCtx.deviceType === 'desktop' ? (
    <Modal
      content={getContent}
      options={{
        disableOutsideClick: true,
        padding: '24px',
        overflow: 'hidden',
        width: 968,
        height: 'fit-content',
        borderRadius: '12px',
      }}
      withPadding={false}
      toggleState={{
        visible: showModal,
        setVisible: setShowModal,
      }}
    />
  ) : (
    <Drawer toggleState={[showModal, setShowModal]} isLoading={false} type="secondary" icon="warranty-icon">
      <div className="drawer-content">
        <WarrantySelectEnhanced
          appCtx={appCtx}
          options={options}
          warrantyOptionCurrent={warrantyOption}
          handleWarrantyOptionsSelected={handleWarrantySelect}
          source="drawer"
        />
        <div className="drawer-sub-footer">
          <div className="submit">
            <Button type="mkp-secondary" onClick={onSubmit}>
              {ACCEPT_MKP}
            </Button>
          </div>
          <button
            type="button"
            className="cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            {CANCEL}
          </button>
        </div>
        <style jsx>{WarrantyModalStyles}</style>
      </div>
    </Drawer>
  );
};

WarrantyModal.defaultProps = {
  showModal: false,
  setShowModal: noop,
  warrantyOptionCurrent: null,
  handleWarrantyChange: noop,
  options: [],
  appCtx: {},
};

WarrantyModal.propTypes = {
  warrantyOptionCurrent: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  handleWarrantyChange: PropTypes.func,
  options: PropTypes.array,
  appCtx: PropTypes.object,
};

export default WarrantyModal;
