import css from 'styled-jsx/css';

const ServicesModalStyles = css`
  .drawer-sub-footer {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .sub-footer {
    display: flex;
    justify-content: flex-end;
    gap: 4px;
    align-items: center;
    width: fit-content;
    justify-self: flex-end;
    padding: 12px 0;
    & :global(button) {
      align-self: center;
      width: auto;
    }
    & :global(button:last-child) {
      align-self: center;
      width: auto;
      padding: 0 20px;
    }
  }
  .cancel {
    display: flex;
    height: 40px;
    padding: 0 32px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
  }
  .drawer-content {
    padding: 8px 20px 20px 20px;
  }
`;

export default ServicesModalStyles;
