import css from 'styled-jsx/css';
import { colors, icons } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const ProductServicesStyles = css`
  .services-popup {
    cursor: initial;
    height: 100%;
    width: 100%;
    background-color: ${colors.white.shade1};
    display: flex;
    flex-direction: column;
  }
  .services-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    padding: 12px 0;
    gap: 12px;

    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;

      .service-icon {
        display: flex;
        height: 20px;
        min-width: 20px;
        background-image: url('${svgToDataUri(icons.servicesIcon)}');
        background-size: 100%;
        background-position: center;
      }
    }
    .service-sku {
      display: flex;
      gap: 12px;
      padding: 0 4px;
      justify-content: space-between;
      width: 100%;

      .label {
        display: flex;
        gap: 8px;

        .service-name {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
        }
        .checkbox {
          display: flex;
          height: 20px;
          min-width: 20px;
          background-size: 100%;
          background-position: center;

          &.full {
            background: ${colors.gray.shade64};
            border-radius: 4px;
            background-image: url('${svgToDataUri(icons.filledCheckBox)}');
          }

          &.empty {
            background-image: url('${svgToDataUri(icons.emptyCheckBox)}');
          }
        }
      }
      .service-price {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
      }
    }
    .more-service-options {
      font-size: 12px;
      font-weight: 700;
      color: ${colors.gray.shade64};
      line-height: 16px;
      border-bottom: 1px solid;
    }
  }
`;

export { ProductServicesStyles };
