/* eslint no-console: 0, no-underscore-dangle: 0 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import ProductsCarouselPod from './ProductsCarouselPod/ProductsCarouselPod';
import Carousel from '../ui/Carousel/Carousel';
import { ProductCarouselStyles } from './ProductsCarousel.style';
import Heading from '../ui/Heading/Heading';
import { mapATGPricesToCatalystPrices } from '../../utils/PriceHelpers';
import { withApplicationContext } from '../../utils/ApplicationContext';
import _ from '../../utils/LodashImports';
import noop from '../../utils/noop';

const ProductsCarousel = ({
  products,
  appCtx,
  heading,
  subHeading,
  carouselPosition,
  parentVariantID,
  handleViewedProducts,
  isSponsored,
  sponsoredClick,
}) => {
  const uniqueViewedProducts = new Set();
  const { regionCode } = appCtx;
  const toggles = _.get(appCtx, 'siteConfig.toggles', {});
  const carouselRecoRef = React.useRef(null);

  const mProducts = products.map((product) => ({
    ...product,
    prices: mapATGPricesToCatalystPrices(product.prices, regionCode, toggles),
  }));

  const aggregateViewedProducts = (id) => {
    if (isSponsored && uniqueViewedProducts.has(id)) return false;
    uniqueViewedProducts.add(id);
    const payloadTitle = heading.split(' ').join('_');
    const skus = Array.from(uniqueViewedProducts).join(',');

    handleViewedProducts(payloadTitle, skus);

    return false;
  };
  const renderProductsCarouselPod = () => {
    return mProducts.map((item, index) => (
      <ProductsCarouselPod
        key={item.productId}
        product={item}
        appCtx={appCtx}
        aggregateViewedProducts={aggregateViewedProducts}
        details={{
          numberProducts: mProducts.length,
          productPosition: index + 1,
          carouselPosition,
          carouselName: heading,
          parentVariantID,
        }}
        isSponsored={isSponsored}
        sponsoredClick={sponsoredClick}
      />
    ));
  };

  const showRecommendationsWrapper = mProducts.length > 0;
  const showSlideButton = products.length > 6;
  return (
    showRecommendationsWrapper && (
      <div data-widget-name={heading} ref={carouselRecoRef} className="products-carousel-wrapper">
        <div className="products-carousel-heading">
          <Heading variation={isSponsored ? 'title15' : 'title14'} level={2}>
            {heading}
          </Heading>
          <h2 hidden={!subHeading} className="products-carousel-sub-heading">
            {subHeading}
          </h2>
        </div>
        <MatchMedia
          content={{
            mobile: () => (
              <Carousel
                items={renderProductsCarouselPod()}
                itemsPerSlide={2}
                minimumItemWidth={240}
                navButtonsVisible={false}
                isForProductsCarousel
                heading={heading}
                carouselPosition={carouselPosition}
                showSlideButton={showSlideButton}
                showIndictors={!isSponsored}
              />
            ),
            tablet: () => (
              <Carousel
                items={renderProductsCarouselPod()}
                itemsPerSlide={4}
                navButtonsVisible={false}
                isForProductsCarousel
                heading={heading}
                carouselPosition={carouselPosition}
                showSlideButton={showSlideButton}
                showIndictors
              />
            ),
            desktop: () => (
              <Carousel
                items={renderProductsCarouselPod()}
                itemsPerSlide={6}
                navButtonsVisible
                isForProductsCarousel
                heading={heading}
                carouselPosition={carouselPosition}
                showSlideButton={showSlideButton}
                showIndictors
              />
            ),
          }}
        />
        <style jsx>{ProductCarouselStyles}</style>
      </div>
    )
  );
};
ProductsCarousel.defaultProps = {
  handleViewedProducts: noop,
  isSponsored: false,
  sponsoredClick: noop,
};

ProductsCarousel.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  appCtx: PropTypes.object.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  carouselPosition: PropTypes.object.isRequired,
  parentVariantID: PropTypes.number.isRequired,
  handleViewedProducts: PropTypes.func,
  isSponsored: PropTypes.bool,
  sponsoredClick: PropTypes.func,
};

export default withApplicationContext(ProductsCarousel);
export { ProductsCarousel };
