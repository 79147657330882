import { isSodimacStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withDigitalData } from '../../utils/DigitalDataContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { withProduct } from '../../utils/ProductContext';
import { getVariant } from '../../utils/variant';
import ComboSpecifications from '../ComboSection/ComboSpecifications';
import SpecificationTable from '../SpecificationTable/SpecificationTable';
import Drawer from '../ui/Drawer/Drawer';
import ScrollTo from '../ui/ScrollTo/ScrollTo';
import { ProductTopSpecificationsStyle } from './ProductTopSpecifications.style';

const ProductTopSpecifications = ({ product, labels, scollToElementId, digitalData, appCtx }) => {
  const [isDrawerActive, activateDrawer] = React.useState(false);
  const topSpecifications = _.get(product, 'attributes.topSpecifications', []);
  const specifications = _.get(product, 'attributes.specifications', []);
  const { deviceType, store } = appCtx;
  const isSodimacSA = isSodimacStandalone(store);

  let topSpecificationsTitle;
  let viewMoreText;
  let specificationsTitle;

  if (isSodimacSA) {
    topSpecificationsTitle = _.get(labels, 'SO_COM_TOP_SPECIFICATIONS', 'Características principales');
    viewMoreText = _.get(labels, 'SO_COM_VIEW_MORE_SPECIFICATONS', 'Ver más');
    specificationsTitle = _.get(labels, 'SO_COMSPECIFICATIONS_LABEL', 'Ver más');
  } else {
    topSpecificationsTitle = labels.TOP_SPECIFICATIONS;
    viewMoreText = labels.VIEW_MORE_SPECIFICATONS;
    specificationsTitle = labels.SPECIFICATIONS_LABEL;
  }

  const { variants, currentVariant } = product;
  const comboData = _.get(getVariant(variants, currentVariant), 'comboData', null);
  digitalData.pdp.setProductFeatures(topSpecifications.length > 0 ? '2' : '');
  if (topSpecifications.length <= 0) {
    return null;
  }
  return (
    <>
      <div className="specifications-container fa--product-characteristic-features">
        <div className="specifications-title">{topSpecificationsTitle}</div>
        <div className="specifications-list">
          <ul>
            {topSpecifications.map((spec) => (
              <li key={spec.name}>
                <strong>{spec.name}</strong>: {spec.value}
              </li>
            ))}
          </ul>
        </div>
        {deviceType === 'desktop' ? (
          <ScrollTo scrollToElementId={scollToElementId} isSoCom={isSodimacSA} />
        ) : (
          <>
            <button type="button" onClick={() => activateDrawer(true)} className="view-more">
              {viewMoreText}
            </button>
            <Drawer
              toggleState={[isDrawerActive, activateDrawer]}
              heading={specificationsTitle}
              isLoading={false}
              type="secondary"
            >
              {comboData?.hasSpecifications ? (
                <ComboSpecifications comboItems={comboData.comboItems} isDrawer labels={labels} />
              ) : (
                <SpecificationTable specifications={specifications} type="secondary" />
              )}
            </Drawer>
          </>
        )}
      </div>
      {deviceType === 'desktop' && <div className="specifications-divider" />}
      <style jsx>{ProductTopSpecificationsStyle}</style>
    </>
  );
};

ProductTopSpecifications.defaultProps = {
  scollToElementId: null,
};
ProductTopSpecifications.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  scollToElementId: PropTypes.string,
  digitalData: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
};

export { ProductTopSpecifications };
export default withLabels(withProduct(withDigitalData(withApplicationContext(ProductTopSpecifications))));
