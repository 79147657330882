const sizeOrder = {
  'TALLA ÚNICA': 3,
  U: 7,
  XXS: 10,
  XS: 20,
  S: 30,
  M: 40,
  L: 50,
  XL: 60,
  XXL: 70,
  XXXL: 80,
  XXXXL: 90,
};

export default sizeOrder;
