import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';
import _ from '../../../utils/LodashImports';

const linkButtonStyle = (theme) => {
  const secondaryColor = _.get(theme, 'secondary_color', null) || colors.secondary_color;
  const borderRadius = _.get(theme, 'border_radius', '80px');
  return css`
    .button {
      font-family: inherit;
      font-weight: 400;
      font-size: ${typography.sm};
      text-align: center;
      width: 100%;
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 0.075em;
      line-height: 14px;
      appearance: none;
      border: none;
      border-radius: 5px;
      padding: 15px 0;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;

      &.button-rebranding {
        font-weight: 700;
        background-color: ${secondaryColor};
        color: ${colors.white.shade1};
        font-size: ${typography.xla};
        text-transform: none;
        &.button-large {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 56px;
          font-size: ${typography.xla};
          letter-spacing: 0.5px;
          border-radius: 30px;
          &.so_com {
            border-radius: 4px;
          }
          &.whitelabel {
            border-radius: ${borderRadius};
          }
        }
        &.button-medium {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 48px;
          font-size: ${typography.xla};
          letter-spacing: 0.5px;
          border-radius: 30px;
          &.so_com {
            border-radius: 4px;
          }
          &.whitelabel {
            border-radius: ${borderRadius};
          }
        }
        &:hover {
          background-color: ${secondaryColor};
        }
      }
      &.button-primary {
        font-weight: 700;
        background-color: ${colors.green.shade1};
        color: ${colors.white.shade1};
        &.button-large {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 55px;
          font-size: ${typography.base};
          letter-spacing: 1px;
          border-radius: 3px;
          &.so_com {
            border-radius: 4px;
          }
          &.whitelabel {
            border-radius: ${borderRadius};
          }
        }
        &.button-large:hover {
          background-color: ${colors.green.shade1};
        }
        &.button-disabled {
          background-color: ${colors.gray.shade2};
          pointer-events: none;
        }
        &.button-disabled:hover {
          background-color: ${colors.gray.shade2};
        }
      }
      &.button-secondary {
        height: 39px;
        font-size: ${typography.sm1};
        background-color: ${secondaryColor};
        color: ${colors.gray.shade3};
      }
      &.button-tertiary {
        height: 39px;
        font-size: ${typography.sm1};
        border: 1px solid ${colors.gray.shade16};
        background-color: ${colors.gray.shade3};
        color: ${colors.gray.shade15};
      }
      &:hover {
        background: ${colors.green.shade2};
      }
      &.button-quaternary {
        display: inline;
        width: auto;
        color: ${colors.gray.shade40};
        text-transform: none;

        &.button-small {
          font-size: ${typography.xs};
        }

        &.button-medium {
          font-size: ${typography.sm1};
        }

        &:hover {
          background: none;
        }
      }
      &.button-mkp-primary {
        font-weight: 700;
        background-color: ${secondaryColor};
        color: ${colors.white.shade1};
        height: 36px;
        text-transform: none;
        border-radius: 30px;
        line-height: 1.2;
        &.button--medium {
          height: 40px;
          font-size: ${typography.lg2};
        }
        &.button--large {
          height: 44px;
          font-size: ${typography.xla};
        }
        &.button--medium-large {
          height: 48px;
          letter-spacing: 0.5px;
          font-size: ${typography.xla};
        }
        &.button--xtra-large {
          height: 56px;
          letter-spacing: 1px;
          font-size: ${typography.xla};
        }
        &[disabled] {
          background-color: ${colors.gray.shade46};
          color: ${colors.gray.shade47};
        }
        &.so_com {
          border-radius: 4px;
        }
        &.whitelabel {
          border-radius: ${borderRadius};
        }
      }

      &.button-mkp-secondary {
        background-color: ${secondaryColor};
        color: ${colors.white.shade1};
        height: 40px;
        text-transform: none;
        border-radius: 30px;
        line-height: 1.2;
        font-size: ${typography.xla};
        &.button-medium {
          height: 40px;
          padding: 8px 24px 9px;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0.5px;
          border-radius: 20px;
          @mixin tabletToDesktop {
            letter-spacing: 1px;
          }
          &.so_com {
            border-radius: 4px;
          }
          &.whitelabel {
            border-radius: ${borderRadius};
          }
        }
        &.button--large {
          height: 44px;
        }
        &.button--medium-large {
          height: 48px;
        }
        &.button--xtra-large {
          height: 56px;
          letter-spacing: 1px;
        }
        &[disabled] {
          background-color: ${colors.gray.shade46};
          color: ${colors.gray.shade47};
        }
      }
    }
    .link-button {
      text-decoration: none;
      color: inherit;
    }
    .so_com {
      border-radius: 4px;
    }
    .whitelabel {
      border-radius: ${borderRadius};
    }
  `;
};

export { linkButtonStyle };
