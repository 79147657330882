import { withUser } from '@digital-retail/falabella-ui-cross-components';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withCart } from '../../utils/CartContext';
import _ from '../../utils/LodashImports';
import { withProduct } from '../../utils/ProductContext';
import { ReviewSectionDesktopStyles, ReviewSectionMobileStyles } from './ReviewSection.style';

const ReviewMetricsPanel = dynamic(
  () => import('@digital-retail/rating-and-reviews').then((lib) => lib.ReviewMetricsPanel),
  {
    ssr: false,
    loading: () => '',
  }
);
const GiveRatingComponent = dynamic(() => import('@digital-retail/rating-and-reviews').then((lib) => lib.GiveRating), {
  ssr: false,
  loading: () => '',
});
const Reviews = dynamic(() => import('@digital-retail/rating-and-reviews').then((lib) => lib.Reviews), {
  ssr: false,
  loading: () => '',
});
const TopPhotos = dynamic(() => import('@digital-retail/rating-and-reviews').then((lib) => lib.TopPhotos), {
  ssr: false,
  loading: () => '',
});

const ReviewSection = ({ isUserLoggedIn, cart, appCtx }) => {
  const { setShowLoginForm } = cart;
  const { deviceType } = appCtx;
  const reviewSectionStyles = deviceType === 'desktop' ? ReviewSectionDesktopStyles : ReviewSectionMobileStyles;

  const reviewSectionHeading = _.get(
    appCtx,
    'siteConfig.textDictionary.INHOUSE_REVIEW_SECTION_HEADING',
    constants.REVIEW_SECTION_HEADING
  );
  const isUserAllowedToReview = () => {
    if (!isUserLoggedIn) {
      setShowLoginForm(true);
    }
  };

  return (
    <div className="review-section-container" id="InHouseReviewsContainer">
      <div className="review-section-heading">{reviewSectionHeading}</div>
      <div className="review-section-top-container">
        <ReviewMetricsPanel />
        <div className="give-rating-container" aria-hidden="true" onClick={isUserAllowedToReview}>
          <GiveRatingComponent />
        </div>
      </div>
      <div className="top-photos-pdp">
        <TopPhotos />
      </div>
      <Reviews />

      <style jsx>{reviewSectionStyles}</style>
    </div>
  );
};

ReviewSection.defaultProps = {
  isUserLoggedIn: false,
  cart: {},
  appCtx: {},
};

ReviewSection.propTypes = {
  isUserLoggedIn: false,
  cart: PropTypes.object,
  appCtx: PropTypes.object,
};

const ReviewSectionWithContext = withApplicationContext(withCart(withUser(withProduct(ReviewSection))));

export { ReviewSectionWithContext as ReviewSection };
