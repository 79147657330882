import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';
import { svgToDataUri, svgReplaceColor } from '../../../utils/styles/svgMixins';
import icons from '../../../config/styles/icons';

const HardLineDesktopStyles = css`
  .pdp-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    padding-bottom: 20px;
    border-radius: 12px;
  }

  .pdp-body {
    background-color: ${colors.white.shade1};
  }

  .pdp-image-section {
    width: 42%;
    padding: 8px 12px 0 32px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .icon {
    min-width: 34px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: block;
    &.internet-icon-disabled {
      background-image: url('${svgToDataUri(svgReplaceColor(icons.onlyInternet, colors.gray.shade40))}');
      height: 30px;
    }
    &.internet-icon-active {
      background-image: url('${svgToDataUri(svgReplaceColor(icons.onlyInternet, colors.green.shade1))}');
      height: 30px;
    }
    &.tienda-active {
      background-image: url('${svgToDataUri(svgReplaceColor(icons.tiendaIcon, colors.green.shade1))}');
      height: 22px;
    }
    &.tienda-disabled {
      background-image: url('${svgToDataUri(svgReplaceColor(icons.tiendaIcon, colors.gray.shade40))}');
      height: 22px;
    }
  }
  .pdp-detail-section {
    display: flex;
    width: 58%;
    flex-direction: column;
    padding: 0 44px 0 49px;
    border-left: 1px solid ${colors.gray.shade3};
    @mixin phabletToTablet {
      padding: 0 30px 0 30px;
    }
  }

  hr.divider-line {
    margin: 0;
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid ${colors.gray.shade3};
    transform: scaleY(-1);
  }
  .pdp-container.out-of-stock {
    .pdp-image-section {
      padding: 0;
      margin: 25px 112px;
      width: 305px;
      height: 305px;
    }
    .pdp-detail-section {
      margin-top: 8px;
      padding: 0 24px 20px;
    }
  }
`;

export default HardLineDesktopStyles;
