import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import apiConfig from '../../../config/api/apiConfig';
import { isWindow } from '../../../utils/deviceChecker';
import _ from '../../../utils/LodashImports';
import noop from '../../../utils/noop';
import useGoogleMap from '../../../utils/useGoogleMap';
import { capitalizeText } from '../../Pod/podUtils';
import { ModalContent, ModalHeader } from '../CustomModal/CustomModal';
import { getStoreFromName } from '../helper';
import styles from './StoreDetail.style';

const StoreDetail = ({ selectedStore, setScreen, config, type, deviceType, labels, workingHours }) => {
  if (!Object.keys(selectedStore).length) {
    return null;
  }
  const district = _.get(selectedStore, 'address.district', '');
  const state = _.get(selectedStore, 'address.district', '');
  const {
    BACK_TO_STORE_LIST = 'Volver a listado de tiendas',
    OPEN_MAPS = 'Abrir mapa',
    CHECK_SCHEDULE = 'Revisar horario',
  } = labels;

  const getHeight = () => {
    if (isWindow()) {
      const mapElement = document.querySelector('.map-section') || {};
      return `${window.innerHeight - mapElement.offsetTop}px`;
    }
    return '';
  };
  const mapRef = useRef();
  useGoogleMap(mapRef, selectedStore);
  const latLng = {
    lat: _.get(selectedStore, 'address.latitude', 0),
    lng: _.get(selectedStore, 'address.longitude', 0),
  };
  const storeFromName = getStoreFromName(_.get(selectedStore, 'storeName', ''));
  return (
    <>
      {!type && <ModalHeader title={config.title} icon={config.icon} />}
      <ModalContent withPadding={false}>
        <div className="store-detail-container">
          {!type && (
            <>
              <button type="button" id="testId-back-to-store" onClick={() => setScreen('screen3')}>
                <i className="csicon-right" />
                <span>{BACK_TO_STORE_LIST}</span>
              </button>
              <div className="divider">
                <div />
              </div>
            </>
          )}
          <div className={`store-details ${type}`}>
            <h3>{capitalizeText(selectedStore.storeName)}</h3>
            {!type && (
              <div className="address">
                <i className="location-icon" />
                <span>{capitalizeText(`${district},${state}`)}</span>
              </div>
            )}
            <div className="work-hours">
              <a rel="noopener noreferrer" target="_blank" href={workingHours[storeFromName]}>
                <span>{CHECK_SCHEDULE}</span>
                <span className="rightArrowIcon" />
              </a>
            </div>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={apiConfig.getGoogleMapLink(latLng)}
              className="open-maps"
            >
              {OPEN_MAPS}
            </a>
          </div>
          {deviceType !== 'desktop' && <div style={{ height: getHeight() }} className="map-section" ref={mapRef} />}
        </div>
      </ModalContent>
      <style jsx>{styles}</style>
    </>
  );
};
StoreDetail.propTypes = {
  selectedStore: PropTypes.object,
  config: PropTypes.object,
  setScreen: PropTypes.func,
  type: PropTypes.string,
  deviceType: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  workingHours: PropTypes.object,
};

StoreDetail.defaultProps = {
  selectedStore: {},
  config: {},
  setScreen: noop,
  type: '',
  workingHours: {},
};

export default StoreDetail;
