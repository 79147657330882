import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import _ from '../../../../utils/LodashImports';
import noop from '../../../../utils/noop';
import { mapATGPricesToCatalystPrices } from '../../../../utils/PriceHelpers';
import Prices from '../../../Prices/Prices';
import BodyCopy from '../../../ui/BodyCopy/BodyCopy';
import { ProductCounter } from '../../../ui/ProductCounter/ProductCounter';
import { CartItemStyles } from '../CartItem.style';
import CartItemActionLabel from './CartItemActionLabel';

const CartItemAdditionalInformation = ({
  viewConfig,
  brandName,
  name,
  variantDetails,
  isMobile,
  productId,
  currentVariant,
  isAtgPrice,
  price,
  disableQuantitySelection,
  stepperStyle,
  totalQuantity,
  handleRemoveItem,
  handleAddItem,
  getMaxForStepper,
  inputVal,
  handleQuantityInput,
  handleInputChange,
  getMaxForProductCounter,
  showButton,
  labels,
  showMaxQuantityAlert,
  maxOnCartAmount,
  errorAddingToCart,
  addedQuantity,
  appCtx,
}) => {
  const { regionCode } = appCtx;

  const toggles = _.get(appCtx, 'siteConfig.toggles', {});

  const isNewPriceApplicable = _.get(appCtx, 'siteConfig.toggles.isNewPriceApplicable', false);

  const isQuantityEditableEnabled = _.get(appCtx, 'siteConfig.toggles.isQuantityEditableEnabled', false);
  return (
    <>
      <div className="additional-information">
        <div className="item-info">
          <BodyCopy type={viewConfig.brandName.type} size={viewConfig.brandName.size}>
            {brandName && brandName.toUpperCase()}
          </BodyCopy>
          <BodyCopy type={viewConfig.description.type} size={viewConfig.description.size} ellipsis maxlines={2}>
            {name}
          </BodyCopy>

          {variantDetails && (
            <BodyCopy type={viewConfig.description.type} size={viewConfig.description.size} ellipsis maxlines={2}>
              {variantDetails}
            </BodyCopy>
          )}
        </div>
        <div
          className={clsx('price', {
            'mt-8px': isNewPriceApplicable && isMobile,
          })}
        >
          <Prices
            key={productId}
            productId={productId}
            variantId={currentVariant.comboData ? currentVariant.id : null}
            priceList={isAtgPrice ? mapATGPricesToCatalystPrices(price, regionCode, toggles) : price}
            variant={viewConfig.price}
            page="CART"
          />
        </div>
        {!isMobile && !disableQuantitySelection && (
          <div className={isMobile ? 'stepper-mobile' : 'stepper'} style={stepperStyle}>
            {totalQuantity >= 0 && (
              <ProductCounter
                count={isQuantityEditableEnabled ? inputVal : totalQuantity}
                className={isMobile ? 'product-count-mobile' : 'product-count-desktop'}
              >
                <ProductCounter.Decrement
                  className={isMobile ? 'decrement-mobile' : 'decrement-desktop'}
                  minimum={0}
                  onClick={() => handleRemoveItem(undefined, undefined, undefined, true)}
                  type="primary"
                />
                {isQuantityEditableEnabled ? (
                  <ProductCounter.CountInput
                    className={isMobile ? 'product-count-value-mobile' : 'product-count-value-desktop'}
                    handleInputBlurKey={handleQuantityInput}
                    handleOnChange={handleInputChange}
                    maximum={getMaxForProductCounter()}
                    minimum={0}
                  />
                ) : (
                  <ProductCounter.CountText type="primary" />
                )}
                <ProductCounter.Increment
                  className={isMobile ? 'increment-mobile' : 'increment-desktop'}
                  maximum={getMaxForStepper()}
                  onClick={() => handleAddItem(undefined, true)}
                  type="primary"
                />
              </ProductCounter>
            )}
          </div>
        )}
        {!isMobile && (
          <CartItemActionLabel
            showButton={showButton}
            totalQuantity={totalQuantity}
            handleAddItem={handleAddItem}
            labels={labels}
            showMaxQuantityAlert={showMaxQuantityAlert}
            maxOnCartAmount={maxOnCartAmount}
            errorAddingToCart={errorAddingToCart}
            addedQuantity={addedQuantity}
            appCtx={appCtx}
          />
        )}
      </div>
      <style jsx>{CartItemStyles}</style>
    </>
  );
};

CartItemAdditionalInformation.defaultProps = {
  brandName: '',
  name: '',
  variantDetails: '',
  isMobile: false,
  productId: '',
  currentVariant: {},
  isAtgPrice: true,
  disableQuantitySelection: false,
  stepperStyle: {},
  totalQuantity: 0,
  handleRemoveItem: noop,
  handleAddItem: noop,
  getMaxForStepper: noop,
  inputVal: 0,
  handleQuantityInput: noop,
  handleInputChange: noop,
  getMaxForProductCounter: noop,
  showButton: false,
  labels: {},
  showMaxQuantityAlert: false,
  maxOnCartAmount: 0,
  errorAddingToCart: '',
  addedQuantity: 0,
};

CartItemAdditionalInformation.propTypes = {
  viewConfig: PropTypes.object.isRequired,
  brandName: PropTypes.string,
  name: PropTypes.string,
  variantDetails: PropTypes.string,
  isMobile: PropTypes.bool,
  productId: PropTypes.string,
  currentVariant: PropTypes.object,
  isAtgPrice: PropTypes.bool,
  price: PropTypes.array.isRequired,
  disableQuantitySelection: PropTypes.bool,
  stepperStyle: PropTypes.object,
  totalQuantity: PropTypes.number,
  handleRemoveItem: PropTypes.func,
  handleAddItem: PropTypes.func,
  getMaxForStepper: PropTypes.func,
  inputVal: PropTypes.number,
  handleQuantityInput: PropTypes.func,
  handleInputChange: PropTypes.func,
  getMaxForProductCounter: PropTypes.func,
  showButton: PropTypes.bool,
  labels: PropTypes.object,
  showMaxQuantityAlert: PropTypes.bool,
  maxOnCartAmount: PropTypes.number,
  errorAddingToCart: PropTypes.string,
  addedQuantity: PropTypes.number,
  appCtx: PropTypes.object.isRequired,
};

export default CartItemAdditionalInformation;
