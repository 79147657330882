import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import noop from '../../utils/noop';
import ProductInfo from '../AdditionalInformation/ProductInfo/ProductInfo';
import Drawer from '../ui/Drawer/Drawer';
import styles from './AdditionalInfoDrawer.style';

const AdditionalInfoDrawer = ({ description, labels, isSoCom }) => {
  const [isDrawerActive, activateDrawer] = React.useState(false);
  if (!description) {
    return null;
  }
  const additionalText = isSoCom
    ? _.get(labels, 'SO_COM_PRODUCT_INFORMATION_LABEL', 'Descripción')
    : _.get(labels, 'PRODUCT_INFORMATION_LABEL', 'Información adicional');

  return (
    <>
      <div
        id="pdp_informacion_tap"
        role="button"
        tabIndex={0}
        onKeyDown={noop}
        className="addtional-info-wrapper"
        onClick={() => activateDrawer(true)}
      >
        <div className="icon info-icon" />
        <p>{additionalText}</p>
        <i className="csicon-arrow_down_small_1" />
      </div>
      <Drawer
        toggleState={[isDrawerActive, activateDrawer]}
        heading={additionalText}
        isLoading={false}
        type="secondary"
      >
        <ProductInfo description={description} />
      </Drawer>
      <style jsx>{styles}</style>
    </>
  );
};
AdditionalInfoDrawer.propTypes = {
  description: PropTypes.string,
  labels: PropTypes.object.isRequired,
  isSoCom: PropTypes.bool,
};
AdditionalInfoDrawer.defaultProps = {
  description: '',
  isSoCom: false,
};
export default AdditionalInfoDrawer;
