import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import noop from '../../../utils/noop';
import { checkboxStyles } from './Checkbox.style';

const Checkbox = (props) => {
  const {
    isChecked,
    onChange,
    id,
    children,
    variant,
    disableClick,
    manageState,
    inConnect,
    selectedBorderClass,
    normalBorderClass,
    inFloorCalculator,
    hasTimeConstraint,
    isShippingFacet,
    disabled,
    theme,
  } = props;
  const themedStyles = checkboxStyles(theme);
  const [isSelected, setSelected] = useState(isChecked);
  const renderCheckIcon = () => {
    return (
      <>
        <i className={`rebrand-icon ${hasTimeConstraint && 'shipping-checkbox'}  ${isSelected ? '' : 'hide-icon'}`} />
        <style jsx>{themedStyles}</style>
      </>
    );
  };
  const handleOnChange = () => {
    if (manageState) {
      setSelected(!isSelected);
    }
    onChange({
      isSelected: !isSelected,
    });
  };
  const titleClass = inConnect ? 'connect-title' : 'title';
  useEffect(() => {
    setSelected(isChecked);
  }, [props]);
  return (
    <label
      className={`checkbox checkbox-rebranding ${isChecked ? 'orange-border' : ''}${disabled ? ' disabled' : ''}`}
      htmlFor={id}
    >
      <input id={id} type="checkbox" checked={isSelected} onChange={disableClick ? noop : handleOnChange} />
      <span
        className={`${titleClass} ${
          isShippingFacet && 'hasTimeConstraint'
        } ${variant}${selectedBorderClass}${normalBorderClass} ${inFloorCalculator ? 'in-floor-calc' : ''}`}
      >
        {renderCheckIcon()}
        {children}
      </span>
      <style jsx>{themedStyles}</style>
    </label>
  );
};

Checkbox.defaultProps = {
  children: null,
  onChange: noop,
  isChecked: false,
  variant: 'secondary',
  disableClick: false,
  manageState: true,
  inConnect: false,
  selectedBorderClass: '',
  normalBorderClass: '',
  inFloorCalculator: false,
  hasTimeConstraint: false,
  isShippingFacet: false,
  disabled: false,
  theme: {},
};

Checkbox.propTypes = {
  children: PropTypes.node,
  isChecked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'services']),
  disableClick: PropTypes.bool,
  manageState: PropTypes.bool,
  inConnect: PropTypes.bool,
  selectedBorderClass: PropTypes.oneOf([' mkp-border', ' mkp-border-bundle', '']),
  normalBorderClass: PropTypes.oneOf([' mkp-normal-border', '']),
  inFloorCalculator: PropTypes.bool,
  hasTimeConstraint: PropTypes.bool,
  isShippingFacet: PropTypes.bool,
  disabled: PropTypes.bool,
  theme: PropTypes.object,
};

export default withTheme(Checkbox);
