import { LoginModal } from '@digital-retail/falabella-ui-cross-components';
import { isSodimacStandalone } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import css from 'styled-jsx/css';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withCart } from '../../../utils/CartContext';
import _ from '../../../utils/LodashImports';
import { withProduct } from '../../../utils/ProductContext';
import { withUser } from '../../../utils/UserContext';
import { changeTextCase } from '../../../utils/jsUtils';
import noop from '../../../utils/noop';
import { getVariant } from '../../../utils/variant';
import Button from '../../ui/Button/Button';
import { ProductCounter } from '../../ui/ProductCounter/ProductCounter';
import AddToCartPersistContainer from '../AddToCartPersistContainer';
import styles from './AddToCartButtonDesktop.style';

const AddToCartButtonStyle = css`
  .marginTop {
    margin-top: 23px;
  }
  .add-to-cart-btn {
    flex: 1;
  }
  .pagar-en-caja {
    flex: 1;
    margin-top: 23px;
  }
`;
const AddToCartButton = ({
  isAddToCartInProgress,
  labels,
  handleAddToCart,
  handleRemoveFromCart,
  isRemoveFromCartInProgress,
  isVendorLogin,
  selectedSize,
  sizes,
  appCtx,
  refreshUserData,
  showLoginForm,
  setShowLoginForm,
  setErrorAddingToCart,
  maxCartQuantity,
  mobileView,
  cart,
  product,
  m2AttributeValue,
  showQuantityError,
  setQuantityError,
  inputVal,
  setInputVal,
  handleInputChange,
}) => {
  const { variants, id: productId, currentVariant: currentVariantId } = product;
  const currentVariant = getVariant(variants, currentVariantId);
  const offeringId = _.get(currentVariant, 'offerings[0]offeringId', '');
  const buyNowProductData = [
    {
      skuId: currentVariantId,
      productId,
      quantity: inputVal || 1,
      offeringId,
      isInternational: _.get(product, 'internationalShipping.applicable', false),
    },
  ];
  const isFloorCalculatorEnabled = _.get(appCtx, 'siteConfig.toggles.isFloorcalculatorEnabled', true);
  const isOneClickButtonVisible = _.get(appCtx, 'siteConfig.toggles.isOneClickButtonVisible', false);
  const [showAddToCart, setShowAddToCart] = useState(false);
  const isFloorProduct = m2AttributeValue > 0 && isFloorCalculatorEnabled;
  const toggleLoginForm = () => {
    setShowLoginForm(!showLoginForm);
  };
  const onClose = () => {
    toggleLoginForm();
  };
  const onUserLogin = () => {
    refreshUserData();
    setErrorAddingToCart();
  };
  const maxQuantityText = _.get(labels, 'MAX_QUANTITY_TEXT_PDP', 'Máximo {maxCartQuantity} unidades.').replace(
    '{maxCartQuantity}',
    maxCartQuantity
  );
  const maxQuantityWarning = _.get(
    labels,
    'MAX_QUANTITY_TEXT_PDP',
    'Solo puedes llevar {maxCartQuantity} unidades'
  ).replace('{maxCartQuantity}', maxCartQuantity);
  const isQuantityEditableEnabled = _.get(appCtx, 'siteConfig.toggles.isQuantityEditableEnabled', false);
  const isCartPersistanceEnabled = _.get(appCtx, 'siteConfig.toggles.isCartPersistanceEnabled', false);
  const isQuantitySelectorVisibleInPDP =
    _.get(appCtx, 'siteConfig.toggles.isQuantitySelectorVisibleInPDP', false) && !isCartPersistanceEnabled;

  const handleRemoveItem = () => {
    const value = parseInt(inputVal, 10);
    if (value - 1 <= maxCartQuantity) {
      setQuantityError(false);
    }
    setInputVal(value - 1);
    const eventToDigitalData = new CustomEvent('DDPDPRemoveQuantitySelector', {
      bubbles: true,
      detail: {
        value: value - 1,
      },
    });
    window.dispatchEvent(eventToDigitalData);
  };
  React.useEffect(() => {
    if (!cart.showCart) {
      setInputVal(1);
    }
  }, [cart.showCart]);

  React.useEffect(() => {
    setInputVal(1);
  }, [product.currentVariant]);

  const handleAddItem = () => {
    const value = parseInt(inputVal, 10);
    if (value < maxCartQuantity) {
      setInputVal(value + 1);
      setQuantityError(false);
    } else {
      setQuantityError(true);
    }
    if (value + 1 >= maxCartQuantity) {
      setQuantityError(true);
    }
    const eventToDigitalData = new CustomEvent('DDPDPAddQuantitySelector', {
      bubbles: true,
      detail: {
        maxCartQuantity,
        value,
      },
    });
    window.dispatchEvent(eventToDigitalData);
  };
  const handleInputBlurKey = (event) => {
    const { target } = event;
    const { value } = target;
    if (!value) {
      setInputVal('1');
    }
  };
  const isSizeSeleted = !!selectedSize;
  const atgBaseUrl = _.get(appCtx, 'siteConfig.endpoints.ATG_AUTH_BASEURL', 'https://www.falabella.com');
  const catalystBaseUrl = _.get(appCtx, 'siteConfig.endpoints.CATALYST_AUTH_BASEURL', 'https://www.falabella.com');
  const getATCButtonText = () => {
    if (sizes.length === 0 || (isSizeSeleted && sizes.length > 0)) {
      return isSodimacStandalone(appCtx.store)
        ? _.get(labels, 'SO_COM_ADD_TO_CART_TEXT', 'Agregar al carro')
        : labels.ADD_TO_CART_TEXT;
    }
    return changeTextCase(labels.SELECT_VARIANT_CHOOSE_OPTIONS);
  };

  const buttonForCustomers = () => (
    <div id="buttonForCustomers">
      {isAddToCartInProgress ? (
        <Button key="add-to-cart-loading" disabled size="xtra-large" type="mkp-primary">
          {labels.ADDING_TO_CART}
        </Button>
      ) : (
        <Button
          key="add-to-cart-button"
          onClick={() => handleAddToCart(inputVal)}
          size="xtra-large"
          id="add-to-cart-button"
          type="mkp-primary"
          extraClasses={cart.showBuyNowButton ? 'button-mkp-primary-xtra-large-outlined' : ''}
        >
          {getATCButtonText()}
        </Button>
      )}
    </div>
  );
  if (isVendorLogin)
    return (
      <>
        <div className="add-to-cart-btn">
          {!showAddToCart ? (
            <>
              <Button
                id="testId-activate-addToCart"
                type="outline"
                onClick={() => setShowAddToCart(true)}
                key="add-to-cart-activate"
              >
                {labels.ACTIVATE_ADD_TO_BAG}
              </Button>
              <div className="pagar-en-caja">
                <Button disabled size="xtra-large" type="primary" key="add-to-cart-pagar-en-caja">
                  {labels.PAY_AT_BOX}
                </Button>
              </div>
            </>
          ) : (
            buttonForCustomers()
          )}
        </div>
        <style jsx>{AddToCartButtonStyle}</style>
      </>
    );

  if (isCartPersistanceEnabled) {
    return (
      <>
        {cart.showBuyNowButton && (
          <div style={{ marginBottom: '8px' }}>
            <Button
              key="add-to-cart-button"
              onClick={() =>
                cart
                  .addProduct({
                    appCtx,
                    products: buyNowProductData,
                    isBuyNowApplicable: true,
                    product,
                  })
                  .then((res) => {
                    // here. if we have a res, we have an error. .apply fallback
                    if (res && res.length) {
                      cart.addProduct({
                        appCtx,
                        products: buyNowProductData,
                        product,
                      });
                    }
                  })
              }
              size="xtra-large"
              id="buy-now-button"
              type="mkp-primary"
              disabled={sizes.length > 0 && !isSizeSeleted}
              extraClasses={isOneClickButtonVisible ? '' : 'btn-hidden'}
            >
              {_.get(labels, 'BUY_NOW_BUTTON_TEXT', 'Comprar ahora')}
            </Button>
          </div>
        )}
        <div id="buttonForCustomers">
          <AddToCartPersistContainer
            isAddToCartInProgress={isAddToCartInProgress}
            maxCartQuantity={maxCartQuantity}
            handleRemoveFromCart={handleRemoveFromCart}
            isRemoveFromCartInProgress={isRemoveFromCartInProgress}
            handleAddToCart={handleAddToCart}
            labels={labels}
            getATCButtonText={getATCButtonText}
            inputVal={inputVal}
          />
        </div>
        <div id="login-modal">
          <LoginModal
            id="testId-atc-button"
            isRebrandingEnabled
            atgBaseUrl={atgBaseUrl}
            catalystBaseUrl={catalystBaseUrl}
            onClose={onClose}
            onUserLogin={onUserLogin}
            tenant={appCtx.regionCode}
            showLoginForm={showLoginForm}
          />
        </div>
        <style jsx>{styles}</style>
      </>
    );
  }
  return (
    <>
      {isQuantitySelectorVisibleInPDP && !mobileView && !isFloorProduct && (
        <>
          <div className="add-quantity-container">
            <div className="counter">
              <ProductCounter count={inputVal} className="product-count-desktop">
                <ProductCounter.Decrement
                  id="quantity-selector-decrement-btn"
                  className="decrement-desktop"
                  minimum={1}
                  onClick={handleRemoveItem}
                />
                {isQuantityEditableEnabled ? (
                  <ProductCounter.CountInput
                    className="product-count-value-desktop"
                    handleOnChange={handleInputChange}
                    maximum={maxCartQuantity}
                    handleInputBlurKey={handleInputBlurKey}
                    minimum={1}
                    id="quantity-selector-increment-input"
                  />
                ) : (
                  <ProductCounter.CountText type="primary" />
                )}
                <ProductCounter.Increment
                  id="quantity-selector-increment-btn"
                  className="increment-desktop"
                  maximum={maxCartQuantity}
                  onClick={handleAddItem}
                  type="primary"
                />
              </ProductCounter>
            </div>
            {!showQuantityError && <div className="max-units-text">{maxCartQuantity && <p>{maxQuantityText}</p>}</div>}
          </div>
          {showQuantityError && (
            <div className="limit-execed-text">
              <div className="warning-icon" />
              <p>{maxQuantityWarning}</p>
            </div>
          )}
        </>
      )}
      {cart.showBuyNowButton && (
        <div style={{ marginBottom: '8px' }}>
          <Button
            key="add-to-cart-button"
            onClick={() =>
              cart
                .addProduct({
                  appCtx,
                  products: buyNowProductData,
                  isBuyNowApplicable: true,
                  product,
                })
                .then((res) => {
                  // here. if we have a res, we have an error. .apply fallback
                  if (res && res.length) {
                    cart.addProduct({
                      appCtx,
                      products: buyNowProductData,
                      product,
                    });
                  }
                })
            }
            size="xtra-large"
            id="buy-now-button"
            type="mkp-primary"
            disabled={sizes.length > 0 && !isSizeSeleted}
            extraClasses={isOneClickButtonVisible ? '' : 'btn-hidden'}
          >
            {_.get(labels, 'BUY_NOW_BUTTON_TEXT', 'Comprar ahora')}
          </Button>
        </div>
      )}
      {buttonForCustomers()}
      <div id="login-modal">
        <LoginModal
          id="testId-atc-button"
          isRebrandingEnabled
          atgBaseUrl={atgBaseUrl}
          catalystBaseUrl={catalystBaseUrl}
          onClose={onClose}
          onUserLogin={onUserLogin}
          tenant={appCtx.regionCode}
          showLoginForm={showLoginForm}
        />
      </div>
      <style jsx>{styles}</style>
    </>
  );
};
AddToCartButton.defaultProps = {
  isAddToCartInProgress: false,
  showLoginForm: false,
  appCtx: {},
  setErrorAddingToCart: noop,
  isRemoveFromCartInProgress: false,
  maxCartQuantity: 999,
  mobileView: false,
  cart: { isBuyNowApplicable: () => {} },
  product: {},
  m2AttributeValue: -1,
};
AddToCartButton.propTypes = {
  isAddToCartInProgress: PropTypes.bool,
  isVendorLogin: PropTypes.bool.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object,
  handleAddToCart: PropTypes.func.isRequired,
  handleRemoveFromCart: PropTypes.func.isRequired,
  isRemoveFromCartInProgress: PropTypes.bool,
  sizes: PropTypes.array.isRequired,
  selectedSize: PropTypes.string.isRequired,
  refreshUserData: PropTypes.func.isRequired,
  showLoginForm: PropTypes.bool,
  mobileView: PropTypes.bool,
  setShowLoginForm: PropTypes.func.isRequired,
  setErrorAddingToCart: PropTypes.func,
  maxCartQuantity: PropTypes.number,
  cart: PropTypes.object,
  product: PropTypes.object,
  m2AttributeValue: PropTypes.number,
  setQuantityError: PropTypes.func.isRequired,
  setInputVal: PropTypes.func.isRequired,
  showQuantityError: PropTypes.bool.isRequired,
  inputVal: PropTypes.number.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};
export default withApplicationContext(withUser(withCart(withProduct(AddToCartButton))));
export { AddToCartButton };
