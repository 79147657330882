import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withLabels } from '../../utils/LabelsContext';
import { CmrOptionListStyle, CmrOptionsHeaderStyle } from './CmrOptionList.style';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withProduct } from '../../utils/ProductContext';
import { svgToDataUri } from '../../utils/styles/svgMixins';
import { formatters } from '../../utils/PriceHelpers';
import { icons } from '../../config/styles';
import CmrListItem from './CmrListItem';

const CmrOptionsHeader = ({ title, closeModal }) => {
  return (
    <>
      <div className="cmr-list-title">
        <span className="accum-text">{title}</span>
        <img alt="cmr-puntos" className="cmr-text-icon" src={svgToDataUri(icons.cmrPuntosTextIcon)} />
        <span className="cmr-al-pagar">al pagar</span>
        <span className="close-btn-container">
          <img
            className="close-btn"
            alt="close-btn"
            src={svgToDataUri(icons.closeBtnCmr)}
            onClick={() => {
              closeModal();
            }}
            role="presentation"
            id="testId-close-modal"
          />
        </span>
      </div>
      <style jsx>{CmrOptionsHeaderStyle}</style>
    </>
  );
};

CmrOptionsHeader.defaultProps = {
  title: '',
};
CmrOptionsHeader.propTypes = {
  title: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
};

const CmrOptionsList = ({ options, title, actions, closeModal, lowerPrice }) => {
  useEffect(() => {
    actions.setCustomHeader(<CmrOptionsHeader title={title} closeModal={closeModal} />);
  }, []);
  return (
    <>
      <div className="cmrOptionsContainer">
        <div className="cmr-line-divider" />
        {options.map((option, index) => (
          <CmrListItem
            title={option.title}
            subTitle={option.subTitle}
            cmrPuntosIcon={option.logo}
            cmrPuntosTextIcon={icons.cmrPuntosIconModal}
            cmrPoints={formatters.DOT_SEPERATOR(Math.trunc(lowerPrice / option.divideFactor))}
            bottomLine={index !== options.length - 1}
          />
        ))}
      </div>
      <style jsx>{CmrOptionListStyle}</style>
    </>
  );
};

CmrOptionsList.defaultProps = {
  options: [],
  title: 'Acumula',
  lowerPrice: 0,
  actions: {},
};

CmrOptionsList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  lowerPrice: PropTypes.number,
  actions: PropTypes.object,
};

export default withApplicationContext(withProduct(withLabels(CmrOptionsList)));
