import PropTypes from 'prop-types';
import React from 'react';
import noop from '../../../utils/noop';
import BodyCopy from '../BodyCopy/BodyCopy';
import { selectInputStyles } from './SelectInput.style';

const Selectinput = ({ onSelectChange, fieldName, defaultValue, fieldOptions, disabled, fieldKey }) => {
  const onChangeHandler = (e) => {
    const selctedOption = fieldOptions.find((item) => item[fieldKey].toString() === e.target.value.toString());
    onSelectChange(selctedOption);
  };

  return (
    <div className="formContainer-item">
      {fieldName && (
        <div className="heading">
          <BodyCopy size="copy5">{fieldName}</BodyCopy>
        </div>
      )}
      <div className="dropdown">
        <select name={fieldName} value={defaultValue} onChange={onChangeHandler} disabled={disabled}>
          <option key={Number.MIN_VALUE} value="default" selected disabled>
            {fieldName}
          </option>
          {fieldOptions.map((field) => (
            <option key={field[fieldKey]} value={field[fieldKey]}>
              {field.name}
            </option>
          ))}
        </select>
        <i className="csicon-arrow_down">
          <span className="sr-only">{fieldName} dropdown</span>
        </i>
      </div>
      <style jsx>{selectInputStyles}</style>
    </div>
  );
};

Selectinput.defaultProps = {
  fieldName: '',
  fieldOptions: [],
  defaultValue: '',
  onSelectChange: noop,
  disabled: false,
  fieldKey: 'code',
};

Selectinput.propTypes = {
  fieldName: PropTypes.string,
  fieldOptions: PropTypes.array,
  defaultValue: PropTypes.string,
  onSelectChange: PropTypes.func,
  disabled: PropTypes.bool,
  fieldKey: PropTypes.string,
};

export default Selectinput;
