import css from 'styled-jsx/css';
import { colors, icons } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const WarrantyStyles = css`
  .warranty-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    padding-bottom: 12px;
    gap: 12px;

    .title {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 16px;

      .warranty-icon {
        display: flex;
        height: 20px;
        min-width: 20px;
        background-image: url('${svgToDataUri(icons.warrantyIcon)}');
        background-size: 100%;
        background-position: center;
      }
    }
    .highlighted-warranty {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      padding: 0 4px;
      font-size: 12px;
      line-height: 16px;
      width: 100%;
      font-weight: 400;

      .label {
        display: flex;
        gap: 8px;
        width: 60%;

        .warranty-name {
          font-size: 12px;
          line-height: 16px;
          font-weight: 400;
        }

        .checkbox {
          display: flex;
          height: 20px;
          min-width: 20px;
          background-size: 100%;
          background-position: center;

          &.full {
            background: ${colors.gray.shade64};
            border-radius: 4px;
            background-image: url('${svgToDataUri(icons.filledCheckBox)}');
          }

          &.empty {
            background-image: url('${svgToDataUri(icons.emptyCheckBox)}');
          }
        }
      }

      .warranty-price {
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
      }
    }
    .more-warranty-options {
      font-size: 12px;
      font-weight: 700;
      color: ${colors.gray.shade64};
      line-height: 16px;
      border-bottom: 1px solid;
    }
  }
`;

export { WarrantyStyles };
