import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import _ from '../../utils/LodashImports';
import {
  ALLOWED_CONFIGS_FOR_PRICE_BREAK,
  ALLOWED_CONFIGS_FOR_UNIT_PRICES,
  formatPriceRange,
} from '../../utils/PriceHelpers';
import { withProduct } from '../../utils/ProductContext';
import { getVariant } from '../../utils/variant';
import DiscountBadge from '../Badges/DiscountBadge/DiscountBadge';
import MyLists from '../MyLists/MyLists';
import BodyCopy from '../ui/BodyCopy/BodyCopy';
import { pricesStyles } from './Prices.style';
import priceStylesConfig, {
  newPriceStylesConfig,
  newUnitPriceStyleConfig,
  oldPriceStyles,
  priceSizes,
  unitPriceStylesConfig,
} from './PriceStyleConfig';

const Prices = ({
  priceList,
  productId,
  variantId,
  offeringId,
  isTabApplicable,
  page,
  layout,
  regionCode,
  variant,
  isSponsoredProduct,
  isConnectProduct,
  operators,
  selectedOperatorId,
  appCtx,
  discountBadge,
  priceCount,
  isOutOfStock,
  labels,
  product,
  showMyList,
  measurement,
  darSectionActive,
}) => {
  const { deviceType, store } = appCtx;
  const isPDP = page === constants.PAGES.PDP;
  const { PRICE_TYPE } = constants;
  const highlitedPriceTypes = [PRICE_TYPE.internet, PRICE_TYPE.event];
  const currentVariant =
    (product && product.variants && product.variants.length && getVariant(product.variants, variantId)) || undefined;
  const comboPricesConfig = {
    perComboText: _.get(appCtx, 'siteConfig.textDictionary.PER_COMBO_TEXT', '/ Combo'),
    comboTotalPriceText: _.get(appCtx, 'siteConfig.textDictionary.COMBO_TOTAL_PRICE_TEXT', 'Total por separado'),
    comboPriceIndex: 2,
  };
  const isMyListsEnabled = _.get(appCtx, 'siteConfig.toggles.isPDPMyListsEnabled', false) && showMyList;
  const shouldBreakFirstPrice = ALLOWED_CONFIGS_FOR_PRICE_BREAK.includes(variant);
  const pricesCount = (priceList || []).length;

  const isNormalPriceCrossedOut = _.get(appCtx, 'siteConfig.toggles.isNormalPriceCrossedOut', false);

  const isNewPriceApplicable = _.get(appCtx, 'siteConfig.toggles.isNewPriceApplicable', false);
  const showUnitPrice = !!ALLOWED_CONFIGS_FOR_UNIT_PRICES.includes(variant);
  const showUnitPriceXLP = showUnitPrice && !isPDP;
  const cmrLogoOnTop =
    page === constants.PAGES.PDP ||
    page === constants.PAGES.SLP ||
    page === constants.PAGES.PLP ||
    page === constants.PAGES.PLANS ||
    (!isSponsoredProduct && page === constants.PAGES.POD) ||
    ([page, variant].includes(constants.PAGES.CART) && isNewPriceApplicable);

  let classCMRContainer = 'cmr-icon-container';
  const hasTwoPricesPlanPage = !!(page === constants.PAGES.PLANS && priceList.length === 2);
  const storeAndRegionCodeClassname = `${store || ''} ${regionCode}`;

  const getPriceImportance = (icon, crossed, isSep) => {
    if (icon && isSep) return '';

    if (icon === 'cmr-icon') {
      return 'high';
    }

    if (crossed) {
      return isNewPriceApplicable ? 'medium' : 'low';
    }

    if (isNewPriceApplicable) {
      if (isSponsoredProduct) {
        return 'septenary';
      }
      return 'senary';
    }
    return '';
  };

  const getPriceConfig = (
    config,
    icon,
    crossed = false,
    isSep = false,
    hasUnitStyles = false,
    pricePosition = 0,
    isComboPrice = false
  ) => {
    const priceConfig = hasUnitStyles ? newUnitPriceStyleConfig : newPriceStylesConfig;

    const variantPriceConfig = priceConfig[variant];
    const lineHeight = _.get(variantPriceConfig, `lineHeight[${pricePosition}]`, '');
    const lineHeightCrossed = _.get(variantPriceConfig, `lineHeight[${2}]`, '');
    const importance = getPriceImportance(icon, crossed, isSep);
    if (isNormalPriceCrossedOut) {
      // **
      let priceSizeNotCrossed = config.size;

      if (!isComboPrice && priceList.length <= 2 && highlitedPriceTypes.includes(priceList[pricePosition].type)) {
        priceSizeNotCrossed = variant === 'CART' ? 'copy10' : config.size;
      }
      if (darSectionActive && variant !== 'CART') {
        priceSizeNotCrossed = 'copy15';
      }
      // **
      return {
        ...config,
        importance,
        size: isNewPriceApplicable && crossed ? variantPriceConfig.crossed : priceSizeNotCrossed,
        lineHeight: isNewPriceApplicable && !crossed ? lineHeight : lineHeightCrossed,
      };
    }
    return isNewPriceApplicable
      ? {
          ...config,
          importance,
          lineHeight,
        }
      : config;
  };

  const getUnitPrice = (unitPrice, symbol) => {
    if (!unitPrice || !showUnitPrice) {
      return '';
    }
    const { price, unit } = unitPrice;
    return price.map((p) => `${symbol} ${p} ${unit}`).join(' - ');
  };

  const getUnitForSale = (unitPrice) => {
    if (!unitPrice || !showUnitPrice) {
      return '';
    }
    const { unitForSale, unit } = unitPrice;
    if (!isPDP) {
      return unit && typeof unit === 'string' ? unit.toLowerCase() : '';
    }
    return unitForSale && typeof unitForSale === 'string' ? unitForSale.toLowerCase() : '';
  };

  const getPrice = (price, symbol) => {
    return price.map((p) => `${symbol} ${p}`).join(' - ');
  };

  const getPriceAttributes = (price, type) => {
    const priceType = type && type.replace('Price', '');
    return { [`data-${priceType}-price`]: price };
  };

  const priceType = {
    PDP: 'pdp-prices',
    POD: 'pod-prices',
    PLP: 'pod-prices',
    CART: 'cart-prices',
    SLP: 'pod-prices',
  };

  const connectOperatorName = () => {
    if (!isConnectProduct || !selectedOperatorId) {
      return null;
    }
    const operator = operators.find((op) => op.id === selectedOperatorId);
    if (operator)
      return (
        <p className="operator-name">
          ({operator.name})<style jsx>{pricesStyles}</style>
        </p>
      );
    return null;
  };

  const getPriceStyle = (hasUnitStyles = false) => {
    if (isNewPriceApplicable) {
      if (hasUnitStyles) {
        return newUnitPriceStyleConfig[variant][priceList.length && priceList[0].icons ? 'cmr' : 'noCmr'];
      }
      return newPriceStylesConfig[variant][pricesCount && priceList[0].icons ? 'cmr' : 'noCmr'];
    }

    if (isNormalPriceCrossedOut) {
      if (hasUnitStyles) {
        return unitPriceStylesConfig[variant][priceList.length && priceList[0].icons ? 'cmr' : 'noCmr'];
      }
      return priceStylesConfig[variant][pricesCount && priceList[0].icons ? 'cmr' : 'noCmr'];
    }

    return oldPriceStyles[variant];
  };

  const priceConfig = (hasUnitStyles) => getPriceStyle(hasUnitStyles);
  return (
    <div
      className={clsx('prices', {
        'price-with-tab': isTabApplicable,
        [`prices-${layout}`]: true,
        'mb-18px': isNewPriceApplicable && page === constants.PAGES.PDP && variant === constants.VIEW_TEMPLATE.TYPE_C,
        isOutOfStock,
      })}
      id={`testId-pod-prices-${productId}`}
    >
      {isPDP && isMyListsEnabled && (
        <div
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          role="button"
          tabIndex={0}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <MyLists
            appCtx={appCtx}
            product={{
              productId,
              variantId,
              offeringId,
            }}
            isPDPpage
          />
        </div>
      )}
      {priceList && (
        <ol
          className={clsx(`ol-${layout} ${priceType[page]} fa--prices`, {
            'li-separation': isNewPriceApplicable,
          })}
        >
          {priceList.map(({ symbol, price, icons = [], label = '', type, crossed, unitPrice, pum = undefined }, i) => {
            const isCmr = icons.length > 0 && icons === 'cmr-icon';
            const unitPriceValue = getUnitPrice(unitPrice, symbol);
            const unitForSale = getUnitForSale(unitPrice);
            const hasUnitStyles = !!showUnitPrice && !!unitPriceValue && !currentVariant?.comboData;
            classCMRContainer =
              hasTwoPricesPlanPage && isCmr ? `${classCMRContainer} cmr-icon-container-two-prices` : classCMRContainer;
            const isFirstPrice = i === 0;
            const isFirstPriceFormatNeeded = isFirstPrice && shouldBreakFirstPrice && price.length > 1;
            const priceToRender = showUnitPriceXLP && unitPrice ? unitPrice.price : price;
            const pricesToRender = isFirstPriceFormatNeeded
              ? formatPriceRange(variant, priceToRender, pricesCount, isCmr, symbol.trim())
              : [
                  `${getPrice(priceToRender, symbol)} ${!label ? '' : label} ${
                    priceConfig(hasUnitStyles)[i].size || ''
                  }`,
                ];
            if (crossed && currentVariant?.comboData?.showTotalPrice) {
              return null;
            }
            const renderPumUnitForSale = (pumUnitForSale) => {
              if (!pumUnitForSale) {
                return null;
              }
              return (
                <div className="pum-unitforsale">
                  <span>{`/ ${pumUnitForSale}`}</span>
                </div>
              );
            };
            const renderPumPrice = (pumobject) => {
              const { symbol: pumSymbol, label: pumLabel, price: pumPrice } = pumobject;
              return (
                <div className="pum-price">
                  <span>{`(${pumLabel} ${labels.PUM_AFFIX} ${pumSymbol} ${pumPrice})`}</span>
                </div>
              );
            };
            return (
              <li
                {...getPriceAttributes(price, type)}
                key={priceToRender}
                className={clsx(`prices-${i}`, {
                  'has-unit-price': hasUnitStyles && isPDP,
                  'mb-2px':
                    isNewPriceApplicable && deviceType === constants.DEVICE_MOBILE && variant === constants.PAGES.CART,
                })}
              >
                <div className={classCMRContainer} data-variant={variant}>
                  {cmrLogoOnTop && icons.length > 0 && icons === 'cmr-icon' && (
                    <i
                      className={clsx(`unica-cmr-icon pdp-icon ${storeAndRegionCodeClassname}`, {
                        'big-icon': isNewPriceApplicable && isPDP,
                        'cmr-icon-small':
                          isNewPriceApplicable &&
                          deviceType === constants.DEVICE_MOBILE &&
                          variant === constants.PAGES.CART,
                        'pdp-connect-tab-plan': priceCount && priceCount === 1,
                      })}
                    />
                  )}
                  {hasUnitStyles && isPDP && (
                    <>
                      <BodyCopy
                        {...getPriceConfig(priceSizes[priceConfig(false)][i], icons, crossed, false, false, i)}
                        crossed={crossed}
                      >
                        {unitPriceValue}
                      </BodyCopy>
                      <BodyCopy
                        {...getPriceConfig(priceSizes[priceConfig(true)][i], icons, crossed, true, false, i)}
                        crossed={false}
                      >
                        &nbsp;|&nbsp;
                      </BodyCopy>
                    </>
                  )}
                  {pricesToRender.map((p, priceToRenderIndex) => (
                    <BodyCopy
                      key={`${productId}-${p}`}
                      isHighlighted={highlitedPriceTypes.includes(type)}
                      {...getPriceConfig(
                        priceSizes[priceConfig(hasUnitStyles)][i],
                        icons,
                        crossed,
                        false,
                        hasUnitStyles,
                        i
                      )}
                      crossed={crossed}
                      width={
                        isFirstPriceFormatNeeded && pricesToRender.length > 1 && priceToRenderIndex === 0
                          ? 'width-100'
                          : ''
                      }
                    >
                      {p}
                      {currentVariant?.comboData ? comboPricesConfig.perComboText : ''}
                      {hasUnitStyles && !isPDP && <>&nbsp;{unitForSale}</>}
                    </BodyCopy>
                  ))}
                  {hasUnitStyles && isPDP && (
                    <BodyCopy
                      {...getPriceConfig(
                        priceSizes[priceConfig(hasUnitStyles)][i],
                        icons,
                        crossed,
                        false,
                        hasUnitStyles,
                        i
                      )}
                      crossed={crossed}
                    >
                      &nbsp;
                      {unitForSale}
                    </BodyCopy>
                  )}
                  {!cmrLogoOnTop && isCmr && (
                    <i
                      className={clsx(`unica-cmr-icon ${storeAndRegionCodeClassname}`, {
                        'big-icon': isNewPriceApplicable && isPDP,
                      })}
                    />
                  )}
                  {isFirstPrice && isConnectProduct && (
                    <div className={isCmr ? 'margin-left-6' : ''}>{connectOperatorName()}</div>
                  )}
                  {!unitForSale && // to not duplicate with sodimac m2 unit for sale development
                    currentVariant &&
                    currentVariant.unitForSale &&
                    !currentVariant?.comboData &&
                    renderPumUnitForSale(currentVariant.unitForSale)}
                  {/* tottus measurement unit */}
                  {measurement && <span className={`${type} tocom-unit`}>{measurement['unidad-de-medida']}</span>}
                  {isFirstPrice && discountBadge && <DiscountBadge badge={discountBadge} />}
                  <div className="price-extras">{pum && renderPumPrice(pum)}</div>
                </div>
              </li>
            );
          })}
          {currentVariant?.comboData &&
            currentVariant?.comboData?.showTotalPrice &&
            (() => {
              const isComboPrice = true;
              return (
                <>
                  <BodyCopy
                    {...getPriceConfig(
                      priceSizes[priceConfig(false)][comboPricesConfig.comboPriceIndex],
                      '',
                      true,
                      true,
                      false,
                      comboPricesConfig.comboPriceIndex,
                      isComboPrice
                    )}
                    crossed
                  >
                    {currentVariant.comboData.totalPrice}
                  </BodyCopy>
                  <BodyCopy
                    {...getPriceConfig(
                      priceSizes[priceConfig(false)][comboPricesConfig.comboPriceIndex],
                      '',
                      true,
                      true,
                      false,
                      comboPricesConfig.comboPriceIndex,
                      isComboPrice
                    )}
                  >
                    &nbsp;/&nbsp;{comboPricesConfig.comboTotalPriceText}
                  </BodyCopy>
                </>
              );
            })()}
        </ol>
      )}
      <style jsx>{pricesStyles}</style>
    </div>
  );
};

Prices.defaultProps = {
  productId: '',
  variantId: '',
  offeringId: '',
  isTabApplicable: false,
  page: 'POD',
  layout: '4_GRID',
  regionCode: 'cl',
  variant: 'PDP_MAIN',
  labels: {},
  operators: [],
  selectedOperatorId: undefined,
  isConnectProduct: false,
  isSponsoredProduct: false,
  appCtx: {},
  discountBadge: undefined,
  priceCount: undefined,
  isOutOfStock: false,
  product: {},
  showMyList: true,
  measurement: undefined,
  darSectionActive: false,
};

Prices.propTypes = {
  priceList: PropTypes.arrayOf(PropTypes.object).isRequired,
  productId: PropTypes.string,
  variantId: PropTypes.string,
  offeringId: PropTypes.string,
  isTabApplicable: PropTypes.bool,
  page: PropTypes.oneOf(['POD', 'PDP', 'CART', 'SLP', 'PLANS']),
  layout: PropTypes.string,
  regionCode: PropTypes.string,
  variant: PropTypes.string,
  labels: PropTypes.object,
  isSponsoredProduct: PropTypes.bool,
  isConnectProduct: PropTypes.bool,
  operators: PropTypes.arrayOf(PropTypes.object),
  selectedOperatorId: PropTypes.string,
  appCtx: PropTypes.object,
  discountBadge: PropTypes.object,
  priceCount: PropTypes.number,
  isOutOfStock: PropTypes.bool,
  showMyList: PropTypes.bool,
  product: PropTypes.object,
  measurement: PropTypes.object,
  darSectionActive: PropTypes.bool,
};

export default withApplicationContext(withProduct(Prices));
export { Prices };
