/* eslint-disable global-require, import/no-dynamic-require */
import React, { useState, useEffect, useContext } from 'react';
import createContainer from 'constate';
import { nameHOC } from './hocutils';
import { changeProductRoute } from './RouteHandler';
import httpService from './httpService';
import apiConfig from '../config/api/apiConfig';
import useColorSwatchesMetrics from '../components/ProductColorSwatches/useColorSwatchesMetrics';
import { constructComboData } from '../components/ComboSection/helper';
import _ from './LodashImports';
import { getVariant } from './variant';

const changeVariant = (productData, setProductData) => (site, variantId, store, regionCode, force) => {
  const { currentVariant, id, slug, variants } = productData;
  if (!force && (variantId === currentVariant || !variants.some((v) => v.id === variantId))) {
    return;
  }
  const updatedProductData = JSON.parse(JSON.stringify(productData));
  updatedProductData.currentVariant = variantId;

  setProductData(updatedProductData);

  changeProductRoute({
    site,
    id,
    slug,
    variant: variantId,
    store,
    regionCode,
  });
};

const loadBundles =
  (productData, setProductData) =>
  async ({ products, regionCode, site }) => {
    const query = JSON.stringify({
      products,
      ...(site ? { site } : {}),
    });
    const { data, error } = await httpService().get(apiConfig.fetchItemDetails(regionCode, query), {
      includeTracing: false,
    });

    if (!error && data.success) {
      setProductData({
        ...productData,
        bundles: data.products,
      });
    }
  };

const loadRecommendedProducts = async ({
  productId,
  zones,
  regionCode,
  channel,
  site,
  widgetsUUID,
  exp,
  variantId,
  userId,
  slots,
  categoryId,
  webCategoryId,
  brandName,
  sellerId,
  contextId,
  politicalId,
  priceGroupId,
}) => {
  const { data } = await httpService().get(
    apiConfig.getWidgets({
      productId,
      zones,
      regionCode,
      channel,
      site,
      widgetsUUID,
      exp,
      variantId,
      userId,
      slots,
      categoryId,
      webCategoryId,
      brandName,
      sellerId,
      contextId,
      politicalId,
      priceGroupId,
    })
  );
  return data.widgets;
};

const loadSponsoredProducts = async ({
  productId,
  zones,
  regionCode,
  refererUrl,
  deviceType,
  site,
  politicalId,
  brandName,
  gender,
}) => {
  const { data, error } = await httpService().get(
    apiConfig.getSponsoredProducts({
      productId,
      zones,
      regionCode,
      deviceType,
      site,
      politicalId,
      brandName,
      gender,
    }),
    { headers: { refererUrl } }
  );
  if (!error && data.success) {
    return data.sponsoredProducts;
  }
  return [];
};

const changeLinkedProductVariant = (productData, setProductData) => (productId, variantId) => {
  const updatedProductData = JSON.parse(JSON.stringify(productData));

  const { linkedProducts } = updatedProductData;
  if (!linkedProducts || !linkedProducts[productId]) {
    return;
  }
  updatedProductData.linkedProducts[productId].currentVariant = variantId;

  setProductData(updatedProductData);
};

const fetchComboData =
  (productData, setProductData) =>
  async ({ bundleIds, bundleLinks, politicalAreaId, priceGroupId, regionCode, toggles, site }) => {
    const fetchComboItemsDetailsUrl = apiConfig.getComboItemsUrl({
      regionCode,
      bundleIds,
      politicalId: politicalAreaId,
      priceGroupId,
      site,
    });
    const { data, errors } = await httpService().get(fetchComboItemsDetailsUrl);
    if (errors) {
      return;
    }
    const comboItems = _.get(data, 'products', []);
    if (comboItems.length === 0 || comboItems.length !== bundleIds.length) {
      return;
    }
    const { currentVariant, variants } = productData;
    const variantData = getVariant(variants, currentVariant);
    const comboData = constructComboData({
      comboItems,
      bundleLinks,
      toggles,
      regionCode,
      variantData,
    });
    if (comboData) {
      variantData.comboData = comboData;
      const updatedVariantsData = variants.filter((variant) => variant.id !== currentVariant);
      updatedVariantsData.push(variantData);
      const updatedProductData = JSON.parse(JSON.stringify(productData));
      updatedProductData.variants = updatedVariantsData;
      setProductData(updatedProductData);
    }
  };

function useProductContext({ data: initialProductData }) {
  const [productData, setProductData] = useState(initialProductData);
  const colorMetrics = useColorSwatchesMetrics();
  useEffect(() => {
    setProductData(initialProductData);
  }, [initialProductData]);
  return {
    recommendedProducts: [],
    ...productData,
    changeVariant: changeVariant(productData, setProductData),
    loadBundles: loadBundles(productData, setProductData),
    loadRecommendedProducts,
    loadSponsoredProducts,
    changeLinkedProductVariant: changeLinkedProductVariant(productData, setProductData),
    colorMetrics,
    fetchComboData: fetchComboData(productData, setProductData),
    setProductData,
  };
}

const ProductContext = createContainer(useProductContext);

const withProduct = (WrappedComponent) => {
  const { getInitialProps } = WrappedComponent;
  const ComponentWithProductData = (props) => {
    const productDataWithState = useContext(ProductContext.Context);
    return <WrappedComponent {...props} product={productDataWithState} />;
  };
  if (typeof getInitialProps === 'function') {
    ComponentWithProductData.getInitialProps = getInitialProps;
  }
  ComponentWithProductData.originalName = nameHOC(WrappedComponent);
  ComponentWithProductData.displayName = nameHOC(WrappedComponent, 'WithProduct');
  return ComponentWithProductData;
};

export default ProductContext;
export { withProduct, loadRecommendedProducts };
