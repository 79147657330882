import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { StepperStyles } from './Stepper.style';
import noop from '../../../utils/noop';

const Stepper = ({
  count,
  onIncrement,
  onDecrement,
  minimum,
  maximum,
  type,
  incrementButtonId,
  decrementButtonId,
  editable,
  onInputValueChange,
  inputType,
  inputMaxLength,
}) => {
  const [inputValue, setInputValue] = useState(count.toString());
  const [userInput, setUserInput] = useState('');
  const [shouldTriggerInputUpdate, setShouldTriggerInputUpdate] = useState(false);
  const inputRef = useRef(null);

  const onInputValueUpdate = () => {
    let newValue = 1;
    if (!inputValue || Number.isNaN(inputValue)) {
      newValue = minimum;
    } else if (inputValue < minimum) {
      newValue = minimum;
    } else if (inputValue > maximum) {
      newValue = maximum;
    } else {
      newValue = inputValue;
    }
    setInputValue(newValue);
    onInputValueChange(parseInt(newValue, 10));
  };

  useEffect(() => {
    if (inputType === 'text') {
      setInputValue(count.toString());
    } else {
      setInputValue(count);
    }
  }, [count]);

  useEffect(() => {
    if (shouldTriggerInputUpdate) {
      onInputValueUpdate();
      setShouldTriggerInputUpdate(false);
    }
  }, [shouldTriggerInputUpdate]);

  const supportedKeyValues = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'Delete'];

  const handleOnChange = (e) => {
    const newValue = e.target.value;
    if (inputRef.current.type === 'number') {
      setInputValue(parseInt(newValue, 10));
    }
    if (inputRef.current.type === 'text') {
      if (supportedKeyValues.includes(userInput)) {
        setInputValue(newValue);
      }
    }
  };

  const handleOnBlur = () => {
    onInputValueUpdate();
  };

  const handleOnKeyDown = (e) => {
    if (e.keyCode === 13 || e.which === 13 || e.key === 'Enter') {
      onInputValueUpdate();
      return false;
    }
    setUserInput(e.key);
    return true;
  };

  const increaseValue = () => {
    if (typeof onIncrement === 'function') {
      onIncrement();
    } else {
      const newValue = (parseInt(inputValue, 10) + 1).toString();
      setInputValue(newValue);
      setShouldTriggerInputUpdate(true);
    }
  };

  const decreaseValue = () => {
    if (typeof onDecrement === 'function') {
      onDecrement();
    } else {
      const newValue = (parseInt(inputValue, 10) - 1).toString();
      setInputValue(newValue);
      setShouldTriggerInputUpdate(true);
    }
  };

  const inputProps = (() => {
    let props = {};
    if (inputType === 'number') {
      props = { min: minimum, max: maximum };
    } else if (inputType === 'text') {
      props = {
        maxLength: typeof inputMaxLength === 'number' && inputMaxLength > 0 ? inputMaxLength : '',
        onKeyDown: handleOnKeyDown,
        onBlur: handleOnBlur,
      };
    }
    return props;
  })();

  return (
    <div className={`product-count ${type}`}>
      <button
        type="button"
        id={decrementButtonId}
        className={`decrement btn-${type}`}
        onClick={decreaseValue}
        disabled={!inputValue || inputValue <= minimum}
      >
        <span>&#8722;</span>
      </button>
      <div className={`product-count-value value-${type}`}>
        {editable ? (
          <input
            id="testId-stepper-input"
            ref={inputRef}
            type={inputType}
            value={inputValue}
            onChange={handleOnChange}
            // onBlur={handleOnBlur}
            // onKeyDown={handleOnKeyDown}
            {...inputProps}
          />
        ) : (
          count
        )}
      </div>
      <button
        type="button"
        id={incrementButtonId}
        className={`increment btn-${type}`}
        onClick={increaseValue}
        disabled={!inputValue || inputValue >= maximum}
      >
        <span>&#43;</span>
      </button>
      <style jsx>{StepperStyles}</style>
    </div>
  );
};

Stepper.defaultProps = {
  onIncrement: null,
  onDecrement: null,
  minimum: 1,
  maximum: 10,
  type: 'primary',
  incrementButtonId: 'testId-stepper-increment-btn',
  decrementButtonId: 'testId-stepper-decrement-btn',
  editable: false,
  onInputValueChange: noop,
  inputType: 'number',
  inputMaxLength: null,
};

Stepper.propTypes = {
  count: PropTypes.number.isRequired,
  onIncrement: PropTypes.func,
  onDecrement: PropTypes.func,
  minimum: PropTypes.number,
  maximum: PropTypes.number,
  type: PropTypes.oneOf(['primary', 'secondary']),
  incrementButtonId: PropTypes.string,
  decrementButtonId: PropTypes.string,
  editable: PropTypes.bool,
  onInputValueChange: PropTypes.func,
  inputType: PropTypes.oneOf(['number', 'text']),
  inputMaxLength: PropTypes.number,
};

export default Stepper;
