import css from 'styled-jsx/css';
import { icons } from '../../../config/styles';
import colors from '../../../config/styles/colors';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const AddToCartButtonStyles = css`
  .add-quantity-container {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .counter {
      width: 112px;
      margin-right: 10px;
    }

    .max-units-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 14.4px;
      color: ${colors.gray.shade19};
    }
  }
  .limit-execed-text {
    background-color: ${colors.yellow.shade4};
    max-width: 286px;
    padding: 4.75px 9.25px;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    border-radius: 4px;

    .warning-icon {
      width: 18.5px;
      height: 18.5px;
      background-image: url('${svgToDataUri(icons.floorCalcWarningIcon)}');
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 9.25px;
    }
    p {
      color: ${colors.black.shade1};
      font-size: 12px;
    }
  }
`;
export default AddToCartButtonStyles;
