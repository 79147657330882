import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { isWhitelabel } from '@digital-retail/store-manager';
import PropTypes from 'prop-types';
import React from 'react';

import BodyCopy from '../ui/BodyCopy/BodyCopy';
import badgesStyles, { puntospesos } from './MultipurposeBadge.style';

const PuntosPesosBadge = ({ badge }) => {
  const cmrPuntosArr = badge.label.split('+');
  return (
    <span>
      {cmrPuntosArr?.[0]}
      <i className="puntospesos-icon" />+{cmrPuntosArr?.[1] || ''}
      <style jsx>{puntospesos}</style>
    </span>
  );
};

PuntosPesosBadge.propTypes = {
  badge: PropTypes.object.isRequired,
};

const MultipurposeBadge = ({ badgeItems, layout, inPdp, store, theme }) => {
  return (
    <div className={`mtp-pod-badges mtp-pod-badges-${layout}`}>
      {badgeItems &&
        badgeItems.map((badge) => (
          <BodyCopy size="copy5" key={badge.label}>
            {(classes) => (
              <span
                id={`testId-Pod-badges-${badge.label}`}
                className={`${classes} pod-badges-item
                  ${inPdp ? 'inPdp' : ''}
                `}
                style={
                  isWhitelabel({ store })
                    ? { backgroundColor: theme?.secondary_color, color: '#FFFFFF' }
                    : {
                        backgroundColor: `${badge.styles.backgroundColor}`,
                        color: `${badge.styles.textColor}`,
                      }
                }
              >
                {badge.id === 'puntospesos' ? <PuntosPesosBadge badge={badge} /> : badge.label}
              </span>
            )}
          </BodyCopy>
        ))}
      <style jsx>{badgesStyles}</style>
    </div>
  );
};

MultipurposeBadge.propTypes = {
  badgeItems: PropTypes.array,
  layout: PropTypes.string,
  inPdp: PropTypes.bool,
  store: PropTypes.string,
  theme: PropTypes.object,
};

MultipurposeBadge.defaultProps = {
  badgeItems: [],
  layout: '',
  inPdp: false,
  store: '',
  theme: null,
};

export default withTheme(MultipurposeBadge);
