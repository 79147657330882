import Head from 'next/head';
import React from 'react';
import { okToShopInfoStylesCss } from './OkToShopInfo.style';

function OkToShopInfoHead() {
  return (
    <Head>
      <script type="text/javascript" async src="https://api.okto.shop/js/oktoshop_snippet-1.0.3.min.js" />
      <style type="text/css">{okToShopInfoStylesCss}</style>
    </Head>
  );
}

export default OkToShopInfoHead;
