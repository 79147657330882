import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import ComboItemsDesktopStyles from './ComboItemsDesktop.style';
import Image from '../ui/Image/Image';
import { withApplicationContext } from '../../utils/ApplicationContext';
import _ from '../../utils/LodashImports';
import { dispatchDDComboEvent, getComboItemPrice } from './helper';

const ScrollToSpecs = ({ scrollToElementId }) => {
  const viewMoreEl = document.getElementById(scrollToElementId);
  const clickHandler = (e) => {
    const expandSwatchButton = document.getElementById('swatch-collapsed-id');

    if (viewMoreEl) {
      if (expandSwatchButton) {
        expandSwatchButton.click();
        setTimeout(() => {
          viewMoreEl.scrollIntoView({ behavior: 'smooth' });
        });
      }
      viewMoreEl.scrollIntoView({ behavior: 'smooth' });
    }
    e.preventDefault();
  };

  return (
    <div>
      <button className="zoom-in-icon" onClick={clickHandler} type="submit" />
      <style jsx>{ComboItemsDesktopStyles}</style>
    </div>
  );
};

const ComboItemsDesktop = ({ appCtx, comboData }) => {
  const { regionCode } = appCtx;
  const toggles = _.get(appCtx, 'siteConfig.toggles', {});
  const COMBO_SECTION_HEADING = _.get(
    appCtx,
    'siteConfig.textDictionary.COMBO_SECTION_HEADING_DESKTOP',
    'Si compras estos productos por separado:'
  );

  const TOTAL_PRICE_SEPARATELY_TEXT = _.get(
    appCtx,
    'siteConfig.textDictionary.COMBO_TOTAL_PRICE_TEXT',
    'Total por separado'
  );

  useEffect(() => {
    dispatchDDComboEvent();
  }, []);

  const getFormattedPrice = (prices = []) => {
    const normalPrice = getComboItemPrice({ prices, regionCode, toggles });
    const symbol = _.get(normalPrice, '[0].symbol');
    const price = _.get(normalPrice, '[0].price[0]');
    return `${symbol}${price}`;
  };
  const { comboItems, totalPrice, showTotalPrice } = comboData;
  return (
    <div className="combos-container">
      <section className="heading">
        <div className="heading-logo" />
        <p className="title">{COMBO_SECTION_HEADING}</p>
      </section>
      <section className="summary-wrapper">
        <div className="photos-section">
          {comboItems.map((item, index) => {
            const imageUrl = _.get(item, 'product.mediaUrls[0]', null);
            const productUrl = _.get(item, 'product.url', null);
            const displayName = _.get(item, 'product.displayName', '');
            return (
              <Fragment key={productUrl}>
                <div className="photo">
                  {productUrl && (
                    <a className="item-redirect" href={productUrl}>
                      {imageUrl && (
                        <div className="image-wrapper">
                          <Image
                            imageUrl={imageUrl}
                            width={80}
                            height={80}
                            alt={displayName}
                            useSrcSet={false}
                            lazyLoad
                          />
                        </div>
                      )}
                    </a>
                  )}
                </div>
                {index !== comboItems.length - 1 && <div className="plus-sign"> + </div>}
              </Fragment>
            );
          })}
          {showTotalPrice && (
            <div className="combo-total-price">
              <div className="label">{TOTAL_PRICE_SEPARATELY_TEXT}</div>
              <div className="price-value">{totalPrice}</div>
            </div>
          )}
        </div>
      </section>
      <section className="combo-items-wrapper">
        {comboItems.map((item) => {
          const { brand, displayName, prices, url, attributes } = _.get(item, 'product', {});
          const specifications = attributes?.specifications || [];

          return (
            <div className="combo-item" key={displayName}>
              <div className="show-specs-wrapper">
                {specifications.length !== 0 && <ScrollToSpecs scrollToElementId={displayName} />}
              </div>
              <div>
                <p className="seller">{brand}</p>
                <a className="item-name" href={url}>
                  <p>{displayName}</p>
                </a>
              </div>
              {showTotalPrice && <div className="item-price">{getFormattedPrice(prices)}</div>}
            </div>
          );
        })}
      </section>
      <style jsx>{ComboItemsDesktopStyles}</style>
    </div>
  );
};

ScrollToSpecs.propTypes = {
  scrollToElementId: PropTypes.string.isRequired,
};

ComboItemsDesktop.propTypes = {
  appCtx: PropTypes.object.isRequired,
  comboData: PropTypes.object.isRequired,
};
export { ScrollToSpecs };

export default withApplicationContext(ComboItemsDesktop);
