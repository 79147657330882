/* eslint no-console: 0, no-underscore-dangle: 0 */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withCart } from '../../utils/CartContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { mapATGPricesToCatalystPrices } from '../../utils/PriceHelpers';
import { withProduct } from '../../utils/ProductContext';
import { isWindow } from '../../utils/deviceChecker';
import { getErrorMessage } from '../../utils/errorHandler';
import { findIndex } from '../../utils/jsUtils';
import { getTenant } from '../../utils/tenantHelper';
import useIntersection from '../../utils/useIntersection';
import { getVariant } from '../../utils/variant';
import Prices from '../Prices/Prices';
import Warning from '../Warning/Warning';
import Button from '../ui/Button/Button';
import Heading from '../ui/Heading/Heading';
import BundlePod from './BundlePod/BundlePod';
import ProductBundleStyles from './ProductBundle.style';
import { canChangeBundle, getTotalPrice } from './ProductBundleHelpers';

const reConstructProduct = (reConProduct) => {
  const { id, name, brandName, currentVariant, variants } = reConProduct;
  const constructedProduct = {
    id,
    brand: brandName,
    displayName: name,
    isSelected: true,
    prices: getVariant(variants, currentVariant).prices,
  };
  return constructedProduct;
};
const ProductBundle = ({ bundles, selectedProduct, appCtx, variant, cart, labels, heading, position, product }) => {
  const { regionCode, deviceType } = appCtx;
  const toggles = _.get(appCtx, 'siteConfig.toggles', {});
  const [total, setTotal] = useState([]);
  const [bundleProducts, setBundleProducts] = useState([]);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [errorAddingToCart, setErrorAddingToCart] = useState('');
  const [selectedProdLength, setSelectedProdLength] = useState(0);
  const isForCart = variant === 'cart';
  const isForRecos = variant === 'recommendation';
  const isSingleProduct = isForRecos && bundles.length === 1;
  const { variants } = selectedProduct;
  const bundleRecoRef = React.useRef(null);

  const isBundlesInViewport = isForRecos ? useIntersection(bundleRecoRef, '0px', '1', false) : false;
  if (isWindow() && isBundlesInViewport && isForRecos) {
    const payloadTitle = heading.split(' ').join('_');
    const skus = bundles.map((p) => p.offeringId).join(',');
    const eventToDigitalData = new CustomEvent('DDPDPBundlesImpressions', {
      bubbles: true,
      detail: { payload: `${payloadTitle}-${skus}`, type: 'bundle' },
    });
    window.dispatchEvent(eventToDigitalData);
  }
  const currentVariantId = _.get(selectedProduct, 'currentVariant');
  const currentVariant = getVariant(variants, currentVariantId);
  const variantOfferingId = _.get(currentVariant, 'offerings[0].offeringId', '');
  const currentProductPrice = {
    prices: isForCart ? [] : currentVariant.prices,
    id: selectedProduct.id,
    isSelected: !isForCart,
  };
  const calculateTotal = (prodPrices) => {
    const { selectedPrice, calculatedPrice } = getTotalPrice(prodPrices, regionCode, isForRecos);
    setSelectedProdLength(selectedPrice.length);
    setTotal(calculatedPrice);
  };
  const onBundleChange = (changedProduct) => {
    const updatedBundles = [...bundleProducts];
    const bundleIndex = findIndex(bundleProducts, (obj) => obj.id === changedProduct.id);
    if (isForCart && !canChangeBundle(changedProduct, bundleProducts)) {
      updatedBundles[bundleIndex] = {
        ...bundles[bundleIndex],
        ...changedProduct,
        isSelected: !changedProduct.isSelected,
      };
      setBundleProducts(updatedBundles);
      return;
    }
    const selectedPrice = [...selectedPrices];
    const productIndex = findIndex(selectedPrice, (obj) => obj.id === changedProduct.id);
    selectedPrice[productIndex] = {
      ...selectedPrice[productIndex],
      isSelected: changedProduct.isSelected,
    };
    updatedBundles[bundleIndex] = {
      ...bundles[bundleIndex],
      ...changedProduct,
    };
    setBundleProducts(updatedBundles);
    calculateTotal(selectedPrice);
    setSelectedPrices(selectedPrice);
  };
  const mapBundles = (rawBundles) => {
    const reStructuredBundles = rawBundles.map((bundle) => {
      const prices = _.get(bundle, 'product.prices', _.get(bundle, 'prices', []));
      const bundleData = variant === 'recommendation' ? bundle : bundle.product;
      if (!bundleData) return {};
      return {
        ...bundleData,
        prices: mapATGPricesToCatalystPrices(prices || [], regionCode, toggles),
        isSelected: bundleData.isSelected || true,
      };
    });
    // by default all bundles will be selected and mapping the selected prods
    setSelectedPrices([currentProductPrice, ...reStructuredBundles]);
    // calculating the total price from all bundle products
    calculateTotal([currentProductPrice, ...reStructuredBundles]);
    // setting the remapped bundles
    setBundleProducts(reStructuredBundles);
  };
  useEffect(() => {
    // re-mapping the bundle product structure to match with the search product response
    mapBundles(bundles);
    if (variant === 'recommendation') {
      product.setProductData({
        ...product,
        bundles,
      });
    }
  }, []);
  const handleAddToCart = () => {
    setErrorAddingToCart('');
    const productToAdd = bundleProducts
      .filter((bundle) => bundle.isSelected)
      .map(({ id, offeringId }) => {
        return {
          skuId: id,
          quantity: 1,
          offeringId,
        };
      });
    cart
      .addProduct({
        appCtx,
        products: isForCart
          ? []
          : [
              {
                skuId: currentVariantId,
                quantity: 1,
                offeringId: variantOfferingId,
              },
            ],
        bundledProducts: productToAdd,
        bundledProductOrigin: isForCart ? 'CART' : 'CTA',
        product,
      })
      .then((res) => {
        if (res) {
          const errorMessage = getErrorMessage(res, appCtx.siteConfig);
          setErrorAddingToCart(errorMessage);
        }
        const eventToDigitalData = new CustomEvent('DDPDPBundlesATC', {
          bubbles: true,
          detail: {
            productToAdd: bundleProducts.map((p) => p.id).join(';'),
            heading,
            tenant: getTenant(appCtx.regionCode),
            selectedProduct: selectedProduct.currentVariant,
            totalProductInReco: bundleProducts.length,
            position,
            selectedProductsLength: productToAdd.length,
          },
        });
        window.dispatchEvent(eventToDigitalData);
      });
  };
  const getPriceVariant = () => {
    if (isForCart) {
      return 'CART';
    }
    return deviceType === 'desktop' ? 'BUNDLES' : 'MOBILE_BUNDLES';
  };

  const bundleTitleClass = 'mkp-bundleTitle';
  const addToCartButtonText = _.get(labels, 'ADD_X_ITEM_TO_BAG', 'AGREGAR LOS {count} AL CARRO');
  const priceContainerClass = ' mkp';
  const ALL_FOR_TEXT = _.get(labels, 'ALL_FOR_TEXT', 'Todos por');
  return (
    <div
      data-widget-name={heading}
      ref={bundleRecoRef}
      className={`container pdp-body bundle-wrapper ${deviceType} ${variant}`}
    >
      <div className={`bundle-heading ${isForRecos ? 'rebranding-header' : ''}`}>
        {!isForRecos && <i className={`csicon-${bundleTitleClass}`} />}
        <Heading variation={isForRecos ? 'title10' : 'title5'} level={2}>
          {heading || labels.BUNDLED_PRODUCT_LABEL}
        </Heading>
      </div>
      <div className={`pod-wrapper ${isForRecos && bundles.length === 1 ? 'single-product' : ''}`}>
        <div className={`bundles-box ${isForRecos && !isSingleProduct ? 'rebranding-bundles-boxes' : ''}`}>
          {!isForCart && (
            <BundlePod
              variant={variant}
              product={reConstructProduct(selectedProduct)}
              disableClick
              isCurrentProduct
              key="selectedProd"
              isSingleProduct={isSingleProduct}
              heading={heading}
              currentProductID={selectedProduct.currentVariant}
              totalProductInReco={bundleProducts.length}
              position={position}
              productPosition={0}
            />
          )}
          {bundleProducts.map((item, i) => (
            <BundlePod
              variant={variant}
              product={item}
              onBundleChange={onBundleChange}
              key={`BundlePod-${item.id}`}
              isSingleProduct={isSingleProduct}
              heading={heading}
              currentProductID={selectedProduct.currentVariant}
              totalProductInReco={bundleProducts.length}
              position={position}
              productPosition={i + 1}
            />
          ))}
        </div>
        {(!isForRecos || isSingleProduct) && (
          <div className={`price-box${priceContainerClass}`}>
            <div className={`prices ${isForCart ? 'cart' : ''}`}>
              <span className="price-total">
                {isSingleProduct ? ALL_FOR_TEXT : labels.BUNDLED_PRODUCT_TOTAL_PRICE_LABEL}:
              </span>
              <Prices priceList={total} deviceType={deviceType} variant={getPriceVariant()} page="PDP" />
            </div>
            <div className="reco-add-to-cart">
              <Button type="mkp-secondary" onClick={handleAddToCart} size="medium-extend">
                {addToCartButtonText.replace('{count}', selectedProdLength)}
              </Button>
            </div>
            {errorAddingToCart && <Warning label={errorAddingToCart} iconSize="copy10" labelSize="copy3" forPDP />}
          </div>
        )}
      </div>
      {isForRecos && !isSingleProduct && (
        <div className={`price-box${priceContainerClass}`}>
          <div className="prices reco-prices">
            <span className="price-total">{ALL_FOR_TEXT}:</span>
            <Prices priceList={total} deviceType={deviceType} variant={getPriceVariant()} page="PDP" />
          </div>
          <div className="reco-add-to-cart">
            <Button type="mkp-secondary" onClick={handleAddToCart} size="medium-extend">
              {addToCartButtonText.replace('{count}', selectedProdLength)}
            </Button>
          </div>
          {errorAddingToCart && <Warning label={errorAddingToCart} iconSize="copy10" labelSize="copy3" forPDP />}
        </div>
      )}
      <style jsx>{ProductBundleStyles}</style>
    </div>
  );
};
ProductBundle.defaultProps = {
  variant: 'default',
  heading: '',
  position: 0,
};
ProductBundle.propTypes = {
  bundles: PropTypes.array.isRequired,
  labels: PropTypes.object.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  variant: PropTypes.string,
  cart: PropTypes.object.isRequired,
  heading: PropTypes.string,
  position: PropTypes.number,
  product: PropTypes.object.isRequired,
};
export default withLabels(withCart(withApplicationContext(withProduct(ProductBundle))));
export { ProductBundle };
