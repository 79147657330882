import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import { ScrollToStyles } from './ScrollTo.style';

const ScrollTo = ({ labels, scrollToElementId, type, isSoCom = false }) => {
  const handleClick = (e) => {
    const viewMoreEl = document.getElementById(scrollToElementId);
    if (!viewMoreEl) {
      return;
    }
    viewMoreEl.scrollIntoView({ behavior: 'smooth' });
    e.preventDefault();
  };
  const viewMoreSpecsText = isSoCom
    ? _.get(labels, 'SO_COM_VIEW_MORE_SPECIFICATONS', 'Ver más')
    : labels.VIEW_MORE_SPECIFICATONS;

  return (
    <button
      className={`specifications-action rebranded ${type}`}
      id="testId-topSpecifications-action-btn"
      onClick={handleClick}
      type="button"
    >
      {viewMoreSpecsText}
      <style jsx>{ScrollToStyles}</style>
    </button>
  );
};

ScrollTo.defaultProps = {
  labels: {},
  scrollToElementId: '',
  type: '',
  isSoCom: false,
};

ScrollTo.propTypes = {
  labels: PropTypes.object,
  scrollToElementId: PropTypes.string,
  type: PropTypes.string,
  isSoCom: PropTypes.bool,
};

export { ScrollTo };
export default withLabels(ScrollTo);
