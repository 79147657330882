export const storeMap = {
  sodimac: 'sodimac',
  tottus: 'tottus',
  linio: 'linio',
  tienda: 'falabella',
  homecenter: 'homecenter',
  // Standalone
  so_com: 'so_com',
  to_com: 'to_com',
  fa_america: 'fa_america',
  fa_carters: 'fa_carters',
  fa_aldo: 'fa_aldo',
  fa_crabarr: 'fa_carters',
};
