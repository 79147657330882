import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { WarrantySelectEnhancedStyles } from './WarrantySelectEnhanced.style';
import noop from '../../../utils/noop';
import Warning from '../../Warning/Warning';
import _ from '../../../utils/LodashImports';
import {
  getWarrantyBenefits,
  isWarrantyRecommended,
  getSelectedIndex,
  getWarrantyLabel,
} from './WarrantySelect.helpers';
import { withProduct } from '../../../utils/ProductContext';

const WarrantySelectEnhanced = ({
  errorMessage,
  handleWarrantyOptionsSelected,
  warrantyOptionCurrent,
  options,
  appCtx,
  product,
  source = '',
}) => {
  const [benefitsVisibility, setBenefitsVisibility] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(getSelectedIndex({ options, warrantyOptionCurrent }));

  useEffect(() => {
    setSelectedIndex(getSelectedIndex({ options, warrantyOptionCurrent }));
  }, [warrantyOptionCurrent]);

  const toggleBenefitsVisibility = () => {
    setBenefitsVisibility((prevState) => !prevState);
  };

  const { deviceType, siteConfig, regionCode } = appCtx;
  const isMobile = deviceType === 'mobile';
  const gCategoryId = _.get(product, 'attributes.GSCCategoryId', '');
  const sellerName = _.get(product, 'variants[0].offerings[0].sellerName', '');
  const {
    WARRANTIES_MODAL_LABEL = 'Extiende la garantía del producto',
    WARRANTY_BENEFITS_LABEL = 'Beneficios de extender la garantía',
    PDP_NO_THANKS = 'Sin garantía extendida',
    WARRANTY_ADDED = 'Agregado',
    WARRANTY_LABEL_TEMPLATE = '{warrantyName}',
    RECOMMENDED_WARRANTY = '¡Recomendado!',
    MORE_WARRANTY_BENEFITS = 'Más beneficios',
  } = _.get(siteConfig, 'textDictionary', {});
  const moreWarrantyBenefitsLink = _.get(siteConfig, 'endpoints.moreWarrantyBenefitsLink');
  const warranties = options.filter((option) => option.offeringId || option.value);

  const warrantyBenefits = getWarrantyBenefits({
    siteConfig,
    regionCode,
    gCategoryId,
  });

  const handleOptionChange = (index) => {
    if (selectedIndex !== index) {
      handleWarrantyOptionsSelected(options[index]);
      setSelectedIndex(index);
    }
  };

  const renderWarrantySku = (warranty, index) => {
    const { name, textPrice } = warranty;
    const warrantyLabel = getWarrantyLabel({
      name,
      sellerName,
      warrantyLabelTemplate: WARRANTY_LABEL_TEMPLATE,
    });
    const isSelected = index === selectedIndex;
    const isRecommended = isWarrantyRecommended(name);
    return (
      <>
        <button
          type="submit"
          id="warranty-sku"
          className={`warranty-sku ${isSelected ? 'selected' : ''}`}
          onClick={() => handleOptionChange(index)}
        >
          <div className="label-wrapper">
            <span className={`radio-button ${isSelected ? 'full' : 'empty'}`} />
            <div className="warranty-details">
              <p className="warranty-text">{warrantyLabel}</p>
              <div className="badges">
                {isRecommended && <p className="recommended-badge">{RECOMMENDED_WARRANTY}</p>}
                {isSelected && (
                  <span className="selected-label">
                    <i className="tick-icon" />
                    <p>{WARRANTY_ADDED}</p>
                  </span>
                )}
              </div>
            </div>
          </div>
          <span className="price">{textPrice}</span>
        </button>
        <style jsx>{WarrantySelectEnhancedStyles}</style>
      </>
    );
  };

  return (
    <div className="warranty-wrapper">
      <div className="title">
        <div className="warranty-icon" />
        <p>{WARRANTIES_MODAL_LABEL}</p>
      </div>
      <div className="body">
        <div className="sku-list">
          {warranties.map((warranty, index) => renderWarrantySku(warranty, index))}
          <button
            type="submit"
            className={`warranty-sku ${warranties.length === selectedIndex ? 'selected' : ''}`}
            onClick={() => handleOptionChange(warranties.length)}
          >
            <div className="label-wrapper">
              <span className={`radio-button ${selectedIndex === warranties.length ? 'full' : 'empty'}`} />
              <span className="warranty-details">
                <span className="warranty-text">{PDP_NO_THANKS}</span>
              </span>
            </div>
          </button>
        </div>
        {warrantyBenefits.length > 0 && (
          <div className="benefits-list">
            <div className={`title ${source}`}>
              <p>{WARRANTY_BENEFITS_LABEL}</p>
              {isMobile && source !== 'drawer' && (
                <button type="submit" onClick={toggleBenefitsVisibility}>
                  <i className={`${benefitsVisibility ? 'collapse' : 'expand'}`} />
                </button>
              )}
            </div>
            {(!isMobile || source === 'drawer' || benefitsVisibility) && (
              <div className="list">
                {warrantyBenefits.map((benefit) => (
                  <span className="benefit">
                    <i className="tick-icon" />
                    <p>{benefit}</p>
                  </span>
                ))}
                {moreWarrantyBenefitsLink && (
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={moreWarrantyBenefitsLink}
                    className="link-wrapper"
                    tabIndex={0}
                  >
                    <span className="more-benefits">{MORE_WARRANTY_BENEFITS}</span>
                    <i className="csicon-right" />
                  </a>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {errorMessage && <Warning label={errorMessage} iconSize="copy2" labelSize="copy5" />}
      <style jsx>{WarrantySelectEnhancedStyles}</style>
    </div>
  );
};

WarrantySelectEnhanced.defaultProps = {
  warrantyOptionCurrent: null,
  errorMessage: null,
  handleWarrantyOptionsSelected: noop,
  options: [],
  appCtx: {},
  product: {},
  source: '',
};

WarrantySelectEnhanced.propTypes = {
  warrantyOptionCurrent: PropTypes.object,
  errorMessage: PropTypes.string,
  handleWarrantyOptionsSelected: PropTypes.func,
  options: PropTypes.array,
  appCtx: PropTypes.object,
  product: PropTypes.object,
  source: PropTypes.string,
};

export default withProduct(WarrantySelectEnhanced);
