import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withApplicationContext } from '../../../../utils/ApplicationContext';
import _ from '../../../../utils/LodashImports';
import noop from '../../../../utils/noop';
import { DeliveryOptionsContentStyles } from './DeliveryOptionsContent.style';

const getSubtitle = (type, appCtx, isFreeShippingApplicable, styles) => {
  const hoyRegex = /hoy/g;
  const getFreeShippingLabelAmount = _.get(appCtx, 'siteConfig.textDictionary.ENVIO_GRATIS_LABEL_AMOUNT', '$29.990');

  if (type === 'HomeDelivery') {
    if (hoyRegex.test(type)) {
      return (
        <>
          <p className="data-subtitle">Si compras hasta las 14:00 horas.</p>
          <style jsx>{styles}</style>
        </>
      );
    }
    if (isFreeShippingApplicable) {
      return (
        <>
          <p className="data-subtitle">
            Envío <span>gratis</span> {`en productos pequeños por compras desde ${getFreeShippingLabelAmount}.`}
          </p>
          <style jsx>{styles}</style>
        </>
      );
    }
  }
  if (type === 'PickupInStore') {
    if (hoyRegex.test(type)) {
      return (
        <>
          <p className="data-subtitle">Si compras hasta las 14:00 horas.</p>
          <style jsx>{styles}</style>
        </>
      );
    }
    return (
      <>
        <p className="data-subtitle">Al pagar podrás seleccionar la fecha para retirar tu pedido.</p>
        <style jsx>{styles}</style>
      </>
    );
  }
  return null;
};

const DeliveryOptionsList = ({ data, appCtx, setBusy, isFreeShippingApplicable, type, deviceType, theme }) => {
  const themedStoresListStyles = DeliveryOptionsContentStyles(theme);
  useEffect(() => {
    setBusy(false);
  }, []);

  const filteredData = data.filter((entity) => {
    return entity.show === true;
  });

  return (
    <div className="data-section">
      <h1>{`Opciones de ${type === 'HomeDelivery' ? 'envío' : 'retiro'}:`}</h1>
      <div className="data-body">
        {filteredData.map((entity) => {
          return (
            <div className="data-list" key={entity.title}>
              <div className="data-entity">
                <i className="check-icon" />
                <p className="data-title">
                  {`${entity.title} ${deviceType === 'desktop' ? '-' : ''} `}
                  <span
                    className={`data-span ${isFreeShippingApplicable || type === 'PickupInStore' ? 'green bold' : ''}`}
                  >{`${entity.price}`}</span>
                </p>
              </div>
              {getSubtitle(type, appCtx, isFreeShippingApplicable, themedStoresListStyles)}
            </div>
          );
        })}
      </div>
      <style jsx>{themedStoresListStyles}</style>
    </div>
  );
};

DeliveryOptionsList.defaultProps = {
  data: [],
  setBusy: noop,
  isFreeShippingApplicable: false,
  type: 'HomeDelivery',
  deviceType: 'Desktop',
};

DeliveryOptionsList.propTypes = {
  data: PropTypes.array,
  appCtx: PropTypes.object.isRequired,
  setBusy: PropTypes.func,
  isFreeShippingApplicable: PropTypes.bool,
  type: PropTypes.string,
  deviceType: PropTypes.string,
  theme: PropTypes.object.isRequired,
};
export default withApplicationContext(withTheme(DeliveryOptionsList));
