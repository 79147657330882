import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { withProduct } from '../../utils/ProductContext';
import Heading from '../ui/Heading/Heading';
import ProductOutOfStockStyles from './ProductOutOfStock.styles';

const ProductOutOfStock = ({ labels, appCtx: { deviceType, comuna } }) => {
  const isMobile = deviceType === constants.DEVICE_MOBILE;

  React.useEffect(() => {
    const eventToDigitalData = new CustomEvent('DDPDPStoreAvailabilityMetricsForOOS', {
      bubbles: true,
      detail: { selectedZone: { comuna: _.get(comuna, 'data.name', '') } },
    });
    window.dispatchEvent(eventToDigitalData);
  }, []);
  return (
    <div className="outofstock-container" id="testId-product-outofstock">
      <div className="warning-box">
        <div className="heading">
          <i className="warning-icon" />
          <Heading level={2} variation={isMobile ? 'title7' : 'title10'}>
            {labels.PRODUCT_OUTOFSTOCK}
          </Heading>
        </div>
      </div>
      <style jsx>{ProductOutOfStockStyles}</style>
    </div>
  );
};

ProductOutOfStock.propTypes = {
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
};

export default withApplicationContext(withLabels(withProduct(ProductOutOfStock)));
