import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { withDigitalData } from '../../utils/DigitalDataContext';
import { withProduct } from '../../utils/ProductContext';
import { getVariant } from '../../utils/variant';
import MatchMedia from '../ui/MatchMedia/MatchMedia';
import AvailabilityDesktop from './AvailabilityDektop/AvailabilityDesktop';
import AvailabilityMobile from './AvailabilityMobile/AvailabilityMobile';

const Availability = ({ product, isSoftline, digitalData, sizes, colorVariants, regionCode }) => {
  const { currentVariant, variants } = product;

  const {
    isHDAvailable: isHomeDeliveryAvailable,
    isCCAvailable: isPickFromStoreAvailable,
    isOnlineSellable,
    isHDVisible,
    isCCVisible,
    isOnlineSellableVisible,
  } = getVariant(variants, currentVariant);

  digitalData.pdp.setHomeDelivery(isHomeDeliveryAvailable);
  digitalData.pdp.setStorePickup(isPickFromStoreAvailable);

  const getStateOfStock = (state) => (state ? constants.AVAILABILITY_INSTOCK : constants.AVAILABILITY_OUTOFSTOCK);

  const getProcessedAvailability = () => {
    const AvailabilityMap = {
      PurchaseOnline: {
        shippingOptionType: constants.AVAILABILITY_PURCHASEONLINE,
        stateOfStock: getStateOfStock(isOnlineSellable),
      },
      PickupInStore: {
        shippingOptionType: constants.AVAILABILITY_PICKUPINSTORE,
        stateOfStock: getStateOfStock(isPickFromStoreAvailable),
      },
      HomeDelivery: {
        shippingOptionType: constants.AVAILABILITY_HOMEDELIVERY,
        stateOfStock: getStateOfStock(isHomeDeliveryAvailable),
      },
    };

    const processedAvailabilityList = [
      {
        isVisible: isHDVisible,
        data: AvailabilityMap.HomeDelivery,
      },
      {
        isVisible: isCCVisible,
        data: AvailabilityMap.PickupInStore,
      },
      {
        isVisible: isOnlineSellableVisible,
        data: AvailabilityMap.PurchaseOnline,
      },
    ];

    const isPurchaseOnlineVisibleInPDP = 0;

    if (isPurchaseOnlineVisibleInPDP) {
      processedAvailabilityList.push(AvailabilityMap.PurchaseOnline);
    }

    return processedAvailabilityList
      .map(({ isVisible, data }) => {
        if (isVisible) {
          return data;
        }
        return undefined;
      })
      .filter(Boolean);
  };

  const processedAvailabilityList = getProcessedAvailability();

  if (processedAvailabilityList.length === 0) {
    return null;
  }

  return (
    <MatchMedia
      content={{
        mobile: () => (
          <AvailabilityMobile
            availabilityList={processedAvailabilityList}
            sizes={sizes}
            colorVariants={colorVariants}
          />
        ),
        tablet: () => (
          <AvailabilityMobile
            availabilityList={processedAvailabilityList}
            sizes={sizes}
            colorVariants={colorVariants}
          />
        ),
        desktop: () => (
          <AvailabilityDesktop
            availabilityList={processedAvailabilityList}
            isSoftline={isSoftline}
            sizes={sizes}
            colorVariants={colorVariants}
            regionCode={regionCode}
          />
        ),
      }}
    />
  );
};
Availability.propTypes = {
  product: PropTypes.object.isRequired,
  isSoftline: PropTypes.bool,
  digitalData: PropTypes.object.isRequired,
  sizes: PropTypes.array,
  colorVariants: PropTypes.array,
  regionCode: PropTypes.string.isRequired,
};

Availability.defaultProps = {
  isSoftline: false,
  sizes: [],
  colorVariants: [],
};

export default withProduct(withDigitalData(Availability));
