/* stylelint-disable no-descending-specificity */
import css from 'styled-jsx/css';
import { svgToDataUri } from '../../../utils/styles/svgMixins';
import { icons, colors } from '../../../config/styles';
import { svgUrl } from '../../../utils/staticUrls';

const StoreDetailStyles = css`
  .store-detail-container {
    button {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      font-size: 16px;
      color: ${colors.blue.shade3};
      .csicon-right {
        color: ${colors.black.shade7};
        transform: rotate(180deg);
        margin-right: 30.75px;
        width: 8.59px;
      }
      span {
        text-decoration: underline;
        line-height: 19.2px;
      }
    }
    .divider {
      padding: 0 18px 21px;
      div {
        border-top: 1px solid #e6e6e6;
      }
    }
    .store-details {
      padding: 0 24px;
      &.secondary {
        position: absolute;
        top: 8px;
        right: 6px;
        background: #fff;
        width: 358px;
        padding: 8px 12px;
        .address {
          line-height: 14px;
        }
        .open-maps {
          padding-top: 4px;
        }
        h3 {
          line-height: 16.8px;
          font-size: 14px;
        }
        .work-hours {
          padding-top: 6px;
        }
      }

      h3 {
        font-weight: bold;
        color: ${colors.gray.shade40};
        line-height: 19.2px;
        font-size: 16px;
      }
      i.location-icon {
        background-image: url('${svgToDataUri(icons.locationSmall)}');
        padding: 8px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 6.33px;
      }
    }
    .address {
      display: flex;
      align-items: center;
      color: ${colors.gray.shade40};
      font-size: 13px;
      line-height: 20px;
      padding: 8px 0 5px 0;
    }
    .work-hours {
      a {
        color: ${colors.gray.shade45};
        font-size: 13px;
        margin: 0 0 29px 23px;
        line-height: 18px;
        text-decoration: none;
        display: inline-block;
        @mixin tabletToDesktop {
          font-size: 12px;
          margin-left: 0;
          margin-bottom: 15px;
        }
        span:first-child {
          border-bottom: 1px solid ${colors.gray.shade40};
        }
      }
      .rightArrowIcon {
        background-image: url(${svgUrl('arrow-right.svg')});
        background-repeat: no-repeat;
        width: 11px;
        height: 8px;
        display: inline-block;
        vertical-align: middle;
        background-position: center;
        margin-left: 7px;
        @mixin tabletToDesktop {
          width: 8.4px;
          height: 6px;
          margin-left: 9px;
        }
      }
    }
    .open-maps {
      color: ${colors.gray.shade45};
      font-size: 13px;
      border-bottom: 1px solid ${colors.gray.shade40};
      padding: 8px 0 0 0;
      line-height: 18px;
      text-decoration: none;
      @mixin tabletToDesktop {
        font-size: 12px;
      }
    }
    div.map-section {
      width: 100%;
      height: 419px;
      margin-top: 16px;
    }
  }
`;

export default StoreDetailStyles;
