/* eslint-disable no-console */
import _ from 'lodash';
import constants from '../config/constants';

function isAValidObject(obj) {
  return Boolean(obj && typeof obj === 'object' && Object.keys(obj).length >= 1);
}

function isValidJson(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

export const getCmrPoints = (labels, regionCode) => {
  const encodedCmrMapping = _.get(labels, 'CMR_MAPPING', null);

  if (encodedCmrMapping) {
    const bufferedCMR = Buffer.from(encodedCmrMapping, 'base64');
    const cmrParsedObject = isValidJson(bufferedCMR) ? JSON.parse(bufferedCMR) : null;

    if (cmrParsedObject) {
      const newCmrListOptions = cmrParsedObject.map((item) => ({
        ...item,
        logo: constants.LOGOMAPPINGS[item.logo] || item.logo,
      }));

      return isAValidObject(newCmrListOptions) ? newCmrListOptions : constants.CMR_LIST_OPTIONS[regionCode];
    }
    return constants.CMR_LIST_OPTIONS[regionCode];
  }
  return constants.CMR_LIST_OPTIONS[regionCode];
};
