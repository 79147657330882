import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withLabels } from '../../utils/LabelsContext';
import _ from '../../utils/LodashImports';
import { withProduct } from '../../utils/ProductContext';
import { getUniqueOperators, getVariant } from '../../utils/variant';
import WithUrl from '../../utils/WithUrl';
import ProductPrices from '../ProductPrices/ProductPrices';
import ProductTopSpecifications from '../ProductTopSpecifications/ProductTopSpecifications';
import Heading from '../ui/Heading/Heading';
import LinkButton from '../ui/LinkButton/LinkButton';
import { ProductSpecificationConnectStyles } from './ProductSpecificationConnect.style';

const getMinimumProductPrice = (prices) => {
  return Array.isArray(prices) && prices.length ? [prices[0]] : [];
};

const ProductSpecificationConnect = ({ product, appCtx, labels }) => {
  const { regionCode, deviceType } = appCtx;
  const { id: productId, name: productName } = product;
  const { currentVariant, variants } = product;
  const variant = getVariant(variants, currentVariant);

  const { deviceCost = [], deviceCmrPoints: cmrPoints, monthlyCost } = variant;

  const activeVariant = variants.find((v) => {
    return v.id === currentVariant;
  });

  const shouldRenderSpecifications = deviceType === constants.DEVICE_DESKTOP;
  const isMobile = deviceType === constants.DEVICE_MOBILE;
  const isMobileOrTablet = [constants.DEVICE_MOBILE, constants.DEVICE_TABLET].includes(deviceType);
  const minProductPrice = getMinimumProductPrice(deviceCost);

  const productColorCode = _.get(activeVariant, 'attributes.colorName', '');
  const operators = getUniqueOperators(productColorCode, variants);
  const isViewPlanActive = operators.length > 0 && minProductPrice.length > 0 && monthlyCost;

  let displayPrice = [];
  if (minProductPrice && minProductPrice.length > 0) {
    displayPrice = minProductPrice;
  }
  const planUrl = WithUrl('PLAN_URL', regionCode, {
    custom: {
      productId,
      productName: productName.replace('/', '-'),
      variantId: activeVariant.id,
    },
  });
  return (
    <div
      className="product-specifications"
      data-backend-category={product.merchantCategoryId}
      data-availability={product.attributes && product.attributes.isPrimeAvailable}
      data-published={product.isPublished}
      id="testId-ProductSpecificationConnect-container"
    >
      <div className="product-specifications-column">
        {shouldRenderSpecifications && <ProductTopSpecifications scollToElementId="productInfoContainer" />}
      </div>
      <div
        className={clsx('product-specifications-column', {
          'product-specifications-column-panel': isMobile,
        })}
      >
        {isViewPlanActive ? (
          <>
            {displayPrice && (
              <div className="connect-prices">
                <div className="info-message">{labels.TEAM_FROM_LABEL}</div>
                <ProductPrices
                  prices={displayPrice}
                  productId={productId}
                  regionCode={regionCode}
                  cmrPoints={cmrPoints}
                  isMobile={isMobile}
                />
              </div>
            )}
            <div className="plan-review-container">
              <div className="info-message">{labels.IDEAL_PLAN_LABEL}</div>
              <p className="plan-review-text">{labels.PLAN_REVIEW_LABEL}</p>
            </div>
            <div className={isMobileOrTablet ? 'viewPlans-container' : ''}>
              <LinkButton
                href={planUrl}
                type="rebranding"
                size={!isMobileOrTablet ? 'large' : 'medium'}
                id="testId-ProductSpecificationConnect-viewPlan"
              >
                <span>{labels.VIEW_PLANES_LABEL_REBRANDING}</span>
              </LinkButton>
            </div>
          </>
        ) : (
          <>
            <Heading labels={3} variation="title8">
              {labels.NO_PLAN_TITLE}
            </Heading>
            <p className="no-plan-text">{labels.NO_PLAN_LABEL}</p>
          </>
        )}
      </div>
      <style jsx>{ProductSpecificationConnectStyles}</style>
    </div>
  );
};

ProductSpecificationConnect.propTypes = {
  product: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
};

export { ProductSpecificationConnect };
export default withProduct(withApplicationContext(withLabels(ProductSpecificationConnect)));
