import css from 'styled-jsx/css';
import { colors } from '../../../config/styles';
import { svgToDataUri, svgReplaceColor } from '../../../utils/styles/svgMixins';
import icons from '../../../config/styles/icons';

const SoftLineMobileStyles = css`
  .pdp-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;
  }

  .pdp-body {
    background-color: ${colors.shade6};
    &-container {
      background-color: white;
    }
  }

  .pdp-image-section {
    width: 100%;
    padding: 0;
  }

  .pdp-detail-section {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 16px;
    margin-top: 11px;
  }
  .flex {
    display: flex;
    align-items: center;
  }
  .icon {
    min-width: 34px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: block;
    &.internet-icon-disabled {
      background-image: url('${svgToDataUri(svgReplaceColor(icons.onlyInternet, colors.gray.shade40))}');
      height: 30px;
    }
    &.internet-icon-active {
      background-image: url('${svgToDataUri(svgReplaceColor(icons.onlyInternet, colors.green.shade1))}');
      height: 30px;
    }
    &.tienda-active {
      background-image: url('${svgToDataUri(svgReplaceColor(icons.tiendaIcon, colors.green.shade1))}');
      height: 22px;
    }
    &.tienda-disabled {
      background-image: url('${svgToDataUri(svgReplaceColor(icons.tiendaIcon, colors.gray.shade40))}');
      height: 22px;
    }
  }
  .headline-image-and-details {
    display: flex;
    margin-top: 17px;
    .pdp-image-section {
      flex: 0 0 84px;
      margin-left: 20px;
      margin-right: 24px;
      padding-top: 20px;
    }
    .pdp-detail-section {
      margin: 0;
      padding: 10px 0;
    }
  }
  .rating_summary-wrapper {
    margin: 0 30px 20px;
  }
  .combo-wrapper {
    border-top: 1px solid ${colors.gray.shade36};
  }
  .out-of-stock {
    .oos-wrapper {
      padding: 0 16px;
    }
  }
`;

export default SoftLineMobileStyles;
