import { isWhitelabel } from '@digital-retail/store-manager';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import AdditionalInfoDrawer from '../../../components/AdditionalInfoDrawer/AdditionalInfoDrawer';
import Availability from '../../../components/Availability/Availability';
import { AvailabilityLocation } from '../../../components/Availability/AvailabilityLocation/AvailabilityLocation';
import { getDeliveryOptionBorderClassName } from '../../../components/Availability/DeliveryOptions/utils';
import CMRCalculatorMobile from '../../../components/CMRCalculator/Mobile';
import ComboItemsMobile from '../../../components/ComboSection/ComboItemsMobile';
import NearbyStores from '../../../components/NearbyStores';
import ProductServices from '../../../components/ProductServices/ProductServices';
import Warranty from '../../../components/Warranty/Warranty';
import Button from '../../../components/ui/Button/Button';
import _ from '../../../utils/LodashImports';
import { useSelectedDeliveryOption } from '../../../utils/hooks/UseSelectedDeliveryOption';
import { getVariant } from '../../../utils/variant';
import ReturnPolicy from '../../ReturnPolicy/ReturnPolicy';
import { GetSharedDetailsStyles } from './index.style';

const GetSharedDetails = ({
  sizes,
  colorVariants,
  regionCode,
  store,
  is1pSeller,
  showStoreAvailability,
  showCollectionButtonVisible,
  handleAddToCart,
  comboData,
  product,
  labels,
  showCMRCalculator,
  setServiceOption,
  setWarrantyOption,
  isWarrantyTooltipEnabled,
  additionalWarrantyLabel,
  layout,
  isSoCom = false,
  appCtx,
}) => {
  const [comunaName, setComunaName] = useState('');
  const { isImprovedWarrantyUiEnabled = true, isImprovedServicesUiEnabled = true } = _.get(
    appCtx,
    'siteConfig.toggles',
    {}
  );

  const { variants, currentVariant: currentVariantId, darSectionActive, warrantyOptions, serviceOptions } = product;
  const variant = getVariant(variants, currentVariantId);
  const sellerName = _.get(variants, '[0].offerings[0].sellerName', '');

  const isWhitelabelSite = isWhitelabel({ store: appCtx.store });

  const { availability } = variant;
  const { zoneConfig } = useSelectedDeliveryOption(availability?.shippingOptionType, appCtx, currentVariantId);
  useEffect(() => {
    if (zoneConfig) {
      setComunaName(zoneConfig.name);
    }
  }, [zoneConfig]);

  return (
    <>
      {showCollectionButtonVisible && (
        <div className={`addToCart-container ${layout === 'hardline' ? 'collection' : ''} fa--add-cart__mobile`}>
          <Button type="outline" onClick={handleAddToCart} size="xtra-large">
            <span>
              {labels.SEE_COLLECTION_BUTTON_LABEL}
              <i className="csicon-arrow_down_small" />
            </span>
          </Button>
        </div>
      )}
      {isImprovedWarrantyUiEnabled && isImprovedServicesUiEnabled && (
        <>
          <Warranty
            warrantyOptions={warrantyOptions}
            setWarrantyOption={setWarrantyOption}
            startsFromLabel={labels.PDP_STARTS_FROM}
            tooltipToggle={isWarrantyTooltipEnabled}
            additionalWarrantyLabel={additionalWarrantyLabel}
            sellerName={sellerName}
          />
          <div className="services-divider" />
          <ProductServices serviceOptions={serviceOptions} setServiceOption={setServiceOption} />
        </>
      )}
      {!!product.showShippingModal && (
        <div className={clsx('delivery-options-wrapper', getDeliveryOptionBorderClassName(isSoCom, isWhitelabelSite))}>
          {comunaName && (
            <div className="avl-content">
              <AvailabilityLocation comunaName={comunaName} />
            </div>
          )}
          <div className="dlv-opt-wrapper">
            <Availability sizes={sizes} colorVariants={colorVariants} regionCode={regionCode} />
            {(!!store || is1pSeller) && showStoreAvailability && (
              <NearbyStores sizes={sizes} colorVariants={colorVariants} />
            )}
          </div>
        </div>
      )}
      <div className="marginWrapper">
        {comboData && <ComboItemsMobile comboData={comboData} />}
        <AdditionalInfoDrawer description={product.longDescription} labels={labels} isSoCom={isSoCom} />
        {showCMRCalculator && (
          <div
            className={`cmrCalculator-container  ${
              layout === 'hardline' ? 'fa--cmr-calculator-hardline__mobile' : 'fa--cmr-calculator-softline__mobile'
            }`}
          >
            <CMRCalculatorMobile errorMessage={labels.CMR_INSTALLMENT_ERROR_MESSAGE} />
          </div>
        )}
        {layout === 'hardline' && (
          <>
            <ReturnPolicy returnPolicy={product.returnPolicy} darSectionActive={darSectionActive} />

            {!isImprovedServicesUiEnabled && !isImprovedWarrantyUiEnabled && (
              <>
                <ProductServices serviceOptions={serviceOptions} setServiceOption={setServiceOption} />
                <Warranty
                  warrantyOptions={warrantyOptions}
                  setWarrantyOption={setWarrantyOption}
                  startsFromLabel={labels.PDP_STARTS_FROM}
                  tooltipToggle={isWarrantyTooltipEnabled}
                  additionalWarrantyLabel={additionalWarrantyLabel}
                  sellerName={sellerName}
                />
              </>
            )}
          </>
        )}
      </div>
      <style jsx>{GetSharedDetailsStyles}</style>
    </>
  );
};

GetSharedDetails.defaultProps = {
  setServiceOption: () => {},
  setWarrantyOption: () => {},
  isWarrantyTooltipEnabled: false,
  additionalWarrantyLabel: false,
  isSoCom: false,
};

GetSharedDetails.propTypes = {
  sizes: PropTypes.array.isRequired,
  colorVariants: PropTypes.array.isRequired,
  regionCode: PropTypes.string.isRequired,
  store: PropTypes.string.isRequired,
  is1pSeller: PropTypes.bool.isRequired,
  showStoreAvailability: PropTypes.bool.isRequired,
  showCollectionButtonVisible: PropTypes.bool.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  comboData: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  showCMRCalculator: PropTypes.bool.isRequired,
  setServiceOption: PropTypes.func,
  setWarrantyOption: PropTypes.func,
  isWarrantyTooltipEnabled: PropTypes.bool,
  additionalWarrantyLabel: PropTypes.bool,
  layout: PropTypes.string.isRequired,
  isSoCom: PropTypes.bool,
  appCtx: PropTypes.object.isRequired,
};

export { GetSharedDetails };
