import { withTheme } from '@digital-retail/falabella-ui-cross-components';
import { isSodimacStandalone } from '@digital-retail/store-manager';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { withCart } from '../../../utils/CartContext';
import { changeTextCase } from '../../../utils/jsUtils';
import _ from '../../../utils/LodashImports';
import { withProduct } from '../../../utils/ProductContext';
import { getVariant } from '../../../utils/variant';
import FloorCalculatorWarning from '../../FloorCalculator/FloorCalculatorWarning/FloorCalculatorWarning';
import Button from '../../ui/Button/Button';
import { ProductCounter } from '../../ui/ProductCounter/ProductCounter';
import Stepper from '../../ui/Stepper/Stepper';
import Warning from '../../Warning/Warning';
import A2CPersistContainer from '../AddToCartPersistContainer';
import styles from './AddToCartButtonMobile.style';

const AddToCartButtonMobile = ({
  maxCartQuantity,
  labels,
  m2Object: { m2AttributeValue, m2ResultValue, m2Quantity, isExtraM2Checked, handleM2QuantityChange, m2Value },
  errorAddingToCart,
  isAddToCartInProgress,
  appCtx,
  handleAddToCart,
  isRemoveFromCartInProgress,
  handleRemoveFromCart,
  cart,
  product,
  setQuantityError,
  showQuantityError,
  inputVal,
  setInputVal,
  handleInputChange,
  sizes,
  selectedSize,
  theme,
}) => {
  const themedButtonStyle = styles(theme, appCtx.store);

  const isSizeSeleted = !!selectedSize;
  const { variants, id: productId, currentVariant: currentVariantId } = product;
  const currentVariant = getVariant(variants, currentVariantId);
  const offeringId = _.get(currentVariant, 'offerings[0]offeringId', '');
  const buyNowProductData = [
    {
      skuId: currentVariantId,
      productId,
      quantity: inputVal || 1,
      offeringId,
      isInternational: _.get(product, 'internationalShipping.applicable', false),
    },
  ];
  React.useEffect(() => {
    if (!cart.showCart) {
      setInputVal(1);
    }
  }, [cart.showCart]);

  React.useEffect(() => {
    setInputVal(1);
  }, [product.currentVariant]);

  const isFloorCalculatorEnabled = _.get(appCtx, 'siteConfig.toggles.isFloorcalculatorEnabled', true);
  const isOneClickButtonVisible = _.get(appCtx, 'siteConfig.toggles.isOneClickButtonVisible', false);
  const isFloorProduct = m2AttributeValue > 0 && isFloorCalculatorEnabled;
  const maxQuantityText = _.get(labels, 'MAX_QUANTITY_TEXT_PDP', 'Máximo {maxCartQuantity} unidades.').replace(
    '{maxCartQuantity}',
    maxCartQuantity
  );
  const maxQuantityWarning = _.get(
    labels,
    'MAX_QUANTITY_TEXT_PDP',
    'Solo puedes llevar {maxCartQuantity} unidades'
  ).replace('{maxCartQuantity}', maxCartQuantity);
  const isQuantityEditableEnabled = _.get(appCtx, 'siteConfig.toggles.isQuantityEditableEnabled', false);
  const isCartPersistanceEnabled = _.get(appCtx, 'siteConfig.toggles.isCartPersistanceEnabled', false);
  const isQuantitySelectorVisibleInPDP =
    _.get(appCtx, 'siteConfig.toggles.isQuantitySelectorVisibleInPDP', false) && !isCartPersistanceEnabled;

  const sellerId = _.get(product, 'variants[0].offerings[0].sellerId', '');
  const isBuyNowApplicable = cart.isBuyNowApplicable(_.get(appCtx, 'siteConfig.toggles'), sellerId);

  const getAddToCartText = () => {
    if (isBuyNowApplicable) {
      return _.get(labels, 'BUY_NOW_BUTTON_TEXT_MKP', 'Comprar Ahora');
    }
    if (isQuantitySelectorVisibleInPDP) {
      return labels.ADD_TO_CART_TEXT_ALONE;
    }
    return isSodimacStandalone(appCtx.store)
      ? _.get(labels, 'SO_COM_ADD_TO_CART_TEXT', 'Agregar al carro')
      : labels.ADD_TO_CART_TEXT;
  };
  const handleRemoveItem = () => {
    const value = parseInt(inputVal, 10);
    if (value - 1 <= maxCartQuantity) {
      setQuantityError(false);
    }
    setInputVal(value - 1);
    const eventToDigitalData = new CustomEvent('DDPDPRemoveQuantitySelector', {
      bubbles: true,
      detail: {
        value: value - 1,
      },
    });
    window.dispatchEvent(eventToDigitalData);
  };
  const handleInputBlurKey = (event) => {
    const { target } = event;
    const { value } = target;
    if (!value) {
      setInputVal('1');
    }
  };

  const handleAddItem = () => {
    const value = parseInt(inputVal, 10);
    if (value < maxCartQuantity) {
      setInputVal(value + 1);
      setQuantityError(false);
    } else {
      setQuantityError(true);
    }
    if (value + 1 >= maxCartQuantity) {
      setQuantityError(true);
    }
    const eventToDigitalData = new CustomEvent('DDPDPAddQuantitySelector', {
      bubbles: true,
      detail: {
        maxCartQuantity,
        value,
      },
    });
    window.dispatchEvent(eventToDigitalData);
  };

  const renderAddToCartButton = () => {
    if (isCartPersistanceEnabled) {
      return (
        <A2CPersistContainer
          isAddToCartInProgress={isAddToCartInProgress}
          maxCartQuantity={maxCartQuantity}
          handleRemoveFromCart={handleRemoveFromCart}
          isRemoveFromCartInProgress={isRemoveFromCartInProgress}
          handleAddToCart={handleAddToCart}
          labels={labels}
          getATCButtonText={getAddToCartText}
          inputVal={inputVal}
        />
      );
    }
    return (
      <div className="addToCart-button-container">
        <Button
          onClick={() => handleAddToCart(inputVal)}
          size="medium-large"
          type="mkp-primary"
          disabled={isAddToCartInProgress}
          extraClasses={cart.showBuyNowButton ? 'button-mkp-primary-xtra-large-outlined' : ''}
        >
          {isAddToCartInProgress ? (
            changeTextCase(labels.ADDING_TO_CART)
          ) : (
            <span className="add-to-cart-text">
              {isFloorProduct ? _.get(labels, 'ADD_REBRANDING', 'Agregar') : getAddToCartText()}
              {isQuantitySelectorVisibleInPDP && !isFloorProduct && !isBuyNowApplicable && (
                <div className="cart-icon" />
              )}
            </span>
          )}
        </Button>
        <style jsx>{themedButtonStyle}</style>
      </div>
    );
  };
  return (
    <>
      <div
        className={`add-quantity-and-add-to-cart-container ${cart.showBuyNowButton ? 'with-buy-now' : ''} ${
          isCartPersistanceEnabled ? 'cart-persist' : ''
        }`}
      >
        {errorAddingToCart && <Warning label={errorAddingToCart} iconSize="copy13" labelSize="copy3" />}
        {isFloorProduct && m2ResultValue > 0 && m2Quantity < m2ResultValue && (
          <FloorCalculatorWarning
            appCtx={appCtx}
            isExtraM2Checked={isExtraM2Checked}
            isMobile={false}
            m2Value={m2Value}
            m2ResultValue={m2ResultValue}
            m2Quantity={m2Quantity}
          />
        )}
        <div className="flex-wrapper">
          {isQuantitySelectorVisibleInPDP && !isFloorProduct && (
            <div className="add-quantity-container">
              <div className="counter">
                <ProductCounter count={inputVal} className="product-count-mobile">
                  <ProductCounter.Decrement
                    className="decrement-mobile"
                    minimum={1}
                    id="quantity-selector-decrement-btn"
                    onClick={handleRemoveItem}
                    type="primary"
                  />
                  {isQuantityEditableEnabled ? (
                    <ProductCounter.CountInput
                      className="product-count-value-mobile"
                      handleOnChange={handleInputChange}
                      maximum={maxCartQuantity}
                      minimum={1}
                      handleInputBlurKey={handleInputBlurKey}
                      id="quantity-selector-increment-input"
                    />
                  ) : (
                    <ProductCounter.CountText type="primary" />
                  )}
                  <ProductCounter.Increment
                    className="increment-mobile"
                    id="quantity-selector-increment-btn"
                    maximum={maxCartQuantity}
                    onClick={handleAddItem}
                    type="primary"
                  />
                </ProductCounter>
              </div>
              <div className="max-units-text">{maxCartQuantity && <p>{maxQuantityText}</p>}</div>
            </div>
          )}
          <div className="btns-action-wrapper">
            <div
              className={clsx(
                'addToCart-container',
                'fa--add-to-cart__mobile',
                {
                  'in-floor-calc': isFloorProduct,
                },
                {
                  'quantity-input': isQuantitySelectorVisibleInPDP && !isFloorProduct,
                }
              )}
            >
              <div className={clsx({ 'add-to-cart-floor': isFloorProduct })}>
                {isFloorProduct && !cart.showBuyNowButton && (
                  <div className="quantity-selector">
                    <Stepper
                      type="secondary"
                      minimum={1}
                      maximum={maxCartQuantity}
                      count={m2Quantity}
                      incrementButtonId="testId-floorCalc-stepper-increment-btn"
                      decrementButtonId="testId-floorCalc-stepper-decrement-btn"
                      editable={isQuantityEditableEnabled}
                      onInputValueChange={handleM2QuantityChange}
                      inputMaxLength={3}
                    />
                  </div>
                )}
                {renderAddToCartButton()}
              </div>
            </div>
            {cart.showBuyNowButton && (
              <Button
                key="add-to-cart-button"
                onClick={() =>
                  cart
                    .addProduct({
                      appCtx,
                      products: buyNowProductData,
                      isBuyNowApplicable: true,
                      product,
                    })
                    .then((res) => {
                      // here. if we have a res, we have an error. .apply fallback
                      if (res && res.length) {
                        cart.addProduct({
                          appCtx,
                          products: buyNowProductData,
                          product,
                        });
                      }
                    })
                }
                size="medium-large"
                id="buy-now-button"
                type="mkp-primary"
                disabled={sizes.length > 0 && !isSizeSeleted}
                extraClasses={isOneClickButtonVisible ? '' : 'btn-hidden'}
              >
                {_.get(labels, 'BUY_NOW_BUTTON_TEXT', 'Comprar ahora')}
              </Button>
            )}
          </div>
        </div>
        {showQuantityError && (
          <div className="limit-execed-text">
            <div className="warning-icon" />
            <p>{maxQuantityWarning}</p>
          </div>
        )}
      </div>
      <style jsx>{themedButtonStyle}</style>
    </>
  );
};

AddToCartButtonMobile.defaultProps = {
  isAddToCartInProgress: false,
  isRemoveFromCartInProgress: false,
  appCtx: {},
  maxCartQuantity: 999,
  errorAddingToCart: '',
  cart: {},
  product: {},
  m2Object: {
    m2AttributeValue: -1,
  },
  sizes: [],
  selectedSize: '',
  theme: null,
};

AddToCartButtonMobile.propTypes = {
  maxCartQuantity: PropTypes.number,
  labels: PropTypes.object.isRequired,
  m2Object: PropTypes.object,
  errorAddingToCart: PropTypes.string,
  isAddToCartInProgress: PropTypes.bool,
  appCtx: PropTypes.object,
  handleAddToCart: PropTypes.func.isRequired,
  handleRemoveFromCart: PropTypes.func.isRequired,
  isRemoveFromCartInProgress: PropTypes.bool,
  cart: PropTypes.object,
  product: PropTypes.object,
  setInputVal: PropTypes.func.isRequired,
  setQuantityError: PropTypes.func.isRequired,
  showQuantityError: PropTypes.bool.isRequired,
  inputVal: PropTypes.number.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  sizes: PropTypes.array,
  selectedSize: PropTypes.string,
  theme: PropTypes.object,
};

export default withCart(withProduct(withTheme(AddToCartButtonMobile)));
export { AddToCartButtonMobile };
