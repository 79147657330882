const helpers = {
  getValidZoomOffset: ({ X, Y, imageWidth, imageHeight, clientWidth, clientHeight }) => {
    let x = X;
    let y = Y;

    if (X > 0) {
      // Left Clip
      x = 0;
    } else if (-X > imageWidth - clientWidth) {
      // Right Clip
      x = clientWidth - imageWidth;
    }

    if (Y > 0) {
      // Top Clip
      y = 0;
    } else if (-Y > imageHeight - clientHeight) {
      // Bottom Clip
      y = clientHeight - imageHeight;
    }

    return [x, y];
  },
  getNewZoomLevel: ({ currentZoom = 0, currentDistance, newDistance, scale, minZoom, maxZoom }) => {
    const zoom = currentZoom + (newDistance - currentDistance) * scale;

    if (zoom < minZoom) {
      return minZoom;
    }
    if (zoom > maxZoom) {
      return maxZoom;
    }

    return zoom;
  },
  getNewZoomOffset: ({ zoom, currentX, currentY, pinchX, pinchY, offsetX, offsetY }) => {
    const X = -currentX * ((zoom - pinchX) / (pinchX || 1)) + offsetX;
    const Y = -currentY * ((zoom - pinchY) / (pinchY || 1)) + offsetY;

    return [X, Y];
  },
  getEuclideanDistance: (x1, y1, x2, y2) => {
    return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
  },
  getZoomCenter: ({ pageX, pageY, topOffset, zoomSize, containerWidth, containerHeight }) => {
    const x = pageX;
    const y = pageY - topOffset;

    const X = -x * ((zoomSize - containerWidth) / containerWidth);
    const Y = -y * ((zoomSize - containerHeight) / containerHeight);

    return [X, Y];
  },
};

export default helpers;
