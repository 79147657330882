import { ZoneModal } from '@digital-retail/falabella-ui-cross-components';
import { isSodimacStandalone } from '@digital-retail/store-manager';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import _ from '../../../utils/LodashImports';
import SelectVariant from '../../Cart/SelectVariant/SelectVariant';
import Button from '../../ui/Button/Button';
import StoreDetail from '../StoreDetail/StoreDetail';
import StoresList from '../StoresList/StoresList';

const ModalContent = ({
  actions,
  appCtx,
  cart,
  setVariantSelected,
  screen,
  config,
  setModalVisible,
  reloadOnZoneChange,
  modalVisible,
  selectedZone,
  zoneConfig,
  setZoneConfig,
  setIsZoneSet,
  loading,
  storesList,
  fetchStores,
  setScreen,
  selectedStore,
  setSelectedStore,
  comunaName,
}) => {
  const { isSizeSelected } = cart;
  const { deviceType, regionCode } = appCtx;
  const stockButton = () => {
    const btnText = _.get(appCtx, 'siteConfig.textDictionary.SEE_STORES_REVISAR_STOCK_BTN', 'Revisar stock');
    return isSizeSelected ? (
      <Button
        id="testId-revisar-stock"
        onClick={() => {
          setVariantSelected(true);
          if (comunaName) {
            setScreen('screen3');
          } else {
            setScreen('screen2');
          }
        }}
        size="medium"
        type="mkp-secondary"
        key="select-variant-add-to-cart"
        strech="auto"
        letterSpace="small"
      >
        {btnText}
      </Button>
    ) : (
      <Button disabled size="medium" type="mkp-secondary" key="select-variant-selected-add-to-cart" letterSpace="small">
        {btnText}
      </Button>
    );
  };
  const handleOnClose = () => {
    setModalVisible(false);
  };
  const saveZones = (data) => {
    setIsZoneSet(true);
    setZoneConfig(data);
    fetchStores(data);
    setScreen('screen3');
    // CookieStorage.setCookie({
    //   name: constants.STORE_MODAL_REGION_DATA,
    //   value: JSON.stringify(data),
    //   regionCode
    // });
  };

  const workingHours = {
    falabella: _.get(appCtx, 'siteConfig.endpoints.WORKING_HOURS_TIENDA', ''),
    sodimac: _.get(appCtx, 'siteConfig.endpoints.WORKING_HOURS_SODIMAC', ''),
    tottus: _.get(appCtx, 'siteConfig.endpoints.WORKING_HOURS_TOTTUS', ''),
  };

  const getModalContent = () => {
    switch (screen) {
      case 'screen1': {
        return <SelectVariant cart={cart} config={config} handleOnClose={handleOnClose} stockButton={stockButton} />;
      }
      case 'screen2': {
        return (
          <ZoneModal
            deviceType={deviceType}
            tenant={regionCode}
            entry={{}}
            handleRouteChange={reloadOnZoneChange}
            isBrandingMarketplace
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            isOnboardingInProgress={false}
            isStore={!!appCtx.store}
            enableSisThemedBorder
            isSodimacStandAlone={isSodimacStandalone(appCtx.store)}
            isDefaultComuna={false}
            selectedZone={zoneConfig.comuna ? zoneConfig.comuna : selectedZone}
            isPopOverVisible={false}
            stockEnTienda={{
              title: _.get(appCtx, 'siteConfig.textDictionary.STORE_AVAILABILITY_TITLE', 'Stock en tienda'),
              bannerText: {
                desktop: _.get(
                  appCtx,
                  'siteConfig.textDictionary.STORE_AVAILABILITY_DESKTOP',
                  'Selecciona tu ubicación para ver las tiendas más cercanas:'
                ),
                mobile: _.get(
                  appCtx,
                  'siteConfig.textDictionary.STORE_AVAILABILITY_MOBILE',
                  'Selecciona región y comuna:'
                ),
              },
              zoneConfig,
              setZoneConfig: saveZones,
              actions: {
                close: () => actions.close(),
              },
            }}
          />
        );
      }
      case 'screen3': {
        return (
          <StoresList
            selectedZone={selectedZone}
            config={config}
            loading={loading}
            storesList={storesList}
            fetchStores={fetchStores}
            setScreen={setScreen}
            selectedStore={selectedStore}
            setSelectedStore={setSelectedStore}
            zoneConfig={zoneConfig}
            actions={{
              close: () => actions.close(),
            }}
          />
        );
      }
      case 'screen4': {
        return (
          <StoreDetail
            deviceType={deviceType}
            config={config}
            selectedStore={selectedStore}
            setScreen={setScreen}
            labels={_.get(appCtx, 'siteConfig.textDictionary', {})}
            workingHours={workingHours}
          />
        );
      }
      default: {
        return <div />;
      }
    }
  };

  return (
    <div className="container">
      <div className="resultContainer">{getModalContent()}</div>
    </div>
  );
};

ModalContent.propTypes = {
  actions: PropTypes.object.isRequired,
  cart: PropTypes.object,
  appCtx: PropTypes.object,
  setSelectedStore: PropTypes.func.isRequired,
  screen: PropTypes.string.isRequired,
  config: PropTypes.object,
  zoneConfig: PropTypes.object,
  selectedZone: PropTypes.object,
  selectedStore: PropTypes.object,
  storesList: PropTypes.array,
  fetchStores: PropTypes.func,
  setModalVisible: PropTypes.func.isRequired,
  setIsZoneSet: PropTypes.func.isRequired,
  setScreen: PropTypes.func.isRequired,
  setVariantSelected: PropTypes.func.isRequired,
  setZoneConfig: PropTypes.func.isRequired,
  reloadOnZoneChange: PropTypes.func,
  loading: PropTypes.bool,
  modalVisible: PropTypes.bool,
  comunaName: PropTypes.string,
};

ModalContent.defaultProps = {
  config: '',
  comunaName: '',
  fetchStores: noop,
  reloadOnZoneChange: noop,
  loading: false,
  modalVisible: false,
  zoneConfig: {
    region: {},
    comuna: {},
  },
  selectedZone: {},
  selectedStore: {},
  storesList: [],
  cart: {},
  appCtx: {},
};

export default withApplicationContext(ModalContent);
export { ModalContent };
