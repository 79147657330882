import { Breadcrumbs } from '@digital-retail/falabella-ui-cross-components';
import PropTypes from 'prop-types';
import React from 'react';
import CMRApertura from '../../../components/CMRApertura/CMRApertura';
import ComboItemsMobile from '../../../components/ComboSection/ComboItemsMobile';
import ImageGallery from '../../../components/ImageGallery/ImageGallery';
import ProductOutOfStock from '../../../components/ProductOutOfStock/ProductOutOfStock';
import ProductSpecificationConnect from '../../../components/ProductSpecificationConnect/ProductSpecificationConnect';
import ProductTopSpecifications from '../../../components/ProductTopSpecifications/ProductTopSpecifications';
import SellerRatings from '../../../components/SellerRatings/SellerRatings';
import constants from '../../../config/constants';
import { RatingAndReviews } from '../../../external_dependencies/ratingAndReviews/RatingAndReviews';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withLabels } from '../../../utils/LabelsContext';
import _ from '../../../utils/LodashImports';
import * as productUtils from '../../../utils/product/productUtils';
import { withProduct } from '../../../utils/ProductContext';
import { withUser } from '../../../utils/UserContext';
import { getVariant } from '../../../utils/variant';
import Helpline from '../../Helpline/HelpLine';
import ProductSpecifications from '../../ProdctSpecifications/ProductSpecifications';
import ConnectTab from '../ConnectTab/ConnectTab';
import PDPBasicInfoMobile from './containers/PDPBasicInfoMobile/PDPBasicInfoMobileHardline';
import HardLineStyle from './HardLineMobile.style';

const PDPMobile = ({ labels, product, isVendorLogin, appCtx }) => {
  const {
    isOutOfStock,
    breadCrumb,
    name: productName,
    variants,
    currentVariant: currentVariantId,
    cmrBannerActive = true,
  } = product;
  const isSoCom = appCtx.store === constants.STORES.so_com;
  const showCMRApertura = isSoCom
    ? _.get(appCtx, 'siteConfig.toggles.isCMRAperturaEnabledForSoCom', false)
    : _.get(appCtx, 'siteConfig.toggles.isCMRAperturaEnabled', false);

  const isSellerRatingsEnabled = _.get(appCtx, 'siteConfig.toggles.isSellerRatingsEnabled', true);

  const currentVariant = getVariant(variants, currentVariantId);
  const comboData = _.get(currentVariant, 'comboData', null);
  const { prices, cmrSavings } = currentVariant;

  const isConnectProduct = productUtils.isConnectProduct(product);
  const tabData = [
    {
      title: () => labels.TEAM,
      content: () => <ProductSpecifications />,
    },
    {
      title: () => labels.PLANES,
      content: () => <ProductSpecificationConnect />,
    },
  ];
  const renderAgentLoginView = () => {
    const connectTabData = [
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? <span className="icon internet-icon-active" /> : <span className="icon internet-icon-disabled" />}
            <p>INTERNET</p>
            <style jsx>{HardLineStyle}</style>
          </div>
        ),
        content: () => <ConnectTab variant="secondary" tabData={tabData} />,
      },
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? <span className="icon tienda-active" /> : <span className="icon tienda-disabled" />}
            <p>TIENDA</p>
            <style jsx>{HardLineStyle}</style>
          </div>
        ),
        content: () => <ConnectTab variant="secondary" tabData={tabData} />,
      },
    ];
    const normalTabData = [
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? <span className="icon internet-icon-active" /> : <span className="icon internet-icon-disabled" />}
            <p>INTERNET</p>
            <style jsx>{HardLineStyle}</style>
          </div>
        ),
        content: () => <ProductSpecifications />,
      },
      {
        title: (flag) => (
          <div className="flex">
            {!flag ? <span className="icon tienda-active" /> : <span className="icon tienda-disabled" />}
            <p>TIENDA</p>
            <style jsx>{HardLineStyle}</style>
          </div>
        ),
        content: () => <ProductSpecifications currentTab="Tienda" />,
      },
    ];
    return (
      <div>
        {isConnectProduct ? (
          <>
            <ProductTopSpecifications scollToElementId="productInfoContainer" />
            <ConnectTab tabData={connectTabData} />
          </>
        ) : (
          <ConnectTab tabData={normalTabData} />
        )}
      </div>
    );
  };
  const renderProductSpecifications = () => {
    return (
      <div>
        {isConnectProduct ? (
          <>
            <ProductTopSpecifications scollToElementId="productInfoContainer" />
            <ConnectTab variant="rebranding" tabData={tabData} />
          </>
        ) : (
          <ProductSpecifications />
        )}
      </div>
    );
  };
  const productSpecifications = () => {
    if (isOutOfStock) return <ProductOutOfStock />;
    if (!isVendorLogin) return renderProductSpecifications();
    if (isVendorLogin) return renderAgentLoginView();
    return null;
  };

  if (isOutOfStock) {
    return (
      <>
        <div className="pdp-body">
          <div className="pdp-body-container">
            <Breadcrumbs
              items={breadCrumb}
              productName={productName}
              isPdpPage
              tenant={appCtx.regionCode}
              host={appCtx.host}
              path={appCtx.url}
            />
            <div className="pdp-container out-of-stock">
              <section className="pdp-detail-section oos-wrapper">
                <ProductOutOfStock />
              </section>
              <div className="headline-image-and-details">
                <section className="pdp-image-section">
                  {/* modify this component for PDP image view */}
                  <ImageGallery />
                </section>
                <section className="pdp-detail-section">
                  <PDPBasicInfoMobile product={product} labels={labels} />
                  {renderProductSpecifications()}
                </section>
              </div>
              <div className="rating_summary-wrapper">
                <RatingAndReviews />
              </div>
              {comboData && (
                <div className="combo-wrapper">
                  <ComboItemsMobile comboData={comboData} />
                </div>
              )}
            </div>
          </div>
        </div>
        <style jsx>{HardLineStyle}</style>
      </>
    );
  }

  return (
    <>
      <div className="pdp-body">
        <div className="pdp-body-container">
          <Breadcrumbs
            items={breadCrumb}
            productName={productName}
            isPdpPage
            tenant={appCtx.regionCode}
            host={appCtx.host}
            path={appCtx.url}
          />
          <div className="pdp-container">
            <section className="pdp-detail-section">
              <PDPBasicInfoMobile product={product} labels={labels} />
            </section>
            <section className="pdp-image-section">
              {/* modify this component for PDP image view */}
              <ImageGallery deviceType="mobile" />
            </section>
            <section className="pdp-detail-section">{productSpecifications()}</section>
            <Helpline product={product} />
            {showCMRApertura && cmrBannerActive && <CMRApertura prices={prices} cmrSavings={cmrSavings} />}
            {isSellerRatingsEnabled && product.sellerInfo?.rating && <SellerRatings sellerInfo={product.sellerInfo} />}
          </div>
        </div>
      </div>
      <style jsx>{HardLineStyle}</style>
    </>
  );
};

PDPMobile.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object,
  isVendorLogin: PropTypes.object.isRequired,
};

PDPMobile.defaultProps = {
  appCtx: {},
};

export default withUser(withProduct(withLabels(withApplicationContext(PDPMobile))));
export { PDPMobile };
