import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import apiConfig from '../../config/api/apiConfig';
import constants from '../../config/constants';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withCart } from '../../utils/CartContext';
import { isWindow } from '../../utils/deviceChecker';
import httpService from '../../utils/httpService';
import _ from '../../utils/LodashImports';
import Logger from '../../utils/Logger';
import noop from '../../utils/noop';
import { withProduct } from '../../utils/ProductContext';
import WebStorage from '../../utils/WebStorage';
import { Modal } from '../ui/Modal/Modal';
import { getScreenConfig } from './index.config';
import { NearbyStoresStyles } from './index.style';
import ModalContent from './ModalContent/ModalContent';

const NearbyStores = ({ appCtx = {}, cart = {}, product = {}, colorVariants, sizes }) => {
  const { deviceType, reloadOnZoneChange, isDefaultComuna, store } = appCtx;
  const isSodimacStandAlone = store === constants.STORES.so_com;
  const { isOutOfStock } = product;
  const selectedZone = _.get(appCtx, 'comuna.data', undefined);
  const variants = _.get(product, 'variants', []);
  const viewTemplate = _.get(product, 'attributes.viewTemplate', '');
  const { isSizeSelected } = cart;
  const {
    STOCK_IN_STORE = 'Stock en tienda',
    NOT_AVAILABLE = 'No disponible',
    EXCLUSIVE_ONLINE = 'Exclusivo online',
    CHECK = 'Revisa stock en tienda',
  } = _.get(appCtx, 'siteConfig.textDictionary', {});

  const [screen, setScreen] = useState('');
  const [variantSelected, setVariantSelected] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [storesList, setStoresList] = useState([]);
  const [zoneConfig, setZoneConfig] = useState({
    region: {},
    comuna: {},
  });
  const [isZoneSet, setIsZoneSet] = useState(false);
  const [selectedStore, setSelectedStore] = useState({});
  const [hasModal, setHasModal] = useState(true);
  const currentVariant = variants.find((v) => v.id === product.currentVariant);
  const comunaName = _.get(zoneConfig, 'comuna.name', '');
  const screenConfig = getScreenConfig({ labels: _.get(appCtx, 'siteConfig.textDictionary', {}) })[screen] || {};
  const layoutType = product.layoutType || constants.DEFAULT_LAYOUT_TYPE;
  const isSoftline =
    layoutType === constants.PRODUCT_CONSTANTS.SOFTLINE && viewTemplate !== constants.VIEW_TEMPLATE.TYPE_A;
  const checkVariantSelection = () => {
    if (sizes && Array.isArray(sizes) && sizes.length) {
      return !!isSizeSelected;
    }
    if (colorVariants && Array.isArray(colorVariants) && colorVariants.length) {
      return true;
    }
    return false;
  };
  useEffect(() => {
    if (!modalVisible) {
      setVariantSelected(checkVariantSelection());
    }
  }, [isSizeSelected, sizes]);

  const fetchStores = async (data) => {
    let latitude = _.get(zoneConfig, 'comuna.latitude', 0);
    let longitude = _.get(zoneConfig, 'comuna.longitude', 0);
    if (data) {
      latitude = _.get(data.comuna, 'latitude', 0);
      longitude = _.get(data.comuna, 'longitude', 0);
    }
    if (isZoneSet && (checkVariantSelection() || variants.length === 1)) {
      setLoading(true);
      const offeringId = _.get(currentVariant, 'offerings[0].offeringId', '');
      const sellerId = _.get(currentVariant, 'offerings[0].sellerId', '');
      const url = apiConfig.getStoreAvailabilityUrl({
        offeringId,
        sellerId,
        latitude,
        longitude,
        regionCode: appCtx.regionCode,
      });
      try {
        const response = await httpService().get(url, {
          headers: {
            'x-commerce': 'Falabella',
            'x-cmref': 'product-discovery-web',
          },
        });
        const errors = _.get(response, 'data.alert.errors', []);
        const stores = _.get(response, 'data.stores', []);
        if (errors.length || !response.data || !stores.length) {
          setHasModal(true);
          setStoresList(stores);
        } else {
          setStoresList(stores);
          setSelectedStore(stores[0]);
          setHasModal(true);
        }
        const selectedComuna = _.get(data || zoneConfig, 'comuna.name', '');
        const selectedRegion = _.get(data || zoneConfig, 'region.name', '');
        const eventToDigitalData = new CustomEvent('DDPDPStoreAvailabilityMetrics', {
          bubbles: true,
          detail: {
            isStoreDataAvailable: stores.length > 0,
            selectedZone: {
              comuna: selectedComuna,
              region: selectedRegion,
            },
          },
        });
        window.dispatchEvent(eventToDigitalData);
      } catch (error) {
        Logger.error(`store availability fetch failed: ${error}`);
      }
      setLoading(false);
    }
  };

  let regionData;
  let comunaData;
  if (isWindow()) {
    regionData = WebStorage.getItem({
      name: 'regionData',
      fromPersistence: true,
    });
    comunaData = WebStorage.getItem({
      name: 'comunaData',
      fromPersistence: true,
    });
  }
  useEffect(() => {
    // const zoneData = CookieStorage.getCookie(constants.STORE_MODAL_REGION_DATA);
    if (regionData && comunaData && !isDefaultComuna) {
      try {
        setZoneConfig({
          region: JSON.parse(regionData),
          comuna: JSON.parse(comunaData),
        });
        setIsZoneSet(true);
      } catch (e) {
        setZoneConfig([]);
      }
    }
  }, [regionData, comunaData]);

  useEffect(() => {
    if (modalVisible) {
      fetchStores();
    }
  }, [modalVisible, isZoneSet, variantSelected]);

  useEffect(() => {
    setHasModal(true);
  }, [currentVariant, selectedZone]);

  const getScreen = () => {
    const isVariantSelected = checkVariantSelection();
    const testScenario = modalVisible
      ? variantSelected || (colorVariants.length && !sizes.length)
      : !!isVariantSelected;
    if (comunaName && isVariantSelected && variantSelected) {
      return 'screen3';
    }
    if (!(isVariantSelected && testScenario) && variants.length > 1) {
      return 'screen1';
    }
    if (!isZoneSet) {
      return 'screen2';
    }
    return 'screen3';
  };

  const getContent = (actions = {}) => (
    <ModalContent
      actions={actions}
      cart={cart}
      setVariantSelected={setVariantSelected}
      screen={screen}
      config={screenConfig}
      setModalVisible={setModalVisible}
      reloadOnZoneChange={reloadOnZoneChange}
      modalVisible={modalVisible}
      selectedZone={selectedZone}
      zoneConfig={zoneConfig}
      setZoneConfig={setZoneConfig}
      setIsZoneSet={setIsZoneSet}
      loading={loading}
      storesList={storesList}
      setScreen={setScreen}
      fetchStores={fetchStores}
      selectedStore={selectedStore}
      comunaName={comunaName}
      setSelectedStore={setSelectedStore}
    />
  );

  const openModal = () => {
    if (!hasModal) {
      return;
    }
    setModalVisible(true);
  };

  useEffect(() => {
    setScreen(getScreen());
  }, [sizes, comunaName, selectedZone, isZoneSet]);
  const renderStoreAvaialbityUI = () => {
    if (!hasModal) {
      return (
        <>
          <span className="no-click">
            <span className="red">{NOT_AVAILABLE}</span>
            <span> - {EXCLUSIVE_ONLINE}</span>
          </span>
          <style jsx>{NearbyStoresStyles}</style>
        </>
      );
    }
    if (!comunaName) {
      return (
        <>
          <span className="underline">{STOCK_IN_STORE}</span>
          <style jsx>{NearbyStoresStyles}</style>
        </>
      );
    }
    return (
      <>
        <span className="underline">{CHECK}</span>
        <style jsx>{NearbyStoresStyles}</style>
      </>
    );
  };
  const checkIconClass = () => {
    let iconClass;
    if (hasModal) {
      iconClass = isSodimacStandAlone ? 'so-com-see-store' : 'see-stores';
    } else {
      iconClass = isSodimacStandAlone ? 'so-com-see-store-disabled' : 'see-stores-disabled';
    }
    return iconClass;
  };

  const isSoftProduct = product.productType === constants.PRODUCT_TYPE.SOFT_GOOD;
  const isGiftCard = product.productType === constants.PRODUCT_TYPE.GIFTCARD;

  return (
    <>
      {hasModal && modalVisible && (
        <Modal
          content={getContent}
          showCloseButton={screenConfig.showCloseButton}
          withPadding={screenConfig.withPadding}
          fullScreen={deviceType !== 'desktop'}
          deviceType={deviceType}
          className="relative"
          closeBtnStyles={deviceType === 'desktop' ? { top: '23px', right: '25.52px' } : { top: '15px', right: '15px' }}
          stopScroll
          options={{
            width: deviceType === 'desktop' ? constants.STORE_AVAILABILITY_MODAL_WIDTH[screen] : '100vw',
            height: deviceType === 'desktop' ? constants.STORE_AVAILABILITY_MODAL_HEIGHT[screen] : '100vh',
            overflow: 'hidden',
            disableOutsideClick: true,
          }}
          toggleState={{
            visible: modalVisible,
            setVisible: setModalVisible,
          }}
        />
      )}
      <div
        id="testId-open-store-availability-modal-mobile"
        role="button"
        tabIndex="-1"
        onKeyDown={noop}
        onClick={hasModal && openModal}
        className={`availability-item rebranded ${!!isSoftline && 'softline-product'} ${
          isOutOfStock && 'out-of-stock'
        } ${isGiftCard || isSoftProduct ? 'flex-product-large' : 'flex-product-standard'}`}
      >
        <div className={`icon ${checkIconClass()}`} />
        <div className="content">
          <div
            id="testId-open-store-availability-modal-desktop"
            role="button"
            tabIndex="-1"
            onKeyDown={noop}
            className="content-sub"
          >
            <div>{renderStoreAvaialbityUI()}</div>
          </div>
        </div>
      </div>
      <style jsx>{NearbyStoresStyles}</style>
    </>
  );
};

NearbyStores.propTypes = {
  appCtx: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired,
  colorVariants: PropTypes.array,
  sizes: PropTypes.array,
};
NearbyStores.defaultProps = {
  colorVariants: [],
  sizes: [],
};

export default withApplicationContext(withCart(withProduct(NearbyStores)));
export { NearbyStores };
