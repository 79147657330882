import css from 'styled-jsx/css';
import { colors, icons } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const styles = css`
  .csicon-arrow_down_small_1 {
    min-width: 20px;
    width: 20px;
    height: 20px;
    background-image: url('${svgToDataUri(icons.chevronRightRebranded)}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    color: ${colors.black.shade1};
  }
  .addtional-info-wrapper {
    border-top: 1px solid ${colors.gray.shade36};
    border-radius: 3px;
    background-color: ${colors.white.shade1};
    padding: 12px 16px 12px 24px;
    min-height: 69px;
    align-items: center;
    max-width: 100vw;
    display: flex;
    p {
      width: 100%;
      padding-left: 16px;
      line-height: 19.2px;
      font-size: 1.6rem;
      font-weight: bold;
      color: ${colors.black.shade1};
    }
  }
  .icon {
    min-width: 34px;
    height: 36px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    @mixin mobileToTablet {
      min-width: 27.69px;
    }
    &.info-icon {
      background-size: 32px;
      background-image: url('${svgToDataUri(icons.infoIcon)}');
    }
  }
`;

export default styles;
