const findIndex = (list, callback) => {
  if (!list) {
    return -1;
  }

  if (Array.prototype.findIndex) {
    return list.findIndex(callback);
  }

  let index = 0;
  list.some((element) => {
    if (callback(element)) {
      return true;
    }
    index += 1;
    return false;
  });
  return index === list.length ? -1 : index;
};

// Replace with lodash isEmpty if available in the project
// import { isEmpty as _isEmpty } from 'lodash';
const isEmpty = (value) => {
  // Handle null/undefined, empty strings, and empty arrays
  if (value == null) return true;
  if (typeof value === 'string' || Array.isArray(value)) return value.length === 0;
  if (typeof value === 'object') return Object.keys(value).length === 0;
  return false;
};

/**
 * Transforms text case based on context
 * @param {string} label - The text to transform
 * @param {number} m2AttributeValue - Special flag for context-specific behavior
 * @returns {string} The transformed text
 */
const changeTextCase = (label, m2AttributeValue = -1) => {
  if (m2AttributeValue > 0) {
    return 'Agregar';
  }

  if (!label) return '';

  const lowerCaseLabel = label.toLowerCase();
  return lowerCaseLabel.charAt(0).toUpperCase() + lowerCaseLabel.slice(1);
};

/**
 * Toggles a class on the document body
 * @param {boolean} condition - Whether to add or remove the class
 * @param {string} name - The class name to toggle
 */
const toggleBodyClass = (condition, name) => {
  if (condition) {
    document.body.classList.add(name);
  } else {
    document.body.classList.remove(name);
  }
};

const allowOnlyNumbers = (value) => {
  let onlyDigitVal = '';
  for (let i = 0; i < value.length; ) {
    if (value.charCodeAt(i) >= 48 && value.charCodeAt(i) <= 57) {
      onlyDigitVal = `${onlyDigitVal}${value.charAt(i)}`;
    }
    i += 1;
  }
  return onlyDigitVal;
};

function capitalizeString(str = '') {
  // Split the string into an array of words
  const words = str.toLowerCase().split(' ');

  // Capitalize the first letter of each word
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back into a string
  return words.join(' ');
}

export { allowOnlyNumbers, capitalizeString, changeTextCase, findIndex, isEmpty, toggleBodyClass };
