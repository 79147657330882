import css from 'styled-jsx/css';
import { colors, icons } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const ReaconditionedContainerStyle = css`
  .reaconditioned-container {
    margin-top: 0;
    padding: 16px 0;
    width: 100%;
  }
  .reacond-message,
  .reacond-icon {
    display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
    .icon-buysection-reacond {
      background-image: url('${svgToDataUri(icons.reaconditionedIcon)}');
      background-repeat: no-repeat;
      width: 32px;
      height: 32px;
      display: inline-block;
    }
  }
  .reacond-text-available {
    font-size: 1.4rem;
    font-weight: 400;
    width: auto;
    float: left;
    span {
      font-weight: 700;
    }
  }
  .reacond-link-container {
    .more-info {
      color: ${colors.gray.shade15};
      border-bottom: 1px solid ${colors.gray.shade15};
      font-size: 13.333px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: pointer;
      font-family: inherit;
      text-decoration: none;
    }
    .csicon-right {
      color: ${colors.gray.shade15};
      width: 25px;
      height: 25px;
      font-size: 10px;
      margin-left: 6px;
    }
  }
`;

export { ReaconditionedContainerStyle };
