import PropTypes from 'prop-types';
import React from 'react';
import MatchMedia from '../../../components/ui/MatchMedia/MatchMedia';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withLabels } from '../../../utils/LabelsContext';
import noop from '../../../utils/noop';
import { withProduct } from '../../../utils/ProductContext';
import ProductSpecificationsDesktop from './Desktop/ProductSpecificationsDesktop';
import ProductSpecificationsMobile from './Mobile/ProductSpecificationMobile';

const SoftlineProductSpecifications = ({
  isPumAvailable,
  pumQuantity,
  primeShipping,
  colorVariants,
  selectedColorSwatchIndex,
  colorSwatchHandler,
  sizes,
  selectedSize,
  sizeChangeHandler,
  handleAddToCart,
  isAddToCartInProgress,
  handleRemoveFromCart,
  isRemoveFromCartInProgress,
  addToCartButtonVisible,
  showCollectionButtonVisible,
  isVendorLogin,
  storeSkuData,
  currentTab,
  errorAddingToCart,
  requestProductFromStore,
  setShowLoginForm,
  showLoginForm,
  setErrorAddingToCart,
  sizeChartData,
  fomo,
}) => {
  const specificationProps = {
    isPumAvailable,
    pumQuantity,
    primeShipping,
    colorVariants,
    selectedColorSwatchIndex,
    colorSwatchHandler,
    selectedSize,
    sizes,
    sizeChangeHandler,
    handleAddToCart,
    isAddToCartInProgress,
    handleRemoveFromCart,
    isRemoveFromCartInProgress,
    addToCartButtonVisible,
    showCollectionButtonVisible,
    isVendorLogin,
    storeSkuData,
    currentTab,
    errorAddingToCart,
    requestProductFromStore,
    setShowLoginForm,
    showLoginForm,
    setErrorAddingToCart,
    sizeChartData,
    fomo,
  };

  return (
    <MatchMedia
      content={{
        desktop: () => <ProductSpecificationsDesktop {...specificationProps} />,
        mobile: () => <ProductSpecificationsMobile {...specificationProps} />,
        tablet: () => <ProductSpecificationsMobile {...specificationProps} />,
      }}
    />
  );
};

SoftlineProductSpecifications.defaultProps = {
  isPumAvailable: false,
  pumQuantity: 0,
  primeShipping: false,
  colorVariants: [],
  selectedColorSwatchIndex: 0,
  colorSwatchHandler: noop,
  sizes: [],
  selectedSize: undefined,
  sizeChangeHandler: noop,
  handleAddToCart: noop,
  requestProductFromStore: noop,
  isAddToCartInProgress: false,
  handleRemoveFromCart: noop,
  isRemoveFromCartInProgress: false,
  addToCartButtonVisible: true,
  showCollectionButtonVisible: false,
  currentTab: '',
  errorAddingToCart: false,
  showLoginForm: false,
  sizeChartData: {},
  fomo: {},
};

SoftlineProductSpecifications.propTypes = {
  isPumAvailable: PropTypes.bool,
  pumQuantity: PropTypes.number,
  primeShipping: PropTypes.bool,
  colorVariants: PropTypes.array,
  selectedColorSwatchIndex: PropTypes.number,
  colorSwatchHandler: PropTypes.func,
  sizes: PropTypes.array,
  selectedSize: PropTypes.string,
  sizeChangeHandler: PropTypes.func,
  requestProductFromStore: PropTypes.func,
  handleAddToCart: PropTypes.func,
  isAddToCartInProgress: PropTypes.bool,
  handleRemoveFromCart: PropTypes.func,
  isRemoveFromCartInProgress: PropTypes.bool,
  addToCartButtonVisible: PropTypes.bool,
  showCollectionButtonVisible: PropTypes.bool,
  isVendorLogin: PropTypes.bool.isRequired,
  storeSkuData: PropTypes.object.isRequired,
  currentTab: PropTypes.string,
  errorAddingToCart: PropTypes.bool,
  setShowLoginForm: PropTypes.func.isRequired,
  setErrorAddingToCart: PropTypes.func.isRequired,
  showLoginForm: PropTypes.bool,
  sizeChartData: PropTypes.object,
  fomo: PropTypes.object,
};

export default withProduct(withLabels(withApplicationContext(SoftlineProductSpecifications)));
