const initCartFromPersistance =
  ({ cart, setCart }) =>
  ({ quantitiesMap, variants, product }) => {
    const { brandName, name, id: productId } = product;

    const initCartItems = variants.reduce((acc, variant) => {
      const skuQuantityInfo = quantitiesMap.get(variant.id);

      if (skuQuantityInfo) {
        const { quantity, cartLineId } = skuQuantityInfo;
        const { id: skuId, medias } = variant;

        acc.push({
          brandName,
          name,
          skuId,
          image: medias[0]?.url || '',
          productId,
          price: variant.prices,
          totalQuantity: quantity,
          cartLineId,
          offeringId: variant.id,
        });
      }
      return acc;
    }, []);

    if (initCartItems.length > 0) {
      setCart({
        ...cart,
        cartItems: initCartItems,
      });
    }
  };

export default initCartFromPersistance;
