import css from 'styled-jsx/css';
import { colors, icons, typography } from '../../../../config/styles';
import { svgToDataUri } from '../../../../utils/styles/svgMixins';

const ProductSpecificationsDesktopStyle = css`
  .product-specifications {
    display: flex;
    flex-direction: row;
    margin-top: 16px;
    &-column {
      width: 100%;
      height: auto;

      span {
        font-size: 14px;
        b {
          font-weight: bold;
          margin-left: 2px;
        }
      }
    }
    .fa--product-specifications-column__desktop {
      display: flex;
      flex-direction: column;
    }
  }

  .prices-container {
    width: 100%;
    margin-bottom: 16px;

    & > div {
      flex: 1 0 50%;
    }
  }

  .cmr-points {
    font-size: ${typography.xs2};
    margin-top: 11px;
    color: ${colors.black.shade7};
  }

  .boomIcon {
    background-image: url('${svgToDataUri(icons.boomIcon)}');
    background-repeat: no-repeat;
    width: 60px;
    height: 13px;
    margin: 10px 0;
    display: block;
  }

  .colorSwatches-container {
    border-bottom: 1px solid ${colors.gray.shade3};
  }

  .size-container {
    padding: 13px 0 24px 0;
  }

  .addToCart-container {
    margin-bottom: 30px;
    :global(.csicon-arrow_down_small, .csicon-arrow_right) {
      margin-left: 9px;
      font-size: ${typography.xs2};
    }
    &.rebranded {
      max-width: 300px;
      margin-bottom: 24px;
    }
  }
  .mkp-softline-size {
    padding-top: 17px;
  }
  .specifications-divider {
    border-bottom: 1px solid ${colors.gray.shade3};
  }
  .multipurpose-badge {
    width: 100%;
    margin-bottom: 24px;
  }
  .meatstickers-container {
    margin-bottom: 16px;
  }
  .delivery-options-wrapper {
    display: flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${colors.green.shade24};
    background: #fff;
    box-shadow: 0 2px 2px 0 rgba(29, 29, 29, 0.1);
  }
  .dlv-opt-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
  }
  .whitelabel {
    border: none;
    box-shadow: none;
  }
`;

export { ProductSpecificationsDesktopStyle };
