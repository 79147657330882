import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { clsx } from 'clsx';
import { BackgroundOverlayStyles } from './BackgroundOverlay.style';
import noop from '../../../utils/noop';

const BackgroundOverlay = ({ shade }) => {
  const overlayRef = useRef();

  return createPortal(
    <div
      ref={overlayRef}
      onKeyDown={noop}
      tabIndex="0"
      role="button"
      className={clsx(`background-overlay`, {
        [`shade-${shade}`]: shade && typeof shade === 'string',
      })}
    >
      <style jsx>{BackgroundOverlayStyles}</style>
    </div>,
    document.body
  );
};

BackgroundOverlay.defaultProps = {
  shade: null,
};

BackgroundOverlay.propTypes = {
  shade: PropTypes.oneOf(['lighter']),
};

export default BackgroundOverlay;
