import css from 'styled-jsx/css';
import { colors, icons } from '../../../config/styles';
import { svgToDataUri } from '../../../utils/styles/svgMixins';

const FloorCalculatorWarningStyles = css`
  .quantity-warning {
    display: flex;
    background: ${colors.yellow.shade4};
    border-radius: 4px;
    position: relative;
    width: 100%;
    padding: 10px;
    @mixin mobileToTablet {
      margin-bottom: 8px;
    }
    .warning-icon-container {
      padding-top: 2px;
      height: 19px;
      width: 19px;
      .warning-icon {
        height: 19px;
        width: 19px;
        background-image: url('${svgToDataUri(icons.floorCalcWarningIcon)}');
        background-size: contain;
        background-repeat: no-repeat;
        display: block;
      }
    }
    .warning-body {
      margin-left: 10px;
      p {
        font-family: Lato, sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: ${colors.black.shade10};
      }
      .bold-text {
        font-weight: bold;
      }
    }
  }
`;

export { FloorCalculatorWarningStyles };
