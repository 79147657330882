import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import constants from '../../../config/constants';
import { capitalizeString } from '../../../utils/jsUtils';
import { DeliveryOptionsSelectedTextStyle } from './DeliveryOptionsSelectedText.style';

const getTextsHomeDelivery = ({ arrivesTodayOrTomorrow, isFreeShippingApplicable, date, month, price, type }) => {
  if (arrivesTodayOrTomorrow && isFreeShippingApplicable) {
    const [labelType, labelDate] = type.split(' ');
    return {
      greenText: `${labelType} gratis ${labelDate}`,
      normalText: ``,
    };
  }
  if (!arrivesTodayOrTomorrow && isFreeShippingApplicable) {
    return {
      greenText: `Llega gratis`,
      normalText: `el ${date}/${month}`,
    };
  }
  if (arrivesTodayOrTomorrow) {
    return {
      greenText: `${type}`,
      normalText: `por ${price}`,
    };
  }
  if (!arrivesTodayOrTomorrow) {
    return {
      greenText: ``,
      normalText: `Llega el ${date}/${month} por ${price}`,
    };
  }
  return {
    greenText: ``,
    normalText: ``,
  };
};

const getTextsPickupInStore = ({ isFreeShippingApplicable, price = '', type, storeName, arrivesTodayOrTomorrow }) => {
  const [, labelDate] = type.split(' ');
  const regex = /(\d{2}\/\d{2})/;
  const dateMatch = type.match(regex);
  const formattedDate = dateMatch ? dateMatch[0] : '';

  if (isFreeShippingApplicable || price.toLowerCase().includes('gratis')) {
    if (arrivesTodayOrTomorrow) {
      return {
        greenText: `Retira gratis ${labelDate}`,
        normalText: `en ${storeName}`,
      };
    }
    if (formattedDate) {
      return {
        greenText: `Retira gratis`,
        normalText: `el ${formattedDate} en ${storeName}`,
      };
    }
  }

  if (arrivesTodayOrTomorrow) {
    return {
      greenText: `Retira ${labelDate}`,
      normalText: `por ${price} en ${storeName}`,
    };
  }
  if (formattedDate) {
    return {
      greenText: ``,
      normalText: `Retira el ${formattedDate} por ${price} en ${storeName}`,
    };
  }

  return {
    greenText: ``,
    normalText: ``,
  };
};

const getTexts = (props) => {
  const { shippingOptionType } = props;
  if (shippingOptionType === 'PickupInStore') {
    return getTextsPickupInStore(props);
  }
  return getTextsHomeDelivery(props);
};

const getFormattedHomeDeliveryText = (props) => {
  const {
    isFreeShippingApplicable,
    type,
    date,
    month,
    price,
    shippingOptionType,
    locationName,
    storeName,
    isError,
    stateOfStock,
  } = props;
  const isOutOfStock = stateOfStock === constants.AVAILABILITY_OUTOFSTOCK;
  if (isError || isOutOfStock) {
    const isHomeDelivery = shippingOptionType === 'HomeDelivery';
    const deliverOptionText = isHomeDelivery ? 'envío' : 'retiro';
    const location = isHomeDelivery ? locationName : storeName;
    const capitalizeLocation = location ? capitalizeString(location) : '';
    const errorAvailabilityText = `No disponible para ${deliverOptionText}`;
    const errorLocationText = ` en ${capitalizeLocation}`;

    return (
      <div className="delivery-option-selection-container">
        <p className="delivery-option-selection not-found">
          <span>{errorAvailabilityText}</span>
          {capitalizeLocation ? errorLocationText : ''}
        </p>
        <style jsx>{DeliveryOptionsSelectedTextStyle}</style>
      </div>
    );
  }
  const arrivesTodayOrTomorrow = /hoy|mañana/.test(type);
  const { greenText, normalText } = getTexts({
    isFreeShippingApplicable,
    date,
    month,
    price,
    type,
    locationName,
    storeName,
    arrivesTodayOrTomorrow,
    shippingOptionType,
  });
  return (
    <div className="delivery-option-selection-container">
      <p className="delivery-option-selection">
        <span className="bold green">{greenText}</span>
        <span>
          {greenText ? ' ' : ''} {normalText}
        </span>
      </p>
      <style jsx>{DeliveryOptionsSelectedTextStyle}</style>
    </div>
  );
};

getFormattedHomeDeliveryText.propTypes = {
  isFreeShippingApplicable: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  month: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  shippingOptionType: PropTypes.string.isRequired,
  locationName: PropTypes.string.isRequired,
  storeName: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  stateOfStock: PropTypes.string.isRequired,
};

function DeliveryOptionsSelectedText({ selectedDeliveryOption, availability }) {
  const type = _.get(selectedDeliveryOption, 'type', '');
  const date = _.get(selectedDeliveryOption, 'date', '');
  const month = _.get(selectedDeliveryOption, 'month', '');
  const price = _.get(selectedDeliveryOption, 'price', '');
  const isError = _.get(selectedDeliveryOption, 'isError', false);
  const locationName = _.get(selectedDeliveryOption, 'locationName', '');
  const storeName = capitalizeString(_.get(selectedDeliveryOption, 'storeName', ''));
  const isFreeShippingApplicable = _.get(selectedDeliveryOption, 'isFreeShippingApplicable', false);
  const { shippingOptionType, stateOfStock } = availability;

  return getFormattedHomeDeliveryText({
    type,
    date,
    month,
    price,
    isError,
    locationName,
    storeName,
    isFreeShippingApplicable,
    shippingOptionType,
    stateOfStock,
  });
}

DeliveryOptionsSelectedText.propTypes = {
  selectedDeliveryOption: PropTypes.object.isRequired,
  availability: PropTypes.object.isRequired,
};

export { getTextsHomeDelivery, getTextsPickupInStore };

export default DeliveryOptionsSelectedText;
