import { GoogleMaps, withTheme } from '@digital-retail/falabella-ui-cross-components';
import { clsx } from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { capitalizeString } from '../../../../utils/jsUtils';
import _ from '../../../../utils/LodashImports';
import noop from '../../../../utils/noop';
import { formatSlotsForMap } from '../utils';
import { DeliveryOptionsContentStyles } from './DeliveryOptionsContent.style';
import StoreListDetails from './StoresListDetails/StoreListDetails';

const DeliveryOptionsWithMaps = ({
  pickupInStoreData,
  setSelectedStoreId,
  selectedStoreId,
  theme,
  textDictionary,
  actions,
  savePickupInStoreOption,
  zoneConfig,
  // getPickupInStoreData,
  deviceType,
  setPickupInStoreData,
  onCambiar,
  setZoneConfig,
}) => {
  const mapRef = React.useRef(null);

  // const [showViewMore, setShowViewMore] = React.useState(false);
  // const [markerData, setMarkerData] = React.useState({
  //   lat: zoneConfig?.latitude,
  //   lng: zoneConfig?.longitude
  // });
  const [view, setView] = React.useState('map');
  const markersData = formatSlotsForMap(pickupInStoreData, selectedStoreId);
  const themedStoresListStyles = DeliveryOptionsContentStyles(theme);
  const title = _.get(textDictionary, 'WITHDRAWAL_AT_STORE', 'Retiro en un punto');
  const saveButtonLabel = _.get(textDictionary, 'SELECT_BUTTON', 'Seleccionar');
  const cancelButtonLabel = _.get(textDictionary, 'CANCEL_BUTTON', 'Cancelar');
  // const viewMoreStoresLabel = _.get(
  //   textDictionary,
  //   'VIEW_MORE_STORES',
  //   'Buscar en esta área'
  // );
  const isMobile = deviceType === 'mobile';

  const handleMarkerClick = (id) => {
    const item = document.getElementById(`testId-${id}`);
    if (item) {
      item.scrollIntoView({
        behavior: 'smooth',
        block: isMobile ? 'end' : 'center',
        inline: 'center',
      });
    }
    setSelectedStoreId(id);
  };
  const saveSelectedDeliveryOption = () => {
    savePickupInStoreOption();
    actions.close();
  };

  const closeModal = () => {
    actions.close();
  };

  const goToScreen2 = () => {
    onCambiar();
    setPickupInStoreData([]);
    setZoneConfig({});
  };
  return (
    <div className="container relative">
      <div className="header">
        <div className="icon-container">
          <i className="icon pickupInStore" />
        </div>
        <div className="heading">
          <p>{title}</p>
        </div>
      </div>
      <div
        role="button"
        id="screen3-zone-change"
        tabIndex={0}
        onKeyDown={noop}
        onClick={goToScreen2}
        className={clsx('zone-change', { 'list-view': view === 'list' })}
      >
        <span>
          {capitalizeString(zoneConfig.name)}, {capitalizeString(zoneConfig.parentStateName)}
        </span>
        <span className="arrow_down" />
      </div>
      <div className="view-button">
        <span
          role="button"
          tabIndex={0}
          onKeyDown={noop}
          onClick={() => setView('map')}
          className={clsx('map-view-btn', {
            active: view === 'map',
          })}
        />
        <span
          role="button"
          tabIndex={0}
          onKeyDown={noop}
          onClick={() => setView('list')}
          className={clsx('list-view-btn', {
            active: view === 'list',
          })}
        />
      </div>
      {view === 'map' && (
        <div className="relative">
          <GoogleMaps
            mapRef={mapRef}
            markersData={markersData}
            isMarkerInteractive
            handleMarkerClick={handleMarkerClick}
            selectedPinId={selectedStoreId}
            // handleDragEnd={handleDragEnd}
            containerStyle={{
              width: '100%',
              height: isMobile ? '100vh' : '270px',
              outline: 0,
            }}
            zoom={13}
            zoomControl={!isMobile}
            isSingleFingerScrollEnabled
          />
          {/* {showViewMore && (
            <button
              type="button"
              className="fetch-more-stores"
              onClick={() => {
                getPickupInStoreData({
                  ...zoneConfig,
                  latitude: markerData?.lat,
                  longitude: markerData?.lng
                });
              }}
            >
              {viewMoreStoresLabel}
            </button>
          )} */}
        </div>
      )}

      <StoreListDetails
        pickupInStoreData={pickupInStoreData}
        setSelectedStoreId={setSelectedStoreId}
        selectedStoreId={selectedStoreId}
        textDictionary={textDictionary}
        view={view}
        isMobile={isMobile}
      />
      <div className="flex-center">
        <button onClick={closeModal} type="button" className="cancel">
          {cancelButtonLabel}
        </button>
        <button disabled={!selectedStoreId} onClick={saveSelectedDeliveryOption} type="button" className="save-zone">
          {saveButtonLabel}
        </button>
      </div>
      <style jsx>{themedStoresListStyles}</style>
    </div>
  );
};

DeliveryOptionsWithMaps.propTypes = {
  pickupInStoreData: PropTypes.array,
  textDictionary: PropTypes.object.isRequired,
  setSelectedStoreId: PropTypes.func.isRequired,
  setZoneConfig: PropTypes.func.isRequired,
  selectedStoreId: PropTypes.any,
  theme: PropTypes.object.isRequired,
  zoneConfig: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  savePickupInStoreOption: PropTypes.func.isRequired,
  setPickupInStoreData: PropTypes.func.isRequired,
  onCambiar: PropTypes.func.isRequired,
  // getPickupInStoreData: PropTypes.func.isRequired,
  deviceType: PropTypes.string.isRequired,
};

DeliveryOptionsWithMaps.defaultProps = {
  pickupInStoreData: [],
  selectedStoreId: '',
};

export default withTheme(DeliveryOptionsWithMaps);
export { DeliveryOptionsWithMaps };
