import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'react-lazyload';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import getImagePath from '../../../utils/GetImages';
import _ from '../../../utils/LodashImports';
import { withProduct } from '../../../utils/ProductContext';
import { imageUrl as imageURLMaker } from '../../../utils/staticUrls';
import { getCFDefaultImage } from '../../ImageGallery/ImageGalleryHelper';
import ImageStyles from './Image.style';

const Image = ({
  id,
  width,
  height,
  alt,
  lazyLoad,
  appCtx,
  type = '',
  product,
  quality,
  useBasePath, // TOBE Deprecated
  useSrcSet,
  crop,
  hideLazyLoadPlaceHolder,
  inlineDimensions,
  imageUrl,
  carrouselImagePosition,
}) => {
  const { regionCode } = appCtx;
  // const [internetSpeed, setInternetSpeed] = useState(1);
  const variantId = id.includes('/') ? id.split('/')[1] : id;
  const isCFMediasEnabled = _.get(appCtx, 'siteConfig.toggles.isCFMediasEnabled', false);
  const mediaDomains = _.get(appCtx, 'siteConfig.configurations.mediaDomains', []);
  const fallBackImagesDomain = _.get(appCtx, 'siteConfig.textDictionary.FALLBACK_IMAGES_DOMAIN');
  const sellerName = _.get(product, 'variants[0].offerings[0].sellerName', '').toLowerCase();

  const imageSrc = getImagePath({
    variantId,
    regionCode,
    isCFMediasEnabled,
    sellerName,
    useBasePath,
    type,
    attr: {
      width,
      height,
      crop,
      quality,
    },
    url: imageUrl,
    mediaDomains,
    fallBackImagesDomain,
    // internetSpeed
  });

  const imageSrcSet = useSrcSet
    ? `${getImagePath({
        variantId,
        regionCode,
        useBasePath,
        type,
        isCFMediasEnabled,
        sellerName,
        attr: {
          width: width * 2,
          height: height * 2,
          crop,
          quality,
        },
        url: imageUrl,
        mediaDomains,
        fallBackImagesDomain,
      })} 2x`
    : '';

  const [src, setSrc] = useState(imageSrc);
  const [srcSet, setSrcSet] = useState(imageSrcSet);

  useEffect(() => {
    // setInternetSpeed(window?.navigator?.connection?.downlink);
    setSrc(imageSrc);
    setSrcSet(imageSrcSet);
  }, [variantId, imageUrl]);

  const onErrorFallback = ({ currentTarget }) => {
    /* eslint-disable no-param-reassign */
    currentTarget.onerror = null;
    currentTarget.src = isCFMediasEnabled
      ? getCFDefaultImage({ sellerName, regionCode, fallBackImagesDomain })
      : imageURLMaker('defaultImage.jpg');
    currentTarget.srcset = isCFMediasEnabled
      ? getCFDefaultImage({ sellerName, regionCode, fallBackImagesDomain })
      : imageURLMaker('defaultImage.jpg');
  };
  const getImage = () => {
    return (
      <>
        <img
          src={src}
          srcSet={srcSet}
          alt={alt}
          id={`testId-pod-image-${carrouselImagePosition || id}`}
          onError={onErrorFallback}
          width={inlineDimensions ? width : undefined}
          height={inlineDimensions ? height : undefined}
        />
        <style jsx>{ImageStyles}</style>
      </>
    );
  };

  return lazyLoad ? (
    <LazyLoad
      placeholder={
        !hideLazyLoadPlaceHolder && (
          <div
            className="place-holder"
            style={{
              height,
            }}
          />
        )
      }
    >
      {getImage()}
    </LazyLoad>
  ) : (
    getImage()
  );
};

Image.propTypes = {
  id: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  alt: PropTypes.string,
  lazyLoad: PropTypes.bool,
  appCtx: PropTypes.object.isRequired,
  quality: PropTypes.number,
  useBasePath: PropTypes.bool,
  useSrcSet: PropTypes.bool,
  crop: PropTypes.object,
  hideLazyLoadPlaceHolder: PropTypes.bool,
  inlineDimensions: PropTypes.bool,
  imageUrl: PropTypes.string,
  product: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['headline', 'thumbnail', 'comparative-pod', '']),
  carrouselImagePosition: PropTypes.number,
};

Image.defaultProps = {
  alt: '',
  id: '',
  lazyLoad: false,
  quality: 70,
  useBasePath: false,
  useSrcSet: true,
  crop: undefined,
  hideLazyLoadPlaceHolder: false,
  inlineDimensions: true,
  imageUrl: '',
  type: '',
  carrouselImagePosition: null,
};

export default withProduct(withApplicationContext(Image));
export { Image };
