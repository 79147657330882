import css from 'styled-jsx/css';
import { colors, typography } from '../../../config/styles';

const colorSwatchGlobalStyles = css.global`
  .swatch--container {
    display: flex;
    align-items: center;
    margin-top: 15px;
    flex-wrap: wrap;
    @mixin mobileToTablet {
      overflow: scroll;
      flex-wrap: initial;
      padding-right: 60px;
      &::after {
        content: '';
        width: 60px;
        height: 65px;
        position: absolute;
        right: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      }
    }

    @mixin tabletToDesktop {
      margin-top: 7px;
    }
    &.fixed-height {
      height: 22px;
    }

    &-PDP {
      width: 100%;
      @mixin tabletToDesktop {
        margin-top: 14px;
      }
      li {
        margin-right: 4px;
      }
    }
  }
  .swatch--container-2_GRID {
    margin-top: 11px;
  }

  .colorSwatch {
    &-small {
      height: 14px;
      width: 14px;
      cursor: pointer;
      margin: auto;
      border: 1px solid ${colors.gray.shade35};
      border-radius: 50%;
    }
    &-medium {
      height: 22px;
      width: 22px;
      cursor: pointer;
      margin: auto;
      border: 1px solid ${colors.gray.shade5};
      border-radius: 50%;
    }
    &-large {
      height: 30px;
      width: 30px;
      cursor: pointer;
      margin: auto;
      border: 1px solid ${colors.gray.shade5};
      border-radius: 50%;
    }
    @mixin tabletToDesktop {
      height: 16px;
      width: 16px;
    }
  }
  .colorSwatch-link {
    text-decoration: none;
    display: flex;
    line-height: 1px;
    border-radius: 50%;
    box-shadow: 0 0 0 2px ${colors.gray.shade5};
    border: 4px solid ${colors.white.shade1};
    margin: 0 0 0 4px;
    transition: all 0.3s ease-in-out;
    color: ${colors.black.shade1};
    :hover {
      box-shadow: 0 0 0 2px ${colors.black.shade1};
    }
  }
  .csicon-add {
    font-size: ${typography.xxs};
  }

  .colorSwatch-li {
    &-small {
      height: 22px;
      width: 22px;
      display: flex;
    }
    &-medium {
      height: 32px;
      width: 32px;
      display: flex;
    }
    &-large {
      height: 40px;
      width: 40px;
      display: flex;
      @mixin mobileToTablet {
        display: flex;
        flex: none;
      }
    }
  }

  .colorSwatch-li-selected {
    &-small {
      height: 22px;
      width: 22px;
      border-radius: 50%;
      border: 1px solid ${colors.gray.shade5};
      padding: 1px;
      display: flex;
    }
    &-medium {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      border: 2px solid ${colors.gray.shade5};
      padding: 1px;
      display: flex;
    }
    &-large {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      border: 2px solid ${colors.gray.shade5};
      padding: 1px;
      display: flex;
      @mixin mobileToTablet {
        display: flex;
        flex: none;
      }
    }
  }
`;

export default colorSwatchGlobalStyles;
