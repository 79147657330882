import css from 'styled-jsx/css';
import { colors, icons, typography } from '../../config/styles';
import { svgToDataUri } from '../../utils/styles/svgMixins';

const ProductNameStyle = css`
  .product-format {
    font-size: 13px;
    font-weight: 300;
    line-height: 20px;
  }
  .product-name {
    color: ${colors.black.shade1};
    font-size: 18px;
    font-weight: 300;
    letter-spacing: -0.3px;
    line-height: normal;
    margin-right: 15px;
    @mixin tabletToDesktop {
      font-size: 20px;
      line-height: normal;
    }
    &.out-of-stock {
      font-size: 18px;
      line-height: 22px;
      @mixin tabletToDesktop {
        font-size: 20px;
        line-height: normal;
      }
    }
  }
`;

const BrandNameStyle = css`
  .product-brand {
    height: 16px;
    font-size: ${typography.sm1};
    font-weight: bold;
    letter-spacing: 1.08px;
    line-height: 16px;
    text-transform: uppercase;
    &-link {
      color: ${colors.black.shade1};
      text-decoration: none;
    }
    &.out-of-stock {
      .product-brand-link {
        font-size: 14px;
      }
    }
  }
`;

const VariantIdStyle = css`
  .variant-id {
    color: ${colors.gray.shade44};
    font-size: 11px;
    letter-spacing: 0.2px;
    line-height: 13px;
    margin-top: 0;
  }
`;

const ReviewAndRatingsStyle = css`
  .more-comments-scroll {
    margin: auto 0;
    .pod-link {
      font-size: ${typography.base};
      font-weight: bold;
      letter-spacing: 0.14px;
      line-height: 17px;
      color: ${colors.green.shade10};
      margin-left: 10px;
      text-decoration: none;
      padding-bottom: 1px;
      border-bottom: 0.5px solid ${colors.green.shade10};

      @mixin desktop {
        border-bottom: 0;
      }
    }
  }
`;

const ShareIconStyle = css`
  .product-share-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-image: url('${svgToDataUri(icons.shareIconRebranded)}');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &::before {
      content: '';
    }
  }
  @media screen and (1025px <= width) {
    .product-share-icon {
      display: none;
    }
  }
`;

export { ProductNameStyle, ShareIconStyle, ReviewAndRatingsStyle, BrandNameStyle, VariantIdStyle };
