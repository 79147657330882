import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withApplicationContext } from '../../utils/ApplicationContext';
import { withLabels } from '../../utils/LabelsContext';
import InternationalBuyModal from '../SellerInfo/InternationalBuyModal/InternationalBuyModal';
import { Modal } from '../ui/Modal/Modal';
import { InternationalShippingStyle } from './InternationalShipping.style';

const InternationalShipping = ({ labels, appCtx }) => {
  const [intBuyToggle, setIntBuyToggle] = useState(false);
  const { regionCode, deviceType } = appCtx;
  const internationalDeliveryText = labels.INTERNATIONAL_DELIVERY || 'Compra International.';
  const props = {
    stopScroll: true,
    customClassBody: 'auto',
    withPadding: false,
    deviceType,
    ...(deviceType === 'desktop' && {
      closeBtnStyles: {
        top: '22px',
        right: '25px',
        fontSize: '11px',
      },
      options: {
        width: 500,
        height: 530,
        disableOutsideClick: false,
      },
    }),
    ...(deviceType === 'mobile' && {
      fullScreen: true,
      fullWidth: true,
      options: {
        overflow: 'hidden',
        disableOutsideClick: true,
        fullWidth: true,
      },
      closeBtnStyles: {
        top: '16px',
        right: '16px',
        fontSize: '15px',
      },
    }),
  };

  return (
    <div className="international_icon_wrapper">
      <span className="aeroplane-icon" />
      <p className="international-text">
        {internationalDeliveryText}
        <button type="button" className="more-info" onClick={() => setIntBuyToggle(!intBuyToggle)}>
          Conocer más
        </button>{' '}
        <i className="csicon-right" />
      </p>
      <Modal
        content={(actions) => <InternationalBuyModal actions={actions} deviceType={deviceType} tenant={regionCode} />}
        toggleState={{
          visible: intBuyToggle,
          setVisible: (bool) => setIntBuyToggle(bool),
        }}
        {...props}
      />
      <style jsx>{InternationalShippingStyle}</style>
    </div>
  );
};

InternationalShipping.propTypes = {
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
};

export default withApplicationContext(withLabels(InternationalShipping));
