import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CarouselControlButtonsStyles } from './CarouselControlButtons.style';
import noop from '../../../../utils/noop';

const CarouselControlButtons = ({
  onNextSlide,
  onPreviousSlide,
  navButtonsVisible,
  isForProductsCarousel,
  carouselPosition,
}) => {
  return (
    <>
      <button
        id={isForProductsCarousel ? `pdp-carousel-arrow-left-${carouselPosition}` : ''}
        type="button"
        className={`${isForProductsCarousel ? 'products-carousel-headline-control' : 'headline-control'} left ${
          navButtonsVisible ? '' : 'hide'
        } ${isForProductsCarousel ? 'secondary-buttons-left' : ''}`}
        onClick={onPreviousSlide}
      >
        <i className={`${isForProductsCarousel ? 'products-carousel-icon' : 'icon'} csicon-arrow_left`} />
      </button>

      <button
        id={isForProductsCarousel ? `pdp-carousel-arrow-right-${carouselPosition}` : ''}
        type="button"
        className={`${isForProductsCarousel ? 'products-carousel-headline-control' : 'headline-control'} right ${
          navButtonsVisible ? '' : 'hide'
        } ${isForProductsCarousel ? 'secondary-buttons-right' : ''}`}
        onClick={onNextSlide}
      >
        <i className={`${isForProductsCarousel ? 'products-carousel-icon' : 'icon'} csicon-arrow_right`} />
      </button>
      <style jsx>{CarouselControlButtonsStyles}</style>
    </>
  );
};

CarouselControlButtons.defaultProps = {
  onNextSlide: noop,
  onPreviousSlide: noop,
  navButtonsVisible: true,
  isForProductsCarousel: false,
  carouselPosition: 0,
};

CarouselControlButtons.propTypes = {
  onNextSlide: PropTypes.func,
  onPreviousSlide: PropTypes.func,
  navButtonsVisible: PropTypes.bool,
  isForProductsCarousel: PropTypes.bool,
  carouselPosition: PropTypes.number,
};

export default CarouselControlButtons;
