import PropTypes from 'prop-types';
import React from 'react';
import { withLabels } from '../../../utils/LabelsContext';
import noop from '../../../utils/noop';
import VariantAvailabilityOptions from '../../VariantAvailabilityOptions/VariantAvailabilityOptions';
import VariantHeader from '../../VariantHeader/VariantHeader';
import Warning from '../../Warning/Warning';
import Button from '../../ui/Button/Button';
import Stepper from '../../ui/Stepper/Stepper';
import { TypeAVariantMobileStyles } from './TypeAVariantMobile.style';

const TypeAVariantMobile = ({
  variantId,
  brandName,
  variantName,
  prices,
  addToCart,
  labels,
  count,
  incrementCount,
  decrementCount,
  maxForStepper,
  showAlert,
  isHomeDeliveryAvailable,
  isPickFromStoreAvailable,
}) => {
  const buttonType = 'mkp-secondary';
  return (
    <div className="typeAVariant mkp">
      <VariantHeader variantId={variantId} brandName={brandName} variantName={variantName} prices={prices} />

      <div className="typeAVariant-info">
        <div className="availability-options">
          <VariantAvailabilityOptions
            deviceType="mobile"
            isHomeDeliveryAvailable={isHomeDeliveryAvailable}
            isPickFromStoreAvailable={isPickFromStoreAvailable}
          />
        </div>
        <div className="addToCart">
          <Stepper
            count={count}
            onIncrement={incrementCount}
            onDecrement={decrementCount}
            minimum={0}
            maximum={maxForStepper}
          />
          <div className="addToCart-button mkp">
            {!showAlert ? (
              <Button size="medium-extend" type={buttonType} onClick={addToCart}>
                <span className="addToCart-button-text mkp">{labels.ADD_TO_BAG.toLowerCase()}</span>
              </Button>
            ) : (
              <Warning label={labels.MAXIMUM_QUANTITY_LIMIT_LABEL} iconSize="copy2" labelSize="copy5" />
            )}
          </div>
        </div>
      </div>
      <style jsx>{TypeAVariantMobileStyles}</style>
    </div>
  );
};

TypeAVariantMobile.defaultProps = {
  brandName: '',
  prices: [],
  addToCart: noop,
  showAlert: false,
  isHomeDeliveryAvailable: false,
  isPickFromStoreAvailable: false,
};

TypeAVariantMobile.propTypes = {
  brandName: PropTypes.string,
  variantName: PropTypes.string.isRequired,
  variantId: PropTypes.string.isRequired,
  labels: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  incrementCount: PropTypes.func.isRequired,
  decrementCount: PropTypes.func.isRequired,
  prices: PropTypes.arrayOf(PropTypes.object),
  addToCart: PropTypes.func,
  maxForStepper: PropTypes.number.isRequired,
  showAlert: PropTypes.bool,
  isHomeDeliveryAvailable: PropTypes.bool,
  isPickFromStoreAvailable: PropTypes.bool,
};

export default withLabels(TypeAVariantMobile);
