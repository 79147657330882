/* eslint-disable react/no-danger */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import constants from '../../../config/constants';
import defaultConfig from '../../../config/url/default.config';
import { withApplicationContext } from '../../../utils/ApplicationContext';
import { withConfig } from '../../../utils/ConfigurationContext';
import { withDigitalData } from '../../../utils/DigitalDataContext';
import { withLabels } from '../../../utils/LabelsContext';
import Tooltip, { TooltipText } from '../../ui/Tooltip/Tooltip';
import { getSellerClass, getSellerLink, getTooltipText, validateSellerName } from '../helpers';
import { SellerInfoDesktopStyle } from './SellerInfoDesktop.style';

const SellerInfoDesktop = ({ product, labels, appCtx, config, digitalData }) => {
  const { regionCode, store } = appCtx;
  const isSellerPopupEnabled = _.get(appCtx, 'siteConfig.toggles.isSellerPopupEnabled', false);
  const { ENVIRONMENT } = config;
  const { currentVariant, variants, isOutOfStock } = product;
  const variant = variants.find((item) => item.id === currentVariant);

  const sellerId = _.get(variant, 'offerings[0].sellerId', '');
  const sellerName = _.get(variant, 'offerings[0].sellerName', '');
  const sellerNameLower = sellerName && sellerName.toLowerCase();

  useEffect(() => {
    digitalData.pdp.setSoldBy(sellerNameLower);
  }, [sellerNameLower]);

  const shouldShowSellerInfo = _.get(appCtx, 'siteConfig.toggles.isSellerInfoVisibleInPDP', false);
  const isSISExperienceOn = _.get(appCtx, 'siteConfig.toggles.isSISExperienceOn', false);

  if (!sellerId || !sellerName || !shouldShowSellerInfo) {
    return null;
  }

  const sellerClass = getSellerClass({ constants, sellerNameLower });
  const sellerRedirectionMap = _.get(
    appCtx,
    'siteConfig.configurations.sellerRedirectionMap',
    defaultConfig.SELLER_REDIRECTION_MAP[ENVIRONMENT][regionCode]
  );
  const validatedSellerName = validateSellerName(sellerNameLower, regionCode);
  return (
    <div className={`sellerText rebranded ${isOutOfStock && 'out-of-stock'}`}>
      <div className="sellerInfoContainer fa--seller-info__desktop">
        <span>{labels.SELLER_SOLD_BY}&nbsp;</span>
        {sellerClass ? <span className={`${sellerClass}Icon`} /> : null}
        <a
          id="testId-SellerInfo-sellerName"
          href={getSellerLink({
            sellerClass,
            regionCode,
            sellerName: sellerClass === 'homecenter' ? validatedSellerName.toUpperCase() : sellerName,
            env: ENVIRONMENT,
            isSISExperienceOn,
            store,
            sellerRedirectionMap,
          })}
          className="link"
        >
          <span className="underline">{validatedSellerName}</span>
        </a>
        {isSellerPopupEnabled && (
          <Tooltip
            position="bottom-middle"
            hasCaret
            tooltipContent={
              <TooltipText
                text={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getTooltipText({
                        sellerName: sellerClass || sellerNameLower,
                        labels,
                        storePlaceholderText: constants.STORE_PLACEHOLDER,
                        sellerData: product.sellerInfo,
                        isNational: !_.get(product, 'internationalShipping.applicable', false),
                      }),
                    }}
                    id="seller-tooltip-text"
                  />
                }
                viewType="medium"
              />
            }
          >
            <i className="csicon-alert alertIcon">
              <span className="sr-only">Seller Info</span>
            </i>
          </Tooltip>
        )}
      </div>
      <style jsx>{SellerInfoDesktopStyle}</style>
    </div>
  );
};

SellerInfoDesktop.propTypes = {
  product: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  appCtx: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  digitalData: PropTypes.object.isRequired,
};

export default withApplicationContext(withLabels(withConfig(withDigitalData(SellerInfoDesktop))));
