import apiConfig from '../../config/api/apiConfig';
import { getSessionConfirmationNumber } from '../../utils/CartHelpers';
import httpService from '../../utils/httpService';
import {
  FETCH_INSTALLMENT,
  FETCH_INSTALLMENT_ERROR,
  FETCH_INSTALLMENT_SUCCESS,
  SAVE_CARD_INFO_PROFILE,
  SAVE_CARD_INFO_PROFILE_ERROR,
  SAVE_CARD_INFO_PROFILE_SUCCESS,
  SAVE_INSTALLMENT_INFO,
  SAVE_INSTALLMENT_INFO_ERROR,
  SAVE_INSTALLMENT_INFO_SUCCESS,
  SAVE_PROMOTION_INFO,
  SAVE_PROMOTION_INFO_ERROR,
  SAVE_PROMOTION_INFO_SUCCESS,
  UPDATE_INSTALLMENT_DETAILS,
} from './actions';

export const getProductQuantity = (cartItems, currentVariant) => {
  if (cartItems.length === 0) {
    return null;
  }
  const cartItem = cartItems.find((item) => item.skuId === currentVariant);
  return cartItem;
};

export const getCache = (cacheArr) => {
  return cacheArr.toString();
};

export const fetchCMRInstallments = async (
  { installmentNum, variantId, bankName, quantity },
  regionCode,
  cache,
  dispatch
) => {
  const url = apiConfig.getCMRCalculationUrl(regionCode);
  try {
    dispatch({ type: FETCH_INSTALLMENT });
    const {
      data: { success, errors = [], ...rest },
    } = await httpService().post(url, {
      skus: [
        {
          skuId: variantId,
          quantity,
        },
      ],
      installmentNum,
      bankName,
    });

    if (success && rest.state && Object.keys(rest.state).length > 0) {
      dispatch({
        type: FETCH_INSTALLMENT_SUCCESS,
        payload: { cache, ...rest },
      });
    } else {
      const [error = { message: 'Empty Response' }] = errors;
      dispatch({
        type: FETCH_INSTALLMENT_ERROR,
        payload: { error: error.message, isLoading: false },
      });
    }
  } catch (e) {
    dispatch({
      type: FETCH_INSTALLMENT_ERROR,
      payload: { error: e.message, isLoading: false },
    });
  }
};

export const getInstallmentStatement = (cmrData = {}, labels = {}) => {
  const { state = {}, textDictionary = {} } = cmrData;
  const { selectedInstallment = '', formattedTotalCAE, formattedInstallmentAmount = '' } = state;

  const { cuotasText = '', CAEText } = textDictionary;

  const totalCAE = formattedTotalCAE ? `${CAEText} ${formattedTotalCAE}.` : '';
  return selectedInstallment
    ? `${selectedInstallment} ${cuotasText} ${labels.OF} ${formattedInstallmentAmount}. ${totalCAE}`
    : '';
};

export const saveCardInfotoProfile = async (payload, dispatch, regionCode, cache) => {
  const { bankCardsDispatch, installmentDispatch } = dispatch;
  try {
    bankCardsDispatch({ type: SAVE_CARD_INFO_PROFILE });
    const sessionConfirmationNumber = await getSessionConfirmationNumber(regionCode);
    const { bankName, cardName, skus } = payload;
    if (sessionConfirmationNumber && bankName && cardName && skus.length) {
      const url = apiConfig.saveCardInfotoProfile(regionCode);

      const {
        data: { success, errors = [], ...rest },
      } = await httpService().post(url, {
        sessionConfirmationNumber,
        ...payload,
      });
      if (success) {
        bankCardsDispatch({
          type: SAVE_CARD_INFO_PROFILE_SUCCESS,
          payload: { ...rest, isLoading: false },
        });
        installmentDispatch({
          type: UPDATE_INSTALLMENT_DETAILS,
          payload: { cache, ...rest },
        });
      } else {
        const [error = {}] = errors;
        bankCardsDispatch({
          type: SAVE_CARD_INFO_PROFILE_ERROR,
          payload: { error: error.message, isLoading: false },
        });
      }
    }
  } catch (e) {
    bankCardsDispatch({
      type: SAVE_CARD_INFO_PROFILE_ERROR,
      payload: { error: e.message, isLoading: false },
    });
  }
};

export const savePromotions = async (payload, dispatch, regionCode, cache) => {
  const { bankCardsDispatch, installmentDispatch } = dispatch;
  try {
    const { selectedBankPromotionId, skus } = payload;
    bankCardsDispatch({ type: SAVE_PROMOTION_INFO });
    const sessionConfirmationNumber = await getSessionConfirmationNumber(regionCode);
    if (sessionConfirmationNumber && selectedBankPromotionId && skus.length) {
      const url = apiConfig.savePromotionInfotoProfile(regionCode);
      const {
        data: { success, errors, ...rest },
      } = await httpService().post(url, {
        sessionConfirmationNumber,
        ...payload,
      });

      if (success) {
        bankCardsDispatch({
          type: SAVE_PROMOTION_INFO_SUCCESS,
          payload: { ...rest, isLoading: false },
        });
        installmentDispatch({
          type: UPDATE_INSTALLMENT_DETAILS,
          payload: { cache, ...rest },
        });
      } else {
        const [error] = errors;
        bankCardsDispatch({
          type: SAVE_PROMOTION_INFO_ERROR,
          payload: { error: error.message, isLoading: false },
        });
      }
    }
  } catch (e) {
    bankCardsDispatch({
      type: SAVE_PROMOTION_INFO_ERROR,
      payload: { error: e.message, isLoading: false },
    });
  }
};

export const saveInstallmentInfoToProfile = async (payload, dispatch, regionCode) => {
  const { installmentInfoDispatch } = dispatch;
  try {
    installmentInfoDispatch({ type: SAVE_INSTALLMENT_INFO });
    const sessionConfirmationNumber = await getSessionConfirmationNumber(regionCode);
    const { selectedInstallment, skus } = payload;
    if (sessionConfirmationNumber && selectedInstallment && skus.length) {
      const url = apiConfig.saveInstallmentInfotoProfile(regionCode);
      const {
        data: { success, errors, ...rest },
      } = await httpService().post(url, {
        sessionConfirmationNumber,
        ...payload,
      });
      if (success) {
        installmentInfoDispatch({
          type: SAVE_INSTALLMENT_INFO_SUCCESS,
          payload: { ...rest, isLoading: false },
        });
      } else {
        const [error] = errors;
        installmentInfoDispatch({
          type: SAVE_INSTALLMENT_INFO_ERROR,
          payload: { error: error.message, isLoading: false },
        });
      }
    }
  } catch (e) {
    installmentInfoDispatch({
      type: SAVE_INSTALLMENT_INFO_ERROR,
      payload: { error: e.message, isLoading: false },
    });
  }
};

export const getCardName = (bankCards, bankName) => {
  const result = bankCards.find((bankCard) => bankCard.bank.value === bankName);
  if (result) {
    return result.cards;
  }
  return [];
};
