import PropTypes from 'prop-types';
import React from 'react';
import reviewStyle from './Reviews.style';

const Reviews = ({ value, layout }) => {
  return (
    <span data-rating={value} className={`reviewCount reviewCount-${layout}`}>
      {`(${value})`}
      <style jsx>{reviewStyle}</style>
    </span>
  );
};

Reviews.defaultProps = {
  layout: '4_GRID',
};

Reviews.propTypes = {
  value: PropTypes.number.isRequired,
  layout: PropTypes.string,
};

export default Reviews;
